
import { Link, useNavigate } from "react-router-dom";
import imagenesEditProject from "../../assets/imgEditProject/imgEditProject";
import CreateProject from "../../components/createProject/createProject";


import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Project } from "../../Interfaces/Project";

import Select from 'react-select';
import { ValidateEmail } from "../../function/Login";
import { alertLogin } from "../../Interfaces/Login";
import { async } from "@firebase/util";
import Cookies from "universal-cookie";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconLoading from "../iconLoading/IconLoading";

const options = [
   { value: 'chocolate', label: 'Chocolate' },
   { value: 'strawberry', label: 'Strawberry' },
   { value: 'vanilla', label: 'Vanilla' }
];

const initialStateOption = {
   value: "",
   label: ""
};

const initialStateProject = {
   id_type_opportunities:"",
   s_types_id: "",
   project_name: "",
   user_company_id: "",
   delivery_date: "",
   // end_date: "",
   dp: "",
   description: "",
   publication_date: "",
   budget: "",
   public_info: "",
   // file:"",
   knowledges: []
};

const initialStateListSpecialization = {
   created_at: "",
   id: 0,
   name: "",
   updated_at: ""
};

const initialStateInput = {
   input: "",
   message: '',
   state: false
}

const initialStateBudget = {
   id: "",
   range: ""
}

type handleInputChangeSpecialtie = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

const CreateProjectComponent = () => {

   const [infoProject, setInfoProject] = useState<Project>(initialStateProject);

   const [File, setFile] = useState({
      file: {
         lastModified: "",
         lastModifiedDate: "",
         name: "",
         size: "",
         type: "",
         webkitRelativePath: ""
      }
   });

   const [specializations, setSpecializations] = useState([initialStateListSpecialization]);

   const [optionStates, setOptionStates] = useState([initialStateOption]);

   const [SelectedValues, setSelectedValues] = useState([]);

   const [listBudget, setListBudget] = useState([initialStateBudget]);

   const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

   const [termsAndConditions, setTermsAndConditions] = useState<boolean | undefined>(false);

   const [sendingData, setSendingData] = useState(false);

   const [characterTextLength, setCharacterTextLength] = useState(2500);

   const [lengthText, setLengthText] = useState(0);

   // Cookies
   const cookies = new Cookies();

   const getSesion = cookies.get('Bearer');

   const MySwal = withReactContent(Swal);

   const navigate = useNavigate();

   const dateToday = new Date();

   const [dateLimitPublish, setDateLimitPublish] = useState<string>('');

   const [dDay, setDDay] = useState<string>('');

   let fileRefProfile = null;

   // Handle values input
   const handleInputProject = ({ target: { name, value, id, } }: handleInputChangeSpecialtie) => {

      if (name == 'description') {
         setLengthText(value.length);
         if (value.length >= 2500) {
            setLengthText(2500);
            setInfoProject({ ...infoProject, [name]: value.substring(0, 2500) });
         } else {
            setInfoProject({ ...infoProject, [name]: value });
         }
      } else {
         if (name == "termsAndConditions") {
            const checkbox = document.getElementById("termsAndConditions",) as HTMLInputElement | null;
            const valueCheck = checkbox?.checked;
            setTermsAndConditions(valueCheck);
         };

         if (name == "s_types_id") knowledged([value]);

         setInfoProject({ ...infoProject, [name]: value });
      }
   };


   // Handle Values Select
   const selectInputRef = useRef<any>();

   const valueOpcion = (e: any) => {
      if (e.length > 0) {
         const values = e.map((option: any) => { return option.value })
         setSelectedValues(values);
      }
   }

   // Clear values in select
   const clearValuesSelected = () => {
      selectInputRef.current.setValue("");
   };


   // handle form end validations
   const handleFormProject = async (event: React.FormEvent<HTMLFormElement>) => {

      event.preventDefault()

      infoProject.knowledges = SelectedValues;

      // clearValuesSelected();

      let verifyValues = [
         { name: "name", value: infoProject.project_name },
         { name: "specialty", value: infoProject.s_types_id },
         { name: "budget", value: infoProject.budget },
         { name: "dateInit", value: infoProject.delivery_date },
         // { name: "dateEnd", value: infoProject.end_date },
         { name: "publication_date", value: infoProject.publication_date },
         { name: "stateProfile", value: infoProject.public_info },
         { name: "description", value: infoProject.description },
         { name: "amount", value: String(infoProject.knowledges.length) },
         { name: "documents", value: File.file.name },
         { name: "receiveDocuments", value: infoProject.dp },
         { name: "termsAndConditions", value: String(termsAndConditions) },
      ];

      infoProject.user_company_id = getSesion.id;
      infoProject.id_type_opportunities = "4";

      const validateInputs = await ValidateEmail(verifyValues);
      setAlert(validateInputs);

      const state = validateInputs.filter(input => input.state === false).map((s) => { return false });

      if (state.length >= 11) { sendData() };

   };


   // Create project

   const sendData = () => {

      setSendingData(true);

      const url = `${process.env.REACT_APP_API_URI}company/create/project/${getSesion.id}`;

      let config = {
         // multipart/form-data
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getSesion.token}`
         }
      };

      // axios.post(url, infoProject, config)
      axios.post(url, infoProject, config)
         .then(response => {

            if (response.data.message === "Número máximo de proyectos publicados por mes alcanzado.") {
               MySwal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 3500
               }).then(function () {
                  navigate('/Planes');
               });

            } else {
               sendFileProject(response.data.data.id);
            };
         })
         .catch(err => {
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 5500
            });
            setSendingData(false);
         })
   };

   const sendFileProject = (projectId: Number) => {

      const url = `${process.env.REACT_APP_API_URI}company/upload-project-file/${projectId}`;

      let config = {
         // multipart/form-data
         headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getSesion.token}`
         }
      };

      axios.post(url, File, config)
         .then(response => {
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: response.data.message,
               showConfirmButton: false,
               timer: 4000
            }).then(() => {
               navigate('/proyectos');
            });
            setSendingData(false);
         })
         .catch(err => {

            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.error,
               showConfirmButton: false,
               timer: 4000
            });
            setSendingData(false);
         })
   }

   // handle files
   const uploadFiles = (e: { target: { files: any; }; }) => {

      let files = e.target.files;

      let image = files[0];

      let imageUrl = URL.createObjectURL(image);

      fileRefProfile = image;

      if (fileRefProfile.type === "application/pdf") {
         setFile({ file: fileRefProfile })
      } else {
         MySwal.fire({
            position: 'center',
            icon: 'error',
            title: "Solo se aceptan archivos .pdf",
            showConfirmButton: false,
            timer: 3500
         });

      };
   };

   // Get knowledged
   async function knowledged(ids: string[]) {

      setOptionStates([initialStateOption]);

      const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/Knowledge/${ids}`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getSesion.token}`
         }
      };
      try {

         setOptionStates([initialStateOption]);

         const resp = await axios.get(urlCountry, config);

         if (resp.data.data.length > 0) {

            resp.data.data.map((infoData: any) => {
               setOptionStates(current => [...current, { value: infoData.id, label: infoData.name }]);
            });
         };

      } catch (err) {
         setOptionStates([initialStateOption]);
      };
   };


   // handle values default
   useEffect(() => {

      // ObtenerPlanActual();
      async function getSpecialties() {

         const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;

         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };
         try {

            const resp = await axios.get(urlCountry, config);
            setSpecializations(resp.data.specializationTypes);

         } catch (err) {
            // Handle Error Here
            console.error(err);
         }
      };
      getSpecialties();

      async function getBudget() {
         const urlBudget = `${process.env.REACT_APP_API_URI}budget`;
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };

         try {
            const resp = await axios.get(urlBudget, config);

            setListBudget(resp.data);

         } catch (err) {
            console.error(err);
         }
      };
      getBudget();

      // console.log(dateToday.setMonth);
      if ((dateToday.getMonth() + 1) < 10) {
         const mounth = `0${dateToday.getMonth() + 1}`;
         if (dateToday.getDate() < 10) {
            const day = `0${dateToday.getDate()}`;
            let formatDate = `${dateToday.getFullYear() + `-` + mounth + `-` + day}`;
            setDateLimitPublish(formatDate);
         }
         else {
            const day = `${dateToday.getDate()}`;
            let formatDate = `${dateToday.getFullYear() + `-` + mounth + `-` + day}`;
            setDateLimitPublish(formatDate);
         }
      } else {
         const mounth = `${dateToday.getMonth() + 1}`;

         if (dateToday.getDate() < 10) {
            const day = `0${dateToday.getDate()}`;
            let formatDate = `${dateToday.getFullYear() + `-` + mounth + `-` + day}`;
            setDateLimitPublish(formatDate);
         }
         else {
            const day = `${dateToday.getDate()}`;
            let formatDate = `${dateToday.getFullYear() + `-` + mounth + `-` + day}`;
            setDateLimitPublish(formatDate);
         }
      }

      // Oportu();

   }, []);


   const [show, setShow] = useState(false);
   const [frutaSeleccionada, setFrutaSeleccionada] = useState('');
   const handleSeleccionDeFruta = (event: any) => {
      setFrutaSeleccionada(event.target.value);
      if (event.target.value == "1") {
         setShow(true);
      }
   };


  

   return (
      <div className="w-full flex justify-center">

         <form className="w-full" onSubmit={handleFormProject}>
          
            <div className="bg-bluecard-big p-5 rounded">
               <h1 className="text-Yellow font-bold text-3xl text-left mb-8 px-2">Requerimientos del proyecto</h1>

               <div className="flex flex-wrap px-2">

                  <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">

                     <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Selecciona la categoría</label>
                     <select
                        value={infoProject.s_types_id}
                        name="s_types_id"
                        onChange={handleInputProject}
                        className="bg-Light-Blue form-control block w-full px-4 py-3 text-xl font-normal text-sky-400 bg-clip-padding border border-solid border-transparent rounded transition ease-in-out md:m-0 focus:border-Yellow focus:outline-none focus:text-white"  >
                        <option className="text-white" selected>...</option>
                        {
                           specializations.map((item) => {
                              return <option value={item.id}>{item.name}</option>
                           })
                        }
                     </select>
                     <p>
                        {
                           alert.filter(input => input.input == "specialty" && input.state === true).map(message => (
                              <span className="text-red-500">{message.message}</span>
                           ))
                        }
                     </p>
                  </div>

                  <div className="mb-6 text-start btn-editpro w-full md:w-1/2 lg:w-2/3">
                     <span className="text-white mb-8 lg:text-sm xl:text-base">
                        Puedes seleccionar todas las habilidades que posees
                     </span>

                     <Select
                        isMulti
                        name="colors"
                        options={optionStates.filter((item) => item.label != "")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={valueOpcion}
                        ref={selectInputRef}
                     />
                     {
                        alert.filter(input => input.input == "amount" && input.state === true).map(message => (
                           <span className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div>

               </div>

               <div className="flex flex-wrap px-2">
                  <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
                     <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Nombre del proyecto</label>
                     <input
                        type="text"
                        className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="project_name"
                        value={infoProject.project_name}
                        onChange={handleInputProject}
                     />
                     {
                        alert.filter(input => input.input == "name" && input.state === true).map(message => (
                           <span className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div>
                  <div className="mb-6 text-start btn-editpro w-full md:w-1/2 lg:w-2/3">
                     <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Adjuntar documento/s complementario</label>
                     <input
                        type="file"
                        className="btn-editpro form-control block w-full mt-2 pr-4 text-xs md:text-lg text-holder-gray font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="documents"
                        onChange={uploadFiles}
                     />
                     {
                        alert.filter(input => input.input == "documents" && input.state === true).map(message => (
                           <span className="text-red-500">{message.message}</span>
                        ))
                     }

                  </div>
               </div>

               <div className="flex flex-wrap px-2">
                  <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
                     <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Presupuesto disponible</label>
                     <select
                        name="budget"
                        value={infoProject.budget}
                        onChange={handleInputProject}
                        className="form-control block w-full px-4 py-2 mt-2 text-xl font-normal text-Yellow bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"  >
                        <option className="text-white" selected>Selecciona un presupuesto</option>

                        {
                           listBudget.map((range) => {
                              return <option value={range.id}>{range.range}</option>
                           })
                        }

                     </select>
                     {
                        alert.filter(input => input.input == "budget" && input.state === true).map(message => (
                           <span className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div>
                  <div className="mb-6 text-start md:pr-2 w-full md:w-1/2 lg:w-1/3">
                     <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Fecha de publicación del proyecto</label>
                     <input
                        type="date"
                        className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="publication_date"
                        min={dateLimitPublish}
                        value={infoProject.publication_date}
                        onChange={handleInputProject}
                     />
                     {
                        alert.filter(input => input.input == "publication_date" && input.state === true).map(message => (
                           <span className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div>
                  <div className="mb-6 text-start md:pr-2 w-full md:w-1/2 lg:w-1/3">
                     <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Fecha de entrega del proyecto</label>
                     <input
                        type="date"
                        className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="delivery_date"
                        min={dateLimitPublish}
                        value={infoProject.delivery_date}
                        onChange={handleInputProject}
                     />
                     {
                        alert.filter(input => input.input == "dateInit" && input.state === true).map(message => (
                           <span className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div>
                  {/* <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
                  <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Fecha limite del proyecto</label>
                  <input
                     type="date"
                     className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                     name="end_date"
                     value={infoProject.end_date}
                     onChange={handleInputProject}
                  />
                  {
                     alert.filter(input => input.input == "dateEnd" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))
                  }
               </div> */}
               </div>

               <div className="flex flex-wrap px-2">
                  <div className="mb-6 text-start btn-editpro w-full">
                     <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Descripción del proyecto</label>
                     <textarea
                        className="form-control block w-full mt-2 px-4 py-2 text-lg font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        rows={7}
                        placeholder="Descripción"
                        name="description"
                        value={infoProject.description}
                        onChange={handleInputProject}
                     />
                     <div className="text-[rgba(500,100,0,0.5)] italic">{lengthText} / {characterTextLength}</div>
                     {
                        alert.filter(input => input.input == "description" && input.state === true).map(message => (
                           <span className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div>
               </div>

               <div className="text-justify md:text-left px-2">
                  <div>
                     <label htmlFor="" className="text-white lg:text-sm xl:text-base">¿Deseas publicar este proyecto utilizando los datos de tu empresa o prefieres publicarlo de forma anónima?</label>
                  </div>
                  <div className="flex mt-2">

                     <div className="px-2">
                        <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="radio"
                           id="public_info1"
                           name="public_info"
                           value="1"
                           onChange={handleInputProject}
                        />
                        <span className="text-white lg:text-sm xl:text-base">Público </span>
                     </div>

                     <div className="px-2">
                        <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white"
                           type="radio"
                           name="public_info"
                           id="public_info2"
                           value="2"
                           onChange={handleInputProject}
                        />
                        <span className="text-white lg:text-sm xl:text-base">Anónima</span>
                     </div>
                     {
                        alert.filter(input => input.input == "stateProfile" && input.state === true).map(message => (
                           <span className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div>
               </div>

               <div className="flex flex-wrap text-justify md:text-left mt-8 px-2">
                  <div className="w-full md:w-1/3 pr-4 lg:pr-2 xl:pr-8">
                     <label htmlFor="" className="text-white lg:text-sm xl:text-base">¿Deseas recibir documento de propuesta?</label>
                     <div className="flex mt-2">
                        <div className="px-2">
                           <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="radio"
                              name="dp"
                              id="dp1"
                              value="1"
                              onChange={handleInputProject}
                           />
                           <span className="text-white lg:text-sm xl:text-base">Si</span>
                        </div>
                        <div className="px-2">
                           <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white"
                              type="radio"
                              name="dp"
                              id="dp2"
                              value="2"
                              onChange={handleInputProject}
                           />
                           <span className="text-white lg:text-sm xl:text-base">No</span>
                        </div>
                        {
                           alert.filter(input => input.input == "receiveDocuments" && input.state === true).map(message => (
                              <span className="text-red-500">{message.message}</span>
                           ))
                        }

                     </div>
                  </div>
                  <div className="w-full mt-8 md:mt-0 md:w-1/3">
                     <label htmlFor="" className="text-white lg:text-sm xl:text-base">Términos y condiciones</label>
                     <div className="flex flex-wrap mt-2">
                        <div className="px-2">
                           <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="checkbox"
                              name="termsAndConditions"
                              id="termsAndConditions"
                              onChange={handleInputProject}
                           />
                           <span className="text-white lg:text-xs xl:text-base">Acepto los <a href="/terminos-de-servicio" target={"_blank"} className="hover:text-Yellow">términos y condiciones</a></span>
                        </div>
                        {
                           alert.filter(input => input.input == "termsAndConditions" && input.state === true).map(message => (
                              <span className="text-red-500">{message.message}</span>
                           ))
                        }
                     </div>
                  </div>
                  <div className="w-full md:w-1/3 mt-8 md:mt-0 flex items-center">

                     <button
                        type="submit"
                        className="inline-block px-7 py-5 bg-Yellow text-white font-medium lg:text-sm xl:text-base leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full text-center">

                        {!sendingData &&
                           <>
                              Publicar mi proyecto
                           </>
                        }

                        {sendingData &&
                           <IconLoading />
                        }
                     </button>

                  </div>
               </div>

            </div>
          
         </form>
      </div>
   )
}

export default CreateProjectComponent;