import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import imagenesEditProject from "../../../assets/imgEditProject/imgEditProject";


const SkeletonChat = () => {

    return (
        <div className="bg-turquoise">
            <section className="block -mt-px md:flex md:flex-wrap">
                <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                    <img className="opacity-50 py-10 h-1/2 lg:h-3/4 xl:h-5/6" src={imagenesEditProject.logo_blanco_mitad} alt="" />
                </div>
                <div className="md:w-full lg:w-4/5 flex flex-wrap justify-center lg:py-5 md:pt-4 lg-pt-0">
                    {/* <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                        <Skeleton circle width={50} height={50} count={1} />
                    </div> */}
                    <div className="w-full md:w-95% flex justify-center">
                        <div className="w-11/12">
                            <div className="flex justify-start pb-0 lg:pb-4">
                                <h1 className="text-Yellow font-bold text-3xl"><Skeleton count={1} /></h1>
                            </div>
                            <div className="lg:columns-1">
                                <div className="mb-6 text-start">
                                    <label htmlFor="nombreCompleto" className="text-white">
                                        <Skeleton count={1} />
                                        <Skeleton count={1} />
                                    </label>
                                    <div className="flex flex-wrap mt-4">
                                        <div className="bg-bluecard-big p-4 rounded w-full">
                                            <div className="text-white">
                                                {/* <div className="text-gray-500 mb-2"><Skeleton count={1} /></div> */}

                                                <div id="chat-messages" style={{ height: '400px', overflow: 'auto' }}>

                                                    {
                                                        [1, 2, 3].map((index) => {
                                                            return (
                                                                <>
                                                                    <div className="bg-Light-purple float-left py-1 px-2 m-2 w-3/4 md:w-1/2 rounded-md">
                                                                        <Skeleton count={1} />
                                                                        <div className="text-gray-500 text-xs">
                                                                            <Skeleton count={1} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="bg-Light-purple float-right py-1 px-2 m-2 w-3/4 md:w-1/2 rounded-md">
                                                                        <Skeleton count={1} />
                                                                        <div className="text-gray-500 text-xs">
                                                                            <Skeleton count={1} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SkeletonChat;