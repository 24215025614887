
import React from 'react'

import imagenesDetallesProject from "../../../assets/imgDetallesProject/imgDetallesProject";


import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props{
   cards:Number
}

const CardsFreelancerSkeleton = ({cards}:Props) => {


  return (
      <>    
         {
            Array(cards).fill(0).map((item, index) =>{
               return(

              
                  <div className="p-2 lg:w-1/2 md:w-1/2 w-full ">
                  <div className="h-full flex items-center bg-purple-cards border-gray-200 border p-4 rounded-lg">
                       <Skeleton circle width={60} height={60} count={1} /> 
                      <div className="flex-grow px-2">
                          <h2 className="text-white title-font font-medium"><Skeleton count={1} /></h2>
                          <p className="text-black"><Skeleton count={1} /> </p>
                          <p className="text-white"> <Skeleton count={3} /></p>
                      </div>
                  </div>
              </div>
                 
               )
            })
         }    
      </>
  )
}

export default CardsFreelancerSkeleton