
import React from 'react'


import certificado from "../assets/certificado/diploma1.jpg";
import findi from "../assets/findi/findi.png";

function Certificado() {
    return (
        <section className="text-gray-600 body-font overflow-hidden">
            <div className="container px-5 pt-10 pb-24 mx-auto">
                <div className="lg:w-1/1 mx-auto flex flex-wrap">

                    <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                        {/* <h2 className="text-sm title-font text-gray-500 tracking-widest">BRAND NAME</h2> */}
                        <div className='flex flex-col items-center  py-3'>

                            <h1 className="text-white text-3xl title-font font-medium mb-4 flex justify-around">
                                Verificación de certificado by
                            </h1>
                            <a href="https://findi.sv/landing/" target="_blank" className="flex justify-center">
                                <img src={findi} className='w-[15%] mt-2' alt="" />
                            </a>
                        </div>
                        <div className="flex mb-4">
                            {/* <a className="flex-grow text-white border-b-2 border-white py-2 text-lg px-1">Información</a> */}
                            {/* <a className="flex-grow text-white border-white py-2 text-lg px-1">Información</a> */}
                        </div>
                        {/* <p className="leading-relaxed mb-4">Fam locavore kickstarter distillery. Mixtape chillwave tumeric sriracha taximy chia microdosing tilde DIY. XOXO fam inxigo juiceramps cornhole raw denim forage brooklyn. Everyday carry +1 seitan poutine tumeric. Gastropub blue bottle austin listicle pour-over, neutra jean.</p> */}
                        <div className="flex border-t border-gray-200 py-2">
                            <span className="text-white">ID Certificado</span>
                            <span className="ml-auto text-white">18032023100</span>
                        </div>
                        <div className="flex border-t border-gray-200 py-2">
                            <span className="text-white">ID Beneficiario</span>
                            <span className="ml-auto text-white">5810x02</span>
                        </div>
                        
                        <div className="flex border-t border-gray-200 py-2">
                            <span className="text-white">Fecha emisión</span>
                            <span className="ml-auto text-white">Feb-15-2024</span>
                        </div>

                        <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                            <span className="text-white">Hash transaccion</span>
                            <span className="ml-auto text-white">0xf9b944ed561c307f104db68e28db</span>
                        </div>


                        <div className="flex">
                            <a href='https://sepolia.etherscan.io/tx/0xf9b944ed561c307f104db681c151e8de956deff6637c59ec3f9dac2e611c28db' target='_blank' className="w-full text-center text-white bg-Yellow border-0 py-2 px-6 focus:outline-none rounded">Ver más</a>
                           
                        </div>
                    </div>
                    <img alt="ecommerce" className="lg:w-1/2 w-full  object-cover object-center rounded" src={certificado} />
                </div>
            </div>
        </section>
        // <section className="text-gray-600 body-font">
        //     <div className="container px-5 py-24 mx-auto flex flex-wrap">
        //         <div className="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">

        //             <div className="w-full sm:p-4 px-4 mb-6">
        //                 <h1 className="title-font font-medium text-xl mb-2 text-gray-900">Moon hashtag pop-up try-hard offal truffaut</h1>
        //                 <div className="leading-relaxed">Pour-over craft beer pug drinking vinegar live-edge gastropub, keytar neutra sustainable fingerstache kickstarter.</div>
        //             </div>
        //         </div>
        //         <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
        //             <img className="object-cover object-center w-full h-full" src={certificado} alt="stats" />
        //         </div>
        //     </div>
        // </section>
        // <section className="text-gray-600 body-font">
        //     <div className="container px-5 py-24 mx-auto flex flex-col">
        //         <div className="lg:w-4/6 mx-auto">
        //             <div className="rounded-lg h-64 overflow-hidden">
        //                 <img alt="content" className="object-cover object-center h-full w-full" src={certificado} />
        //             </div>
        //             <div className="flex flex-col sm:flex-row mt-10">
        //                 <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
        //                     <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
        //                         <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-10 h-10" viewBox="0 0 24 24">
        //                             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
        //                             <circle cx="12" cy="7" r="4"></circle>
        //                         </svg>
        //                     </div>
        //                     <div className="flex flex-col items-center text-center justify-center">
        //                         <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Phoebe Caulfield</h2>
        //                         <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
        //                         <p className="text-base">Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>
        //                     </div>
        //                 </div>
        //                 <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
        //                     <p className="leading-relaxed text-lg mb-4">Meggings portland fingerstache lyft, post-ironic fixie man bun banh mi umami everyday carry hexagon locavore direct trade art party. Locavore small batch listicle gastropub farm-to-table lumbersexual salvia messenger bag. Coloring book flannel truffaut craft beer drinking vinegar sartorial, disrupt fashion axe normcore meh butcher. Portland 90's scenester vexillologist forage post-ironic asymmetrical, chartreuse disrupt butcher paleo intelligentsia pabst before they sold out four loko. 3 wolf moon brooklyn.</p>
        //                     <a className="text-indigo-500 inline-flex items-center">Learn More
        //                         <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
        //                             <path d="M5 12h14M12 5l7 7-7 7"></path>
        //                         </svg>
        //                     </a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}

export default Certificado;