import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Skeleton from "react-loading-skeleton";

const PlanesSkeleton = () => {

    const initialPlans = {
        id: 0,
        name: '',
        normalPrice: 0,
        planDescription: '',
        projectsPerMounth: 0,
        salePrice: 0,
        activeProjectsPerMounth: 0,
        activeStatus: 0,
        commision: 0,
        billingPeriod: 0,
        items:[]
    }

    const idParams = useParams().id;
    const idPlanAct = Number(idParams);

    const MySwal = withReactContent(Swal);

    const [dataPlans, setDataPlans] = useState([initialPlans]);

    const navigate = useNavigate();


    useEffect(() => {
        getPlans();
    }, []);
    
    // Obtener los planes de la plataforma
    const getPlans = async() =>{
        const urlGetPlans = `${process.env.REACT_APP_API_URI}admin-plans/list`;
        try 
        {
            const response = await axios.get(urlGetPlans);
            setDataPlans(response.data.data);
        } 
        catch (error) 
        {
            console.error(error);
        }
    }

    const cancelPlan = () =>{
        MySwal.fire({
            position: 'center',
            icon: 'warning',
            title: '¡ La siguiente acción cancelara y anulara la suscripción del plan actual !',
            showConfirmButton: true,
            confirmButtonColor: '#dc3741',
            confirmButtonText: 'Anular plan',
            showCancelButton: true,
            cancelButtonColor: '#7066e0',
            cancelButtonText: 'Cerrar'
        }).then((result) => {
            if (result.isConfirmed) 
            {
              Swal.fire({
                icon: 'success',
                title: 'La suscripción al plan ha sido cancelada',
                allowOutsideClick: false
              });
            }
        });
    }

    const payPlan = (id:number) =>{
        navigate(`/obtener-plan/${id}`);
    }

  
    return (
            <div className="w-full bg-gradient-to-b from-Light-purple p-5">
                <h1 className="text-3xl md:text-4xl flex justify-center items-center font-semibold p-5 my-5">
                    
                    <span className="text-Yellow">
                        <Skeleton count={1} width={160} /> 
                    </span>
                    &nbsp;
                    
                    <span className="text-Morado-Ticongle">
                        <Skeleton count={1} width={160} /> 
                    </span>
                </h1>
                <p className="text-lg text-center text-white"> <Skeleton count={2} width="100%" /> </p>

                <div className="flex flex-wrap my-5 p-2 xl:p-14">
                <section className="text-gray-600 body-font overflow-hidden">
                  <div className="container px-5 py-10 mx-auto">

                      <div className="flex flex-wrap -m-4">

                        {
                            Array(4).fill(0).map((item, index) =>{
                                return(
                                    <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                                        <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden bg-bluecard-big">
                                            
                                            <h2 className="text-sm tracking-widest title-font mb-1 font-medium text-white"><Skeleton count={1} width="100%" /></h2>
                                            
                                            <h1 className="text-5xl text-gray-900 leading-none flex items-center justify-center pb-4 mb-4 border-b border-gray-200">
                                                <span className=""><Skeleton count={1} width={215} /></span>
                                            </h1>
                                            
                                            <div className="w-full">
                                                <Skeleton count={4} width="100%" />
                                            </div>

                                        </div>
                                    </div>  
                                )
                            })
                        }
                        

                    
                  </div>
            </div>
            
            </section>
                </div>
            </div>
    );
}

export default PlanesSkeleton;
