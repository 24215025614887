import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import OptionLoginSkeleton from "../Skeleton/modal/optionLoginSkeleton";

type ModalLoginProps = {
    addClassModal: string;
    IsLoadingModal: boolean;
    setAddClassModal: (value: string) => void;
};

const ModalLogin = ({ addClassModal, IsLoadingModal, setAddClassModal }: ModalLoginProps) => {

    return (
        <>
            <div className={`${addClassModal} modal bg-black bg-opacity-50 fade fixed top-0  mx-auto pb-96 pt-20 px-1 sm:px-10 xl:px-14 2xl:px-52 w-full h-full outline-none overflow-x-hidden overflow-y-scroll justify-center" id="exampleModalXl" tabIndex={-1} aria-labelledby="exampleModalXlLabel" aria-modal="true" role="dialog z-50`}>
                <div className="modal-dialog modal-xl relative w-auto pointer-events-none">
                    <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-Dark-purple bg-clip-padding rounded-md outline-none text-current">

                        <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
                            <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalXlLabel"></h5>

                            <button type="button"
                                className="p-1 btn-close box-content text-white text-4xl border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-red-400 hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal" aria-label="Close" onClick={() => setAddClassModal("hidden")}><FontAwesomeIcon icon={faXmark} />
                            </button>

                        </div>
                        <div className="modal-body relative py-4 px-8 xl:px-24">

                            {IsLoadingModal && <OptionLoginSkeleton /> }
                          
                            {!IsLoadingModal &&
                                <>
                                    <section>
                                        <hgroup className="text-center">
                                            <h1 className="text-white text-2xl py-8">¡ Debes iniciar sesión !</h1>
                                            <h4 className="text-white">inicia sesión como:</h4>
                                        </hgroup>
                                        <div className="flex flex-wrap justify-center my-8">
                                            <Link
                                                to={'/Login'}
                                                onClick={() => setAddClassModal("hidden")}
                                                className="m-4 inline-flex items-center rounded-full bg-Yellow border-0 py-1 px-6 transition-colors focus:outline-none hover:bg-amber-600 lg:text-base md:text-sm mt-4 md:mt-0 text-white"
                                            >
                                                Talento
                                            </Link>
                                            <Link
                                                to={'/login-empresa'}
                                                onClick={() => setAddClassModal("hidden")}
                                                className="m-4 inline-flex items-center rounded-full bg-purple-600 border-0 py-1 px-6 transition-colors focus:outline-none hover:bg-purple-600 lg:text-base md:text-sm mt-4 md:mt-0 text-white"
                                            >
                                                Empresa
                                            </Link>
                                        </div>
                                    </section>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalLogin;