import { useEffect, useState } from "react";
import PublishProject from "../../views/PublishProject";
import { Link, useParams } from "react-router-dom";

import imagenesEditProject from "../../assets/imgEditProject/imgEditProject";
import CreateProjectComponent from "./createProject";
import FormPasantia from "../FormsProyects/FormPasantia";
import FormPlaza from "../FormsProyects/FormPlaza";
import FormProfesionalServices from "../FormsProyects/FormProfesionalServices";
import axios from "axios";
import Cookies from "universal-cookie";


const EditarOportunidad = () => {
   
    const typeForm = "update";
    const editTypeOpportunity = useParams();
    const [showOportunidad, setshowOportunidad]:any = useState("");

    const [selectProject, setselectProject] = useState({ id: 0 });
    const [typeOpportunity, setTypeOpportunity] = useState([]);
    
    const cookies = new Cookies();
    const getSesion = cookies.get('Bearer');

    const handleSelectedProject = (event: any) => {
        const projectId = event.target.value;
        setselectProject({ id: projectId });
    };

    async function getTypeOportunity() {
  
        const url = `${process.env.REACT_APP_API_URI}type-opportunity/filter`;

        console.log(url, "url")
  
        let config = {
           headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${getSesion.token}`
           }
        };
        try {
  
           const resp = await axios.get(url, config);

           setTypeOpportunity(resp.data.data);
  
        } catch (err) {

           console.log(err)

        };
     };

    useEffect(() => {
        getTypeOportunity();
        setshowOportunidad(editTypeOpportunity.oportunidad);
    }, []);

    
    return (

        <div className="bg-turquoise">
            <section className="block -mt-px md:flex md:flex-wrap">
                <div className="md:w-full lg:w-5/5 flex flex-wrap justify-center pb-5 lg:py-5 md:pt-4 lg-pt-0">
                    <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                        <Link to="" onClick={() => window.history.go(-1)}>
                            <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                        </Link>
                    </div>

                    <div className="w-full md:w-95% flex justify-center">
                        <div className="w-11/12">
                            <div className="flex justify-start pb-0 lg:pb-4 py-4">
                                <h1 className="text-Yellow font-bold text-3xl w-full">¡Editar información!</h1>
                            </div>
                            <div className="">
                                {(showOportunidad == "pasantia") && <FormPasantia typeForm={typeForm} />}
                                {(showOportunidad == "plaza") && <FormPlaza typeForm={typeForm} />}
                                {(showOportunidad == "servicio-profesional") && <FormProfesionalServices typeForm={typeForm} />}
                                {(showOportunidad == "proyecto") && <PublishProject />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}


export default EditarOportunidad;