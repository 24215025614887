import imgQuienesSomos from "../assets/imgQuienesSomos/imgQuienesSomos";

const QuienSomos = () => {

  return (
    <>
      <div className="bg-sky-900 py-16">
        <h1 className="text-orange-500 text-center font-semibold text-4xl pb-12">¿Quiénes Somos?</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-10">
          <div className="text-center flex justify-center">
            <img className="w-full sm:w-1/2" src={imgQuienesSomos.IconoSomos} alt="" />
          </div>
          <div className="flex justify-center items-center pt-8 md:pt-0">
            <div>
              <p className="text-white font-semibold text-justify">
                <span className="text-Morado-Ticongle text-2xl">Chamba</span>
                <span className="text-Azul-Ticongle text-2xl">ti</span>
                <span className="text-Naranja-Ticongle text-2xl">con </span> 
                <span>
                  es una plataforma que te permite tener una experiencia relevante, 
                  dinámica e integrada. Así mismo, poder concluir proyectos de manera exitosa con el apoyo 
                  de los talentos que forman parte de nuestro sitio. En Chambaticon aprovechamos las 
                  ventajas que ofrece la tecnología para responder a los desafíos que plantea el mundo actual, 
                  brindamos los elementos esenciales para que los usuarios cuenten con alternativas concretas 
                  de innovación.
                </span>
                {/* <span>
                  le permite ofrecer a sus estudiantes experiencias de aprendizaje
                  relevantes, dinámicas e integradas a su entorno. En Chambaticon aprovechamos las ventajas que
                  ofrece la tecnología para responder a los desafíos que plantea el mundo actual. Brindamos los
                  elementos esenciales para que los centros educativos cuenten con alternativas concretas de
                  innovación.
                </span> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-16 px-10 bg-Morado-Ticongle">
        <div className="flex justify-center items-center">
          <div>
          <h1 className="text-Naranja-Ticongle text-center md:text-start font-semibold text-2xl">La mejor opción para encontrar y contratar talentos</h1>
          {/* <h1 className="text-Naranja-Ticongle text-center md:text-start font-semibold text-2xl">El mejor lugar para encontrar y contratar freelancers</h1> */}
          <br />
          <h1 className="text-start font-semibold text-white uppercase">
            En Chambaticon creemos en nuestros talentos y en el trabajo remoto por eso apoyamos el cambio que esta modalidad de trabajo está dando a nivel mundial.
          </h1>
          {/* <h1 className="text-start font-semibold text-white">
            EN CHAMBATICON CREEMOS EN EL TRABAJO REMOTO Y EL FREELANCING Y
            APOYAMOS EL CAMBIO QUE SE ESTÁ DANDO EN EL TRABAJO A ESCALA
            MUNDIAL.
          </h1> */}
          <br />
          <p className="text-white font-semibold text-justify">
            Las empresas y los emprendedores confían cada vez más en esta forma innovadora de trabajo porque les ofrece flexibilidad y muchas facilidades para escalar sus negocios a un ritmo progresivo, diariamente en Chambaticon nuevas ideas se convierten en proyectos que generan trabajo de calidad para los talentos. Al mismo tiempo, ayudamos a que miles de emprendimientos puedan crecer gracias a nuestros servicios. Emprendedores, creativos, soñadores, visionarios y trabajadores independientes se conectan en Chambaticon con algo en común: creer y crear en la innovación del futuro en el mundo. Si estás buscando talentos para trabajar en tu empresa o negocio, publica tu proyecto ahora.
          </p>
          {/* <p className="text-white font-semibold text-justify">
            Empresas y emprendedores confían cada vez más en esta modalidad porque les ofrece flexibilidad y
            muchas facilidades para escalar sus negocios a su ritmo. Diariamente en Chambaticon nuevas ideas
            se convierten en proyectos que generan trabajo de calidad para los freelancers del mundo. A la par,
            ayudamos a que miles de emprendimientos, startups y PyMEs puedan crecer gracias a nuestros
            servicios. Emprendedores, creativos, soñadores, visionarios y trabajadores independientes se
            conectan en Chambaticon con algo en común: creer y crear el futuro del mundo. Si estás buscando
            freelancers para trabajar en tu proyecto publica tu proyecto gratis ahora. Si estás buscando trabajar
            en un proyecto entra aquí.
          </p> */}
          </div>
        </div>
        <div className="text-center flex justify-center">
          <img className="w-full sm:w-1/2" src={imgQuienesSomos.compu} alt="" />
        </div>
      </div>
    </>
  );
}

export default QuienSomos;