import { faCalendar, faComment, faMoneyBill, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { Interface } from 'readline'
import { StarRating } from 'star-rating-react-ts'
import IconLoadingWhite from '../iconLoading/IconLoadingWhite'
import { Console } from 'console'

interface Props {
    proposal: {
        nameProject: string;
        gmailFreelancer: string;
        amount: string;
        dateOfDelivery: string;
        file: string;
        photo: string;
        freelace_id: string;
        state: string;
        proposal_id: string;
        names: string;
        lastnames: string;
        description: string;
    }
    acceptProposal: (id: string, idFrelancer: string) => void
    RechazadaProposal: (id: string) => void
    handleDocumentProposal: (id: string, file: string) => void
    ProjectFinishQ: (id: string, idProject: any, idFreelancer: string) => void
    sendingData: boolean
    animateProposal: {
        animate: boolean,
        proposal: string
    }
    getQ: (e: any) => void
    themeStar: {
        colors: {
            backgroundDefault: string,
            backgroundColorActive: string,
            backgroundColorHover: string,
        },
        size: number,
    }
    sendQualification: (id: string, proposalID: string) => void
    star: boolean
    qualifyFreelancer: {
        star: boolean,
        proposalID: string
    }
    newMessageCaht: number
    GetIdProyecto: string | undefined
    assignedStatus: number | undefined
    paymentProject: number
    showModalProject: (description: string, photo: string, names: string, lastnames: string, id_freelance: string) => void
    other: (state: boolean) => void
}


export default function ProposalOfProject({ proposal, acceptProposal, RechazadaProposal, sendingData, animateProposal, handleDocumentProposal, ProjectFinishQ, getQ, themeStar, sendQualification, star, qualifyFreelancer, newMessageCaht, GetIdProyecto, assignedStatus, paymentProject, showModalProject, other }: Props) {

    return (
        <div className="w-full">
            <div className="flex flex-wrap">
                <div className="my-2 w-full pr-2 2xl:p-2">
                    {/* <div className='bg-Dark-purple rounded flex flex-wrap px-4 sm:px-4 py-3'> */}
                    <div className={` ${(proposal.state == "1") ? 'bg-purple-cards' : 'bg-Dark-purple'}  rounded flex flex-wrap py-4 px-4 sm:px-4`}>
                        <div className="flex justify-center mx-2 items-center w-full md:w-1/4 xl:w-22% 2xl:w-30%">
                            <Link to={`/freelancer/${proposal.freelace_id}`} target="_blank">
                            <img className="w-16 h-16 rounded-full object-cover object-center " src={`data:image/jpeg;base64,${proposal.photo}`} style={{ maxWidth: "190px" }} alt="user photo" />
                                {/* <img src={`${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${proposal.photo}`} className="h-24 w-24 md:h-16 md:w-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden object-cover object-center" alt="" /> */}
                            </Link>
                        </div>
                        <div className="w-full md:w-65% xl:w-65% 2xl:w-3/5">
                            {/* <h1 className="text-white font-roboto font-bold text-basic h-9 w-full overflow-hidden py-2">{proposal.nameProject}</h1> */}
                            <Link to={`/freelancer/${proposal.freelace_id}`} target="_blank">
                                <h3 className="text-white text-sm flex h-6 w-full overflow-hidden mt-4"><FontAwesomeIcon className="pr-1" icon={faUser} />
                                    <span className="text-white">
                                        {proposal.names} {proposal.lastnames}
                                    </span>
                                </h3>
                            </Link>
                            <h3 className="text-white text-sm ">
                                <FontAwesomeIcon className="pr-1" icon={faMoneyBill} />
                                <span className="text-white">${proposal.amount}</span>
                            </h3>
                            <h3 className="text-white text-sm ">
                                <FontAwesomeIcon className="pr-1" icon={faCalendar} />
                                <span className="text-white"> Finaliza: {proposal.dateOfDelivery}</span>
                            </h3>
                            <h3 className="text-white text-sm cursor-pointer">
                                {(proposal.state == "1") &&
                                    <Link to={`/mensajes-proyecto/empresa/${GetIdProyecto}/${proposal.freelace_id}/${assignedStatus}`}>
                                        <FontAwesomeIcon className="pr-1" icon={faComment} />
                                        <span className="text-white"> Mensajes +{newMessageCaht}</span>
                                    </Link>
                                }
                            </h3>
                        </div>
                        <div className="text-left w-full flex flex-wrap justify-center md:pl-2 h-52 lg:h-40 overflow-hidden">
                            {(qualifyFreelancer.star && (qualifyFreelancer.proposalID == proposal.proposal_id))
                                ? (
                                    (animateProposal.animate && (animateProposal.proposal == proposal.proposal_id))
                                        ? (<IconLoadingWhite></IconLoadingWhite>)
                                        : (<div className="w-full m-2">
                                            <h4 className="text-white text-center">Califica al freelancer</h4>
                                            <div className="flex flex-wrap justify-center">
                                                <StarRating onClick={getQ} theme={themeStar} />
                                            </div>
                                            <div className="flex flex-wrap justify-center">
                                                <button
                                                    className="h-7 flex justify-center bg-Dark-purple items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-3 my-2 cursor-pointer"
                                                    onClick={() => { sendQualification(proposal.freelace_id, proposal.proposal_id) }}
                                                >
                                                    Calificar
                                                </button>
                                            </div>
                                        </div>
                                        )
                                )
                                : ((animateProposal.animate && (animateProposal.proposal == proposal.proposal_id))
                                    ? (<IconLoadingWhite></IconLoadingWhite>)
                                    : (<>
                                        <div className="w-full pt-4 flex items-center justify-center overflow-hidden">
                                            <button onClick={() => { showModalProject(proposal.description, proposal.photo, proposal.names, proposal.lastnames, proposal.freelace_id); other(true) }}
                                                className="text-white underline hover:text-Yellow hover:cursor-pointer">
                                                Ver más
                                            </button>
                                        </div>
                                        {(proposal.state == "3")
                                            ? (<div className="h-9 w-full flex justify-center items-center text-center font-roboto xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"> Propuesta Rechazada </div>

                                            ) : (
                                                <div
                                                    className={animateProposal.animate && (animateProposal.proposal == proposal.proposal_id) ? '' : (proposal.state == "1") ? 'flex flex-wrap w-full pt-1 text-sm justify-center gap-4 md:py-8 lg:py-12' : 'flex flex-wrap w-full pt-1 text-sm justify-center gap-4 md:py-8 lg:py-12'}
                                                    id={proposal.proposal_id}
                                                >
                                                    {((assignedStatus == 2 || assignedStatus == 1))
                                                        ? (<>
                                                            { ((proposal.state == "2") || (proposal.state == "1"))
                                                                ? (<>
                                                                    { (proposal.file !== null) ? (
                                                                        <button
                                                                            onClick={() => handleDocumentProposal(`${process.env.REACT_APP_PHOTO_URI}company-file/freelance-proposal/${GetIdProyecto}/${proposal.file}`, `${proposal.file}`)}
                                                                            className="h-9 flex justify-center bg-Light-Blue items-center text-center font-roboto xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 md:mr-1 my-2"
                                                                        >
                                                                            Documento
                                                                        </button>
                                                                    ) : ('')}
                                                                    { (proposal.state == "1") &&
                                                                        <div
                                                                            className="h-9 w-full bg-Dark-purple flex justify-center items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"
                                                                            onClick={() => ProjectFinishQ(proposal.proposal_id, GetIdProyecto, proposal.freelace_id)}
                                                                        >
                                                                            Finalizar
                                                                        </div>
                                                                    }
                                                                    { (proposal.state == "2") &&
                                                                        <>
                                                                            <button
                                                                                onClick={() => acceptProposal(proposal.proposal_id, proposal.freelace_id)}
                                                                                className="h-9 flex justify-center bg-purple-cards items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"
                                                                            >
                                                                                Aceptar
                                                                            </button>
                                                                            <button
                                                                                onClick={() => RechazadaProposal(proposal.proposal_id)}
                                                                                className="h-9 flex justify-center bg-Yellow items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"
                                                                            >
                                                                                Rechazar
                                                                            </button>
                                                                        </>
                                                                    }
                                                                </>
                                                                ) 
                                                                : ( <div
                                                                        className="h-9 w-full bg-Dark-purple flex justify-center items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"
                                                                        onClick={() => ProjectFinishQ(proposal.proposal_id, GetIdProyecto, proposal.freelace_id)}
                                                                    >
                                                                        Finalizar
                                                                    </div>
                                                                )}
                                                        </>
                                                        ) : ( <></> )

                                                    }
                                                    { (((assignedStatus == 3) && (proposal.state == "1"))) 
                                                        ? ( <>
                                                                <button className="w-full h-10 lg:w-45% flex items-center justify-center bg-Yellow text-white text-sm font-bold transition-colors rounded-lg hover:bg-dark-yellow px-4 md:py-2 lg:py-0">
                                                                    Proyecto finalizado
                                                                </button>

                                                                {(paymentProject == 0) &&
                                                                    <Link
                                                                        to={`/pago-freelancer/${GetIdProyecto}/${proposal.freelace_id}`}
                                                                        className="w-full h-10 lg:w-45% flex items-center justify-center bg-Light-Blue text-white text-sm font-bold transition-colors rounded-lg hover:bg-blue-900 px-4 md:py-2 lg:py-0 text-center"
                                                                    >
                                                                        Realizar pago
                                                                    </Link>
                                                                }
                                                            </>
                                                            ) 
                                                        : (<></>)
                                                    }
                                                </div>
                                            )
                                        }
                                    </>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
