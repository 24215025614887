import { Link } from "react-router-dom";

const PoliticasdeChambaticon = () => {
   return (
      <>
         <div className="flex-row" id="politicasChambaticon">
            
            <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 p-5 rounded-lg text-justify text-white font-medium">
                  <div className="w-full my-5">
                     <Link to="" onClick={() => window.location.href = "/"}>
                        <img src="/static/media/boton_back2_boton2.2da335d0bf766228caf2.png" className="w-15% sm:w-10% md:w-5%" alt="" />
                     </Link>
                  </div>
                  <h1 className="text-orange-500 font-bold text-2xl text-center pb-8 ">Políticas de Chambaticon</h1>
                  <h3>
                     En Chambaticon, reconocemos la importancia de proteger la privacidad de la información personal de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos la información que usted proporciona al utilizar nuestra plataforma y nuestros servicios. Al acceder y utilizar Chambaticon,  alojado en www.chambaticon.sv usted acepta los términos y prácticas descritos en esta política.
                  </h3>
               </div>
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  {/* <h1 className="text-orange-500 text-2xl pb-8">Información que Recopilamos</h1> */}
                  <h1 className="text-orange-500 text-xl">1. Información que Recopilamos</h1>
                  <br />

                  <h1 className="text-orange-500 text-lg py-4">Información de Registro: <br/>
                     <span className="text-white">Al registrarse en Chambaticon como usuario, podemos solicitar cierta información personal, que puede incluir su nombre, dirección de correo electrónico, número de teléfono, documentos de identificación, país de residencia y otra información relevante para su cuenta.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Información de Perfil: <br/> 
                     <span className="text-white">Los usuarios pueden optar por proporcionar información adicional en sus perfiles, como detalles sobre su educación, experiencia laboral, habilidades y enlaces a perfiles en redes sociales.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Información de Pago: <br/> 
                     <span className="text-white">Si utiliza nuestros servicios de pago, recopilaremos información de facturación y pago, como detalles de tarjetas de crédito u otra información de pago</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Información de Uso: <br/> 
                     <span className="text-white">Recopilamos información sobre cómo usted interactúa con nuestra la plataforma, incluidos los proyectos que visita, las búsquedas que realiza y las acciones que realiza dentro de la plataforma.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">
                     Cookies y Tecnologías Similares: <br/>  <span className="text-white">Utilizamos cookies y otras tecnologías de seguimiento para mejorar la experiencia del usuario, analizar tendencias, administrar la plataforma y recopilar información demográfica sobre nuestra base de usuarios en su conjunto.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Información recopilada automáticamente: <br/> 
                     <span className="text-white"> Nuestra la plataforma puede recopilar cierta información automáticamente, como tu dirección IP, tipo de navegador, proveedor de servicios de Internet, páginas de referencia/salida, archivos visualizados en nuestra plataforma (por ejemplo, páginas HTML, gráficos, etc.), sistema operativo, fecha/hora de acceso y datos de clics. Utilizamos esta información para analizar tendencias, administrar el sitio  y mejorar la experiencia del usuario.</span>
                  </h1>
               </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">

               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">2.  Uso de la información recopilada</h1>
                  <p>La información que recopilamos se utiliza de las siguientes maneras: <br/> </p>
                  <br />

                  <h1 className="text-orange-500 text-lg py-4">Provisión de Servicios: <br/> 
                     <span className="text-white">Utilizamos la información proporcionada para ofrecer y mejorar nuestros servicios, incluida la facilitación de la conexión entre empresas y talentos, ayudando a conectar a los talentos con las oportunidades que mejor se ajusten a sus habilidades y experiencia.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Personalización de la Experiencia del Usuario: <br/> 
                     <span className="text-white">Utilizamos la información para personalizar la experiencia del usuario en nuestra plataforma, ofreciendo contenido, proyectos y sugerencias que sean relevantes y adecuados a sus intereses y necesidades.</span>

                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Comunicación con los Usuarios: <br/> 
                     <span className="text-white">Utilizamos la información de contacto proporcionada para comunicarnos con los usuarios sobre su cuenta, proyectos en curso, actualizaciones de la plataforma y oportunidades relevantes que puedan interesarles.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Procesamiento de Transacciones: <br/> 
                     <span className="text-white">Si los usuarios optan por utilizar nuestros servicios de pago, utilizamos la información de facturación y pago para procesar transacciones y facilitar los pagos relacionados con los servicios prestados en la plataforma.</span>

                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Mejora de nuestros servicios: <br/> 
                     <span className="text-white">Utilizamos la información recopilada para analizar tendencias, evaluar el rendimiento de nuestra plataforma y servicios, y realizar mejoras continuas para ofrecer una experiencia de usuario óptima.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Investigación y Desarrollo: <br/> 
                     <span className="text-white">
                        Utilizamos la información para realizar investigaciones y análisis, lo que nos ayuda a comprender mejor las tendencias del mercado laboral, las necesidades de los usuarios y otras variables relevantes para mejorar nuestros servicios.
                     </span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Seguridad y Protección: <br/> 
                     <span className="text-white">Utilizamos la información para detectar y prevenir fraudes, actividades no autorizadas y otros comportamientos que puedan violar nuestros Términos de Servicio o comprometer la seguridad de nuestros usuarios y de la plataforma en general.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Procesar transacciones y pagos: <br/> 
                     <span className="text-white">Utilizaremos la información de facturación y pago para procesar los pagos correspondientes a los servicios prestados en la plataforma, remunerando a los freelancers por su trabajo una vez completado satisfactoriamente.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Cumplimiento Legal y Resolución de Disputas: <br/> 
                     <span className="text-white">Utilizamos la información según sea necesario para cumplir con nuestras obligaciones legales, responder a procesos legales, proteger nuestros derechos legales y resolver disputas con usuarios u otras partes interesadas.</span>
                  </h1>





               </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">

               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">3.Compartir Información</h1>

                  <p>Entendemos la importancia de proteger su privacidad y su información personal. Sin embargo, en ciertas circunstancias, podemos compartir su información con terceros para diversos fines, que incluyen, entre otros: <br/> </p>
                  

                  <h1 className="text-orange-500 text-lg py-4">Empresas y Empleadores: <br/>  
                  <span className="text-white">Compartimos la información de los talentos con empresas y empleadores que buscan contratar talento para sus proyectos. Esto puede incluir compartir currículums, perfiles profesionales y otra información relevante que facilite el proceso de selección.</span>
                  </h1>
                  
                  <h1 className="text-orange-500 text-lg py-4">Otros Usuarios de la Plataforma: <br/>  
                  <span className="text-white">En algunos casos, podemos permitir que los usuarios de la plataforma accedan y vean cierta información de otros usuarios, como perfiles públicos, para facilitar la conexión y la colaboración entre talentos y empleadores.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Proveedores de Servicios: <br/>  
                  <span className="text-white">Podemos compartir información con terceros proveedores de servicios que nos ayudan a operar, mantener y mejorar la plataforma, como servicios de alojamiento web, análisis de datos y procesamiento de pagos. Estos proveedores están sujetos a estrictas obligaciones de confidencialidad y solo pueden utilizar la información conforme a nuestras instrucciones.</span>
                  </h1>
                  
                  <h1 className="text-orange-500 text-lg py-4">Cumplimiento Legal y Requerimientos Regulatorios: <br/>  
                  <span className="text-white">Podemos divulgar información cuando sea necesario para cumplir con la ley, responder a solicitudes legales válidas (como órdenes judiciales o citaciones) o proteger nuestros derechos, propiedad o seguridad, así como los de nuestros usuarios y otras partes interesadas.</span>

                  </h1>






               </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">4. Seguridad de la información</h1>

                  <p>Tomamos medidas razonables para proteger la información personal de nuestros usuarios contra pérdida, robo y acceso no autorizado. Sin embargo, no podemos garantizar la seguridad absoluta de la información transmitida a través de Internet.</p>
                  
                  <p>Nos comprometemos a proteger la seguridad de la información recopilada a través de nuestro sitio web Chambaticon. Implementamos medidas de seguridad técnicas, administrativas y físicas adecuadas para proteger contra el acceso no autorizado, la divulgación, la alteración o destrucción de la información recopilada. Estas medidas </p>

                  <p>incluyen, entre otras, el cifrado de datos, el control de acceso a la información, la gestión de contraseñas seguras, el monitoreo de la red y la revisión periódica de nuestras prácticas de seguridad. Sin embargo, no podemos garantizar la seguridad absoluta de la información transmitida a través de Internet.</p>

                  <p>Le recomendamos que tome medidas adicionales para proteger la seguridad y privacidad de su información personal, como proteger su contraseña y cerrar sesión cuando acceda a su cuenta desde dispositivos compartidos o públicos</p>
               </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">5. Derechos del titular de los datos</h1>
                  <p>Te otorgamos ciertos derechos sobre tus datos personales, incluido el derecho de acceso, rectificación, eliminación, restricción del procesamiento y portabilidad de datos. Puedes ejercer estos derechos y ponerte en contacto con nosotros para cualquier consulta o solicitud relacionada con tus datos personales a través del correo electrónico hello@ticongle.agency.</p>
               </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">6. Retención de datos</h1>
                  <p>Mantendremos tu información personal durante el tiempo necesario para cumplir con los propósitos mencionados en esta Política de Privacidad, a menos que se requiera o permita un período de retención más prolongado por ley.</p>
               </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">7. Enlaces a sitios web de terceros</h1>
                  <p>Nuestra plataforma puede contener enlaces a otros sitios web de terceros. Esta Política de Privacidad solo se aplica a nuestra plataforma, por lo que te recomendamos que revises las políticas de privacidad de los sitios web de terceros que visites.</p>
               </div>
            </div>
         
            <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">9. Contacto</h1>
                  <p>Si tiene alguna pregunta, inquietud o solicitud con respecto a esta Política de Privacidad,  no dudes en ponerte en contacto con nosotros a través de los siguientes medios: </p>
                  <ul>
                     <li>• Correo electrónico: hello@ticongle.agency</li>
                     <li>• Número de teléfono: 2249-2301</li>
                     <li>• Dirección: Calle Reforma, Local 251, Colonia San Benito, en la ciudad de San Salvador, República de El Salvador</li>
                  </ul>

               </div>
            </div>
































            {/* <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 p-5 rounded-lg text-justify text-white font-medium">
                  <div className="w-full my-5">
                     <Link to="" onClick={() => window.location.href = "/"}>
                        <img src="/static/media/boton_back2_boton2.2da335d0bf766228caf2.png" className="w-15% sm:w-10% md:w-5%" alt="" />
                     </Link>
                  </div>
                  <h1 className="text-orange-500 font-bold text-2xl text-center pb-8 ">Políticas de Chambaticon</h1>
                  <h3>
                     En Chambaticon, reconocemos la importancia de proteger la privacidad de la información personal de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos la información que usted proporciona al utilizar nuestra plataforma y nuestros servicios. Al acceder y utilizar Chambaticon,  alojado en www.chambaticon.sv usted acepta los términos y prácticas descritos en esta política.
                  </h3>
                  <h3>
                     En Chambaticon creemos que una buena comunidad es una con reglas claras. A continuación enumeramos las políticas del sitio para diferentes aspectos de la plataforma. Ten en cuenta que es un trabajo en progreso y a medida que Chambaticon crezca éstas reglas van a evolucionar también. Recuerda que el uso de Chambaticon está regido por nuestros términos y condiciones y nuestro
                     acuerdo de usuario. Las políticas son un agregado a los anteriores. Como siempre, esperamos tus comentarios al respecto
                  </h3>
               </div>
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">1. Comportamiento en el sitio</h1>
                  <br />

                  <h1 className="text-orange-500 text-lg py-4">Información de Registro:
                     <span className="text-white">Al registrarse en Chambaticon como usuario, podemos solicitar cierta información personal, que puede incluir su nombre, dirección de correo electrónico, número de teléfono, documentos de identificación, país de residencia y otra información relevante para su cuenta.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Información de Perfil:
                     <span className="text-white">Los usuarios pueden optar por proporcionar información adicional en sus perfiles, como detalles sobre su educación, experiencia laboral, habilidades y enlaces a perfiles en redes sociales.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Información de Pago:
                     <span className="text-white">Si utiliza nuestros servicios de pago, recopilaremos información de facturación y pago, como detalles de tarjetas de crédito u otra información de pago</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Información de Uso:
                     <span className="text-white">Recopilamos información sobre cómo usted interactúa con nuestra la plataforma, incluidos los proyectos que visita, las búsquedas que realiza y las acciones que realiza dentro de la plataforma.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">
                     <span className="text-white">Cookies y Tecnologías Similares: Utilizamos cookies y otras tecnologías de seguimiento para mejorar la experiencia del usuario, analizar tendencias, administrar la plataforma y recopilar información demográfica sobre nuestra base de usuarios en su conjunto.</span>
                  </h1>

                  <h1 className="text-orange-500 text-lg py-4">Información recopilada automáticamente:
                     <span className="text-white">Nuestra la plataforma puede recopilar cierta información automáticamente, como tu dirección IP, tipo de navegador, proveedor de servicios de Internet, páginas de referencia/salida, archivos visualizados en nuestra plataforma (por ejemplo, páginas HTML, gráficos, etc.), sistema operativo, fecha/hora de acceso y datos de clics. Utilizamos esta información para analizar tendencias, administrar el sitio  y mejorar la experiencia del usuario.</span>
                  </h1>






                 
                  <br /><br />
                  <h1 className="text-orange-500 text-xl">2. Contenido del sitio</h1>
                  <br />
                  <h4>No publicarás proyectos, mensajes o contenido en el sitio de:</h4>
                  <br />
                  <ul>
                     <li>• Pornografía y contenido no apto para menores.</li>
                     <li>• Contenido violento.</li>
                     <li>• Contenido relacionado con la intolerancia racial o con el ataque a un particular, a un grupo o a una organización.</li>
                     <li>• Contenido que incluya irreverencia excesiva.</li>
                     <li>• Contenido relacionado con la piratería informática.</li>
                     <li>• Contenido relacionado con casinos y juegos de azar.</li>
                     <li>• Contenido relacionado con drogas y artículos relacionados.</li>
                     <li>• Contenido relacionado con la venta de armas o de munición (por ejemplo, armas de fuego y sus piezas, navajas o pistolas de descarga eléctrica).</li>
                     <li>• Contenido relacionado con la venta de productos que sean réplicas o imitaciones de artículos de diseño o de otros artículos.</li>
                     <li>• Software de índole maliciosa (virus informáticos, spyware, etc.)</li>
                     <li>• Cualquier otro contenido que sea ilegal, promocione una actividad ilegal o infrinja los derechos legales de otras personas.</li>
                  </ul>
                  <br /><br />
                  <h1 className="text-orange-500 text-xl">3. Políticas para la publicación de trabajos</h1>
                  <br />
                  <h4>
                     Si un trabajo infringe cualquiera de las políticas de Chambaticon, será retirado del sitio. El cliente es libre para
                     volver a publicar el trabajo si la violación se corrige. Las razones más comunes por qué se retira un anuncio de trabajo
                     de Chambaticon:
                  </h4>
                  <br />
                  <ul>
                     <li>• Viola los Términos de servicio de otra empresa o entidad.</li>
                     <li>• Pide a los proveedores realizar trabajo no remunerado.</li>
                     <li>• El contenido viola la sección 2 de éstas políticas.</li>
                     <li>• El pago se ofrecen fuera de la plataforma de pago de Chambaticon.</li>
                     <li>• El pago se basa en la comisión o compensación en acciones.</li>
                     <li>• Viola la integridad de una institución académica, incluyendo hacer la tarea, escribir ensayos para la escuela, exámenes o presentación de solicitudes.</li>
                     <li>• Viole cualquier ley de El Salvador o cualquier otro país.</li>
                     <li>• No debe contener información personal o de contacto.</li>
                     <li>• No debe promocionar otros servicios o productos.</li>
                     <li>• No es para conseguir empleados de tiempo completo</li>
                  </ul>
                  <br /><br />
                  <h1 className="text-orange-500 text-xl">3.1 Guía para la publicación de proyectos</h1>
                  <br />
                  <p>Como trabajador, usted se compromete a seguir las siguientes condiciones al momento de enviar una propuesta:</p>
                  <br />
                  <ul className="list-disc px-4">
                     <li>Proporcionar propuestas que considere como una buena opción y pueda completarlos en el tiempo establecido por el cliente.</li>
                     <li>Ayudar al cliente a esclarecer sus ideas y desarrollar plenamente el alcance del trabajo hasta el resultado final.</li>
                     <li>Escribir una propuesta concreta y detallada.</li>
                     <li>Establecer expectativas que puede cumplir o superar.</li>
                     <li>Especificar las condiciones de pago que se vinculan a la realización de hitos.</li>
                     <li>Especificar cómo las órdenes de cambios y revisiones serán manejadas.</li>
                     <li>Especificar cómo será el proceso de la garantía del trabajo.</li>
                     <li>
                        Presentar una propuesta precisa que refleje su comprensión del alcance del trabajo y la cantidad de tiempo / esfuerzo
                        que va a comprometer con el fin de lograr la satisfacción del cliente.
                     </li>
                     <li>Informar al cliente de las horas de oficina y el tiempo de respuesta comprometido.</li>
                     <li>
                        Presentar una propuesta de nuevo cuando sea necesario para reflejar cualquier cambio en la fecha de entrega de precios,
                        o enfoque, antes del final del período de trabajo.
                     </li>
                     <li>
                        No presentar precios injustificadamente bajos, o propuestas de ensayo. Si desea presentar una propuesta, pero necesita más
                        información sobre el trabajo, escriba un mensaje para solicitar más información, indicando sus suposiciones sobre el alcance del
                        trabajo y explicar que va a actualizar la propuesta una vez que reciba más información.
                     </li>
                     <li>No presentar propuestas que se hacen con la intención de cometer fraude.</li>
                     <li>No presentar contenido repetitivo o propuestas genéricas.</li>
                     <li>No ofrecer sus servicios a cambio de un buen feedback o gratis.</li>
                  </ul>
                
                  <br /><br />
                  <h1 className="text-orange-500 text-xl">4. Guía para la publicación de propuestas</h1>
                  <br />
                  <h4>Como trabajador, usted se compromete a seguir las siguientes pautas al momento de enviar una propuesta:</h4>
                  <br />
                  <ul className="list-disc px-4">
                     <li>Proporcionar propuestas sólo para los trabajos en los que usted es una buena opción y pueda completarlos en el tiempo establecido por el Cliente.</li>
                     <li>Ayudar al cliente a clarificar sus necesidades y desarrollar plenamente el alcance del trabajo y los resultados finales.</li>
                     <li>Escribir una propuesta concreta y detallada con alcance claro, entregables e hitos.</li>
                     <li>Establecer expectativas que puede cumplir o superar.</li>
                     <li>Especificar las condiciones de pago que se vinculan a la realización de hitos.</li>
                     <li>Especificar cómo las órdenes de cambios y revisiones serán manejadas.</li>
                     <li>Especificar cómo la garantía del trabajo será manejada.</li>
                     <li>Presentar una propuesta precisa que refleje su comprensión del alcance del trabajo y la cantidad de tiempo / esfuerzo que va a comprometer con el fin de lograr la satisfacción del cliente.</li>
                     <li>Informar al cliente de las horas de oficina y el tiempo de respuesta comprometido.</li>
                     <li>Presentar una propuesta de nuevo cuando sea necesario para reflejar cualquier cambio en la fecha de entrega de precios, o enfoque, antes del final del período de propuesta.</li>
                     <li>No presentar precios injustificadamente bajos, propuesta o propuestas de ensayo. Si desea presentar una propuesta, pero necesita más información sobre el trabajo, escriba un mensaje para
                        solicitar más información, indicando sus suposiciones sobre el alcance del trabajo en su propuesta y explicitar que va a actualizar la propuesta una vez que reciba más información.
                     </li>
                     <li>No presentar propuestas que se hacen con la intención de cometer fraude.
                     </li>
                     <li>No presentar contenido repetitivo o propuestas genéricas.</li>
                     <li>No ofrecer sus servicios a cambio de un buen feedback o gratis.</li>
                  </ul>
               </div>
            </div> */}
         </div>
      </>
   )
}

export default PoliticasdeChambaticon;