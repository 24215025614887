
// Your web app's Firebase configuration
export const FirebaseConfig = () => {

   const Config = {
    apiKey: "AIzaSyD8W86IhGBMsw1bKh7L_YrmhoSf9tvdFf0",
    authDomain: "chambaticon-354504.firebaseapp.com",
    projectId: "chambaticon-354504",
    storageBucket: "chambaticon-354504.appspot.com",
    messagingSenderId: "602656407461",
    appId: "1:602656407461:web:bcad3403f604c84cf8f24c"
  }

  return Config;

};