import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import imagenesEditProject from "../../../assets/imgEditProject/imgEditProject";
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate, Link } from "react-router-dom";
import IconLoading from "../../iconLoading/IconLoading";

import Select from 'react-select';

const initialStateSpecialties = {
    id: 0,
    name: "",
    created_at: "",
    updated_at: ""
}

const initialStateknowledge = {
    id: 0,
    name: "",
    type_id: 0
}

const initialStateId = {
    id: "0"
}

const initialknowledge = {
    knowledge_id: "0",
    level: "0"
}

const initialStateOption = {
    value: "",
    label: ""
};



// type
type handleInputChangeSpecialtie = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>

let checkedState: string[] = ["0"];

const SpecialtiesKnowledge = () => {


    const [specialties, setStateSpecialties] = useState([initialStateSpecialties]);

    const [Specialty, setSpecialty] = useState(0);

    const [SelectedValues, setSelectedValues] = useState([]);

    const [optionStates, setOptionStates] = useState([initialStateOption]);

    const [idUser, setidUser] = useState<number>(0);

    const [sendingData, setSendingData] = useState(false);

    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get("Bearer");

    //  Get Specialties
    useEffect(() => {
        async function getSpecialties() {

            const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${getSesion.token}`
                }
            };
            try {

                const resp = await axios.get(urlCountry, config);

                setStateSpecialties(resp.data.specializationTypes);

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        getSpecialties();

        const getIdUser = () => {
            const { id } = cookies.get('Bearer');
            setidUser(id)
        }
        getIdUser();

    }, []);


    // event form
    const handleLoginSession = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        // Send data
        sendDataValidate();
    };

    const sendDataValidate = () => {
        setSendingData(true);
        const url = `${process.env.REACT_APP_API_URI}freelancer/register/Knowledge-specializations`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        }

        const knowledges = {
            'user_id': idUser,
            'knowledges': SelectedValues
        };

        axios.post(url, knowledges, config)
            .then(response => {
                //   console.log(response.data.message);
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(() => {
                    setSendingData(false);
                    navigate('/perfil-freelancer');
                });
            })
            .catch(err => {
                // console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.log,
                    showConfirmButton: false,
                    timer: 3500
                });
                setSendingData(false);
            })

    }

    // Handle Values Select
    const selectInputRef = useRef<any>();

    const valueOpcion = (e: any) => {
        if (e.length > 0) {
            const values = e.map((option: any) => {
                const Knowledged = { knowledge_id: option.value, level: 0 };
                return Knowledged
            })
            setSelectedValues(values);
        };
    }

    // Handle values input
    const handleInputProject = ({ target: { name, value } }: handleInputChangeSpecialtie) => {
        if (name == "s_types_id") {
            knowledged(value);
            setSpecialty(Number(value));
        }

    };

    async function knowledged(ids: string) {

        setOptionStates([initialStateOption]);
        // get Session
        const getSesion = cookies.get('Bearer');

        const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/Knowledge/${ids}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`,
            }
        };

        try{

            const resp = await axios.get(urlCountry, config);

            if (resp.data.data.length > 0) {
                resp.data.data.map((infoData: any) => {
                    setOptionStates(current => [...current, { value: infoData.id, label: infoData.name }]);
                });
            };

        } catch (err){
            console.error(err);
        }
    };

    return (
        <div className="w-full md:w-95% flex justify-center">
            <form className="w-full md:w-95%" onSubmit={handleLoginSession}>
                <div className="flex justify-center pb-0 lg:pb-12">
                    <h2 className="text-Yellow text-2xl md:text-3xl text-center font-semibold py-2 md:py-8">Registrar Especialidad</h2>
                </div>
                <div className="">
                    <div className="mb-6 text-start">
                        <label htmlFor="" className="text-white columns-1">Selecciona tu/s especialización/es</label>
                        <span className="text-gray-400 text-xs italic block">Puedes seleccionar todas las habilidades que posees</span>
                        <div className="flex flex-wrap justify-center py-4">
                            <div className="w-full md:w-1/1 px-2 py-2">
                                <select
                                    value={Specialty}
                                    name="s_types_id"
                                    onChange={handleInputProject}
                                    className="bg-Light-purple select-skills form-control block w-full px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out md:m-0 focus:border-Yellow focus:outline-none focus:text-white"  >
                                    <option className="text-white" defaultValue={0}>Selecciona tu especialización</option>
                                    {
                                        specialties.map((Specialtie) => {
                                            return <option value={Specialtie.id}>{Specialtie.name}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className="w-full md:w-1/1 px-2 py-2">
                                <Select
                                    isMulti
                                    name="colors"
                                    options={optionStates.filter((item) => item.label != "")}
                                    className="basic-multi-select skills-style"
                                    classNamePrefix="select"
                                    onChange={valueOpcion}
                                    ref={selectInputRef}
                                />
                            </div>

                            {/* {
                            specialties.map((Specialtie) =>{
                                return(
                                    <>
                                        <div className="flex items-center border-solid border border-indigo-600 p-4 rounded-md mb-2">
                                            <input 
                                            className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none  checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" 
                                            type="checkbox"
                                            value={Specialtie.id}
                                            id={Specialtie.name+'_'+Specialtie.id}
                                            name={Specialtie.name}
                                            onChange={handleInputSpecialtie} 
                                            />
                                            <span className="text-white pl-2">{Specialtie.name}</span>
                                        </div>
                                    </>
                                )
                            })
                        } */}
                        </div>
                    </div>
                </div>
                {/* <div>
                <div className="mb-6 text-start">
                    <label htmlFor="" className="text-white columns-1">Arrastra el nivel de tus conocimientos</label>
                    <div className="mb-4 xl:border-b border-gray-200 dark:border-gray-700 mt-5">
        
                </div>
                    <div className="flex flex-wrap border-solid border border-indigo-600 rounded mt-2 md:justify-between">
                    
                        {
                            (knowledge.length >1 ) ? knowledge.filter(a => a.id != 0).map((data) =>{
                                return(
                                        <div className="px-8 grid py-4 w-full md:w-1/2 lg:w-1/3">
                                            <label htmlFor="" className="text-white h-12 overflow-hidden">{data.name}</label>
                                            <input type="range" className=" mt-2" name={String(data.type_id)} id={String(data.id)}  onChange={handleInputKnowledge}   />
                                        </div>
                                    )
                            }) : <h2 className="text-white p-5"> Selecciona tu/s especialización/es</h2>
                        }

                    </div>
                </div>
            </div> */}
                <div className="columns-1 flex justify-center">
                    <div className="text-center lg:text-left w-full md:w-1/2">
                        <button
                            type="submit"
                            className="inline-block px-7 py-3 bg-Yellow text-white font-medium text-sm leading-snug uppercase rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full">
                            {!sendingData &&
                                <>
                                    Guardar Cambios
                                </>
                            }

                            {sendingData &&
                                <IconLoading />
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default SpecialtiesKnowledge;


