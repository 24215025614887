import imagenesHome from "../assets/imgHome/imagenesHome.js";

import { Link, NavLink, useNavigate } from "react-router-dom";


import AOS from 'aos';
import 'aos/dist/aos.css';
import SpecialtyListHome from "../components/HomeSpecialty/SpecialtyListHome";
import { useEffect } from "react";
import { HandleSession } from '../function/Login';

AOS.init();



const Home = () =>{

	return(
		<>
			<div>
				<header className="w-full h-full md:py-20 px-1 md:px-5 sm:px-5 xl:px-24 bg-gradient-to-b from-Light-purple">
					<div className="flex flex-wrap">
						<div className="md:w-1/2 w-full">
							<div className="h-full flex justify-center flex-col px-3 md:px-5 md:py-0 py-3">
								<h2 className="text-white  text-xl 2xl:text-3xl md:text-2xl md:text-left text-center font-semibold">El mejor lugar para encontrar y dar <span className="text-Yellow">"Chamba"</span> sin salir de casa</h2>
								<div className="hidden md:block lg:flex">
									<div className="my-10">
										<Link className="rounded-full bg-Yellow py-3 lg:px-5 px-6 mx-2 2xl:text-lg text-base font-semibold text-white transition-colors hover:bg-dark-yellow" to="/Login">Quiero ser Talento</Link>
									</div>
									<div className="my-10">
										<Link className="bg-purple-600 rounded-full py-3 lg:px-5 px-6 mx-2 2xl:text-lg text-base font-semibold text-white transition-colors hover:bg-purple-800" to="/login-empresa">Contrata un Talento</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="md:w-1/2 w-full">
							<div className="px-5">
								<img 
									src={imagenesHome.image_header} 
									className="w-full h-auto p-5 object-center"
									alt=""
								/>
							</div>
						</div>
						<div className="md:hidden w-full">
							<div className="px-5 flex flex-wrap">
								<div className="mt-5 w-full p-1">
									<Link className="w-full block text-center py-3 px-6 rounded-full bg-Yellow text-sm font-semibold text-white transition-colors hover:bg-dark-yellow" to="/Login">Quiero ser Talento</Link>
								</div>
								<div className="mt-2 mb-5 w-full p-1">
									<Link className="bg-purple-600 w-full block text-center py-3 px-6 rounded-full text-sm font-semibold text-white transition-colors hover:bg-purple-800" to="/login-empresa">Contrata un Talento</Link>
								</div>
							</div>
						</div>
					</div>
				</header>
				<div className="text-start bg-turquoise md:px-32 flex justify-center">
					<h2 className="text-white text-2xl md:text-3xl font-semibold">
						Busca y da <span className="text-Yellow">Chamba</span>
					</h2>
				</div>
				<section className="bg-turquoise md:pb-24 py-12 md:px-5 px-6">
					<div className="flex flex-wrap">
						<div className="xl:w-1/4 md:w-1/2 w-full py-3">
							<div 
								data-aos="flip-right"
								data-aos-easing="ease-out-cubic"
								data-aos-duration="2000"
								className="bg-Light-Blue 2xl:p-6 p-3 lg:p-5 mx-3 rounded-lg h-full">
								<div className="flex justify-center">
									<img className="w-3/4 h-auto object-center p-2" src={imagenesHome.a2}/>
								</div>
								<div>
									<h4 className="text-center text-white text-base font-semibold my-3">DATE A CONOCER</h4> 
									<p className="text-white text-base text-start">¿Querés trabajar desde tu casa? Si conoces tecnologías de desarrollo o diseño gráfico regístrate en nuestra plataforma y formá parte de nuestro selecto equipo de talentos.</p>
								</div>
							</div>
						</div>
						<div className="xl:w-1/4 md:w-1/2 w-full py-3">
							<div 
								data-aos="flip-right"
								data-aos-easing="ease-out-cubic"
								data-aos-duration="2000"
								className="bg-Light-Blue 2xl:p-6 p-3 lg:p-5 mx-3 rounded-lg h-full">
								<div className="flex justify-center">
									<img className="w-3/4 h-auto object-center p-2" src={imagenesHome.a3}/>
								</div>
								<div>
									<h4 className="text-center text-white text-base font-semibold my-3">DALE CHAMBA A UN TALENTO</h4> 
									<p className="text-white text-base text-start">
										Si deseas desarrollar un proyecto tecnológico y no sabes como realizarlo, contrata a uno de nuestros 
										talentos, todos han sido acreditados para ser parte de Chambaticon.
									</p>
								</div>
							</div>
						</div>
						<div className="xl:w-1/4 md:w-1/2 w-full py-3">
							<div 
								data-aos="flip-right"
								data-aos-easing="ease-out-cubic"
								data-aos-duration="2000"
								className="bg-Light-Blue 2xl:p-6 p-3 lg:p-5 mx-3 rounded-lg h-full">
								<div className="flex justify-center">
									<img className="w-3/4 h-auto object-center p-2" src={imagenesHome.a4}/>
								</div>
								<div>
									<h4 className="text-center text-white text-base font-semibold my-3">EMPIEZA YA</h4> 
									<p className="text-white text-base text-start">
										Para ser parte de nuestros talentos tienes que registrarte y llenar tu perfil, el resto lo hace la 
										plataforma. Una vez seas parte de nuestro equipo, obtendrás trabajo de una manera fácil y rápida.
									</p>
								</div>
							</div>
						</div>
						<div className="xl:w-1/4 md:w-1/2 w-full py-3">
							<div 
								data-aos="flip-right"
								data-aos-easing="ease-out-cubic"
								data-aos-duration="2000"
								className="bg-Light-Blue 2xl:p-6 p-3 lg:p-5 mx-3 rounded-lg h-full">
								<div className="flex justify-center">
									<img className="w-3/4 h-auto object-center p-2" src={imagenesHome.a5}/>
								</div>
								<div>
									<h4 className="text-center text-white text-base font-semibold my-3">PAGA A TU TALENTO</h4> 
									<p className="text-white text-base text-start">
										Si tu proyecto fue finalizado con éxito y estás completamente satisfecho, es momento de remunerar el  trabajo de tu talento.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div
					data-aos="fade-up"
					className="text-start bg-turquoise md:px-32 flex justify-center">
					<h2 className="text-white text-2xl md:text-3xl font-semibold">
						Pasos para <span className="text-Yellow">Chambear</span>
					</h2>
				</div>
				<section className="bg-turquoise md:pb-24 py-12 md:px-12 lg:px-24 px-5 grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-2 relative">
					<div className="absolute right-0 hidden sm:block">
						<img className="w-60" src={imagenesHome.cara_icono1} alt=""/>
					</div>
					<div className="z-50 px-1">
						<div  
							data-aos="fade-up"
							data-aos-anchor-placement="top-bottom" 
							data-aos-duration="1000"
							className="flex flex-wrap text-sm text-start px-2">
							<div className="w-1/4 xl:w-1/5 z-50">
								<div className="bg-Light-purple z-50 my-4 w-20 h-20 rounded-full flex items-stretch justify-center">
									<img className="w-10 self-center" src={imagenesHome.anadir} alt=""/>
								</div>
							</div>
							<div className="w-3/4 xl:w-4/5 self-center pl-7 lg:pl-1 xl:pl-0">
								<h4 className="text-white self-center md:text-base text-sm font-bold ">REGÍSTRATE</h4>
							</div>
						</div>
						<div 
							data-aos="fade-up"
							data-aos-anchor-placement="top-bottom" 
							data-aos-duration="1600"
							className="bg-white w-1 absolute left-18 md:left-24 lg:left-36 top-36 h-80">

						</div>
						<div  
							data-aos="fade-up"
							data-aos-anchor-placement="top-bottom" 
							data-aos-duration="1500"
							className="flex flex-wrap text-sm text-start px-2">
							<div className="w-1/4 xl:w-1/5 z-50">
								<div className="bg-Light-purple z-50 my-4 w-20 h-20 rounded-full flex items-stretch justify-center">
									<img className="w-10 self-center" src={imagenesHome.lupa} alt=""/>
								</div>
							</div>
							<div className="w-3/4 xl:w-4/5 self-center pl-7 lg:pl-1 xl:pl-0">
								<h4 className="text-white self-center md:text-base text-sm font-bold ">DATE A CONOCER</h4>
							</div>
						</div>
						<div  
							data-aos="fade-up"
							data-aos-anchor-placement="top-bottom" 
							data-aos-duration="2000"
							className="flex flex-wrap text-sm text-start px-2">
							<div className="w-1/4 xl:w-1/5 z-50">
								<div className="bg-Light-purple z-50 my-4 w-20 h-20 rounded-full flex items-stretch justify-center">
									<img className="w-10 self-center" src={imagenesHome.cliente} alt=""/>
								</div>
							</div>
							<div className="w-3/4 xl:w-4/5 self-center pl-7 lg:pl-1 xl:pl-0">
								<h4 className="text-white self-center md:text-base text-sm font-bold ">CREA TU CARTERA DE CLIENTES</h4>
							</div>
						</div>
						<div  
							data-aos="fade-up"
							data-aos-anchor-placement="top-bottom" 
							data-aos-duration="2500"
							className="flex flex-wrap text-sm text-start px-2">
							<div className="w-1/4 xl:w-1/5 z-50">
								<div className="bg-Light-purple z-50 my-4 w-20 h-20 rounded-full flex items-stretch justify-center">
									<img className="w-10 self-center" src={imagenesHome.dinero} alt=""/>
								</div>
							</div>
							<div className="w-3/4 xl:w-4/5 self-center pl-7 lg:pl-1 xl:pl-0">
								<h4 className="text-white self-center md:text-base text-sm font-bold ">GENERA TUS INGRESOS DESDE DONDE ESTÉS</h4>
							</div>
						</div>
					</div>
					<div className="z-50 flex items-center justify-center px-4">
						<img className="w-full 2xl:w-4/5 h-auto object-center rounded-lg" src={imagenesHome._DSC0313} alt=""/>
					</div>
				</section>
				<div className="text-start bg-turquoise md:px-32 flex justify-center">
					<h2 data-aos="fade-up" className="text-white text-2xl md:text-3xl font-semibold">
						¿En que quieres <span className="text-Yellow">Chambear</span>?
					</h2>
				</div>
			
				<SpecialtyListHome></SpecialtyListHome>

				<section className="bg-turquoise grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2 md:px-24 relative md:pb-15 py-12 px-6">
					<div className="z-10 hidden lg:block absolute right-0">
						<img className="w-60" src={imagenesHome.cara_icono1} alt=""/>
					</div>
					<div className="z-50 flex md:hidden py-5 items-center justify-center text-center">
						<div className="text-center">
							<h1 className="text-white text-xl font-bold">¡PONTE BUXO!</h1>
							<h1 className="text-white text-xl font-bold">GANA DINERO EN MODO TALENTO</h1>
						</div>
					</div>
					<div 
						data-aos="fade-up"
						data-aos-duration="3000"
						className="z-50 flex items-center justify-center px-5 pb-2">
						<img  src={imagenesHome.footer_home} alt=""/>
					</div>
					<div 
						
						className="hidden z-50 md:flex items-center justify-center text-center">
						<div className="text-center">
						
							<h1 className="text-white font-bold text-3xl">¡PONTE BUXO!</h1>
							<h1 className="text-white font-bold text-3xl">GANA DINERO EN MODO TALENTO</h1>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}

export default Home;