
import imagenesEditPerfil from "../../../assets/imgEditPerfil/imagenesEditPerfil.js";
import imagenesEditProject from "../../../assets/imgEditProject/imgEditProject";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CompanyEditSkeleton = () =>{

    return(
    <>
        <div className="bg-turquoise ">
            <section className="block justify-center md:flex md:flex-wrap">
                {/* <div className="hidden lg:flex w-1/6 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                    <img className="py-10 opacity-50 h-1/2 lg:h-2/6 xl:h-1/2" src={imagenesEditPerfil.logo_blanco_mitad} alt="" />
                    <Skeleton circle width={195} height={195} count={1} /> 
                </div> */}
                <div className="w-full md:w-1/2 lg:w-1/4 pt-4 btn-file">
                    {/* <div className="w-full sm:w-15%">
                        <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                    </div> */}
                    <div className="mb-2 flex justify-center">
                        
                        <Skeleton circle width={195} height={195} count={1} /> 

                    </div>
                    <div className="mb-2 flex justify-center">
                        <Skeleton width={195} height={40} count={1} /> 
                    </div>

                
                </div>

                <div className="w-full md:w-1/2 lg:w-7/12 flex flex-wrap justify-center pb-5 lg:py-5">
                    <h2 className="w-full text-Yellow text-2xl md:text-3xl text-center font-semibold py-2 xl:py-3 mb-2"> <Skeleton width={300} count={1} /> </h2>
                    <form className="w-72 lg:w-3/4">

                        <div className="lg:columns-2">
                            <div className="mb-6 text-start">
                                <label htmlFor="nombreCompleto" className="text-white"><Skeleton count={1} /></label>
                                   <Skeleton width={195} height={40} count={1} /> 
                            </div>

                            <div className="mb-6 text-start">
                                <label htmlFor="nombreCompleto" className="text-white"><Skeleton count={1} /></label>
                                   <Skeleton width={195} height={40} count={1} /> 
                            </div>
                        </div>

                        <div className="lg:columns-2">
                            {/* Country  */}
                            <div className="mb-6 text-start">
                                <label htmlFor="nombreCompleto" className="text-white"><Skeleton count={1} /></label>
                                   <Skeleton width={195} height={40} count={1} /> 
                            </div>
                            <div className="mb-6"></div>
                        </div>

                        <div className="lg:columns-1 mt-5">
                            <div className="mb-6 text-start">
                                <label htmlFor="nombreCompleto" className="text-white"><Skeleton width={250} count={1} /></label>
                                   <Skeleton height={60} count={1} /> 
                            </div>

                        </div>

                        <div className="lg:columns-2">

                           <div className="mb-6 text-start">
                              <label htmlFor="nombreCompleto" className="text-white"><Skeleton count={1} /></label>
                                 <Skeleton width={195} height={40} count={1} /> 
                           </div>

                           <div className="mb-6 text-start">
                              <label htmlFor="nombreCompleto" className="text-white"><Skeleton count={1} /></label>
                                 <Skeleton width={195} height={40} count={1} /> 
                           </div>

                        </div>

                        <div className="lg:columns-2">

                           <div className="mb-6 text-start">
                              <label htmlFor="nombreCompleto" className="text-white"><Skeleton count={1} /></label>
                                 <Skeleton width={195} height={40} count={1} /> 
                           </div>

                           <div className="mb-6 text-start">
                              <label htmlFor="nombreCompleto" className="text-white"><Skeleton count={1} /></label>
                                 <Skeleton width={195} height={40} count={1} /> 
                           </div>
                           
                        </div>

                        <div className="lg:columns-1">
                            <div className="mb-6 text-start flex justify-center items-center">
                                   <Skeleton width={210} count={1} /> 
                            </div>
                        </div>


                        {/* social networks */}
                        <div className="lg:columns-2">

                           <div className="mb-6 text-start">
                              <label htmlFor="nombreCompleto" className="text-white"><Skeleton width={150} count={1} /></label>
                                 <Skeleton height={40} count={1} /> 
                           </div>

                           <div className="mb-6 text-start">
                              <label htmlFor="nombreCompleto" className="text-white"><Skeleton width={150} count={1} /></label>
                                 <Skeleton height={40} count={1} /> 
                           </div>

                        </div>

                        <div className="lg:columns-2">

                           <div className="mb-6 text-start">
                              <label htmlFor="nombreCompleto" className="text-white"><Skeleton width={150} count={1} /></label>
                                 <Skeleton height={40} count={1} /> 
                           </div>

                           <div className="mb-6 text-start">
                              <label htmlFor="nombreCompleto" className="text-white"><Skeleton width={150} count={1} /></label>
                                 <Skeleton height={40} count={1} /> 
                           </div>

                        </div>

                        <div className="columns-1">
                            <div className="text-center lg:text-left mx-12">
                               <Skeleton height={40} count={1} /> 
                            </div>
                        </div>
                    </form>
                </div>            
            </section>
        </div>
    </>
    )
}

export default CompanyEditSkeleton;