import loadingWhite from "../../assets/Icons/loadingWhite.svg";

const IconLoadingWhite = () =>{
   return(
      <div className="flex justify-center items-center text-white rounded-lg">
         <img src={loadingWhite} className="iconLoadingWhite" />
      </div>
   )
}

export default IconLoadingWhite;
