const Preloader = () => {
  return (
    <div className={ `w-full flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 m-auto z-40` }>
        <div className="bg-Naranja-Ticongle p-2 w-4 h-4 rounded-full animate-bounce m-1 first-circle"></div>
        <div className="bg-Naranja-Ticongle p-2 w-4 h-4 rounded-full animate-bounce m-1 second-circle"></div>
        <div className="bg-Naranja-Ticongle p-2 w-4 h-4 rounded-full animate-bounce m-1 third-circle"></div>
    </div>
  );
}	

export default Preloader;