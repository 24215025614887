import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faPaperPlane, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { animateScroll } from 'react-scroll';
import SkeletonChat from "../components/Skeleton/chat/skeletonChat";

const Chatprojects = () => {

    const initialMessage = {
        message: ''
    }
    const initialMessageChat = {
        message: '',
        author_id: 0,
        chat_id: 0,
        receiver_id: 0,
        id: 0,
        created_at: '',
        hours: '',
        minutes: '',
        date: ''
    }

    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const [chatSkeleton, setChatSkeleton] = useState(true);

    const params = useParams();


    const [messageChat, setMessageChat] = useState('');

    const [idChat, setIdChat] = useState(0);

    const [formChat, setFormChat] = useState<Message>(initialMessage);

    const [chatHistory, setChatHistory] = useState([initialMessageChat]);

    const [viewChat, setViewChat] = useState(false);

    type handleInputChangeMessage = ChangeEvent<HTMLInputElement>;

    interface Message {
        message: string
    }

    useEffect(() => {
        getProject();
    }, []);

    const getProject = () => {
        const urlProject = `${process.env.REACT_APP_API_URI}project/get/${params.id}`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };
        const response = axios.get(urlProject, config);
        response.then(res => {
            setIdChat(res.data.chat_room);
            getChat(res.data.chat_room);
        });
        response.catch(err => {
            console.log(err.data);
        })
    }

    const getChat = async (idC: number) => {
        const urlChat = `${process.env.REACT_APP_API_URI}chat/read/${idC}`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };
        const response = axios.get(urlChat, config);
        response.then(res => {
            if(res.data.length == 0){
                setMessageChat('No hay mensajes');
                setTimeout(() => { setChatSkeleton(false) }, 100); 
            }else{
                setViewChat(true);
                setMessageChat('Mensajes');
                formatArr(res.data)
                setChatHistory(res.data);
                console.log(res.data);
                setTimeout(() => { scrollToBottom() }, 100);
                setTimeout(() => { setChatSkeleton(false) }, 100);
            };
        })
        response.catch(err => {
            console.log(err);
        })
    }

    const formatArr = (arr: any) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].created_at = new Date(arr[i].created_at);
            arr[i].hours = arr[i].created_at.getHours();
            arr[i].minutes = arr[i].created_at.getMinutes();
            arr[i].date = arr[i].created_at.getDay() + '/' + arr[i].created_at.getMonth() + "/" + arr[i].created_at.getFullYear();
        }
        return arr;
    }

    const sendMessageChat = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const urlFormChat = `${process.env.REACT_APP_API_URI}chat/send/${getSesion.id}`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        let data = {
            chat: idChat,
            receiver: params.if,
            message: formChat.message
        }
        const response = axios.post(urlFormChat, data, config);
        response.then(res => {
            setFormChat({ message: '' });
            getProject();
        })
    }

    const handleChange = ({ target: { name, value } }: handleInputChangeMessage) => {
        setFormChat({ ...formChat, [name]: value });
    }

    const scrollToBottom = () => {
        animateScroll.scrollToBottom({ containerId: 'chat-messages', duration: 0, smooth: "easeOutQuad" });
    };

    return (
        <>
            {
                (chatSkeleton) ? (
                    <SkeletonChat></SkeletonChat>
                ) : (
                    <div className="bg-turquoise">
                        <section className="block -mt-px md:flex md:flex-wrap">
                            <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                                <img className="opacity-50 py-10 h-1/2 lg:h-3/4 xl:h-5/6" src={imagenesEditProject.logo_blanco_mitad} alt="" />
                            </div>
                            <div className="md:w-full lg:w-4/5 flex flex-wrap justify-center pb-5 lg:py-5 md:pt-4 lg-pt-0">
                                <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                                    <Link to="" onClick={() => window.history.go(-1)}>
                                        <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                                    </Link>
                                </div>
                                <div className="w-full md:w-95% flex justify-center">
                                    <div className="w-11/12">
                                        <div className="flex justify-start pb-0 lg:pb-4">
                                            <h1 className="text-Yellow font-bold text-3xl">¡Mensajes del proyecto!</h1>
                                        </div>
                                        <div className="lg:columns-1">
                                            <div className="mb-6 text-start">
                                                <label htmlFor="nombreCompleto" className="text-white">Actualizar mensajes
                                                    <button onClick={getProject}>
                                                        <FontAwesomeIcon className="mx-4 text-Yellow cursor-pointer hover:text-Light-Blue" icon={faRefresh} />
                                                    </button>
                                                </label>
                                                <div className="flex flex-wrap mt-4">
                                                    <div className="bg-bluecard-big p-4 rounded w-full">
                                                        <div className="text-white">
                                                            <div className="text-gray-500 mb-2">{messageChat}</div>
                                                            <div id="chat-messages" style={{ height: '400px', overflow: 'auto' }}>
                                                                {viewChat &&
                                                                    chatHistory.map((cht, i) =>
                                                                        <div key={i} className={`${getSesion.id == cht.author_id ? 'bg-blue-900 float-right' : 'bg-Light-purple float-left'} py-1 px-2 m-2 w-3/4 md:w-1/2 rounded-md`}>
                                                                            {cht.message}
                                                                            <div className="text-gray-500 text-xs">
                                                                                {cht.hours}:{cht.minutes} {/*cht.date*/}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {/* <div ref={messagesEndRef} /> */}
                                                            </div>
                                                            {/* <div className="overflow-y-scroll h-96">
                                                        
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="my-4 h-10 w-full">
                                                        {/* <div className="bg-bluecard-big p-1 rounded w-4/5">
                                                    <div className="text-white">
                                                        
                                                    </div>
                                                </div> */}
                                                        {(params.projectState != "1" && params.profile == "freelancer") &&
                                                            <form action="" className="flex flex-wrap w-full" onSubmit={sendMessageChat}>
                                                                <input
                                                                    type="text"
                                                                    className="form-control block w-4/5 px-4 py-2 text-lg font-normal text-white bg-clip-padding border border-solid border-transparent rounded-l-lg transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                                                    placeholder="Mensaje..."
                                                                    name="message"
                                                                    value={formChat.message}
                                                                    // value={infoProject.description}
                                                                    onChange={handleChange}
                                                                />
                                                                <button className="bg-Yellow hover:bg-Light-Blue rounded-r-lg text-white w-1/5 flex justify-center items-center"><span className="hidden md:block px-2">Enviar</span> <FontAwesomeIcon icon={faPaperPlane} /></button>
                                                            </form>
                                                        }

                                                        {(params.projectState != "3" && params.profile == "empresa") &&
                                                            <form action="" className="flex flex-wrap w-full" onSubmit={sendMessageChat}>
                                                                <input
                                                                    type="text"
                                                                    className="form-control block w-4/5 px-4 py-2 text-lg font-normal text-white bg-clip-padding border border-solid border-transparent rounded-l-lg transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                                                    placeholder="Mensaje..."
                                                                    name="message"
                                                                    value={formChat.message}
                                                                    // value={infoProject.description}
                                                                    onChange={handleChange}
                                                                />
                                                                <button className="bg-Yellow hover:bg-Light-Blue rounded-r-lg text-white w-1/5 flex justify-center items-center"><span className="hidden md:block px-2">Enviar</span> <FontAwesomeIcon icon={faPaperPlane} /></button>
                                                            </form>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )
            }

        </>
    )
}

export default Chatprojects;