import { Link } from "react-router-dom";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import CreateProjectComponent from "../components/createProject/createProject";
import UpdatedProjectCompany from "../components/createProject/updatedProyect";


const UpdatedProjectView = () =>{
    return(
        <>
            <div className="bg-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                        <img className="opacity-50 py-10 h-1/2 lg:h-3/4 xl:h-5/6" src={imagenesEditProject.logo_blanco_mitad} alt="" />
                    </div>
                    <div className="md:w-full lg:w-4/5 flex flex-wrap justify-center pb-5 lg:py-5 md:pt-4 lg-pt-0">
                        <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                            <Link to="" onClick={()=>window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                            </Link>
                        </div>

                        <UpdatedProjectCompany></UpdatedProjectCompany>

                    </div>
                </section>
            </div>
        </>
    )
}

export default UpdatedProjectView;