

import React from 'react';
import { Link } from 'react-router-dom';


function PolIticadeCookies() {
    return (
        <div className="flex-row" id="politicasChambaticon">

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 p-5 rounded-lg text-justify text-white font-medium">
                    <div className="w-full my-5">
                        <Link to="" onClick={() => window.location.href = "/"}>
                            <img src="/static/media/boton_back2_boton2.2da335d0bf766228caf2.png" className="w-15% sm:w-10% md:w-5%" alt="" />
                        </Link>
                    </div>
                    <h1 className="text-orange-500 font-bold text-2xl text-center pb-8 ">Política de Cookies</h1>
                    <h3><span className='text-orange-500'>CHAMBATICON</span> comunica a los usuarios, a través de este aviso, que puede utilizar cookies cuando el usuario navega por las diferentes pantallas y páginas del sitio. Durante el uso de nuestra plataforma usted acepta y autoriza expresamente el uso de cookies, de acuerdo con nuestra Política de Privacidad.</h3>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">1. ¿Qué son las cookies?</h1>
                    <p>Una cookie es un archivo de texto pequeño que un servidor web puede guardar en el disco duro de un equipo para almacenar información sobre el usuario. La cookie identifica el equipo de forma única y solo puede ser leída por el sitio web que la envió al equipo. No es un archivo ejecutable ni un programa, por lo que no puede propagar virus u otro software malicioso, ni puede tener una longitud superior a 4.000 caracteres.</p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">2. ¿Para qué sirven las cookies?</h1>

                    <p className='py-4'>Las cookies tienen diversas finalidades, como:</p>
                    <ul>
                        <li>• Análisis: Permiten cuantificar el número de usuarios y realizar análisis estadísticos para mejorar el servicio.</li>
                        <li>• Personalización: Recordar preferencias del usuario para ofrecer una experiencia de navegación más fluida y personalizada.</li>
                        <li>• Funcionalidad: Ayudan al usuario a tener una mejor experiencia de navegación.</li>
                        <li>• Publicidad: Mostrar anuncios relevantes a los intereses del usuario.</li>
                    </ul>
                    <p className='py-4'>Chambaticon utiliza cookies para reconocer a los usuarios registrados, obtener información sobre la fecha y hora de la última visita del usuario, medir parámetros de tráfico y personalizar la experiencia de navegación.</p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">

                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">3.Tipos de cookies utilizadas:</h1>

                    <p>Pueden encontrarse las cookies de Chambaticon en la configuración del navegador del usuario. La plataforma de Chambaticon utiliza los siguientes tipos de cookies: <br /> </p>


                    <h1 className="text-orange-500 text-lg py-4">
                        Cookies analíticas: <br />
                        <span className='text-white'>estas cookies se utilizan para analizar el comportamiento de los usuarios de forma agregada y anónima incluyendo el número de visitantes a la plataforma y a diferentes fichas de producto, la procedencia de las visitas, día y hora, plataforma, número de clics en una ficha, palabras de búsqueda que utiliza el usuario para encontrar su contenido deseado. De esta forma Chambaticon se sirve de un medio de gran utilidad para poder introducir mejoras en la plataforma y saber qué contenido o diseño es más relevante para el usuario.</span>
                    </h1>

                    <h1 className="text-orange-500 text-lg py-4">
                        Cookies funcionales: <br /> <span className='text-white'>son cookies que ayudan al usuario a tener una mejor experiencia de la navegación por la plataforma. Un ejemplo de uso de este tipo de cookies son las que se utilizan para almacenar los datos de la última búsqueda de un producto para autocompletar el buscador y últimos datos consultados por el usuario.</span>

                    </h1>

                    <h1 className="text-orange-500 text-lg py-4">Cookies técnicas: <br /> <span className='text-white'>las cookies técnicas son necesarias para mostrar correctamente la página web y garantizar el correcto funcionamiento la plataforma.</span>
                    </h1>

                    <h1 className="text-orange-500 text-lg py-4">Cookies de publicidad: <br /> <span className='text-white'>utilizamos estas cookies con el fin de mostrar anuncios en otras páginas para ofrecer anuncios más adecuados a los intereses de los usuarios. En este caso tenemos tanto cookies propias como cookies almacenados por terceros</span>
                    </h1>

                    <p className='py-4'>
                        Le aseguramos que ninguna de ellas conserva información de identificación personal sobre usted. Algunas cookies personalizan su visita, otras recuerdan sus preferencias, mientras que otras solo nos proveen estadísticas de los visitantes.
                    </p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">

                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">

                    <h1 className="text-orange-500 text-xl">4.¿Cómo puede controlar las cookies?</h1>

                    <p>Puede controlar y administrar el uso de cookies en su navegador. La mayoría de los navegadores web le permiten rechazar o aceptar cookies, así como eliminar las cookies existentes. Tenga en cuenta que si elige rechazar las cookies, es posible que no pueda utilizar todas las funciones de nuestra plataforma. </p>
                    <br />

                    <p>Para obtener más información sobre cómo controlar las cookies en su navegador, consulte la sección de ayuda o soporte de su navegador.</p>

                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">

                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">

                    <h1 className="text-orange-500 text-xl">5.Cambios en esta política</h1>

                    <p>Nos reservamos el derecho de modificar esta política de cookies en cualquier momento y a nuestra sola discreción. Cualquier cambio en esta política se publicará en esta página con una fecha de actualización revisada. Le recomendamos que revise periódicamente esta página para estar al tanto de cualquier cambio. El uso continuado de nuestra plataforma después de la publicación de los cambios constituirá su aceptación de dichos cambios.</p>


                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">6. Contacto</h1>
                  <p>Si tiene alguna pregunta, inquietud o solicitud con respecto a esta Política de Privacidad,  no dudes en ponerte en contacto con nosotros a través de los siguientes medios: </p>
                  <ul>
                     <li>• Correo electrónico: hello@ticongle.agency</li>
                     <li>• Número de teléfono: 2249-2301</li>
                     <li>• Dirección: Calle Reforma, Local 251, Colonia San Benito, en la ciudad de San Salvador, República de El Salvador</li>
                  </ul>

               </div>
            </div>
































        </div>
    )
}

export default PolIticadeCookies