import imagenesHome from "../../assets/imgHome/imagenesHome";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faInstagram, faInstagramSquare, faLinkedin, faTwitterSquare, faWhatsapp, faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";

import { Link, NavLink } from "react-router-dom";
import { useState } from "react";

const Footer = () => {

	const [yearToday, setYeartoday] = useState<Date>(new Date);

	return (
		<>
			<footer>
				<div className="bg-Dark-purple py-10 grid sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-3 px-3 md:px-24">
					<div className="flex md:p-0 p-5">
						<Link className="self-center" to={''}>
							<img src={imagenesHome.chamba_ticon} alt="" />
						</Link>
					</div>
					<div className="py-4">
						<ul className="text-center">
							<li className="text-white  lg:text-sm text-xs font-semibold py-1"><Link to="/quienes-somos">QUIÉNES SOMOS</Link></li>
							<li className="text-white  lg:text-sm text-xs font-semibold py-1"><Link to="/Contactanos">CONTÁCTANOS</Link></li>
							<li className="text-white  lg:text-sm text-xs font-semibold py-1"><Link to="/Terminos-y-Condiciones">TERMINOS Y CONDICIONES</Link></li>
							<li className="text-white  lg:text-sm text-xs font-semibold py-1"><Link to="/politica-de-chambaticon">POLÍTICAS DE PRIVACIDAD</Link></li>
							<li className="text-white  lg:text-sm text-xs font-semibold py-1"><Link to="/Politica-de-cookies">POLITICA DE COOKIES</Link></li>

						</ul>
					</div>
					<div className="flex items-center justify-center md:py-0 py-3">
						<a target="_blank" href="https://www.facebook.com/Chambaticon-103086529154814/"><FontAwesomeIcon className="text-white hover:text-Yellow transition-colors px-5" size="2x" icon={faFacebookSquare} /></a>
						{/* <a target="_blank" href="#"><FontAwesomeIcon className="text-white hover:text-Yellow transition-colors px-5" size="2x" icon={faTwitterSquare} /></a> */}
						<a target="_blank" href="https://instagram.com/chambaticon?igshid=YmMyMTA2M2Y="><FontAwesomeIcon className="text-white hover:text-Yellow transition-colors px-5" size="2x" icon={faInstagram} /></a>
						{/* <a target="_blank" href="#"><FontAwesomeIcon className="text-white hover:text-Yellow transition-colors px-5" size="2x" icon={faWhatsapp} /></a> */}
						<a target="_blank" href="https://www.linkedin.com/company/chambaticon/"><FontAwesomeIcon className="text-white hover:text-Yellow transition-colors px-5" size="2x" icon={faLinkedin} /></a>
					</div>
				</div>
				<section className="bg-purple-bfooter py-5">
					<div className="grid grid-cols-1 sm:grid-cols-3">
						<div>
							<h6 className=" text-white text-center text-sm font-semibold">© {yearToday.getFullYear()} Chambaticon</h6>
						</div>
						<div className="hidden sm:block"></div>
						<div className=" text-white text-sm text-center font-semibold">Hecho en El Salvador por <a target="_blank" href="https://conexion.sv" className="text-Yellow">CONEXION</a></div>
					</div>
				</section>
			</footer>
		</>
	)
}

export default Footer;