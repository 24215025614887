import React, { useRef, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Link } from 'react-router-dom';
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";

const PaymentForm = () => {

  const datosCvc = {
    cvc: ""
  };
  interface InterfaceDatosCvc {
    cvc: string
  }
  const datosExpiry = {
    expiry: "",
  };
  interface InterfaceDatosExpiry {
    expiry: string
  }
  const datosName = {
    name: ""
  };
  interface InterfaceDatosName {
    name: string
  }
  const datosNumber = {
    number: ""
  };
  interface InterfaceDatosNumber {
    number: string
  }

  const [name, setName] = useState<InterfaceDatosName>(datosName);

  const [expiry, setExpiry] = useState<InterfaceDatosExpiry>(datosExpiry);

  const [cvc, setCvc] = useState<InterfaceDatosCvc>(datosCvc);

  const [number, setNumber] = useState<InterfaceDatosNumber>(datosNumber);

  const [isFrontOfCardVisible, setIsFrontOfCardVisible] = useState(true);


  return (
    <>
      <div className="bg-gradient-to-b from-Light-purple via-turquoise to-turquoise">
        <section className="block md:flex md:flex-wrap h-full -mt-px">
          <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
            <img className="opacity-50 py-10 h-1/2 lg:h-96 xl:h-35" src={imagenesEditProject.logo_blanco_mitad} alt="" />
          </div>
          <div className="w-full lg:w-4/5 mb-12 md:mb-0 flex flex-wrap justify-start pb-5 px-6">
            <div className="w-full sm:w-5% mt-4 md:mt-4">
              <Link to="" onClick={() => window.history.go(-1)}>
                <img src={imagenesEditProject.arrow_back_4} className="w-10% sm:w-full md:mx-4" alt="" />
              </Link>
            </div>
            <div className="w-full md:w-95% flex justify-center">
              <div className="w-full md:w-95%">
                <div className="flex justify-center pb-0 lg:pb-12">
                  <h2 className="text-white text-2xl md:text-3xl text-center font-semibold py-2 md:py-8">Detalles de la suscripción</h2>
                </div>
                <div className="flex flex-wrap p-5">
                  <div className="w-full sm:w-1/2">
                    <Cards
                      cvc={cvc.cvc}
                      expiry={expiry.expiry}
                      name={name.name}
                      number={number.number}
                      focused={isFrontOfCardVisible ? "number" : "cvc"}
                    />
                  </div>
                </div>

                <form>
                  <input
                    type="text"
                    name="nombre"
                    placeholder="Nombre del titular"
                    onChange={(event) => setName({ name: event.target.value })}
                  />
                  <input
                    type="number"
                    name="number"
                    placeholder="Numero de tarjeta"
                    onChange={(event) => setNumber({ number: event.target.value })}
                  />
                  <input
                    type="number"
                    name="cvc"
                    placeholder="CVC"
                    onBlur={(event) => { setIsFrontOfCardVisible(true); }}
                    onFocus={(event) => { setIsFrontOfCardVisible(false); }}
                    onChange={(event) => {
                      setCvc({
                        cvc: event.target.value
                      });
                    }}
                  />
                  <input
                    type="number"
                    name="expiry"
                    placeholder="Expiración"
                    onChange={(event) => setExpiry({ expiry: event.target.value })}
                  />
                </form>
              </div>
            </div>
          </div>

        </section>
      </div>

    </>
  );
}
export default PaymentForm;