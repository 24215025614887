
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const optionLoginSkeleton =  () =>{


    return(
       <>
          <section>
             <hgroup className="text-center">
                <h1 className="text-white text-2xl py-8"><Skeleton count={1} /></h1>
                <h4 className="text-white"><Skeleton count={1} /></h4>
             </hgroup>
             <div className="flex flex-wrap justify-center my-8">
                <button
                   className="m-4 inline-flex items-center rounded-full border-0 py-1 px-6 transition-colors focus:outline-none lg:text-base md:text-sm mt-4 md:mt-0 text-white"
                >
                   <Skeleton count={1} />
                </button>
                <button
                   className="m-4 inline-flex items-center rounded-full  border-0 py-1 px-6 transition-colors focus:outline-none lg:text-base md:text-sm mt-4 md:mt-0 text-white"
                >
                   <Skeleton count={1} />
                </button>
             </div>
          </section>
       </>
    )
}

export default optionLoginSkeleton;
