import imagenesDetallesProject from "../assets/imgDetallesProject/imgDetallesProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcaseMedical } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { HandleSession } from "../function/Login";
import imgDefault from '../assets/imgEditPerfil/user.png';

import CardsFreelancerSkeleton from '../components/Skeleton/cardsFreelancer/cardsFreelancerSkeleton';

import LazyLoad from 'react-lazyload';
import { TransitionGroup } from 'react-transition-group';

import imgListFreeLancer from '../assets/imgs/no_hay_freelances.svg';

// Modal de opción para iniciar sesión como freelancer o empresa
import ModalLogin from '../components/modals/modalLogin';


const initialState = {
    about_me: null || undefined || "",
    id: 0,
    lastnames: "",
    freelancer: "",
    proyectos_finalizados: 0,
    names: null || undefined || "",
    photo: "",
    specializations: [{ nombre: "" }],
    state: "",
    filter1: "",
    filter2: "",
    filter3: "",
    filter4: ""
};



// Types
type handleSeekerType = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const initialStateListSpecialization = {
    icon: "",
    id: "",
    name: ""
};

const ListFreelanceCategory = () => {

    const [specializations, setSpecializations] = useState([initialStateListSpecialization]);

    const [listFreelancer, setListFreelancer] = useState([initialState]);

    const [InitialStateList, setInitialStateList] = useState([initialState]);

    const [IsLoading, setIsLoading] = useState(false);

    const [MostrarImg, setMostrarImg] = useState(imgDefault);
    // validate buttons and logo
    const verifySession = HandleSession();

    const [messageDefault, setMessageDefault] = useState(false);

    const [Seeker, setSeeker] = useState('');

    // states modals
    const [addClassModal, setAddClassModal] = useState("hidden");

    const [IsLoadingModal, setIsLoadingModal] = useState(false);

    // form input event
    const handleSeeker = ({ target: { name, value } }: handleSeekerType) => {
        setSeeker(value);
        filterFreeLancer(value);
    };

    const filterFreeLancer = (value: string) => {

        if (!value) {
            setListFreelancer(InitialStateList);
        } else {

            const data = listFreelancer.filter((item, index) => {

                if (item.about_me == null || item.about_me == "null" || item.about_me == "" || item.about_me == undefined) {

                } else {
                    if (item.about_me.toLowerCase().includes(value.toLocaleLowerCase()) || item.names.toLowerCase().includes(value.toLocaleLowerCase()) || item.filter1.toLowerCase().includes(value.toLocaleLowerCase()) || item.filter2.toLowerCase().includes(value.toLocaleLowerCase()) || item.filter3.toLowerCase().includes(value.toLocaleLowerCase()) || item.filter4.toLowerCase().includes(value.toLocaleLowerCase()) || item.state.toLowerCase().includes(value.toLocaleLowerCase())) {

                        return item;
                    }
                }

            });

            if (data.length > 0) {
                setListFreelancer(data);
            } else {
                setListFreelancer(InitialStateList);
            }
        }

    };

    const params = useParams();

    useEffect(() => {

        async function fetchData() {

            let urlListFreelancer = "";

            if (params.id) {
                urlListFreelancer = `${process.env.REACT_APP_API_URI}freelancer/search?id=${params.id}`;
            } else {
                urlListFreelancer = `${process.env.REACT_APP_API_URI}freelancer/search`;
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };

            try{

                const resp = await axios.get(urlListFreelancer, config,);
               
                setMessageDefault(false);
                setIsLoading(true);


                const ListFreelancer = resp.data.map((profile: any) => {

                    const result = Array.from(new Set(profile.specializations.original.data.map((s: { category_id: any; }) => s.category_id)))
                        .map(id => {
                            return {
                                nombre: profile.specializations.original.data.find((s: { category_id: unknown; }) => s.category_id === id).specialization_category,
                                id: profile.specializations.original.data.find((s: { category_id: unknown; }) => s.category_id === id).category_id
                            };
                        });

                    let imgCompany = "";

                    if (profile.photo === null || profile.photo === "") {
                        imgCompany = imgDefault;
                    } else {
                        imgCompany = `${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${profile.photo}`;
                    }

                    // console.log('result-info-info', result);

                    const filter1 = (result[0] == undefined) ? "1" : result[0].nombre;
                    const filter2 = (result[1] == undefined) ? "1" : result[1].nombre;
                    const filter3 = (result[2] == undefined) ? "1" : result[2].nombre;
                    const filter4 = (result[3] == undefined) ? "1" : result[3].nombre;

                    const data = {
                        about_me: profile.about_me,
                        id: profile.id,
                        lastnames: profile.lastnames,
                        names: profile.names,
                        photo: imgCompany,
                        proyectos_finalizados: profile.proyectos_finalizados,
                        specializations: result,
                        state: profile.state,
                        filter1: filter1,
                        filter2: filter2,
                        filter3: filter3,
                        filter4: filter4
                    }

                    return data;
                    // }
                })

                setListFreelancer(ListFreelancer);
                setInitialStateList(ListFreelancer);
                setTimeout(() => { setIsLoading(false) }, 1000);

            } catch (err) {
                setMessageDefault(true);
            };
        };
        fetchData();

        async function getSpecialties() {

            const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };
            try {

                const resp = await axios.get(urlCountry, config);
                const specialtyListHome = resp.data.specializationTypes.filter((item: { id: string | undefined; }) => item.id == params.id);

                setSpecializations(specialtyListHome);

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        getSpecialties();

    }, []);

    const upModal = (state: Boolean) => {
        // console.log("CERRAR");
        if (state) {
            setAddClassModal("modalOpenProject");
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        } else {
            setAddClassModal("modalCloseProject");
            // console.log("CERRAR");
            document.getElementsByTagName("body")[0].style.overflow = "visible";
        }
        setIsLoadingModal(true);
        setInterval(() => {
            setIsLoadingModal(false);
        }, 1000);
    };

    return (
        <>
            <div className="bg-gradient-to-b from-Light-purple via-turquoise to-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    <div className="hidden lg:flex w-10% bg-gradient-to-b from-Light-purple via-turquoise to-turquoise flex justify-end">
                        <img className="" src={imagenesDetallesProject.fondo_cadena} alt="" />
                    </div>
                    <div className="md:w-full lg:w-90% flex justify-center pb-5 lg:py-5">
                        <div className="mx-1 lg:mx-10 w-full">
                            <div className="flex justify-center pb-2 lg:pb-4 mt-4 lg:mt-0">
                                {/* <h1 className="text-white font-bold text-xl sm:text-3xl roboto text-Yellow">Freelancers en chambaticon</h1> */}
                                <h1 className="text-white font-bold text-lg sm:text-2xl roboto text-Yellow">Encuentra al freelancer de {specializations[0].name}</h1>
                            </div>
                            <div className="flex justify-center">
                                <div className="mb-6 text-start w-full md:w-1/2">
                                    {/* <span className="text-white">{Seeker}</span> */}
                                    <input
                                        type="text"
                                        className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        name="direction"
                                        placeholder="Buscar..."
                                        value={Seeker}
                                        onChange={handleSeeker}
                                    />

                                </div>
                            </div>

                            {(messageDefault) &&
                                <div>
                                    <p className="text-center text-white text-xl mt-6 ">No se encuentran freelancer de {specializations[0].name} registrados</p>
                                    <div className="flex justify-center">
                                        <img src={imgListFreeLancer} alt="" width="51%" />
                                    </div>
                                </div>
                            }


                            <div className="grid md:gap-4 lg:gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:mx-4">

                                {!(messageDefault) &&
                                    <>
                                        {IsLoading && <CardsFreelancerSkeleton cards={6} />}

                                        {!IsLoading &&
                                            (listFreelancer[0].names != "" &&

                                                listFreelancer.map((freelancer) => {
                                                    return (
                                                        <>
                                                            <LazyLoad throttle={700} height={400}>
                                                                <TransitionGroup key="1"
                                                                    timeOut={{ enter: 5000, exit: 300 }}
                                                                    transitionEnter={true}
                                                                    transitionLeave={true}
                                                                    data-aos="fade-up"
                                                                >
                                                                    <div className="w-full">
                                                                        <div className="flex flex-wrap">
                                                                            <div className="my-2 w-full pr-2 2xl:p-2">
                                                                                <div className="bg-purple-cards rounded flex flex-wrap px-4 sm:px-8 py-3">
                                                                                    <div className="flex justify-center items-center w-full md:w-22% lg:w-1/4 xl:w-1/4 2xl:w-33%">
                                                                                        <img src={`${freelancer.photo}`} className="h-24 w-24 md:h-16 md:w-16 lg:h-16 lg:w-16 rounded-full overflow-hidden object-cover object-center" alt="" />
                                                                                    </div>
                                                                                    <div className="text-left w-full sm:w-3/4 md:w-78% lg:w-3/4 xl:w-3/4 2xl:w-67% md:pl-2 mt-3 md:mt-1">
                                                                                        <div>
                                                                                            <h1 className="text-white text-sm font-bold h-10 overflow-hidden">{freelancer.names} {freelancer.lastnames}</h1>
                                                                                            <h3 className="text-white text-sm text-Dark-purple font-bold">{freelancer.state}</h3>
                                                                                            <h3 className="text-white text-sm text-Dark-purple font-bold">Proyectos finalizados: <span className="text-white">{freelancer.proyectos_finalizados}</span></h3>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full">
                                                                                        <div className="h-10 2xl:h-12 py-2 overflow-hidden">
                                                                                            <div className="flex flex-wrap items-center justify-between">
                                                                                                {
                                                                                                    freelancer.specializations.map((specialization: any, index) => {
                                                                                                        if (specialization.id == params.id) {
                                                                                                            return (
                                                                                                                <div className="bg-cyan-700 px-2 py-1.5 mr-1 mt-1 my-2 rounded text-2xs sm:text-xs text-white flex items-center">{specialization.nombre}</div>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                }


                                                                                                {
                                                                                                    ((freelancer.specializations.length > 1) &&
                                                                                                        <div>
                                                                                                            <FontAwesomeIcon className="text-white" icon={faBriefcaseMedical} />
                                                                                                        </div>
                                                                                                    )
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full h-14 2xl:h-18 overflow-hidden">
                                                                                        <p className="text-white text-xs sm:text-xs text-left mt-2 h-24 overflow-hidden">{freelancer.about_me}</p>
                                                                                    </div>
                                                                                    <div className="w-full text-xs flex justify-end">


                                                                                        {(verifySession.Session == false) ? (
                                                                                            <button
                                                                                                className="bg-Dark-purple w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded px-4 py-1.5 mt-1"
                                                                                                onClick={() => upModal(true)}
                                                                                            >
                                                                                                Ver perfil
                                                                                            </button>
                                                                                            // <Link to={`/Login`}
                                                                                            // className="bg-Dark-purple w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded px-4 py-1.5 mt-1">
                                                                                            //     Ver perfil
                                                                                            // </Link>
                                                                                        ) : (
                                                                                            <Link to={`/freelancer/${freelancer.id}`}
                                                                                                className="bg-Dark-purple w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded-lg px-4 py-1.5 mt-1">
                                                                                                Ver perfil
                                                                                            </Link>
                                                                                        )}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TransitionGroup>
                                                            </LazyLoad>
                                                        </>
                                                    )
                                                })
                                            )
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ModalLogin addClassModal={addClassModal} setAddClassModal={setAddClassModal} IsLoadingModal={IsLoadingModal} />
        </>
    )
}

export default ListFreelanceCategory;