import { stringify } from "querystring";
import { alertLogin, Input } from "../../Interfaces/Login";



export const ValidateEmail = (data:Input[]) =>{
   // export const ValidateEmail = () =>{

   let errors: alertLogin[] = [];

   const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   
   const regExUrl = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;

      data.map((input) =>{

      switch(input.name) { 

         case 'name': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 
         
         case 'institution': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'company_contact_name': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'nickname': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'nit': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 
         
         case 'lastnames': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });

            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'gmail': { 
            if (input.value === '' || input.value === null) {
               errors.push({
                  input:input.name,
                  message:'Por favor ingresa tu correo electrónico.',
                  state:true
               });
            }else if (!isValidEmail.test(input.value)) {
               errors.push({
                  input:input.name,
                  message:'Ingresa un correo electrónico válido.',
                  state:true
               });
            
            }else{
             errors.push({
               input:input.name,
               message:'',
               state:false
            })
            }

            break; 
         } 
         
         case 'gmail2': { 
            if (input.value === '' || input.value === null) {
               errors.push({
                  input:input.name,
                  message:'Por favor ingresa tu correo electrónico.',
                  state:true
               });
            }else if (!isValidEmail.test(input.value)) {
               errors.push({
                  input:input.name,
                  message:'Ingresa un correo electrónico válido.',
                  state:true
               });
            
            }else{
             errors.push({
               input:input.name,
               message:'',
               state:false
            })
            }

            break; 
         } 
       
         case 'passwordLogin': {

            if (input.value === '' || input.value === null) {
   
               errors.push({
                  input:input.name,
                  message:'Por favor ingresa tu Contraseña',
                  state:true
               });


            }else{
               errors.push({
                  input:input.name,
                  message:'',
                  state:false
               });
            }
            
            break; 
         } 

         case 'password': {

            if (input.value === '' || input.value === null) {
   
               errors.push({
                  input:input.name,
                  message:'Por favor ingresa tu Contraseña',
                  state:true
               });


            } else if (input.value.length < 8) {

               errors.push({
                  input:input.name,
                  message:'Contraseña debe tener un mínimo de 8 caracteres.',
                  state:true
               });

                        
            }else{
               
               var mayuscula = false;
               var minuscula = false;
               var numero = false;
               var caracter_raro = false;
               
               for(var i = 0;i<input.value.length;i++){
                     if(input.value.charCodeAt(i) >= 65 && input.value.charCodeAt(i) <= 90){
                        mayuscula = true;
                     }
                     else if(input.value.charCodeAt(i) >= 97 && input.value.charCodeAt(i) <= 122){
                        minuscula = true;
                     }
                     else if(input.value.charCodeAt(i) >= 48 && input.value.charCodeAt(i) <= 57){
                        numero = true;
                     }else{
                        caracter_raro = true;
                     }
               }
               

               if(mayuscula == true && minuscula == true && caracter_raro == true && numero == true){
                     errors.push({
                        input:input.name,
                        message:'',
                        state:false
                     })
                  }else{

                     errors.push({
                        input:input.name,
                        message:'Ingresa una combinación de al menos 8 caracteres, Entre una letra minúscula, mayúscula, número y caracteres especial.',
                        state:true
                     });     
               }
               
            }
            
            break; 
         } 

         case 'confirmPassword': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Por favor ingresa su Contraseña',
                  state:true
               });


            } else if (input.value.length < 8) {

               errors.push({
                  input:input.name,
                  message:'Contraseña debe tener un mínimo de 8 caracteres.',
                  state:true
               });
                        
            }else{
               var mayuscula = false;
               var minuscula = false;
               var numero = false;
               var caracter_raro = false;
               
               for(var i = 0;i<input.value.length;i++){
                     if(input.value.charCodeAt(i) >= 65 && input.value.charCodeAt(i) <= 90){
                        mayuscula = true;
                     }
                     else if(input.value.charCodeAt(i) >= 97 && input.value.charCodeAt(i) <= 122){
                        minuscula = true;
                     }
                     else if(input.value.charCodeAt(i) >= 48 && input.value.charCodeAt(i) <= 57){
                        numero = true;
                     }else{
                        caracter_raro = true;
                     }
               }
               

               if(mayuscula == true && minuscula == true && caracter_raro == true && numero == true){
                     errors.push({
                        input:input.name,
                        message:'',
                        state:false
                     })
                  }else{

                     errors.push({
                        input:input.name,
                        message:'Ingresa una combinación de al menos 8 caracteres, Entre una letra minúscula, mayúscula, número y caracteres especial.',
                        state:true
                     });     
               }
            }
            
            
            break; 
         } 
         
         case 'specialty': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });

            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            
            break; 
         } 
         
         case 'dateOfBirth': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });

            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            
            break; 
         } 
         
          case 'country': {

            if (input.value === '' || input.value === null || input.value === "default") {
            
               errors.push({
                  input:input.name,
                  message:'Pais requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'distrito': {

            if (input.value === '' || input.value === null || input.value === "default") {
            
               errors.push({
                  input:input.name,
                  message:'Distrito requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'department': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Departamento requerido',
                  state:true
               });
               
            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'municipality': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Municipio requerido ',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'address': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido ',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'areaCode': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Código de área requerido ',
                  state:true
               });

            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               });
            }
            
            break; 
         } 

         case 'tel': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Celular requerido  ',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'tel2': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Teléfono secundario requerido  ',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'sex': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 
         
         case 'dui': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 
         
         case 'schedules': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'parent': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 
         
         case 'language': {

            if (Number(input.value) === 0) {
      
               errors.push({
                  input:input.name,
                  message:'Seleccionar un idioma ',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
         
         case 'amount': {

            if (Number(input.value) === 0) {
      
               errors.push({
                  input:input.name,
                  message:'Selecciona una habilidad',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'documents': {

            if (input.value === "" || input.value === null) {
      
               errors.push({
                  input:input.name,
                  message:'Documento/s requeridos',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'receiveDocuments': {

            if (input.value === "" || input.value === null) {
      
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
         
         case 'profile': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 
         
         case 'description': {

            if (input.value === '' || input.value === null || input.value === "No se proporcionó ninguna descripción. ¡Haznos saber más sobre ti!") {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'cargo': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'level': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'dateInit': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'dateEnd': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'publication_date': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'stateProfile': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'budget': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'economic_activity': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
   
         case 'termsAndConditions': {

            if (input.value === "false") {
            
               errors.push({
                  input:input.name,
                  message:'Términos y Condiciones requeridos',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
       
         case 'facebook': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'instagram': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'twitter': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'linkedin': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         } 

         case 'billing': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
         
         case 'iva': {

            if (input.value ===  ""|| input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'method': {

            if (input.value === '' || input.value === null || input.value === ' ') {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'method_details': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
       
         case 'typeService': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'typeSalary': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'Deadline': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'salary': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
         
         case 'descrption': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
         

         case 'textRequired': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'urlRequired': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })


            } else if (!regExUrl.test(input.value)) {
               errors.push({
                  input:input.name,
                  message:'Ingresa una url valida',
                  state:true
               });
            
            }
            else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
 
         default: { 
            // errors.push({
            //    input:"requeridos",
            //    message:'Campos requeridos',
            //    state:true
            // });
            break; 
         } 
      } 
   })
 
   return errors;
  
}