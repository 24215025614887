import imagenesDetallesProject from "../assets/imgDetallesProject/imgDetallesProject";
// import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/modals/Modal";
import { useEffect, useState } from "react";
import axios from "axios";
import { ProjectInfo } from "../Interfaces/InfoProyect";
import { HandleSession } from "../function/Login";
import CardsProjectSkeleton from "../components/Skeleton/cardsProyect/cardsProyectSkeleton";
import LazyLoad from 'react-lazyload';
import { TransitionGroup } from 'react-transition-group';
import React, {Suspense} from "react";

import imgListProyect from '../assets/imgs/no_hay_proyectos.svg';
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";

const initialState = {
    assigned_status: 0,
    company_project_file:"",
    accept_proposals_files:0,
    company_name: "",
    photo:"",
    budget: "",
    created_at: "",
    delivery_date: "",
    description: "",
    end_date: "",
    file_folder_path: "",
    freelance_id: 0,
    id: 0,
    knowledges_tags:[],
    project_name: "",
    public_info: 0,
    publicationStatus: 0,
    publication_date: "",
    s_types_id: 0,
    status: 0,
    updated_at: "",
    user_company_id: 0
}
const initialStateListSpecialization = {
    icon:"",
    id:"",
    name:""
 };

const ListProjectCategory = () =>{

    const [specializations, setSpecializations] = useState([initialStateListSpecialization]);


    const [listProject, setListProject] = useState([initialState]);

    const [infoProject, setInfoProject] = useState<ProjectInfo>(initialState);

    // console.log('info-Project', infoProject);

    const [addClassModal, setAddClassModal] = useState("modalCloseProject");

    const [IsLoading, setIsLoading] = useState(false);

    const [IsLoadingModal, setIsLoadingModal] = useState(false);

    const [messageDefault, setMessageDefault] = useState(false);
    
    const [StatusProfileFreelancer, setStatusProfileFreelancer] = useState(0);

    // Cookies
    const cookies = new Cookies();

    //Knodledges click
    const [knowledgesChanges, setknowledgesChanges] = useState(false);

    const changesKnowledges = () => {
        if(knowledgesChanges){
            setknowledgesChanges(false);
        }else{
            setknowledgesChanges(true);
        };
    };

    // 
    const verifyTypeSession = HandleSession();   
      
    const showModalProject = (state:Boolean, id:Number | undefined) =>{
        // console.log("CERRAR");
        if(state){
            setAddClassModal("modalOpenProject");
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        }else{
            setAddClassModal("modalCloseProject");
            // console.log("CERRAR");
            document.getElementsByTagName("body")[0].style.overflow = "visible";
        };

        getInfoProject(id);
        setIsLoadingModal(true);

    };


    const getInfoProject = async (idProject:Number | undefined) =>{

        if(idProject != null || idProject != undefined){

            const urlCountry = `${process.env.REACT_APP_API_URI}project/get/${idProject}`;
        
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    }
            };
            
            try{

                const resp = await axios.get(urlCountry, config);
                
                setInfoProject(resp.data);
                
                setTimeout(() =>{ setIsLoadingModal(false) }, 2000);

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        }else{
            setInfoProject(initialState);
        }

   }


   const params = useParams();

    useEffect(() => {

         async function getProject() {

                let urlGetProject = "";

                if(params.id){
                    urlGetProject = `${process.env.REACT_APP_API_URI}project/search?category=${params.id}`;
                }else{
                    urlGetProject = `${process.env.REACT_APP_API_URI}project/search`;
                };

               let config = {
                  headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      }
               };
              
               try {

                  const resp = await axios.get(urlGetProject, config);
                  
                  if(resp.data.length != 0){

                      setMessageDefault(false);     
                      setIsLoading(true);
                      setListProject(resp.data);
                    
                      setTimeout(() => { setIsLoading(false) }, 1000);
                  
                }else{
                    setMessageDefault(true);
                };
     
              } catch (err) {
                 console.error(err);
             }

           };

           getProject();

           async function getInfoUser() {
           
            const idUser = cookies.get('Bearer');
            const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/profile/${idUser.id}`;
            
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };

            try{

                const resp = await axios.get(urlCountry, config);
                setStatusProfileFreelancer(resp.data.data.complete_profile_status);
            
            }catch(err){
                console.error(err);
            };
        };
        getInfoUser();

        async function getSpecialties() {
        
            const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;
        
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };

            try{
        
                const resp = await axios.get(urlCountry, config);
                const specialtyListHome = resp.data.specializationTypes.filter((item: { id: string | undefined; }) => item.id == params.id);
                setSpecializations(specialtyListHome);
        
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        getSpecialties();
     
     }, []);

    return(
        <>
            <div className="bg-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    <div className="hidden lg:flex lg:w-10% bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                        <img className="" src={imagenesDetallesProject.fondo_cadena} alt="" />
                    </div>
                    <div className="bg-gradient-to-b from-Light-purple via-turquoise to-turquoise md:w-full lg:w-90% flex justify-center pb-5 lg:py-5 lg:px-10 px-6">
                        <div className="mx-1 lg:mx-8 w-full">
                            <div className="flex justify-center mt-6 mb-8 lg:mt-0">
                                <h1 className="text-Yellow font-bold text-2xl sm:text-3xl">Proyectos de {specializations[0].name}</h1>
                            </div>
                            
                            {(messageDefault) && 
                                <div>
                                    <p className="text-white text-center text-xl mt-6">No se encuentran proyectos de {specializations[0].name} disponibles</p>    
                                    <div className="flex justify-center">
                                        <img src={imgListProyect} alt="" width="80%" />
                                    </div>
                                </div>
                            }

                            <div className="grid md:gap-4 lg:gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 ">

                                {!(messageDefault) &&
                                    <>
                                         {IsLoading && <CardsProjectSkeleton cards={6} />}
                                            
                                            {!IsLoading && 

                                                <>
                                                    {listProject[0].company_name !="" && 
                                                        listProject.map((item) =>{
                                                            return(
                                                                <>
                                                                <LazyLoad throttle={700} height={400}>
                                                                        <TransitionGroup key="1"
                                                                                timeOut={{ enter: 5000, exit: 300 }}
                                                                                transitionEnter={true}
                                                                                transitionLeave={true}
                                                                                data-aos="fade-up"
                                                                            >
                                                                                <div className="w-full">
                                                                                    <div className="bg-purple-cards shadow-xl rounded-lg px-5 py-1 mb-3">
                                                                                        <div className="flex flex-wrap">
                                                                                            <div className="w-full">
                                                                                                <div className="pt-5">
                                                                                                    <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden h-14 overflow-hidden">{item.project_name}</h1>
                                                                                                    <div className="w-full">
                                                                                                        <div className={`${knowledgesChanges == false ? 'h-10 md:h-12 overflow-hidden': 'h-auto md:h-auto'} py-2`}>
                                                                                                            <div className="flex flex-wrap">

                                                                                                                {item.knowledges_tags.map((know) =>{
                                                                                                                    return(
                                                                                                                        <div className="bg-cyan-700 px-3 py-1.5 mr-2 my-1 rounded text-2xs sm:text-xs text-white flex items-center">{know}</div>

                                                                                                                    )
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className="text-white text-sm text-left h-14 overflow-hidden">{item.description}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="w-full">
                                                                                                <div className="pt-5 flex justify-end">
                                                                                                    {
                                                                                                        (verifyTypeSession.type === 3) &&
                                                                                                        <>
                                                                                                            {(StatusProfileFreelancer === 3 ) &&
                                                                                                                <button
                                                                                                                    className="bg-Yellow w-full sm:w-auto text-sm text-center font-bold text-black rounded-lg px-4 py-1 mb-2" data-bs-toggle="modal" 
                                                                                                                    data-bs-target="#exampleModalXl" 
                                                                                                                    onClick={()=>{showModalProject(true, item.id)}}>
                                                                                                                        Ver proyecto
                                                                                                                </button>
                                                                                                        
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </TransitionGroup>
                                                                    </LazyLoad>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>

                                            }
                                    </> 
                                }
                               


                            </div>
                        </div>
                    </div>
                    </section>
            </div>
            
            <Modal addClass={addClassModal} HandleModal={showModalProject} infoProject={infoProject} IsLoading={IsLoadingModal} />
        </>
    )
}

export default ListProjectCategory;