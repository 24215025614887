import { ChangeEvent, useState } from "react";
import { ValidateEmail } from "../../function/Login";
import { alertLogin } from "../../Interfaces/Login";

import axios from 'axios';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link, useNavigate } from "react-router-dom";
import IconLoading from "../iconLoading/IconLoading";
import ReactInputMask from "react-input-mask";


// Types
type handleInputChange = ChangeEvent<HTMLInputElement>

// Initial states
const initialStateInput = {
   input: "",
   message: '',
   state: false
};


const RecoverPassword = () => {

   const [toggleShowAndHide, setToggleShowAndHide] = useState<Boolean>(false);

   const [StartSesion, setStartSesion] = useState(true);

   const [addClass, setAddClass] = useState<string>("hidden bg-fadeOut");

   const MySwal = withReactContent(Swal);

   const [sendingData, setSendingData] = useState(false);

   const [form, setForm] = useState({
      typeDocument: "",
      document: ""
   });

   const navigate = useNavigate();

   const showModal = () => {
      toggleShowAndHide ? setToggleShowAndHide(false) : setToggleShowAndHide(true);

      if (!toggleShowAndHide) {
         setAddClass("block bg-fadeIn");
      } else {
         setAddClass("bg-fadeOut");
         setTimeout(() => { setAddClass("hidden") }, 500);
      }
   };


   const [recoveryPassword, setRecoveryPassword] = useState<string>("");

   const [alert, setalert] = useState<alertLogin[]>([initialStateInput]);


   const handleInputGmail = ({ target: { name, value, id } }: handleInputChange) => {
      // console.log(name, value);
      setRecoveryPassword(value);
   };


   // event form
   const handleRP = (event: React.FormEvent<HTMLButtonElement>) => {

      event.preventDefault()

      let verifyValues = [
         {name: "typeDocument", value: form.typeDocument}, 
         {name: "document", value: form.document}
      ];

      const validarInputs = verifyValues.map((input) => {
         //Validar la mascara del input
         if (input.name === "document") {
            console.log(input.value, "input.value");
            // Verificar si tiene _ para validar si es un input con mascara
            let inputMask = input.value.includes("_");
            if (inputMask || input.value === "") {
               return { input: input.name, message: "Campo obligatorio", state: true };
            } else {
               return { input: input.name, message: "", state: false };
            }
         } else {
            if (input.value === "" || input.value === "default") {
               return { input: input.name, message: "Campo obligatorio", state: true };
            } else {
               return { input: input.name, message: "", state: false };
            }
         }
      });

      const state = validarInputs.filter(input => input.state === false).map((s) => { return false });

      setalert(validarInputs);

      console.log(state)

      console.log(verifyValues)


      if (state.length == 2) {
         setSendingData(true);

         setRecoveryPassword("");
      
         const url = `${process.env.REACT_APP_API_URI}registro/link-account-registro`;
         
         let config = {
            headers: {
               'Content-Type': 'multipart/form-data',
               'Accept': 'application/json',
            }
         };
   
         const verifyIdentity = {
            typeDocument:form.typeDocument,
            document:form.document
         };
   
         // console.log("Verify Identify ==> ",verifyIdentity);
         
         axios.post(url, verifyIdentity, config)
         .then(response => {
            console.log("response ==> ",response);
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: response.data.message,
               showConfirmButton: false,
               timer: 5000
            }).then(() => {
               navigate('/Login');
            });
            
            setSendingData(false);
         })
         .catch(err => {
            console.log(err);
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 5000
            });

            setSendingData(false);
         });

      }else{
         setTimeout(() => {
            setSendingData(false);
         }, 4000);
      };
   };

   return (
      <>
         <p className="text-sm font-semibold mt-2 pt-1 mb-0">
            Regístrate como
            &nbsp;
            <Link to="/registro-freelancer" className="text-amber-500 hover:text-amber-600 focus:text-amber-600 transition-colors cursor-pointer">
               Talento
            </Link>
            &nbsp;
            o
            &nbsp;
            <Link to="/registro-empresa" className="text-amber-500 hover:text-amber-600 focus:text-amber-600 transition-colors cursor-pointer">
               Empresa
            </Link>
            {/* o
            <Link to="/registro-empleador" className="px-2 text-amber-500 hover:text-amber-600 focus:text-amber-600 transition-colors cursor-pointer">
               Empleador
            </Link> */}
         </p>
         <p className="text-sm font-semibold mt-2 pt-1 mb-0" onClick={showModal}>
            ¿Olvidaste tu contraseña?
            &nbsp;
            <a className="text-amber-500 hover:text-amber-600 focus:text-amber-600 transition-colors cursor-pointer">
               Click aquí
            </a>
         </p>
         {/* Modal */}
         <div id="modal-component-container" className={`fixed inset-0  hidden-modal ${toggleShowAndHide ? addClass : addClass}`}>
            <div className="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-96 text-center sm:block sm:pb-0 ">
               <div className='modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75'></div>
               <div className='modal-space-container hidden h-80 sm:inline-block sm:align-middle'> &nbsp;</div>
               <div className={`modal-container inline-block align-bottom bg-turquoise rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:aling-middle sm:w-2/4 w-full ${toggleShowAndHide ? 'modal-scaleIn' : 'modal-scaleout '}`}>
                  {/* <div className="modal-title text-center p-5">
                     <h1 className="text-3xl font-medium text-white">Mofal-title</h1>
                  </div> */}
                  <div className='modal-wrapper bg-stable-background-modal px-4 pt-5 pb-4 sm:p-6 sm:pb-4 '>
                     <div className="modal-wrapper-flex">
                        <h2 className="text-white 2xl pb-3"> ¿Olvidaste tu contraseña? </h2>
                        <p className="text-Yellow pb-3">
                           Se te enviará un email con las instrucciones para cambiar tu contraseña.
                        </p>
                        <form className="lg:w-4/4">
                           <div className="flex flex-wrap">
                              <div className="w-full md:w-1/2 p-1">
                                 <label htmlFor="typeDocument" className="text-white">Tipo de documento</label>
                                 <select
                                    value={form.typeDocument}
                                    name="typeDocument"
                                    onChange={(e) => setForm({
                                       ...form,
                                       typeDocument: e.target.value
                                    })}
                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"  
                                    defaultValue={'default'}
                                 >
                                    <option value="default" >Seleccione un tipo de documento</option>
                                    <option value="1">DUI</option>
                                    <option value="2">NIT</option>
                                 </select>
                                 {alert.filter(input => input.input == "typeDocument" && input.state === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                 ))}
                              </div>

                              <div className="w-full md:w-1/2 p-1">
                                 <label htmlFor="document" className="text-white">Documento</label>
                                 <ReactInputMask
                                    mask={form.typeDocument === "1" ? "99999999-9" : "9999-999999-999-9"}
                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                    name="document"
                                    value={form.document}
                                    onChange={(e) => setForm({
                                       ...form,
                                       document: e.target.value
                                    })}
                                    disabled={form.typeDocument === "" || form.typeDocument === "default" ? true : false}
                                 />
                                 {alert.filter(input => input.input == "document" && input.state === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                 ))}
                              </div>

                           </div>
                        </form>

                     </div>
                  </div>
                  <div className='modal-actions bg-stable-background-modal px-4 py-3 sm:px-6 sm:flex justify-end'>
                     <div className="mb-4 order-2 sm:order-1">

                        <button onClick={handleRP} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-Yellow text-white font-mediumtext-gray-700hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                           
                           {!sendingData &&
                              <>
                              Enviar
                              </>
                           }

                           {sendingData &&
                           <div className="w-[40px] py-2">
                              <IconLoading></IconLoading>
                           </div>
                           }
                        </button>



                     </div>
                     <div className="mb-4 order-1 sm:order-2">
                        <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-Light-Blue text-white font-mediumtext-gray-700hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={showModal}>
                           Cerrar
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
export default RecoverPassword;
