import React from 'react';
import './Star.scss';
import { ReactComponent as StarIcon } from '../assets/star.svg';

interface StarProps {
  rating: number;
  onEnter: (arg: number) => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement>, rating: number) => void;
  isStarActive: boolean;
  isHovering: boolean;
  theme?: StarTheme;
  readOnly: boolean;
  icon?: JSX.Element;
}

const defaultTheme: StarTheme = {
  colors: {
    backgroundDefault: 'lightgray',
    backgroundColorActive: '#D8A127',
    backgroundColorHover: '#275ed8',
  },
  size: 50,
}
export const Star: React.FC<StarProps> =
    ({
       isStarActive,
       rating,
       onEnter,
       onClick,
       isHovering,
       theme,
       readOnly,
       icon,
     }) => {
      const {
        backgroundColorHover,
        backgroundColorActive,
        backgroundDefault,
      } = {...defaultTheme.colors, ...theme?.colors};
      const size = theme?.size || defaultTheme.size
      return (
          <button
              disabled={readOnly}
              aria-label={`${rating} star rating`}
              onClick={(e) => onClick(e, rating)}
              onMouseEnter={() => onEnter(rating)}
              style={{
                height: 'auto',
                width: size,
                color: isStarActive ? isHovering ? backgroundColorHover : backgroundColorActive : backgroundDefault,
              }}
              className="star">
            {icon || <StarIcon role="img"/>}
          </button>
      )
    };