import { Link } from "react-router-dom";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import CreateProjectComponent from "../components/createProject/createProject";

const PublishProject = () =>{
    
    return(
        <section className="bg-turquoise block -mt-px md:flex md:flex-wrap">
            <CreateProjectComponent></CreateProjectComponent>
        </section>
    );

};

export default PublishProject;