import React, { useEffect, useState } from 'react'

import { Link } from "react-router-dom";

import axios from "axios";
import imagenesHome from '../../assets/imgHome/imagenesHome';


const initialStateListSpecialization = {
   icon:"",
   id:"",
   name:""
};


function SpecialtyListHome() {

   const [specializations, setSpecializations] = useState([initialStateListSpecialization]);
   
   useEffect(() => {
         
      async function getSpecialties() {
   
         const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;
   
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };
         try {
   
            const resp = await axios.get(urlCountry, config);
            setSpecializations(resp.data.specializationTypes);
   
         } catch (err) {
            console.error(err);
         }
      };
      getSpecialties();
      
   }, []);



   return (
   <section className="bg-turquoise md:pb-24 py-12 px-3 md:px-12 lg:px-24 grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 ">
      <div className="z-10 absolute left-0">
         <img className="w-60" src={imagenesHome.cara_icono2} alt=""/>
      </div>



      {
         specializations.map((item, key) =>{
            return(
               <div 
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-offset="0"
                  className="z-50 py-4 md:px-3 bg-purple-cards m-1 rounded-lg justify-center"
                  key={key}
               >
                  <div className="flex items-center">
                     <div className="bg-white mx-2 my-1 w-16 h-16 md:w-20 md:h-20 rounded-full flex items-stretch justify-center">
                        <img className="w-12 md:w-16 self-center" src={`${process.env.REACT_APP_PHOTO_URI}specialization-icon/${item.icon}`} alt=""/>
                     </div>
                        <div className="w-67%">
                        <h4 className="text-white text-start px-3 text-sm font-bold pb-3">{item.name}</h4>
                        <div className="flex items-center  px-1">
                           <Link to={`/categorias-proyectos/${item.id}`} className="transition-colors rounded bg-Yellow hover:bg-dark-yellow py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Proyectos</Link>
                           <Link to={`/categorias-freelancers/${item.id}`} className="transition-colors bg-blue-800 hover:bg-blue-900 rounded py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Talentos</Link>
                        </div>
                     </div>
                  </div>
               </div>
            )
         })
      }


{/* 
      <div 
         data-aos="fade-zoom-in"
         data-aos-easing="ease-in-back"
         data-aos-delay="200"
         data-aos-offset="0"
         className="z-50 py-4 md:px-3 bg-purple-cards m-1 rounded-lg justify-center">
         <div className="flex items-center">
            <div className="bg-white mx-2 my-1 w-16 h-16 md:w-20 md:h-20 rounded-full flex items-stretch justify-center">
               <img className="w-12 md:w-16 self-center" src={imagenesHome.publicidad_digital} alt=""/>
            </div>
            <div className="w-67%">
               <h4 className="text-white text-start px-3 text-sm font-bold pb-3">Marketing Digital</h4>
               <div className="flex items-center  px-1">
                  <Link to="/ListProject" className="transition-colors rounded bg-Yellow hover:bg-dark-yellow py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Proyectos</Link>
                  <Link to="/freelancers" className="transition-colors bg-blue-800 hover:bg-blue-900 rounded py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Freelancers</Link>
               </div>
            </div>
         </div>
      </div>

      <div 
      data-aos="fade-zoom-in"
         data-aos-easing="ease-in-back"
         data-aos-delay="300"
         data-aos-offset="0"
      className="z-50 py-4 md:px-3 bg-purple-cards m-1 rounded-lg justify-center">
         <div className="flex items-center">
            <div className="bg-white mx-2 my-1 w-16 h-16 md:w-20 md:h-20 rounded-full flex items-stretch justify-center">
               <img className="w-12 md:w-16 self-center" src={imagenesHome.desarrollo} alt=""/>
            </div>
               <div className="w-67%">
               <h4 className="text-white text-start px-3 text-sm font-bold pb-3">Desarrollo Web</h4>
               <div className="flex items-center  px-1">
                  <Link to="/ListProject" className="transition-colors rounded bg-Yellow hover:bg-dark-yellow py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Proyectos</Link>
                  <Link to="/freelancers" className="transition-colors bg-blue-800 hover:bg-blue-900 rounded py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Freelancers</Link>
               </div>
            </div>
         </div>
      </div>

   <div 
      data-aos="fade-zoom-in"
       data-aos-easing="ease-in-back"
       data-aos-delay="400"
       data-aos-offset="0"
      className="z-50 py-4 md:px-3 bg-purple-cards m-1 rounded-lg justify-center"
   >
      <div className="flex items-center">
         <div className="bg-white mx-2 my-1 w-16 h-16 md:w-20 md:h-20 rounded-full flex items-stretch justify-center">
            <img className="w-12 md:w-16 self-center" src={imagenesHome.app} alt=""/>
         </div>
         <div className="w-67%">
            <h4 className="text-white text-start px-3 text-sm font-bold pb-3">Desarrollo de Aplicaciones Móviles</h4>
            <div className="flex items-center  px-1">
               <Link to="/ListProject" className="transition-colors rounded bg-Yellow hover:bg-dark-yellow py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Proyectos</Link>
               <Link to="/freelancers" className="transition-colors bg-blue-800 hover:bg-blue-900 rounded py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Freelancers</Link>
            </div>
         </div>
      </div>
   </div>

   <div 
   data-aos="fade-zoom-in"
       data-aos-easing="ease-in-back"
       data-aos-delay="500"
       data-aos-offset="0"
   className="z-50 py-4 md:px-3 bg-purple-cards m-1 rounded-lg justify-center">
      <div className="flex items-center">
         <div className="bg-white mx-2 my-1 w-16 h-16 md:w-20 md:h-20 rounded-full flex items-stretch justify-center">
            <img className="w-12 md:w-16 self-center" src={imagenesHome.fotografo} alt=""/>
         </div>
         <div className="w-67%">
            <h4 className="text-white text-start px-3 text-sm font-bold pb-3">Fotografía</h4>
            <div className="flex items-center  px-1">
               <Link to="/ListProject" className="transition-colors rounded bg-Yellow hover:bg-dark-yellow py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Proyectos</Link>
               <Link to="/freelancers" className="transition-colors bg-blue-800 hover:bg-blue-900 rounded py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Freelancers</Link>
            </div>
         </div>
      </div>
   </div>

   <div 
   data-aos="fade-zoom-in"
       data-aos-easing="ease-in-back"
       data-aos-delay="600"
       data-aos-offset="0"
   className="z-50 py-4 md:px-3 bg-purple-cards m-1 rounded-lg justify-center">
      <div className="flex items-center">
         <div className="bg-white mx-2 my-1 w-16 h-16 md:w-20 md:h-20 rounded-full flex items-stretch justify-center">
            <img className="w-12 md:w-16 self-center" src={imagenesHome.camara_de_video} alt=""/>
         </div>
         <div className="w-67%">
            <h4 className="text-white text-start px-3 text-sm font-bold pb-3">Producción Audiovisual</h4>
            <div className="flex items-center  px-1">
               <Link to="/ListProject" className="transition-colors rounded bg-Yellow hover:bg-dark-yellow py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Proyectos</Link>
               <Link to="/freelancers" className="transition-colors bg-blue-800 hover:bg-blue-900 rounded py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Freelancers</Link>
            </div>
         </div>
      </div>
   </div>

   <div 
   data-aos="fade-zoom-in"
       data-aos-easing="ease-in-back"
       data-aos-delay="700"
       data-aos-offset="0"
   className="z-50 py-4 md:px-3 bg-purple-cards m-1 rounded-lg justify-center">
      <div className="flex items-center">
         <div className="bg-white mx-2 my-1 w-16 h-16 md:w-20 md:h-20 rounded-full flex items-stretch justify-center">
            <img className="w-12 md:w-16 self-center" src={imagenesHome.disenador_grafico} alt=""/>
         </div>
         <div className="w-67%">
            <h4 className="text-white text-start px-3 text-sm font-bold pb-3">Diseño Gráfico</h4>
            <div className="flex items-center  px-1">
               <Link to="/ListProject" className="transition-colors rounded bg-Yellow hover:bg-dark-yellow py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Proyectos</Link>
               <Link to="/freelancers" className="transition-colors bg-blue-800 hover:bg-blue-900 rounded py-2 px-4 mx-1 text-white text-xs xl:text-sm font-bold">Freelancers</Link>
            </div>
         </div>
      </div>
   </div> */}


</section>
  )
}

export default SpecialtyListHome