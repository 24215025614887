import RegisterFreealncer from "../components/RgFreelancer/formRgFreelancers";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { Link } from 'react-router-dom';

const RegistroFreelancer = () => {
    return (
        <section className="bg-color-login overflow-auto">
            <div className="h-full text-gray-800">
                <div className="flex flex-wrap h-full">
                    <div className="hidden lg:flex w-1/5 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end" id="contenedorImg">
                        <img src={imagenesEditProject.logo_blanco_mitad} id="img" className="opacity-50 py-10 h-1/2 lg:h-3/4 xl:h-5/6" alt="" />
                    </div>
                    <div className="w-full lg:w-4/5 mb-12 md:mb-0 flex flex-wrap justify-start pb-5 px-6">
                        <div className="w-full sm:w-5% mt-4 lg:mt-8">
                            <Link to="" onClick={()=>window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                            </Link>
                        </div>
                        <RegisterFreealncer />
                    
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RegistroFreelancer;