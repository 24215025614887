import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const DataTableComponent = ({ columns, data, expandedComponent }) => {

    // console.log("tableDataComponent", tableData);
    // console.log("columns", columns);
    // console.log("data", data);

    const paginationProps = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    const tableData = {
        columns,
        data
    };

    return (
        <DataTableExtensions
            {...tableData}
            filterPlaceholder={"Buscar"} 
            print={false}
            export={false}
        >
            <DataTable
                columns={columns}
                data={data.length > 0 && data}
                noHeader
                defaultSortField="label"
                defaultSortAsc={false}
                pagination
                highlightOnHover
                paginationComponentOptions={paginationProps}
                responsive
                noDataComponent={<span className="font-bold text-white my-5">No hay oportunidades registradas.</span>}
                expandableRows
                expandableRowsComponent={expandedComponent !== undefined && expandedComponent}
            />
        </DataTableExtensions>
    )
}

export default DataTableComponent;