import { faArrowDown, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, ChangeEvent, FocusEvent } from "react";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import Cards from 'react-credit-cards';

import axios from 'axios';
import { InfoCardValidate, Plans } from '../Interfaces/plans';

import 'react-credit-cards/es/styles-compiled.css';

import Cookies from "universal-cookie";

import InputMask from 'react-input-mask';
import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { validateFormPayProjectAndSubscription } from "../function/validateFormPay";
import { alertLogin } from "../Interfaces/Login";
import SkeletonPagoPlan from "../components/Skeleton/Plan/PagoPlanSkeleton";
import IconLoading from "../components/iconLoading/IconLoading";

const PurchasePlans = () => {

    const initialPlans = {
        id: 0,
        name: '',
        normalPrice: 0,
        planDescription: '',
        projectsPerMounth: 0,
        salePrice: 0,
        activeProjectsPerMounth: 0,
        activeStatus: 0,
        commision: 0,
        billingPeriod: 0
    }
    
    const initialPlansActual = {
        id: 0,
        name: '',
        normalPrice: 0,
        planDescription: '',
        projectsPerMounth: 0,
        salePrice: 0,
        activeProjectsPerMounth: 0,
        activeStatus: 0,
        commision: 0,
        billingPeriod: 0,
        fechaFin: "",
        fechaInicio: ""
    }

    const initialStateInput = {
        input: "",
        message: '',
        state: false
    }

    const [inputsCard, setinputsCard] = useState({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
        locale: ''
    });

   
    const idParams = useParams().id;
   
    const idPlanPaymant = Number(idParams);

    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const MySwal = withReactContent(Swal);

    const typeCards = ['visa', 'mastercard'];

    const initialStateCard = {
        cvv: '',
        card: '',
        cardholder: '',
        expire_date: '',
        plan: idPlanPaymant,
        focused: undefined,
        locale: ''
    };

    const initialStateCardUpgrade = {
        cvv: '',
        card: '',
        cardholder: '',
        expire_date: '',
        plan: idPlanPaymant,
        focused: undefined,
        locale: '',
        id_plan: 0,
        subscription_code: "",
        upgradePlan: 0
    };

    const initialSubscriptionCodeUpgrade = {
        subscription_code: ""
    };


    const [dataPlan, setDataPlan] = useState<Plans>(initialPlans);
    const [dataPlanActual, setDataPlanActual] = useState(initialPlansActual);
    const [subscriptionCodeUpgrade, setSubscriptionCodeUpgrade] = useState(initialSubscriptionCodeUpgrade);

    // const [infoCard, setInfoCard] = useState<ReactCreditCardProps>(initialStateCard);
    const [infoCard, setInfoCard] = useState<InfoCardValidate>(initialStateCard);
    const [infoCardUpgradePlan, setInfoCardUpgradePlan] = useState(initialStateCardUpgrade);

    const [cvv, setCvv] = React.useState<string>("");

    const [expire_date, setExpire_date] = React.useState<string>("");

    const [card, setCard] = React.useState<string>("");

    const navigate = useNavigate();

    const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

    const [termsAndConditions, setTermsAndConditions] = useState<boolean | undefined>(false);

    const [skeletonPagoPlan, setSkeletonPagoPlan] = useState(true);

    const [sendingData, setSendingData] = useState(false);

    let AñoSuscripcionInicio = dataPlanActual.fechaInicio.substring(0,4);
    let MesSuscripcionInicio = dataPlanActual.fechaInicio.substring(5,7);
    let DiaSuscripcionInicio = dataPlanActual.fechaInicio.substring(8,10);

    if(MesSuscripcionInicio == "01"){
        MesSuscripcionInicio = "Enero";
    }else if(MesSuscripcionInicio == "02"){
        MesSuscripcionInicio = "Febrero";
    }else if(MesSuscripcionInicio == "03"){
        MesSuscripcionInicio = "Marzo";
    }else if(MesSuscripcionInicio == "04"){
        MesSuscripcionInicio = "Abril";
    }else if(MesSuscripcionInicio == "05"){
        MesSuscripcionInicio = "Mayo";
    }else if(MesSuscripcionInicio == "06"){
        MesSuscripcionInicio = "Junio";
    }else if(MesSuscripcionInicio == "07"){
        MesSuscripcionInicio = "Julio";
    }else if(MesSuscripcionInicio == "08"){
        MesSuscripcionInicio = "Agosto";
    }else if(MesSuscripcionInicio == "09"){
        MesSuscripcionInicio = "Septiembre";
    }else if(MesSuscripcionInicio == "10"){
        MesSuscripcionInicio = "Octubre";
    }else if(MesSuscripcionInicio == "11"){
        MesSuscripcionInicio = "Noviembre";
    }else if(MesSuscripcionInicio == "12"){
        MesSuscripcionInicio = "Diciembre";
    }

    let AñoSuscripcionFin = dataPlanActual.fechaFin.substring(0,4);
    let MesSuscripcionFin = dataPlanActual.fechaFin.substring(5,7);
    let DiaSuscripcionFin = dataPlanActual.fechaFin.substring(8,10);

    if(MesSuscripcionFin == "01"){
        MesSuscripcionFin = "Enero";
    }else if(MesSuscripcionFin == "02"){
        MesSuscripcionFin = "Febrero";
    }else if(MesSuscripcionFin == "03"){
        MesSuscripcionFin = "Marzo";
    }else if(MesSuscripcionFin == "04"){
        MesSuscripcionFin = "Abril";
    }else if(MesSuscripcionFin == "05"){
        MesSuscripcionFin = "Mayo";
    }else if(MesSuscripcionFin == "06"){
        MesSuscripcionFin = "Junio";
    }else if(MesSuscripcionFin == "07"){
        MesSuscripcionFin = "Julio";
    }else if(MesSuscripcionFin == "08"){
        MesSuscripcionFin = "Agosto";
    }else if(MesSuscripcionFin == "09"){
        MesSuscripcionFin = "Septiembre";
    }else if(MesSuscripcionFin == "10"){
        MesSuscripcionFin = "Octubre";
    }else if(MesSuscripcionFin == "11"){
        MesSuscripcionFin = "Noviembre";
    }else if(MesSuscripcionFin == "12"){
        MesSuscripcionFin = "Diciembre";
    }


    type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

    const [validateSubNumber, setValidateSubNumber] = useState();

    useEffect(() => {
        getPlan(idPlanPaymant);
        ObtenerDataPlanActual();
    }, []);

    async function ObtenerDataPlanActual() {
        const ObtenerPlanActual = `${process.env.REACT_APP_API_URI}payment/getPlans/${getSesion.id}/${idParams}`;
        const ObtenerValidarSuscripcion = `${process.env.REACT_APP_API_URI}payment/validate-sub/${getSesion.id}`;

        let config = {
           headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${getSesion.token}`
           }
        };

        try {
  
            const resp1 = await axios.get(ObtenerValidarSuscripcion, config);
            setValidateSubNumber(resp1.data);

            if(resp1.data == 1){
                try {
  
                    const resp2 = await axios.get(ObtenerPlanActual, config);           
                    setDataPlanActual(resp2.data.planActual)
                    setSubscriptionCodeUpgrade(resp2.data.subscription_code)
             
                 } catch (err) {
                    // Handle Error Here
                    console.error(err);
                 }
            }

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
        
     };

    // Obtener plan de la plataforma
    const getPlan = async (id: Number) => {
        const urlGetPlan = `${process.env.REACT_APP_API_URI}admin-plans/find/${id}`;
        try {

            const response = await axios.get(urlGetPlan);
            setDataPlan(response.data);
        
        }catch(error) {
            console.error(error);
        };
    }

    const handleInputChange = ({ target: { name, value, id, } }: handleInputChange) => {
       
        if (name == "termsAndConditions") {
            const checkbox = document.getElementById("termsAndConditions",) as HTMLInputElement | null;
            const valueCheck = checkbox?.checked;
            setTermsAndConditions(valueCheck);
        };

        //Input Mask
        if (name == 'cvv') {
            setCvv(value);
        }
        if (name == 'expire_date') {
            setExpire_date(value);
        }
        if (name == 'card') {
            setCard(value);
        }

        setInfoCard({ ...infoCard, [name]: value });
    };

    const handelSubmitPayment = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        let verifyValues = [
            { name: "cvv", value: infoCard.cvv },
            { name: "card", value: infoCard.card },
            { name: "cardholder", value: infoCard.cardholder },
            { name: "expire_date", value: infoCard.expire_date }
        ];


        const validatedInputs = validateFormPayProjectAndSubscription(verifyValues);

        setAlert(validatedInputs);

        const state = validatedInputs.filter(input => input.state === false).map((s) => { return false });

        infoCard.card = infoCard.card.replace(/\s+/g, '');

        infoCardUpgradePlan.card = infoCard.card.replace(/\s+/g, '');

        if (state.length >= 4) {

            if (termsAndConditions) {

                Swal.fire({
                    title: '¿Seguro que desea continuar con el pago?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Seguir',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        sendData();
                    };
                })

            } else {

                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Debes de aceptar los términos y condiciones',
                    showConfirmButton: false,
                    timer: 3500
                });
            }
        }

    }

    async function sendData() {

        setSendingData(true);

        let urlPayment = "";

        if (validateSubNumber == 0) {
            urlPayment = `${process.env.REACT_APP_API_URI}payment/subscription`;
        }else{
            urlPayment = `${process.env.REACT_APP_API_URI}payment/upgrade-subscription`;
        }

        infoCardUpgradePlan.subscription_code = subscriptionCodeUpgrade.toString();

        infoCardUpgradePlan.card = infoCard.card;
        infoCardUpgradePlan.cvv = infoCard.cvv;
        infoCardUpgradePlan.cardholder = infoCard.cardholder;
        infoCardUpgradePlan.expire_date = infoCard.expire_date;
        infoCardUpgradePlan.plan = infoCard.plan;
        infoCardUpgradePlan.focused = infoCard.focused;
        infoCardUpgradePlan.id_plan = idPlanPaymant;
        infoCardUpgradePlan.upgradePlan = 1;

        let config = {
            // multipart/form-data
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        if(validateSubNumber == 0){

            try {

                // console.log(infoCard, config);
    
                var response = await axios.post(urlPayment, infoCard, config);

                MySwal.fire({
                    position: 'center',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function(){
                    navigate('/perfil-empresa');
                });
    
                setSendingData(false);
    
            } catch (err: any){

                MySwal.fire({
                    position: 'center',
                    icon: "warning",
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function(){
                    if(err.response.data.message == "Proceda a completar el perfil de pago para proseguir con el pago."){
                        navigate('/perfil-pago');
                    }
                })
                
                setSendingData(false);

            }

        }else{
            try {

                var response = await axios.put(urlPayment, infoCardUpgradePlan, config);

                MySwal.fire({
                    position: 'center',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(async function(){

                    try {

                        urlPayment = `${process.env.REACT_APP_API_URI}payment/project`;
            
                        var response2 = await axios.post(urlPayment, infoCardUpgradePlan, config);
                    
                        MySwal.fire({
                            position: 'center',
                            title: response2.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function(){
                            navigate('/perfil-empresa');
                        });
             
                        setSendingData(false);
        
                    } catch (err: any) {
            
                        MySwal.fire({
                            position: 'center',
                            icon: "warning",
                            title: err.response.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function(){
                            if(err.response.data.message == "Proceda a completar el perfil de pago para proseguir con el pago."){
                                navigate('/perfil-pago');
                            }
                        })
                        setSendingData(false);
                    };
                });
    
                setSendingData(false);
    
            } catch (err: any) {
    
                MySwal.fire({
                    position: 'center',
                    icon: "warning",
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function(){
                    if(err.response.data.message == "Proceda a completar el perfil de pago para proseguir con el pago."){
                        navigate('/perfil-pago');
                    }
                })
                
                setSendingData(false);

            }
        }
    }

    const [isFrontOfCardVisible, setIsFrontOfCardVisible] = useState(true);
    
    const days = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

    const months = ["","Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    
    function sumarDias(dias:any){

        var fecha = new Date();

        fecha.setDate(fecha.getDate() + dias);
        
        // `getDate()` devuelve el día del mes (del 1 al 31)
        var day = fecha.getDate();
        
        // `getMonth()` devuelve el mes (de 0 a 11)
        var month = fecha.getMonth() + 1;
        
        // `getFullYear()` devuelve el año completo
        var year = fecha.getFullYear();

        // return fecha;
        return `${day} de ${months[month]} de ${year}`;
          
    };
    
    setTimeout(() => { setSkeletonPagoPlan(false); }, 3500);

    return (
        <>
            {(skeletonPagoPlan) ? (
                <SkeletonPagoPlan></SkeletonPagoPlan>
            ):(
                <div className="w-full bg-gradient-to-b from-Light-purple p-5">
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                            <Link to="" onClick={() => window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="p-2 my-5 md:p-10 bg-turquoise rounded-lg">
                        <h3 className="text-Yellow font-bold text-3xl text-center md:text-left pt-10 md:pt-0 md:mt-10">Información de pago</h3>
                        <form onSubmit={handelSubmitPayment}>
                            <div className="flex flex-wrap">

                                <div className="w-full lg:w-1/2 p-2">
                                    <div>
                                        <div className="flex flex-wrap">
                                            <div className="w-full md:w-1/1 md:pr-4">
                                                <div className="my-10">
                                                    <Cards
                                                        cvc={cvv}
                                                        expiry={expire_date}
                                                        name={infoCard.cardholder}
                                                        number={card}
                                                        focused={isFrontOfCardVisible ? "number" : "cvc"}
                                                        locale={{ valid: "VALIDA HASTA" }}
                                                        placeholders={{ name: "NOMBRE" }}
                                                        acceptedCards={["visa", "mastercard"]}
                                                    />
                                                </div>
                                                <label className="text-white" htmlFor="card">Número de la tarjeta</label>

                                                <InputMask
                                                    className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                    mask={card !== "" ? "9999 9999 9999 9999" : ""}
                                                    id="card"
                                                    // maskChar=""
                                                    placeholder="0000 0000 0000 0000"
                                                    name="card"
                                                    value={infoCard.card}
                                                    onChange={handleInputChange}
                                                />
                                                {
                                                    alert.filter(input => input.input == "card" && input.state === true).map(message => (
                                                        <span className="text-red-500">{message.message}</span>
                                                    ))
                                                }
                                            </div>

                                            <div className="w-full md:w-1/1 md:pr-4 mb-2">
                                                <label className="text-white" htmlFor="cardholder">Nombre de la tarjeta</label>
                                                <input
                                                    type="text"
                                                    id="cardholder"
                                                    className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                    name="cardholder"
                                                    value={infoCard.cardholder}
                                                    onChange={handleInputChange}
                                                />
                                                {
                                                    alert.filter(input => input.input == "cardholder" && input.state === true).map(message => (
                                                        <span className="text-red-500">{message.message}</span>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap">
                                        <div className="w-1/2 md:pr-4 mb-2">
                                            <label className="text-white" htmlFor="expire_date">Vencimiento</label>

                                            <InputMask
                                                className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                mask={expire_date !== "" ? "99/9999" : ""}
                                                id="expire_date"
                                                // maskChar=""
                                                placeholder="00/0000"
                                                name="expire_date"
                                                value={infoCard.expire_date}
                                                onChange={handleInputChange}
                                            />
                                            {
                                                alert.filter(input => input.input == "expire_date" && input.state === true).map(message => (
                                                    <span className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>

                                        <div className="w-1/2 md:pr-4 mb-2">
                                            <label className="text-white" htmlFor="cvv">CVV</label>
                                            <InputMask
                                                className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                mask={cvv !== "" ? "999" : ""}
                                                id="cvv"
                                                // maskChar=""
                                                placeholder="000"
                                                name="cvv"
                                                value={infoCard.cvv}
                                                onBlur={() => { setIsFrontOfCardVisible(true); }}
                                                onFocus={() => { setIsFrontOfCardVisible(false); }}
                                                onChange={handleInputChange}
                                            />
                                            {
                                                alert.filter(input => input.input == "cvv" && input.state === true).map(message => (
                                                    <span className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>

                                    </div>

                                    <small className="text-white"><FontAwesomeIcon icon={faExclamationCircle} className="mx-2" />
                                        Vamos a procesar el pago en dólares USD
                                    </small>
                                    
                                    <div className="columns-1 py-5 px-2">
                                    <input 
                                        className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none accent-pink-500  checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white checked:after:w-1"
                                        type="checkbox"
                                        name="termsAndConditions"
                                        onChange={handleInputChange}
                                        id="termsAndConditions"
                                        />
                                    <span className="text-white text-xs lg:text-xs xl:text-base"><a href="" className="hover:text-Yellow">
                                        <Link to="/terminos-de-servicio">Confirmo que he leído y acepto los Términos de servicio y la Política de privacidad de Chambaticon y entiendo que los servicios de Chambaticon se proporcionan por suscripción. Al cancelar tu suscripción, podrás volver a adquirir tu plan la misma fecha que tomaste tu plan, pero del mes próximo.</Link></a></span>
                                    </div>

                                </div>
                                <div className="p-5 md:hidden w-full">
                                    <hr className="my-10 md:my-0 text-white border w-full" />
                                </div>
                                <div className="w-full lg:w-1/2 p-2 flex items-center justify-center">
                                    <div className={(validateSubNumber == 1) ? "flex flex-wrap" : "w-full"}>
                                    {
                                        (validateSubNumber == 1) 
                                        &&
                                        <section className="text-white body-font overflow-hidden w-full">
                                        <div className="container mx-auto">
                                            <div className="w-full mx-auto flex flex-wrap">
                                                <div className="w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                                                    <h2 className="text-sm title-font text-white tracking-widest">Plan actual</h2>
                                                    <h2 className="text-Yellow text-2xl md:text-3xl font-semibold ">{dataPlanActual.name}</h2>
                                                    <div className="flex mb-4">
                                                        <h1 className="flex-grow text-white border-b-2 border-Azul-Ticongle py-2 text-lg px-1">Descripción</h1>
                                                    </div>
                                                    <p className="leading-relaxed mb-4">{dataPlanActual.planDescription}</p>
                                                    <div className="flex border-t border-gray-200 py-2">
                                                        <span className="text-white">Fecha de suscripción</span>
                                                        <span className="ml-auto text-white">{DiaSuscripcionInicio} de {MesSuscripcionInicio} de {AñoSuscripcionInicio}</span>
                                                    </div>
                                                    <div className="flex border-t border-gray-200 py-2">
                                                        <span className="text-white">Fecha de vencimiento</span>
                                                        <span className="ml-auto text-white">{DiaSuscripcionFin} de {MesSuscripcionFin} de {AñoSuscripcionFin}</span>
                                                    </div>
                                                    <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                                                        <span className="text-white">Total</span>

                                                        {(dataPlanActual.salePrice == 0) ? (
                                                            <span className="ml-auto text-white">${dataPlanActual.normalPrice}</span>
                                                        ):(
                                                            <span className="ml-auto text-white">${dataPlanActual.salePrice}</span>
                                                        )}

                                                    </div>
                                                    <div className="flex justify-center items-center w-full">

                                                            <FontAwesomeIcon className="text-7xl" icon={faArrowDown} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    }
                                    <section className="text-white body-font overflow-hidden w-full">
                                        <div className="container mx-auto">
                                            <div className="w-full mx-auto flex flex-wrap">
                                                <div className="w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                                                    <h2 className="text-sm title-font text-white tracking-widest">Plan a contratar</h2>
                                                    <h2 className="text-Yellow text-2xl md:text-3xl font-semibold ">{dataPlan.name}</h2>
                                                    <div className="flex mb-4">
                                                        <h1 className="flex-grow text-white border-b-2 border-Azul-Ticongle py-2 text-lg px-1">Descripción</h1>
                                                    </div>
                                                    <p className="leading-relaxed mb-4">{dataPlan.planDescription}</p>
                                                    <div className="flex border-t border-gray-200 py-2">
                                                        <span className="text-white">Fecha de suscripción</span>
                                                        <span className="ml-auto text-white">{ sumarDias(0) }</span>
                                                    </div>
                                                    <div className="flex border-t border-gray-200 py-2">
                                                        <span className="text-white">Fecha de vencimiento</span>
                                                        <span className="ml-auto text-white">{ sumarDias(dataPlan.billingPeriod) }</span>
                                                    </div>
                                                    <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                                                        <span className="text-white">Total</span>

                                                        {(dataPlan.salePrice == 0) ? (
                                                            <span className="ml-auto text-white">${dataPlan.normalPrice}</span>
                                                        ):(
                                                            <span className="ml-auto text-white">${dataPlan.salePrice}</span>
                                                        )}

                                                    </div>
                                                    <div className="flex text-center">

                                                    {!sendingData && 
                                                        <button 
                                                            type="submit" 
                                                            className="
                                                                flex justify-center ml-auto px-7 py-5 md:py-3 bg-Yellow
                                                            text-white font-medium lg:text-sm xl:text-base 
                                                                leading-snug rounded shadow-md hover:bg-amber-600 
                                                                hover:shadow-lg focus:bg-amber-700 focus:shadow-lg 
                                                                focus:outline-none focus:ring-0 active:bg-amber-800 
                                                                active:shadow-lg transition duration-150 ease-in-out w-full">
                                                                    FINALIZAR COMPRA
                                                        </button>
                                                        }
                                                        
                                                        {sendingData && 
                                                            <button 
                                                                className="
                                                                    flex justify-center ml-auto px-7 py-5 md:py-3 bg-Yellow
                                                                text-white font-medium lg:text-sm xl:text-base 
                                                                    leading-snug rounded shadow-md hover:bg-amber-600 
                                                                    hover:shadow-lg focus:bg-amber-700 focus:shadow-lg 
                                                                    focus:outline-none focus:ring-0 active:bg-amber-800 
                                                                    active:shadow-lg transition duration-150 ease-in-out w-full">
                                                                    <IconLoading />
                                                            </button>
                                                        }



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            )}

            
        </>
    );
}

export default PurchasePlans;