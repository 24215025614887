import logo_blanco_mitad from './logo_blanco_mitad.png'
import logo from './chambaticon_navbar.png'
import logo_cadena from './fondo_login_izquierdo_sin_icono2.jpg'
import arrow_back_1 from './boton_back_2_boton2.png'
import arrow_back_2 from './boton_back_22_boton2.png'
import arrow_back_3 from './boton_back.png'
import arrow_back_4 from './boton_back2_boton2.png'



export default {
    "logo_blanco_mitad": logo_blanco_mitad,
    "logo": logo,
    "logo_cadena": logo_cadena,
    "arrow_back_1": arrow_back_1,
    "arrow_back_2": arrow_back_2,
    "arrow_back_3": arrow_back_3,
    "arrow_back_4": arrow_back_4
}