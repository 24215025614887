import React from 'react'



import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const ModalKnowledgeSkeleton = () => {
  return (
   <>
       <div className='modal-wrapper bg-stable-background-modal px-4 pt-5 pb-4 sm:p-6 sm:pb-4 '>
            <div className="modal-wrapper-flex">
               <div className="px-8 grid py-4 w-full">
                  <label htmlFor="" className="text-white h-12 overflow-hidden">
                     <Skeleton width={150} count={1} />
                  </label>
                  <Skeleton count={1} />
               </div>
            </div>
         </div>

         <div className='modal-actions bg-stable-background-modal px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>

            <div className="mb-4 p-4">
               <Skeleton width={80} height={40} count={1} />
            </div>

            <div className="mb-4 p-4">
               <Skeleton width={80} height={40} count={1} />
            </div>

            <div className="mb-4 p-4">
               <Skeleton width={80} height={40} count={1} />
            </div>

         </div>
   </>
  )
};

export default ModalKnowledgeSkeleton;
