import { url } from "inspector";
import imgPerfilFreelancer from "../assets/imgPerfilFreelancer/imgPerfilFreelancer";

import {
  faUsers,
  faEnvelope,
  faPhone,
  faLocationDot,
  faLanguage,
  faPenToSquare,
  faMapLocation,
  faMapLocationDot,
  faShare,
  faShareNodes,
  faBriefcase,
  faCloudArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgDefault from "../assets/imgEditPerfil/user.png";
import Swal from 'sweetalert2'
import {
  faFacebookSquare,
  faInstagram,
  faInstagramSquare,
  faTwitterSquare,
  faWhatsapp,
  faWhatsappSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import axios from "axios";
import Cookies from "universal-cookie";
import {
  ShowInfoFreelancer,
  UpdatedInfoFreelancer,
} from "../Interfaces/UpdatedInfoFreelancer";
import { userInfo } from "os";

import TabsInfo from "../components/TabsProfileFreelancers/Tabs";
import ProfileFreelancerSkeleton from "../components/Skeleton/ProfileFreelancer/profileFreelancer";

import { StarRating } from "star-rating-react-ts";
import SkeletonStar from "../components/Skeleton/ProfileFreelancer/starsQualification";
import { HandleSession } from "../function/Login";
import ImgProfileSkeleton from "../components/Skeleton/ProfileCompany/imgProfileSkeleton";

// InitialState
const initialStateShowInfoFreelancer = {
  infoProjectFreelncer: "",
  hambaticon_projects: "",
  names: "",
  email: "",
  nickName: "",
  dateOfBirth: "",
  phone: "",
  phone_alternative: "",
  sex: "",
  dui: "",
  nit: "",
  country: "",
  department: "",
  Distrito: "",
  city: "",
  language: [],
  direction: "",
  about_me: "",
  facebook: "",
  instagram: "",
  twitter: "",
  linkedin: "",
  imagen1: "",
  imagen2: "",
  id: "",
  briefcase_link: ""
};

const CreacionProfileFreelancer = () => {

  // Info user
  const [showInfo, setShowInfo] = useState<ShowInfoFreelancer>(initialStateShowInfoFreelancer);

  // Cookies
  const cookies = new Cookies();

  const navigate = useNavigate();

  const getSesion = cookies.get("Bearer");

  const [DataUser, setDataUser] = useState<any>(undefined);

  const [IsLoading, setIsLoading] = useState(true);

  const [starFree, setStarFree] = useState(0);

  const [average, setAverage] = useState("0");

  const [skeletonStar, setSkeletonStar] = useState(true);

  const [StatusProfileFreelancer, setStatusProfileFreelancer] = useState(0);

  const themeStar = {
    colors: {
      backgroundDefault: "lightgray",
      backgroundColorActive: "#FF9200",
      backgroundColorHover: "#FF9200",
    },
    size: 30,
  };

  var sectionStyle = {
    backgroundImage: "url(" + `${showInfo.imagen2}` + ")",
  };


  const [MostrarImg, setMostrarImg] = useState(imgDefault);
  const [loadImgProfile, setloadImgProfile] = useState(true);

  async function getProfileInfo(typeDocument: string, document: string) {

    const url = `${process.env.REACT_APP_API_URI}registro/student-profile-image`;

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
      }
    };

    const verifyPhoto = {
      typeDocument: typeDocument,
      document: document
    };


    await axios.post(url, verifyPhoto, config)
      .then(response => {

        let imgCompany: any = "";

        if (response.data.data === null || response.data.data === "") {
          imgCompany = null;
        } else {
          imgCompany = response.data.data;
        };

        // console.log("imgCompany ==>", imgCompany);

        setMostrarImg(imgCompany);

        setTimeout(() => {
          setloadImgProfile(false);
        }, 1000);

      })
      .catch(err => {
        console.log(err);

      });
  };

  //  Get info user
  useEffect(() => {
    getQualification();
    async function getInfoUserSession() {
      const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/profile/${getSesion.id}`;
      let config = {
        headers: {
          'Content-Type': "application/json",
          'Accept': "application/json",
          'Authorization': `Bearer ${getSesion.token}`
        },
      };
      // console.log(urlCountry, config);
      try {
        const resp = await axios.get(urlCountry, config);
        console.log("resp ==>", resp);
        setStatusProfileFreelancer(resp.data.data.complete_profile_status);

        // setDataUser(resp.data.data);

        // console.log("DATA USER->", resp.data.data);

        if (resp.data.data) {

          let nameSex = "";

          if (resp.data.data.sexo == 1) {
            nameSex = "Hombre";
          } else if (resp.data.data.sexo == 2) {
            nameSex = "Mujer";
          } else {
            nameSex = "Otro";
          }

          // console.log(resp.data.data,"Data user ---------------------------");
          var username = resp.data.data.user.username;
          showInfo.names = resp.data.data.user.names + " " + resp.data.data.user.lastnames;
          showInfo.email = resp.data.data.user.email;
          showInfo.nickName = resp.data.data.user.username;
          showInfo.dateOfBirth = resp.data.data.age;
          showInfo.phone = resp.data.data.phone;
          showInfo.phone_alternative = resp.data.data.phone_alternative;
          showInfo.sex = nameSex;
          showInfo.dui = resp.data.data.dui;
          showInfo.nit = resp.data.data.nit;
          showInfo.country = resp.data.data.country.name;
          showInfo.department = resp.data.data.state.name;
          showInfo.city = resp.data.data.city.name;
          showInfo.Distrito = resp.data.data.district.name;
          showInfo.briefcase_link = resp.data.data.briefcase_link;
          showInfo.infoProjectFreelncer = resp.data.data.projects_data;

          // if (resp.data.data.languages[0] != null){
            if (resp.data.data.languages.length != 0){
            showInfo.language = resp.data.data.languages;
          } else {
            showInfo.language = [{ id: 0, name: "" }];
          }

          showInfo.direction = resp.data.data.direction;


          if (resp.data.data.about_me == "" || resp.data.data.about_me == null || resp.data.data.about_me == "null" || resp.data.data.about_me == " null") {
            showInfo.about_me = "No se proporcionó ninguna descripción. ¡Haznos saber más sobre ti!";
          } else {
            showInfo.about_me = resp.data.data.about_me;
          };

          if (resp.data.data.social_media_links[0]) {
            showInfo.facebook = resp.data.data.social_media_links[0];
          } else {
            showInfo.facebook = 0;
          }

          if (resp.data.data.social_media_links[1]) {
            showInfo.twitter = resp.data.data.social_media_links[1];
          } else {
            showInfo.twitter = 0;
          }

          if (resp.data.data.social_media_links[2]) {
            showInfo.linkedin = resp.data.data.social_media_links[2];
          } else {
            showInfo.linkedin = 0;
          }

          if (resp.data.data.social_media_links[3]) {
            showInfo.instagram = resp.data.data.social_media_links[3];
          } else {
            showInfo.instagram = 0;
          }

          // if (resp.data.data.photo != null) {
          //   // showInfo.imagen1            = `http://localhost:8000/freelancer/profile-img/${resp.data.data.photo}`;
          //   showInfo.imagen1 = `${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${resp.data.data.photo}`;
          // } else {
          //   showInfo.imagen1 = imgDefault;
          // }

          if (resp.data.data.cover_photo != null) {
            const nameIngBanner = resp.data.data.cover_photo;

            showInfo.imagen2 = (
              <div
                id="bgbanner"
                className="w-full h-full bg-center bg-cover OpacityBannerProfile"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_PHOTO_URI}freelancer/profile-cover/${resp.data.data.cover_photo})`,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            );
          } else {
            showInfo.imagen2 = (
              <div
                className="w-full h-full bg-center bg-cover OpacityBannerProfile"
                style={{
                  backgroundImage: "url(" + imgPerfilFreelancer.banner_a_medida + ")",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            );
          }

          if (resp.data.data.dui != "") {
            getProfileInfo("dui", resp.data.data.dui);
          } else {
            getProfileInfo("nit", resp.data.data.nit);
          };
          // getProfileInfo("dui", resp.data.data.dui);
        }

        setIsLoading(false);
      } catch (err) {
        // Handle Error Here
        console.error("DATA ERROR USER->" + err);
      }
    }
    getInfoUserSession();
  }, []);




  const getQualification = async () => {
    const urlQ = `${process.env.REACT_APP_API_URI}rating/freelancer/${getSesion.id}`;
    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios.get(urlQ, config)

    if (response.data.length != 0) {
      let suma = 0;
      let promedio = 0;

      for (let i = 0; i < response.data.length; i++) {
        suma = parseFloat(response.data[i].rating) + suma;
      };

      promedio = suma / response.data.length;

      setAverage(promedio.toFixed(1));

      setStarFree(Math.trunc(promedio));

    }
    setSkeletonStar(false);
  };

  const urlDominio = window.location.host;

  const urlEnlacePerfil = `${urlDominio}/freelancer/share/s`;

  return (
    <>
      {IsLoading && <ProfileFreelancerSkeleton />}

      {!IsLoading && (
        <div className="flex">
          {/* <div className="w-2/12 hidden lg:flex">
            <img
              src={imgPerfilFreelancer.fondo_login_izquierdo_sin_icono2}
              className="w-full"
              alt=""
            />
          </div> */}
          <div className="w-full lg:w-12/12 bg-turquoise">
            <main className="profile-pages">
              <section
                className="block bg-black relative"
                style={{ height: "275px" }}
              >
                {showInfo.imagen2}
                <Link to="/editar-perfil-freelance">
                  {/* <FontAwesomeIcon
                    className="text-Yellow text-2xl mr-4 absolute top-5 right-2"
                    icon={faPenToSquare}
                  /> */}
                  <button type="button" className="mr-4 absolute top-56 right-2 z-10 text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 me-2 mb-2">
                  Actualizar perfil
                  <FontAwesomeIcon
                    className="text-white text-xl px-1"
                    icon={faPenToSquare}
                  /> 
                  </button>
                </Link>
                <textarea id="textArea"></textarea>
                {StatusProfileFreelancer == 3 && (
                  <button
                    onClick={() =>
                      navigator.clipboard
                        .writeText(urlEnlacePerfil)
                        .then(() => {
                          Swal.fire({
                            icon: 'success',
                            title: 'Enlace de perfil copiado con exito al portapapeles',
                            showConfirmButton: false,
                            timer: 1500
                          })
                        })
                        .catch((err) => {
                          Swal.fire({
                            icon: 'error',
                            title: 'Error al copiar al portapapeles',
                            showConfirmButton: false,
                            timer: 1500
                          })
                        })
                    }
                  >
                    <FontAwesomeIcon
                      className="text-Yellow text-2xl mr-4 absolute top-5 right-12"
                      icon={faShareNodes}
                    />
                  </button>
                )}
              </section>
              <section className="py-16 bg-turquoise">
                <div>
                  <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-xl -mt-16 bg-turquoise">
                    <div className="px-6">
                      <div className="flex flex-wrap -mt-36 justify-center sm:justify-start">
                        <div>
                          <div className="w-full lg:w-3/12 lg:order-2 flex">
                            <div className="relative">
                              {loadImgProfile
                                ? <ImgProfileSkeleton />
                                : <img className="rounded-full relative w-48 h-48 align-middle object-cover border-8 border-orange-500" src={MostrarImg === null ? imgDefault : `data:image/jpeg;base64,${MostrarImg}`} style={{ maxWidth: "190px" }} />
                              }
                              {/* <img
                                alt="..."
                                src={showInfo.imagen1}
                                className="rounded-full relative w-48 h-48 align-middle object-cover border-8 border-orange-500"
                                style={{ maxWidth: "190px" }}
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-center sm:text-start md:ml-5">
                          <h1 className="text-white font-bold text-2xl">
                            {showInfo.names}
                          </h1>
                          <h1 className="flex items-end justify-center md:justify-start">
                            {skeletonStar ? (
                              <SkeletonStar />
                            ) : (
                              <StarRating
                                initialRating={starFree}
                                theme={themeStar}
                                readOnly={true}
                              />
                            )}
                            <span className="text-white">({average})</span>
                          </h1>
                          <h3 className="text-white mb-2">
                            {showInfo.nickName}
                          </h3>
                          {/* <span className="text-white border rounded-full p-1 text-sm">&nbsp;DUI: {showInfo.dui}&nbsp;</span> */}
                          <div className="mt-3">
                            {showInfo.facebook != 0 && (
                              <a href={"https://www.facebook.com/" + showInfo.facebook} target="_black">
                                <FontAwesomeIcon
                                  className="text-white text-2xl mr-4 ml-4 sm:ml-0 hover:text-Yellow"
                                  icon={faFacebookSquare}
                                />
                              </a>
                            )}

                            {showInfo.twitter != 0 && (
                              <a href={"https://twitter.com/"+showInfo.twitter} target="_black">
                                <FontAwesomeIcon
                                  className="text-white text-2xl mr-4 hover:text-Yellow"
                                  icon={faTwitterSquare}
                                />
                              </a>
                            )}

                            {showInfo.instagram != 0 && (
                              <a href={"https://www.instagram.com/"+showInfo.instagram} target="_black">
                                <FontAwesomeIcon
                                  className="text-white text-2xl mr-4 hover:text-Yellow"
                                  icon={faInstagramSquare}
                                />
                              </a>
                            )}

                            {showInfo.linkedin != 0 && (
                              <a href={"https://www.linkedin.com/in/"+showInfo.linkedin} target="_black">
                                <FontAwesomeIcon
                                  className="text-white text-2xl mr-4 hover:text-Yellow"
                                  icon={faLinkedin}
                                />
                              </a>
                            )}

                            {(showInfo.briefcase_link != null)
                              ?
                              (<a href={showInfo.briefcase_link} target="_black">
                                <FontAwesomeIcon
                                  className="text-white text-2xl mr-4 hover:text-Yellow"
                                  icon={faBriefcase}
                                  title="Portafolio externo"
                                />
                              </a>)
                              : <span></span>
                            }

                            {/* <a href="#">
                              <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faWhatsapp} />
                            </a> */}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap p-4">
                        <div className="lg:w-2/3 w-full">
                          <div className="p-4">
                            <h3 className="text-start text-orange-500 font-semibold">
                              Acerca de
                            </h3>
                            <p className="text-justify font-semibold text-white text-sm whitespace-pre-wrap">
                              {showInfo.about_me}
                            </p>
                          </div>

                          <div className="flex flex-wrap">
                            <div className="w-full p-2">
                              <div className="p-4 flex bg-Light-purple rounded-md">
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full sm:flex">
                                  <h2>Estado del perfil: &nbsp;</h2>
                                  <h2>
                                    {StatusProfileFreelancer == 3 && (
                                      <span className="text-orange-500">
                                        {" "}
                                        Completo
                                      </span>
                                    )}

                                    {StatusProfileFreelancer == 2 && (
                                      <>
                                        <span className="text-orange-500">
                                          {" "}
                                          Incompleto
                                        </span>
                                      </>
                                    )}

                                    {StatusProfileFreelancer == 1 && (
                                      <>
                                        <span className="text-orange-500">
                                          {" "}
                                          En validación
                                        </span>
                                      </>
                                    )}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap mt-5">
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon
                                    className="text-white text-2xl mr-4"
                                    icon={faUsers}
                                  />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Edad:</h2>
                                  <h2>{showInfo.dateOfBirth} años</h2>
                                </div>
                              </div>
                            </div>
                            {/* <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faEnvelope} />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Email:</h2>
                                  <h2>{showInfo.email}</h2>
                                </div>
                              </div>
                            </div> */}
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon
                                    className="text-white text-2xl mr-4"
                                    icon={faLocationDot}
                                  />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Municipio:</h2>
                                  <h2>{showInfo.city}</h2>
                                </div>
                              </div>
                            </div>
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon
                                    className="text-white text-2xl mr-4"
                                    icon={faMapLocationDot}
                                  />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Departamento:</h2>
                                  <h2>{showInfo.department}</h2>
                                </div>
                              </div>
                            </div>
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon
                                    className="text-white text-2xl mr-4"
                                    icon={faMapLocationDot}
                                  />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Distrito:</h2>
                                  <h2>{showInfo.Distrito}</h2>
                                </div>
                              </div>
                            </div>
                            {/* <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLocationDot} />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Dirección:</h2>
                                  <h2>{showInfo.direction}</h2>
                                </div>
                              </div>
                            </div> */}
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon
                                    className="text-white text-2xl mr-4"
                                    icon={faLanguage}
                                  />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Idiomas:</h2>
                                  <h2>
                                    {showInfo.language.map(
                                      (idioma: any, index: number) => {
                                        let name = "";
                                        if (
                                          !(
                                            showInfo.language.length - 1 ==
                                            index
                                          )
                                        ) {
                                          name = idioma.name + ", ";
                                        } else {
                                          name = idioma.name;
                                        }

                                        return <span key={name}>{name}</span>;
                                      }
                                    )}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="w-full sm:w-1/2 p-2 cursor-pointer"
                              onClick={
                                () => {
                                  if (StatusProfileFreelancer == 3 || StatusProfileFreelancer == 1) {
                                    if (showInfo.dui !== null) {
                                      if (MostrarImg !== null) {
                                        sessionStorage.setItem("photo", MostrarImg);
                                        cookies.set("dui", showInfo.dui, { path: "/" });
                                        navigate(`/CVPDF`);
                                      } else {
                                        Swal.fire({
                                          icon: 'error',
                                          title: 'Perfil incompleto',
                                          text: 'Para descargar el CV, es necesario tener una foto de perfil',
                                        })
                                      }
                                    } else if (showInfo.nit !== null) {
                                      if (MostrarImg !== null) {
                                        sessionStorage.setItem("photo", MostrarImg);
                                        cookies.set("nit", showInfo.nit, { path: "/" });
                                        navigate(`/CVPDF`);
                                      } else {
                                        Swal.fire({
                                          icon: 'error',
                                          title: 'Perfil incompleto',
                                          text: 'Para descargar el CV, es necesario tener una foto de perfil',
                                        })
                                        sessionStorage.remove("photo");
                                      }
                                    } else {
                                      Swal.fire({
                                        icon: 'error',
                                        title: 'Perfil incompleto',
                                        text: 'Para descargar el CV, es necesario completar el perfil',
                                      })
                                      sessionStorage.remove("photo");
                                    }
                                  } else {
                                    Swal.fire({
                                      icon: 'error',
                                      title: 'Perfil incompleto',
                                      text: 'Para descargar el CV, es necesario completar el perfil',
                                    })
                                  }
                                }
                              }
                            >
                              <div className="flex bg-Light-purple rounded-md p-4 h-full hover:bg-indigo-900 transition-colors">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon
                                    className="text-white text-2xl mr-4"
                                    icon={faCloudArrowDown}
                                  />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full flex items-center">
                                  <h2>Mostrar CV</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="lg:w-1/3 w-full">
                          <div className="flex flex-wrap">
                            <div className="w-1/2 lg:w-full p-2">
                              <div className="p-4 flex bg-Light-Blue rounded-md mt-5">
                                <div className="w-full">
                                  <h1 className="text-start text-white font-semibold">
                                    Proyectos finalizados
                                  </h1>
                                  <h1 className="text-center md:text-end text-white font-semibold text-3xl">
                                    {showInfo.infoProjectFreelncer.finished}
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div className="w-1/2 lg:w-full p-2">
                              <div className="p-4 flex bg-Light-Blue rounded-md mt-5">
                                <div className="w-full">
                                  <h1 className="text-start text-white font-semibold">
                                    Proyectos en proceso
                                  </h1>
                                  <h1 className="text-center md:text-end text-white font-semibold text-3xl">
                                    {showInfo.infoProjectFreelncer.working}
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div className="w-1/2 lg:w-full p-2">
                              <div className="p-4 flex bg-Light-Blue rounded-md mt-5">
                                <div className="w-full">
                                  <h1 className="text-start text-white font-semibold">
                                    Proyectos aplicados
                                  </h1>
                                  <h1 className="text-center md:text-end text-white font-semibold text-3xl">
                                    {showInfo.infoProjectFreelncer.applied}
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div className="w-1/2 lg:w-full p-2">
                              <div className="p-4 flex bg-Light-Blue rounded-md mt-5">
                                <div className="w-full">
                                  <h1 className="text-start text-white font-semibold">
                                    Proyectos Rechazados
                                  </h1>
                                  <h1 className="text-center md:text-end text-white font-semibold text-3xl">
                                    {showInfo.infoProjectFreelncer.rejected}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div>
                        Hola
                      </div> */}
                      {/* components tabs */}
                      <div className="mb-4 mt-4">
                        <TabsInfo idFree={undefined} state="session"></TabsInfo>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      )}
    </>
  );
};

export default CreacionProfileFreelancer;
