import { stringify } from "querystring";
import { alertLogin, InputPay } from "../Interfaces/Login";



export const validateFormPayProjectAndSubscription = (data:InputPay[]) =>{

   let errors: alertLogin[] = [];

   
   const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   

      data.map((input) =>{

      switch(input.name) { 

         case 'cvv': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
         
         case 'card': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'cardholder': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }

         case 'expire_date': {

            if (input.value === '' || input.value === null) {
            
               errors.push({
                  input:input.name,
                  message:'Campo requerido',
                  state:true
               });


            }else{
                errors.push({
                  input:input.name,
                  message:'',
                  state:false
               })
            }
            
            break; 
         }
 


         
         default: { 
            // errors.push({
            //    input:"requeridos",
            //    message:'Campos requeridos',
            //    state:true
            // });
            break; 
         } 
      } 
   })
 
   return errors;
  
}