
import { Link } from "react-router-dom";
import imgDefault from '../../../assets/imgEditPerfil/user.png';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProjectInfoSkeleton =  () =>{


    return(
        <>
            <div className="flex flex-wrap">
               <div className="w-full md:w-30% lg:w-3/12">
                  <div className=" flex justify-center mb-8">
                        <div className="w-52 h-52 overflow-hidden">                                                
                            <Skeleton circle width={195} height={195} count={1} />
                        </div>
                  </div>
                  {/* <div className="columns-2 md:columns-2 xl:columns-2">
                     <Skeleton count={1} height={40} />
                     <Skeleton count={1} height={40} />
                     <Skeleton count={1} height={40} />
                     <Skeleton count={1} height={40} />

                  </div> */}
               </div>
               <div className="w-full md:w-70% lg:w-9/12 md:pl-12 relative">
                  <h1 className="text-white text-lg md:text-4xl"> <Skeleton count={1} /></h1>
                  <h2 className="text-Yellow text-base md:text-3xl py-4"> <Skeleton count={1} /></h2>
                  <div className="items-center py-4">
                        <p className="text-white text-base md:text-xl"> <Skeleton count={1} /></p>
                  </div>
                  <h2 className="text-white text-base md:text-3xl py-4"> <Skeleton count={1} /></h2>
                  <p className="text-white text-bse md:text-lg mb-8 md:mb-24">
                     <Skeleton count={4} />
                  </p>
                  {/* <div className="bottom-0 right-0 w-full lg:w-4/5 sm:absolute sm:flex justify-end mt-2 mb-2">
                     <span className="p-3"> <Skeleton count={1} width={100} height={30}  /></span>
                     <span className="p-3"> <Skeleton count={1} width={100} height={30}  /></span>
                  </div> */}
               </div>
            </div>
        </>
    )
}

export default ProjectInfoSkeleton;