import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PlanesSkeleton from "../components/Skeleton/planes/planesSkeleton";
import Cookies from "universal-cookie";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { stringify } from "querystring";

const Planes = () => {

    const initialPlans = {
        id: 0,
        name: '',
        normalPrice: 0,
        planDescription: '',
        projectsPerMonth: 0,
        salePrice: 0,
        activeProjectsPerMonth: 0,
        activeStatus: 0,
        commision: 0,
        billingPeriod: 0,
        items: []
    }

    const [planActual, setPlanActual] = useState();

    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    async function ObtenerPlanActual() {

        const url = `${process.env.REACT_APP_API_URI}company/profile/${getSesion.id}`;

        let config = {
            // multipart/form-data
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        const resp = await axios.get(url, config);

        try {
            setPlanActual(resp.data.planes_id)
        } catch (error) {
            console.log(error)
        }


    };

    const idParams = planActual;
    const idPlanAct = Number(idParams);

    const MySwal = withReactContent(Swal);

    const [dataPlans, setDataPlans] = useState([initialPlans]);

    const navigate = useNavigate();

    const [animateSkeleton, setAnimateSkeleton] = useState(true);

    useEffect(() => {
        ObtenerPlanActual();
        getPlans();
    }, []);

    // Obtener los planes de la plataforma
    const getPlans = async () => {
        const urlGetPlans = `${process.env.REACT_APP_API_URI}admin-plans/list`;
        try {
            const response = await axios.get(urlGetPlans);

            setDataPlans(response.data.data);
            
            setTimeout(() => { setAnimateSkeleton(false); }, 2000);
        }

        catch (error) {
            console.error(error);
        }
    }

    const cancelPlan = () => {
        MySwal.fire({
            position: 'center',
            icon: 'warning',
            title: '¡ La siguiente acción cancelara y anulara la suscripción del plan actual !',
            showConfirmButton: true,
            confirmButtonColor: '#dc3741',
            confirmButtonText: 'Anular plan',
            showCancelButton: true,
            cancelButtonColor: '#7066e0',
            cancelButtonText: 'Cerrar'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    title: 'La suscripción al plan ha sido cancelada',
                    allowOutsideClick: false
                });
            }
        });
    }

    const payPlan = (id: number) => {
        navigate(`/obtener-plan/${id}`);
    }


    return (
        <>
<div className="bg-Light-purple sm:w-auto ">
            <div className="w-full sm:w-20 md:w-20 pl-2 ml-10 sm:w-auto">
                <Link to="" onClick={() => window.history.go(-1)}>
                    <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4 sm:w-auto" alt="" />
                </Link>
            </div>
            </div>
            {animateSkeleton ? (
                <PlanesSkeleton></PlanesSkeleton>
            ) : (

                <div className="w-full bg-gradient-to-b from-Light-purple p-5">
                    <h1 className="text-3xl md:text-4xl flex justify-center items-center font-semibold p-5 my-5">
                        <span className="text-Yellow">Planes</span>
                        &nbsp;
                        <span className="text-Morado-Ticongle">chamba</span>
                        <span className="text-Azul-Ticongle">ti</span>
                        <span className="text-Yellow">con</span>
                    </h1>
                    <p className="text-lg text-center text-white">
                        Encuentra mejores beneficios al contratar un plan para tu empresa. Observa nuestros planes disponibles, puede ser el indicado para tu negocio.
                    </p>

                    <div className="flex flex-wrap my-5 p-2 xl:p-14">
                        <section className="text-gray-600 body-font overflow-hidden">
                            <div className="container px-5 py-10 mx-auto">

                                <div className="flex flex-wrap -m-4">

                                    {
                                        dataPlans.map((items) => {
                                            return (
                                                <>

                                                    {(items.salePrice == 0) ? (
                                                        <div className="p-4 md:w-1/2 w-full">
                                                            <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden bg-bluecard-big">
                                                                <h2 className="text-sm tracking-widest title-font mb-1 font-medium text-white">{items.name}</h2>
                                                                {/* <h1 className="text-5xl pb-4 mb-4 border-b border-gray-200 leading-none text-white"> 
                                                            {items.normalPrice == 0 ? 'FREE' : '$ '+items.normalPrice}
                                                        </h1> */}

                                                                <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                                                                    <span className="text-white">{items.normalPrice == 0 ? 'FREE' : '$' + items.normalPrice}</span>
                                                                    <span className="text-lg ml-1 font-normal text-white">{items.normalPrice == 0 ? '' : ' /mensual'}</span>
                                                                </h1>

                                                                <div className="flex my-5">
                                                                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                            <path d="M20 6L9 17l-5-5"></path>
                                                                        </svg>
                                                                    </span>
                                                                    <label className="ml-2 text-xs font-medium text-white">{items.planDescription}</label>
                                                                </div>
                                                                {
                                                                    (items.normalPrice == 0)
                                                                        ?
                                                                        <div className="flex my-5">
                                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                                    <path d="M20 6L9 17l-5-5"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <label className="ml-2 text-xs font-medium text-white">Necesita validación de los administradores para publicar las oportunidades</label>
                                                                        </div>
                                                                        :
                                                                        <div className="flex my-5">
                                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                                    <path d="M20 6L9 17l-5-5"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <label className="ml-2 text-xs font-medium text-white">No necesita validación de los administradores para publicar tus oportunidades</label>
                                                                        </div>
                                                                }
                                                                <div className="flex my-5">
                                                                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                            <path d="M20 6L9 17l-5-5"></path>
                                                                        </svg>
                                                                    </span>
                                                                    <label className="ml-2 text-xs font-medium text-white">Cantidad de oportunidades a publicar mensualmente {items.projectsPerMonth}</label>
                                                                </div>
                                                                <div className="flex my-5">
                                                                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                            <path d="M20 6L9 17l-5-5"></path>
                                                                        </svg>
                                                                    </span>
                                                                    <label className="ml-2 text-xs font-medium text-white">Cantidad de oportunidades activas mensualmente {items.activeProjectsPerMonth}</label>
                                                                </div>

                                                                {
                                                                    (items.id == idPlanAct) ? (
                                                                        <div className="flex justify-center mt-4">
                                                                            <div className="absolute bottom-5 flex items-center">
                                                                                <FontAwesomeIcon className="text-green-400" icon={faCircleCheck} />
                                                                                <div className="text-white text-xl p-1 w-3/5 md:w-4/5 text-center">Aplicado</div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (

                                                                        (items.normalPrice == 0)
                                                                            ?
                                                                            <button className="flex items-center mt-auto text-white bg-Yellow border-0 py-2 px-4 w-full focus:outline-none rounded" onClick={() => payPlan(items.id)} disabled>Gratuito
                                                                                {/* <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                                                </svg> */}
                                                                            </button>
                                                                            :
                                                                            <button className="flex items-center mt-auto text-white bg-Yellow border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded" onClick={() => payPlan(items.id)} >Aplicar
                                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                                                </svg>
                                                                            </button>
                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                                                            <div className="h-full p-6 rounded-lg border-2 border-Yellow flex flex-col relative overflow-hidden bg-bluecard-big">
                                                                <span className="bg-Yellow text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">OFERTA</span>
                                                                <h2 className="text-sm tracking-widest title-font mb-1 font-medium text-white">{items.name}</h2>
                                                                <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                                                                    <span className="text-white">${items.salePrice}</span>
                                                                    <span className="text-lg ml-1 font-normal text-white"> /mensual</span>
                                                                </h1>

                                                                <div className="flex my-5">

                                                                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                            <path d="M20 6L9 17l-5-5"></path>
                                                                        </svg>
                                                                    </span>

                                                                    <label className="ml-2 text-xs font-medium text-white">{items.planDescription}</label>
                                                                </div>
                                                                {
                                                                    (items.normalPrice == 0)
                                                                        ?
                                                                        <div className="flex my-5">
                                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                                    <path d="M20 6L9 17l-5-5"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <label className="ml-2 text-xs font-medium text-white">Necesita validación de los administradores para publicar las oportunidades</label>
                                                                        </div>
                                                                        :
                                                                        <div className="flex my-5">
                                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                                    <path d="M20 6L9 17l-5-5"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <label className="ml-2 text-xs font-medium text-white">No necesita validación de los administradores para publicar las oportunidades</label>
                                                                        </div>
                                                                }
                                                                <div className="flex my-5">
                                                                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                            <path d="M20 6L9 17l-5-5"></path>
                                                                        </svg>
                                                                    </span>
                                                                    <label className="ml-2 text-xs font-medium text-white">Cantidad de oportunidades a publicar mensualmente {items.projectsPerMonth}</label>
                                                                </div>
                                                                <div className="flex my-5">
                                                                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                            <path d="M20 6L9 17l-5-5"></path>
                                                                        </svg>
                                                                    </span>
                                                                    <label className="ml-2 text-xs font-medium text-white">Cantidad de oportunidades activas mesualmente {items.activeProjectsPerMonth}</label>
                                                                </div>


                                                                {/* <p className="flex items-center text-white mb-2">
                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-Yellow text-white rounded-full flex-shrink-0">
                                                                
                                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                <path d="M20 6L9 17l-5-5"></path>
                                                            </svg>
                                                            
                                                            </span>{items.planDescription}
                                                        </p> */}

                                                                {
                                                                    (items.id == idPlanAct) ? (
                                                                        <div className="flex justify-center mt-4">
                                                                            <div className="absolute bottom-5 flex items-center">
                                                                                <FontAwesomeIcon className="text-green-400" icon={faCircleCheck} />
                                                                                <div className="text-white text-xl p-1 w-3/5 md:w-4/5 text-center">Aplicado</div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (

                                                                        (items.normalPrice == 0)
                                                                            ?
                                                                            <button className="flex items-center mt-auto text-white bg-Yellow border-0 py-2 px-4 w-full focus:outline-none rounded" onClick={() => payPlan(items.id)} disabled>Gratuito
                                                                                {/* <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                                                </svg> */}
                                                                            </button>
                                                                            :
                                                                            <button className="flex items-center mt-auto text-white bg-Yellow border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded" onClick={() => payPlan(items.id)} >Aplicar
                                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                                                </svg>
                                                                            </button>
                                                                    )
                                                                }


                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )

                                        })
                                    }

                                </div>

                            </div>
                        </section>
                    </div>
                </div>
            )}



        </>
    );
}

export default Planes;
