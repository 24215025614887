import imagenesDetallesProject from "../assets/imgDetallesProject/imgDetallesProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcaseMedical, faDownload } from "@fortawesome/free-solid-svg-icons";
import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { HandleSession } from "../function/Login";
import imgDefault from '../assets/imgEditPerfil/user.png';

import CardsFreelancerSkeleton from '../components/Skeleton/cardsFreelancer/cardsFreelancerSkeleton';

import LazyLoad from 'react-lazyload';
import { TransitionGroup } from 'react-transition-group';

import imgListFreeLancer from '../assets/imgs/no_hay_freelances.svg';

// Modal de opción para iniciar sesión como freelancer o empresa
import ModalLogin from '../components/modals/modalLogin';


const initialState = {
    about_me: null || undefined || "",
    id: 0,
    lastnames: "",
    freelancer: "",
    proyectos_finalizados: 0,
    names: null || undefined || "",
    photo: "",
    specializations: [{ nombre: "" }],
    state: "",
    filter1: "",
    filter2: "",
    filter3: "",
    filter4: "",
    username: ''
};


// Types
type handleSeekerType = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const ListFreelance = () => {


    const [listFreelancer, setListFreelancer] = useState([initialState]);

    const [InitialStateList, setInitialStateList] = useState([initialState]);

    const [IsLoading, setIsLoading] = useState(false);

    const [MostrarImg, setMostrarImg] = useState(imgDefault);
    // validate buttons and logo
    const verifySession = HandleSession();

    const [messageDefault, setMessageDefault] = useState(false);

    const [Seeker, setSeeker] = useState('');

    // states modals
    const [addClassModal, setAddClassModal] = useState("modalCloseProject");

    const [IsLoadingModal, setIsLoadingModal] = useState(false);

    // const urlDominio = window.location.host;

    const urlEnlacePerfil = `/profile-public/`;

    // form input event
    const handleSeeker = ({ target: { name, value } }: handleSeekerType) => {
        setSeeker(value);
        filterFreeLancer(value);
    };



    const filterFreeLancer = (value: string) => {

        if (!value) {
            setListFreelancer(InitialStateList);
        } else {

            const data = listFreelancer.filter((item, index) => {

                if (item.about_me == null || item.about_me == "null" || item.about_me == "" || item.about_me == undefined) {

                } else {
                    if (item.about_me.toLowerCase().includes(value.toLocaleLowerCase()) || item.names.toLowerCase().includes(value.toLocaleLowerCase()) || item.filter1.toLowerCase().includes(value.toLocaleLowerCase()) || item.filter2.toLowerCase().includes(value.toLocaleLowerCase()) || item.filter3.toLowerCase().includes(value.toLocaleLowerCase()) || item.filter4.toLowerCase().includes(value.toLocaleLowerCase()) || item.state.toLowerCase().includes(value.toLocaleLowerCase())) {

                        return item;
                    }
                }

            });

            // console.log('data-data-data-data', data);

            if (data.length > 0) {
                setListFreelancer(data);
            } else {
                setListFreelancer(InitialStateList);
            }
        }

    };

    const params = useParams();

    useEffect(() => {

        async function fetchData() {

            let urlListFreelancer = "";

            if (params.id) {
                urlListFreelancer = `${process.env.REACT_APP_API_URI}freelancer/search?id=${params.id}`;
            } else {
                urlListFreelancer = `${process.env.REACT_APP_API_URI}freelancer/search`;
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };

            try {

                const resp = await axios.get(urlListFreelancer, config,);

                console.log("resp ---", resp);
                setMessageDefault(false);
                setIsLoading(true);


                const ListFreelancer = resp.data.map((profile: any) => {

                    const result = Array.from(new Set(profile.specializations.original.data.map((s: { category_id: any; }) => s.category_id)))
                        .map(id => {
                            return {
                                nombre: profile.specializations.original.data.find((s: { category_id: unknown; }) => s.category_id === id).specialization_category
                            };
                        });



                    const filter1 = (result[0] == undefined) ? "1" : result[0].nombre;
                    const filter2 = (result[1] == undefined) ? "1" : result[1].nombre;
                    const filter3 = (result[2] == undefined) ? "1" : result[2].nombre;
                    const filter4 = (result[3] == undefined) ? "1" : result[3].nombre;


                    const data = {
                        about_me: profile.about_me,
                        username: profile.username,
                        id: profile.id,
                        lastnames: profile.lastnames,
                        names: profile.names,
                        photo: profile.photo,
                        proyectos_finalizados: profile.proyectos_finalizados,
                        specializations: result,
                        state: profile.state,
                        filter1: filter1,
                        filter2: filter2,
                        filter3: filter3,
                        filter4: filter4
                    }

                    return data;
                    // }
                })

                console.log('ListFreelancer--', ListFreelancer);
                // setMessageDefault


                setListFreelancer(ListFreelancer);

                setInitialStateList(ListFreelancer);

                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);



            } catch (err) {
                setMessageDefault(true);
            }
        };
        fetchData();

    }, []);

    const upModal = (state: Boolean) => {
        // console.log("CERRAR");
        if (state) {
            setAddClassModal("modalOpenProject");
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        } else {
            setAddClassModal("modalCloseProject");
            // console.log("CERRAR");
            document.getElementsByTagName("body")[0].style.overflow = "visible";
        }
        setIsLoadingModal(true);
        setInterval(() => {
            setIsLoadingModal(false);
        }, 1000);
    }


    return (
        <>

            <div className="bg-gradient-to-b from-Light-purple via-turquoise to-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    {/* <div className="hidden lg:flex w-10% bg-gradient-to-b from-Light-purple via-turquoise to-turquoise flex justify-end">
                        <img className="" src={imagenesDetallesProject.fondo_cadena} alt="" />
                    </div> */}
                    <div className="md:w-full lg:w-full flex justify-center pb-5 lg:py-5">
                        <div className="mx-1 lg:mx-10 w-full">
                            <div className="flex justify-center pb-2 lg:pb-4 mt-4 lg:mt-0">
                                {/* <h1 className="text-white font-bold text-xl sm:text-3xl roboto text-Yellow">Freelancers en chambaticon</h1> */}
                                <h1 className="text-white font-bold text-lg sm:text-2xl roboto text-Yellow">Encuentra al talento ideal para tu proyecto</h1>
                            </div>
                            <div className="flex justify-center">
                                <div className="mb-6 text-start w-full md:w-1/2">
                                    {/* <span className="text-white">{Seeker}</span> */}
                                    <input
                                        type="text"
                                        className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        name="direction"
                                        placeholder="Buscar..."
                                        value={Seeker}
                                        onChange={handleSeeker}
                                    />

                                </div>
                            </div>

                            {(messageDefault) &&
                                <div>
                                    <p className="text-center text-white text-xl mt-6 ">No se encuentran talento registrados</p>
                                    <div className="flex justify-center">
                                        <img src={imgListFreeLancer} alt="" width="51%" />
                                    </div>
                                </div>
                            }



                            <section className="text-gray-600 body-font">
                                <div className="container py-8 mx-auto">
                                    <div className="flex flex-wrap ">

                                        {!(messageDefault) &&
                                            <>
                                                {IsLoading && <CardsFreelancerSkeleton cards={6} />}

                                                {!IsLoading &&
                                                    (listFreelancer[0].names != "" &&

                                                        listFreelancer.map((freelancer, key) => {
                                                            return (
                                                                <>
                                                                    {/* <LazyLoad throttle={700} height={400}> */}
                                                                    {/* <TransitionGroup key="1"
                                                                            timeOut={{ enter: 5000, exit: 300 }}
                                                                            transitionEnter={true}
                                                                            transitionLeave={true}
                                                                            data-aos="fade-up"
                                                                        > */}
                                                                    <div className="p-2 lg:w-1/2 md:w-1/2 w-full ">
                                                                        <div className="h-full flex items-center bg-[#090F2C] border-gray-200 border p-4 rounded-lg">


                                                                            <img alt="team" className="w-32 h-32 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={`data:image/jpeg;base64,${freelancer.photo}`} />

                                                                            <div className="flex-grow">
                                                                                <h2 className="text-white title-font font-medium text-lg ">{freelancer.names} {freelancer.lastnames}</h2>

                                                                                <p className="text-gray-400 text-base">{freelancer.state}</p>

                                                                                <div className="flex flex-wrap items-center">
                                                                                    <div className="bg-cyan-700 px-2 py-1.5 mr-1 mt-1 my-2 rounded text-2xs sm:text-xs text-white flex items-center">
                                                                                        Proyectos finalizados:
                                                                                        <span className="bg-indigo-100 text-indigo-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center mx-2">
                                                                                        {freelancer.proyectos_finalizados}
                                                                                        </span>
                                                                                       
                                                                                    </div>
                                                                                </div>

                                                                                <div className="flex flex-wrap items-center">

                                                                                    {
                                                                                        freelancer.specializations.map((specialization: any, index) => {
                                                                                            if (index <= 2) {
                                                                                                return (
                                                                                                    <div className="bg-[#FB9000] px-2 py-1.5 mr-1 mt-1 my-2 rounded text-2xs sm:text-xs text-white flex items-center">
                                                                                                        {specialization.nombre}
                                                                                                    </div>
                                                                                                    // <div className="bg-cyan-700 px-2 py-1.5 mr-1 mt-1 my-2 rounded text-2xs sm:text-xs text-white flex items-center">{specialization.nombre}</div>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    {
                                                                                        ((freelancer.specializations.length > 2) &&
                                                                                            <div>
                                                                                                <FontAwesomeIcon className="text-white" icon={faBriefcaseMedical} />
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                
                                                                                <div className="">
                                                                                    <p className="text-white text-sm">
                                                                                        {freelancer.about_me.substring(0,150)}
                                                                                    </p>

                                                                                    <div className="pt-2 flex justify-end ">
                                                                                    {(verifySession.Session == false) ? (
                                                                                            <button
                                                                                                className="bg-Yellow w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded px-4 py-1.5 mt-1"
                                                                                                onClick={() => upModal(true)}
                                                                                            >
                                                                                                Ver perfil
                                                                                            </button>
                                                                                        ) : (
                                                                                            <Link to={`${urlEnlacePerfil}${freelancer.username}/${freelancer.id}`}
                                                                                                className="text-Yellow w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded-lg px-4 py-1.5 mt-1">
                                                                                                Ver perfil
                                                                                            </Link>
                                                                                        )}
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* </TransitionGroup> */}
                                                                    {/* </LazyLoad> */}
                                                                </>
                                                            )
                                                        })
                                                    )
                                                }
                                            </>
                                        }


                                    </div>
                                </div>
                            </section>


                        </div>
                    </div>
                </section>
            </div>
            <ModalLogin addClassModal={addClassModal} setAddClassModal={setAddClassModal} IsLoadingModal={IsLoadingModal} />
        </>
    )
}

export default ListFreelance;