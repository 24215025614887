import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';

import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { Link, useNavigate, useParams } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

function InfoServicesProfessional(){

    const id_internships = useParams();
    console.log("id_internships ==>y",id_internships);

    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    // Cookies
    const cookies = new Cookies();
    const getSesion = cookies.get("Bearer");


    const initialProfessionalServices = {
        application_deadline: "",
        created_at: "",
        description: "",
        id: "",
        id_company: "",
        id_type_opportunities: "",
        id_type_salary: "",
        id_type_service: "",
        name: "",
        other_type_service: "",
        salary: "",
        status: "",
        updated_at: ""
    };
    const [professionalServices, setProfessionalServices] = useState([initialProfessionalServices]);

    console.log(professionalServices);

    const typeService = (param) => {
        const id_type_service = param;
        const listServices = ["", "Consultoría", "Asesoramiento", "Diseño Gráfico", "Desarrollo de software"];
        return listServices[id_type_service];
    };

    const typeSalary = (param) => {
        const id_type_salary = param;
        const listTypeSalary = ["", "Por hora", "Por proyecto"];
        return listTypeSalary[id_type_salary];
    };

    const applyServicesProfesional = () =>{
        MySwal.fire({
            title: "¿Está seguro?",
            text: "Sí, deseo presentar mi perfil para el servicio profesional.",
            color:"#fff",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Enviar",
            cancelButtonText:"Cancelar"
          }).then((result) => {
            if (result.isConfirmed) sendData();
          });
    };

    const sendData = () =>{

        const url = `${process.env.REACT_APP_API_URI}freelancer/apply-project/${getSesion.id}`; 

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        const newData = {
            budget:"",
            id_type_opportunities:3,
            project_id:id_internships.id,
            project_proposal:"",
        };
 
        console.log("newData ==>", newData);
        
        axios.post(url, newData, config)
            .then(response => {            
                MySwal.fire({
                    title: "¡Completado!",
                    text: "Tu perfil ha sido enviado con éxito! En los próximos días recibirás una notificación sobre el proceso a través de tu correo electrónico.",
                    color:"#fff",
                    icon: "success"
                  });
    
                  setTimeout(() => {
                    navigate('/perfil-freelancer');
                  }, 5000);
            })
            .catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });
            })
    
    }

    useEffect(() => {
        const InfoProfessionalService = async () => {

            // let urlListOpportunity = `${process.env.REACT_APP_API_URI}opportunity/search?id_type_opportunity=3`;
            let urlListOpportunity = `${process.env.REACT_APP_API_URI}project/get/${id_internships.id}?id_type_opportunities=3`;


            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${getSesion.token}`
                }
            };

            try {
                const resp = await axios.get(urlListOpportunity, config);
                const getProfessionalServices = resp.data;
                // console.log(getProfessionalServices, "getProfessionalServices");
                setProfessionalServices(getProfessionalServices);
            } catch (err) {
                console.error(err);
            };

        };
        InfoProfessionalService();
    }, [])


    return (

        <div className="bg-turquoise">
            <section className="block -mt-px md:flex md:flex-wrap">

                <div className="md:w-full lg:w-5/5 flex flex-wrap justify-center pb-5 lg:py-5 md:pt-4 lg-pt-0">

                    <div className="w-full md:w-full flex justify-center">
                        <div className="w-11/12">

                            <div className="flex justify-start pb-0 lg:pb-4 py-4">
                                <Link to="" onClick={() => window.history.go(-1)} className='w-16 h-16'>
                                    <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                                </Link>
                                <h1 className="text-Yellow text-center font-bold text-6xl w-full">Servicio profesional</h1>
                            </div>

                            <div className="flex flex-col ">

                                <h1 className="w-full text-left my-4 text-white lg:text-4xl md:text-3xl sm:text-3xl xs:text-xl">
                                    {professionalServices.name}
                                </h1>

                                <div className="xl:w-full lg:w-full md:w-full sm:w-full xs:w-full mx-auto flex flex-col gap-4 items-center relative">

                                    <p className="w-fit text-white dark:text-gray-400 text-md">
                                        {professionalServices.description}
                                    </p>



                                    <div className="w-full my-auto py-6 flex flex-col justify-center gap-2">
                                        <div className="w-full flex sm:flex-row xs:flex-col gap-2 justify-center">

                                            <div className="w-full">
                                                <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">

                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Tipo Servicio</dt>
                                                        <dd className="text-lg text-white"> {typeService(professionalServices.id_type_service)}</dd>
                                                    </div>

                                                    <div className="flex flex-col py-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Remuneración</dt>
                                                        <dd className="text-lg text-white">{typeSalary(professionalServices.id_type_salary)}</dd>
                                                    </div>

                                                    <div className="flex flex-col py-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Fecha limite de aplicación</dt>
                                                        <dd className="text-lg text-white">{professionalServices.application_deadline}</dd>
                                                    </div>

                                                    {/* <div className="flex flex-col py-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Salario</dt>
                                                        <dd className="text-lg text-white">${professionalServices.salary}</dd>
                                                    </div> */}


                                                </dl>
                                            </div>

                                            <div className="w-full">
                                                <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">

                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Salario</dt>
                                                        <dd className="text-lg text-white">${professionalServices.salary}</dd>
                                                    </div>

                                                    <div className="flex flex-col pb-3">
                                                        <a onClick={() => applyServicesProfesional() } className="w-1/2 text-center rounded-lg bg-Yellow text-white border-0 py-4 px-3 lg:text-base md:text-sm mt-4">
                                                            Enviar mi perfil
                                                        </a>
                                                    </div>
                                                    

                                                </dl>
                                            </div>

                                        </div>


                                    </div>




                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default InfoServicesProfessional;