

import React from 'react';
import { Link } from 'react-router-dom';


function TerminosYCondiciones() {
    return (
        <div className="flex-row" id="politicasChambaticon">

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 p-5 rounded-lg text-justify text-white font-medium">
                    <div className="w-full my-5">
                        <Link to="" onClick={() => window.location.href = "/"}>
                            <img src="/static/media/boton_back2_boton2.2da335d0bf766228caf2.png" className="w-15% sm:w-10% md:w-5%" alt="" />
                        </Link>
                    </div>
                    <h1 className="text-orange-500 font-bold text-2xl text-center ">Términos y Condiciones de uso de Chambaticon</h1>
                    {/* <h3><span className='text-orange-500'>CHAMBATICON</span> comunica a los usuarios, a través de este aviso, que puede utilizar cookies cuando el usuario navega por las diferentes pantallas y páginas del sitio. Durante el uso de nuestra plataforma usted acepta y autoriza expresamente el uso de cookies, de acuerdo con nuestra Política de Privacidad.</h3> */}
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl pb-4">1. Presentación</h1>
                    <p className='py-4'><span className='text-orange-500'>CHAMBATICON</span> (en adelante, "nosotros") le da la bienvenida a los talentos y empresas (en adelante, “usuario” o “usted”) a nuestra plataforma www.chambaticon.sv, (en adelante, el “la plataforma”), donde encontrará información sobre nosotros.</p>

                    <p className='py-4'><span className='text-orange-500'>CHAMBATICON</span> es una plataforma en línea que facilita la conexión entre empresas, emprendedores y talentosos freelancers especializados en diversas áreas como Marketing digital, diseño, multimedia, programación entre otras. Nuestro servicio permite a las empresas encontrar el talento idóneo para llevar a cabo proyectos específicos, ofreciendo una variedad de servicios que incluyen desde el reclutamiento de talento hasta la gestión de pagos una vez que el proyecto se ha completado con éxito.</p>

                    <p className='py-4'>Además, en Chambaticon fomentamos y promovemos el trabajo remoto y el freelancing como modalidades laborales innovadoras y en crecimiento. Nos enfocamos en brindar flexibilidad y oportunidades tanto para los empleadores que buscan talento para sus proyectos, como para los trabajadores independientes en busca de oportunidades laborales ajustadas a sus habilidades y preferencias.</p>

                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">2. Información </h1>

                    <p className='py-4'>El presente documento contiene las reglas aplicables al uso de la plataforma www.chambaticon.sv, que es propiedad de <span className='text-orange-500'>ASOCIACIÓN CONEXION AL DESARROLLO DE EL SALVADOR</span>, (en adelante, "<span className='text-orange-500' >CONEXION</span>") de nacionalidad salvadoreña, del domicilio de San Salvador, de plazo indefinido, con Número de Identificación Tributaria: 0614-130409-103-1, ubicado en Calle Reforma, Local 251, Colonia San Benito, en la ciudad de San Salvador, República de El Salvador. </p>

                    <p className='py-4'>

                        Si necesita comunicarse con nosotros, puede hacerlo a través de nuestro correo electrónico <span className='text-orange-500'>hello@ticongle.agency</span> o por teléfono al <span className='text-orange-500'>+503 2249-2301</span>.
                    </p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">

                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">3. Aceptación de los términos:</h1>

                    <p className='py-4'>Al ingresar, conectarse, acceder, utilizar el sitio web y/o solicitar la participación en Chambaticon, usted reconoce haber leído y entendido los siguientes términos y condiciones de uso, los cuales incluyen los términos de nuestra Política de Privacidad. Al hacerlo, usted acepta quedar sujeto a ellos y cumplir con todas las leyes y regulaciones aplicables relacionadas con el uso del sitio web y la participación en nuestra plataforma. Reconoce que estos Términos constituyen un contrato legal vinculante y exigible entre Chambaticon y usted. </p>

                    <p className='py-4'>En caso de que no esté de acuerdo con alguna parte de estos términos, le recomendamos que se abstenga de ingresar, conectarse, acceder, utilizar el sitio web y/o de solicitar participar en la plataforma en cualquier forma.</p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">

                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">

                    <h1 className="text-orange-500 text-xl py-4">4. Requisito de Edad</h1>

                    <p>Para ingresar, conectarse, acceder o utilizar el sitio web y/o solicitar la participación en la plataforma, debe tener al menos 18 años de edad. Al aceptar estos términos y condiciones, usted declara y garantiza que cumple con este requisito de edad. Los padres, tutores o responsables de los menores de 18 años que utilicen el sitio web son plena y exclusivamente responsables por el uso que este le dé al mismo. </p>

                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">

                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">

                    <h1 className="text-orange-500 text-xl">5. Términos para participar en Chambaticon</h1>

                    <p>Los siguientes términos y condiciones se aplicarán a todos los talentos y empresas participantes en Chambaticon:</p>

                    <div className='container p-2'>
                        <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                            <h1 className="text-orange-500 text-xl">1. Registro y validación de usuario</h1>
                            <p className='p-4'>
                                Para acceder a los servicios ofrecidos por Chambaticon, debe registrarse como usuario. El registro de una cuenta como empresa o empleador requiere que envíe determinada información a Chambaticon, como nombre de la empresa, representante legal o encargado, nacionalidad, domicilio, número de teléfono, correo electrónico, datos de identificación, actividad económica a la que se dedica, entre otras. Los perfiles de los talentos o Freelancers deben reflejar en forma precisa su formación académica, experiencia laboral, habilidades, redes sociales y la información personal del Freelancer.
                            </p>

                            <p className='p-4'>Los datos introducidos para efectuar el registro de la empresa o del talento deben contener información veraz, completa y actualizada según lo solicitado en el formulario de registro.</p>

                            <p className='p-4'>El usuario comprende y acepta que el registro en Chambaticon no garantiza automáticamente la aprobación o activación de la cuenta, y que Chambaticon se reserva el derecho de verificar la autenticidad de la información proporcionada por el usuario y cuando lo estime oportuno, puede solicitar por medio de correo electrónico o teléfono, datos adicionales o evidencia de identidad para validar la cuenta. Además, se reserva el derecho de rechazar o desactivar cualquier cuenta que no cumpla con los requisitos de registro o que infrinja las políticas de la plataforma.</p>

                            <p className='p-4'>
                                Asi mismo usted es responsable de mantener la confidencialidad de su cuenta y contraseña, y acepta notificar a Chambaticon inmediatamente cualquier uso no autorizado de su cuenta o cualquier otra violación de seguridad.
                            </p>


                        </div>

                        <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                            <h1 className="text-orange-500 text-xl">2. Exclusividad para Talentos de Ticongle Academy y Asociación Conexión</h1>
                            <p className='p-4'>
                                El uso de la plataforma Chambaticon estará exclusivamente reservado para los talentos que hayan completado algún programa o bootcamp ofrecido por Ticongle Academy o Asociación Conexion. Se requerirá verificación de la participación en estos programas para acceder y utilizar los servicios proporcionados por Chambaticon.
                            </p>
                        </div>

                        <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                            <h1 className="text-orange-500 text-xl">3. Publicación de proyectos y contratación de talentos</h1>
                            <p className='p-4'>
                                Las empresas o empleadores pueden publicar proyectos en la plataforma, describiendo los requisitos del trabajo y cualquier otra información relevante. Al publicar un proyecto, acepta proporcionar información precisa y completa sobre el trabajo ofrecido.
                            </p>
                            <p className='p-4'>
                                Los freelancers pueden postularse para trabajar en proyectos publicados en Chambaticon. Al enviar una solicitud, aceptas proporcionar información precisa y relevante sobre tus habilidades y experiencia.
                            </p>

                            <p className='p-4'>
                                Chambaticon no garantiza la selección de un freelancer para un proyecto específico ni la finalización exitosa del trabajo. La contratación final y las condiciones de trabajo son responsabilidad exclusiva del empleador y el freelancer.
                            </p>

                        </div>

                        <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                            <h1 className="text-orange-500 text-xl">4. Producto del trabajo y entrega</h1>
                            <p className='p-4'>
                                Una vez finalizado el proyecto, el freelancer se compromete a entregar el trabajo finalizado al empleador en el formato y dentro de los plazos acordados durante la negociación. El empleador acepta recibir el trabajo conforme a las especificaciones establecidas y se compromete a revisarlo dentro de un tiempo estipulado para garantizar su satisfacción.
                            </p>
                            <p className='p-4'>
                                El trabajo finalizado debe cumplir con los estándares de calidad acordados previamente y reflejar las especificaciones y requisitos del proyecto definidos durante la negociación inicial. En caso de que el trabajo entregado no cumpla con estos criterios, el empleador tiene derecho a solicitar modificaciones o correcciones dentro del plazo establecido.
                            </p>

                            <p className='p-4'>
                                Ambas partes acuerdan comunicarse de manera efectiva durante el proceso de entrega del trabajo finalizado. Cualquier problema o discrepancia que surja durante este proceso deberá ser comunicado y resuelto de manera oportuna para garantizar una entrega exitosa y satisfactoria del proyecto.
                            </p>

                        </div>

                        <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">

                            <h1 className="text-orange-500 text-xl">5. Términos de pago de honorarios </h1>

                            <div className='container px-4'>
                                <div>
                                    <h1 className="text-orange-500 text-lg py-4">Negociación de Honorarios: <br /></h1>
                                    <p><span className='text-white'>Las empresas, al solicitar publicar proyectos en Chambaticon, indicarán un presupuesto estimado para los servicios que requieren. Los freelancers responderán con propuestas y cotizaciones detalladas una vez que hayan revisado los requisitos del proyecto. La aceptación de una cotización permite la comunicación entre ambas partes a través de la plataforma, facilitando la negociación de los honorarios.</span></p>
                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg py-4"> Comunicación en la plataforma: <br /> </h1>

                                    <p className='text-white py-2 text-lg'>Para facilitar la comunicación entre empleadores y freelancers, Chambaticon permite el intercambio de mensajes dentro de la plataforma, para que puedan comunicarse y negociar los honorarios de manera eficiente y transparente.</p>

                                    <p className='text-white py-2 text-lg'>En casos en los que sea necesario compartir archivos adicionales o finalizar la entrega de un proyecto, recomendamos utilizar métodos seguros de intercambio de archivos, como enlaces a servicios en la nube como Google Drive o Dropbox. </p>

                                    <p className='text-white py-2 text-lg'>Es importante tener en cuenta que cualquier comunicación relacionada con la negociación de honorarios y la finalización de proyectos debe iniciarse y registrarse inicialmente dentro de la plataforma de Chambaticon. Si bien comprendemos la necesidad ocasional de comunicación fuera de la plataforma para facilitar el intercambio de archivos, es importante que la comunicación principal y la negociación se realicen a través de nuestros canales designados.</p>

                                    <p className='text-white py-2 text-lg'>Chambaticon se reserva el derecho de tomar medidas disciplinarias en caso de que se detecte que el empleador y el freelancer están manteniendo comunicación fuera de la plataforma. Dichas medidas pueden incluir la suspensión temporal o permanente de las cuentas de ambas partes involucradas.</p>
                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg py-4">Independencia de Chambaticon: <br /> </h1>
                                    <p className='text-white'>Chambaticon no interviene en las negociaciones entre el empleador y el freelancer en relación con los honorarios acordados. La plataforma no asume responsabilidad por disputas derivadas de acuerdos o desacuerdos en términos de pago entre las partes involucradas.</p>

                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Finalización del proyecto y autorización de pago:</h1>

                                    <p className='text-white py-4'>Una vez que empleador y freelancer confirmen la finalización exitosa de un proyecto según los términos establecidos, Chambaticon habilitará iniciar el proceso de pago correspondiente. Es importante destacar que Chambaticon no se hace responsable de la insatisfacción con el trabajo realizado por el freelancer.</p>

                                    <p className='text-white py-4'>El empleador se compromete a remunerar al freelancer de manera oportuna y de acuerdo con los términos acordados previamente durante la negociación.</p>
                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Proceso de Pago:</h1>

                                    <p className='text-white py-4'>Las empresas o empleadores, al contratar los servicios de un freelancer a través de Chambaticon, están obligados a realizar todos los pagos relacionados con el trabajo realizado exclusivamente a través de la plataforma de Chambaticon.</p>

                                    <p className='text-white py-4'>Queda estrictamente prohibido hacer acuerdos de pago fuera de nuestra plataforma. Es fundamental que los pagos se realicen dentro de nuestra plataforma para garantizar la transparencia, la seguridad y la protección tanto para el empleador como para el freelancer. </p>

                                    <p className='text-white py-4'>Chambaticon no se hace responsable de ningún acuerdo de pago que se realice fuera de nuestra plataforma y se reserva el derecho de tomar medidas contra aquellos que incumplan esta política, pudiendo resultar en la suspensión o terminación de la cuenta del usuario infractor. Chambaticon tampoco se hacer responsable de cualquier incumplimiento en el pago por parte del empleador.</p>
                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Liberación de Pago:</h1>

                                    <p className='text-white py-4'>Tras verificar la finalización exitosa de un proyecto de según con los términos establecidos, Chambaticon procederá a liberar los fondos al freelancer dentro del período especificado posterior a la entrega exitosa del proyecto.</p>

                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Comisiones:</h1>

                                    <p className='text-white py-4'>Chambaticon se reserva el derecho de aplicar omisiones por el uso de sus servicios, las cuales pueden variar según el tipo de transacción y las políticas vigentes de la plataforma. Estas comisiones se informarán de manera transparente antes de que el cliente confirme la contratación del freelancer.</p>

                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Divisas y Tarifas de Conversión:</h1>

                                    <p className='text-white py-4'>Chambaticon puede aplicar tarifas de conversión en caso de pagos realizados en monedas distintas a la predeterminada por el método de pago de la empresa.</p>

                                    <p className='text-white py-4'>Es responsabilidad de los usuarios revisar detenidamente estos términos antes de iniciar cualquier proyecto en Chambaticon, a fin de comprender completamente sus derechos y responsabilidades en relación con las transacciones financieras y el trabajo realizado.</p>



                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Procedimiento de resolución de disputas:</h1>

                                    <p className='text-white py-4'>En caso de surgir una disputa entre las partes involucradas en un proyecto, ya sea relacionada con el pago, la calidad del trabajo o cualquier otro aspecto, ambas partes se comprometen a informar a Chambaticon de manera inmediata.</p>

                                    <p className='text-white py-4'>Chambaticon analizará el caso de forma imparcial y tomará las medidas necesarias para resolver la disputa de manera justa y equitativa. Ambas partes acuerdan cooperar plenamente con Chambaticon durante el proceso de resolución de disputas. No obstante, la plataforma se desliga de cualquier responsabilidad en caso de que las partes no lleguen a un acuerdo</p>

                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Transferencia de Derechos de Autor y Propiedad Intelectual:</h1>

                                    <p className='text-white py-4'>Una vez que el empleador haya completado el pago acordado por los servicios prestados por el freelancer en el marco de un proyecto específico, el freelancer otorga automáticamente y transfiere todos los derechos de autor, propiedad intelectual y cualquier otro derecho de propiedad sobre el trabajo realizado al empleador. Esto incluye, pero no se limita a, los derechos de reproducción, distribución, exhibición pública y modificación del trabajo.</p>

                                    <p className='text-white py-4'>El freelancer reconoce y acepta que el empleador tendrá el derecho exclusivo de utilizar, reproducir, modificar, distribuir y sublicenciar el trabajo, así como de reclamar la autoría del mismo. El freelancer renuncia irrevocablemente a cualquier derecho moral o similar que pueda tener sobre el trabajo, incluyendo el derecho a ser identificado como el autor del mismo. Esta transferencia de derechos se realiza sin restricciones territoriales ni limitaciones de tiempo, y es válida y efectiva en todo el mundo.</p>



                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Planes de suscripción para empresas:</h1>

                                    <p className='text-white py-4'>Chambaticon ofrece diversos planes de suscripción exclusivos para empresas, los cuales se diferencian según las necesidades específicas de cada organización. Los principales criterios de diferenciación entre los planes son:</p>

                                    <div className='container p-4'>

                                        <h1 className="text-orange-500 text-lg py-4">
                                            Validación de administradores para publicar proyectos: <br />
                                            <span className='text-white'>Algunos planes pueden requerir validación por parte de los administradores para publicar proyectos en la plataforma, mientras que otros planes pueden permitir a los usuarios publicar proyectos sin necesidad de aprobación previa.</span>
                                        </h1>

                                        <h1 className="text-orange-500 text-lg py-4">
                                            Cantidad de proyectos a publicar mensualmente: <br />
                                            <span className='text-white'>Los planes varían en la cantidad de proyectos que las empresas pueden publicar en la plataforma cada mes. Planes superiores pueden ofrecer una mayor cantidad de proyectos disponibles para su publicación.</span>
                                        </h1>

                                        <h1 className="text-orange-500 text-lg py-4">
                                            Cantidad de proyectos activos mensualmente: <br />
                                            <span className='text-white'>Asimismo, los planes diferencian en la cantidad máxima de proyectos que una empresa puede tener activos en la plataforma al mismo tiempo. Planes más avanzados pueden permitir una mayor cantidad de proyectos activos simultáneamente.</span>
                                        </h1>


                                    </div>

                                    <p className='text-white py-4'>Es importante destacar que el pago de los planes de suscripción será mensual, automático, anticipado y sucesivo. Los detalles específicos de cada plan de suscripción, incluyendo características y precios, estarán disponibles en la plataforma de Chambaticon y serán comunicados a las empresas interesadas.</p>



                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-lg pt-4">Cancelación de planes de suscripción para empresas </h1>

                                    <p className='text-white py-4'>La empresa tendrá la facultad de cancelar su suscripción en cualquier momento. Sin embargo, continuará disfrutando de los beneficios, servicios y contenidos según las características del Plan de Suscripción que haya adquirido hasta el final del período de facturación correspondiente. La cancelación entrará en vigor a partir del día siguiente al último día del período respectivo, lo que resultará en la interrupción del cobro automático.</p>

                                    <p className='text-white py-4'>Es importante destacar que la cancelación del Plan de Suscripción no exime al suscriptor de la responsabilidad de pagar cualquier cantidad adeudada hasta la fecha de cancelación, incluyendo los honorarios de los freelancers contratados para los proyectos en curso.</p>

                                    <p className='text-white py-4'>En caso de cancelación del Plan, el suscriptor conservará el acceso a su cuenta en el "Plan Gratuito". Para efectuar la cancelación deberá acceder a su perfil de empresa y hacer clic en el icono de editar. Luego, selecciona "Detalles de la suscripción" y encontrará el botón "Cancelar suscripción". Al hacer clic en él, aparecerá una ventana emergente para confirmar la cancelación. Una vez confirmada, tu suscripción será cancelada exitosamente.</p>

                                    <p className='text-white py-4'>El usuario que cancele su suscripción, por cualquier motivo, conservará la posibilidad de reactivar una membresía en cualquier momento mediante la selección del Plan de Suscripción de su elección.</p>

                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-xl">Eliminar Cuenta </h1>

                                    <p className='text-white py-4'>Los usuarios que deseen eliminar su cuenta deben enviar una solicitud a Chambaticon indicando su deseo de hacerlo. Antes de proceder con la eliminación de la cuenta, Chambaticon verificará que no existan disputas en curso, proyectos pendientes, pagos pendientes a freelancers u otras obligaciones financieras o contractuales asociadas a la cuenta del usuario.</p>

                                    <p className='text-white py-4'>Una vez verificado que no existen compromisos pendientes, Chambaticon procederá a eliminar permanentemente la cuenta y todos los datos asociados a la misma. Esta eliminación incluirá la información de perfil, historial de proyectos, mensajes y cualquier otro contenido relacionado con la cuenta.</p>

                                    <p className='text-white py-4'>Chambaticon se reserva el derecho de retener cierta información de la cuenta con fines legales, regulatorios, de seguridad o de prevención de fraudes, incluso después de que se haya eliminado la cuenta.</p>

                                    <p className='text-white py-4'>El usuario que cancele su suscripción, por cualquier motivo, conservará la posibilidad de reactivar una membresía en cualquier momento mediante la selección del Plan de Suscripción de su elección.</p>

                                </div>

                                <div>
                                    <h1 className="text-orange-500 text-xl">Restricciones de Contenido </h1>

                                    <p className='text-white py-2 text-lg'>El usuario se compromete a no cargar, publicar, transmitir, distribuir o almacenar en o a través de la plataforma de Chambaticon ningún contenido que:</p>

                                    <ul className='px-4'>
                                        <li className='py-2'>a Sea difamatorio, calumnioso, fraudulento, obsceno, pornográfico, indecente, vulgar, ofensivo, discriminatorio, amenazante, o que incite a la violencia o al odio racial, étnico o religioso.</li>

                                        <li className='py-2'>• Sea relacionado con la piratería informática.</li>

                                        <li className='py-2'>• Sea relacionado con casinos y juegos de azar.</li>

                                        <li className='py-2'>• Sea relacionado con drogas y artículos relacionados.</li>

                                        <li className='py-2'>• Sea relacionado con la venta de armas o de munición (por ejemplo, armas de fuego y sus piezas, navajas o pistolas de descarga eléctrica).</li>

                                        <li className='py-2'>• Vulnere los derechos de propiedad intelectual, incluyendo, pero no limitado a, derechos de autor, marcas registradas, patentes, secretos comerciales u otros derechos de propiedad de terceros.</li>

                                        <li className='py-2'>• Contenga virus de software u otros códigos, archivos o programas informáticos diseñados para interrumpir, destruir o limitar la funcionalidad de cualquier software o hardware informático o equipo de telecomunicaciones.</li>

                                        <li className='py-2'>•Cualquier otro contenido que sea ilegal, promocione una actividad ilegal o infrinja los derechos legales de otras personas.</li>
                                    </ul>

                                    <p className='text-white py-4'>El usuario es responsable de todo el contenido que publique o transmita a través de nuestra plataforma. No asumimos ninguna responsabilidad por el contenido publicado por los usuarios. No obstante, Chambaticon revisa regularmente el contenido subido a la plataforma para garantizar el cumplimiento de requisitos.</p>

                                    <p className='text-white py-4'>
                                        En caso de que el usuario detecte contenido que no cumpla con los requisitos antes mencionados, puede informar a Chambaticon enviando un correo electrónico a la dirección hello@ticongle.agency, especificando el nombre de la empresa, proyecto o talento que incumple con dichos requisitos.
                                    </p>

                                    <p className='text-white py-4'>Cuando Chambaticon verifique que se ha producido la inserción de contenido no apto o prohibido, procederá a su inmediata eliminación, reservándose la facultad de no admitir la inserción de más contenido por parte del talento o empresa responsable.</p>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">6. Restricciones de uso </h1>

                    <p className='py-4'> Hay ciertas conductas que están estrictamente prohibidas cuando se usa el sitio. Lea atentamente las siguientes restricciones. El incumplimiento de cualquiera de las disposiciones establecidas en estos términos puede resultar en la terminación del uso del sitio y/o contenido y también puede exponerlo a responsabilidad civil y/o penal. A menos que Chambaticon permita explícitamente lo contrario en virtud de estos Términos o por escrito, no puede:</p>

                    <ul>
                        <li className='py-2'>• Usar el sitio y/o el contenido para cualquier propósito ilegal, inmoral y/o no autorizado, incluyendo, entre otros, el acoso a otros usuarios, la violación de derechos de autor u otros derechos de propiedad intelectual, y la transmisión de contenido ofensivo o dañino.</li>
                        
                        <li className='py-2'>• Ingresar información falsa o inexacta a nuestra plataforma.</li>
                        
                        <li className='py-2'>• Usar el sitio y/o el contenido para fines no personales o comerciales. El usuario no deberá facilitar acceso a esta plataforma a personas que no califiquen para el uso del mismo ni podrán lucrar, revender o comercializar productos o servicios mediante Chambaticon.</li>
                        
                        <li className='py-2'>• Interferir o violar los derechos de los usuarios a la privacidad y otros derechos, o cosechar o recopilar información de identificación personal sobre los usuarios sin su consentimiento expreso, ya sea manualmente o con el uso de cualquier robot o cualquier aplicación de búsqueda o recuperación, o usar otro dispositivo, proceso o método manual o automático para acceder al sitio y recuperar, indexar y/o información de minas de datos; </li>


                        <li className='py-2'>• Interferir o interrumpir el funcionamiento del sitio o los servidores o redes que alojan el sitio, o desobedece cualquier ley, reglamento, requisito, procedimiento, o políticas de dichos servidores o redes; </li>
                       
                        <li className='py-2'>• Declarar falsamente o tergiversar su afiliación con cualquier persona o entidad, o expresa o implica que Chambaticon lo respalda a usted, a su sitio, su negocio o cualquier declaración que haga, o presente información falsa o inexacta sobre el sitio; </li>
                       
                        <li className='py-2'>• Tomar cualquier medida que imponga o pueda imponer, una carga irrazonable o desproporcionadamente grande en nuestra infraestructura de plataforma, según lo determinemos nosotros;</li>
                       
                        <li className='py-2'>• Omitir cualquier medida que podamos usar para prevenir o restringir el acceso al Sitio; </li>
                       
                        <li className='py-2'>• Copiar, distribuir, mostrar, ejecutar públicamente, ponga a disposición del público, reduzca a forma legible para humanos, descompile, desensamble, adapte, sublicencia, hacer cualquier uso comercial, vender, alquilar, transferir, prestar, procesar, compilar, realizar ingeniería inversa, combinar con otro software, traducir, modificar o crear trabajos derivados de cualquier material que esté sujeto a los derechos de propiedad de Chambaticon, incluida la Propiedad Intelectual de Chambaticon, de cualquier manera o por cualquier medio; </li>
                       
                        <li className='py-2'>• Hacer uso del contenido en cualquier otro sitio o entorno informático en red para cualquier propósito sin el consentimiento previo por escrito Chambaticon; </li>
                       
                        <li className='py-2'>• Vender, licenciar o explotar para cualquier propósito comercial cualquier uso o acceso al sitio y/o al contenido;</li>
                        
                        <li className='py-2'>• Crear una base de datos descargando y almacenando sistemáticamente todo o parte del contenido del sitio; </li>
                        
                        <li className='py-2'>•Transmitir o poner a disposición en relación con el sitio cualquier virus, gusano, caballo de troya, bomba de tiempo, error web, spyware o cualquier otro código, archivo o programa de computadora que pueda o esté destinado a dañar o secuestrar la operación de cualquier hardware, software, o equipos de telecomunicaciones, o cualquier otro código o componente realmente o potencialmente dañino, disruptivo o invasivo; </li>
                        
                        <li className='py-2'>• Usar el Sitio para cualquier propósito para el cual el Sitio no esté destinado; y/o </li>
                        
                        <li className='py-2'>• Infringir y/o viola cualquiera de los Términos.</li>












                    </ul>

                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">7. Privacidad y Confidencialidad </h1>
                    <p className='py-4'>Respetamos su privacidad y nos comprometemos a proteger la información que comparte con nosotros. Creemos que tiene derecho a conocer nuestras prácticas con respecto a la información que recopilamos cuando se conecta, accede o usa el sitio web y/o solicita y participa en Chambaticon. Nuestras políticas, prácticas y el tipo de información recopilada a través del sitio se describen en detalle en nuestra Política de privacidad disponible en https://chambaticon.sv/politica-de-privacidad, que se incorpora aquí por referencia. Usted acepta que Chambaticon puede usar la información que usted proporciona o pone a su disposición de acuerdo con la Política de Privacidad. Si tiene la intención de acceder o usar el Sitio y/o solicitar la participación en Chambaticon, primero debe leer y aceptar la Política de privacidad.</p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">8. Propiedad Intelectual </h1>

                    <p className='py-4'>Todo el contenido de esta plataforma, incluyendo pero no limitado a marcas, señales de publicidad, texto, gráficos, logotipos, imágenes, videos, software y otros materiales, estén o no registrados o puedan registrarse, pertenece a <span className='text-orange-500'>ASOCIACIÓN CONEXION AL DESARROLLO DE EL SALVADOR</span> o a sus licenciantes y están protegidos por leyes vigentes en El Salvador y los convenios internacionales en materia de propiedad intelectual. Se prohíbe copiar, modificar, distribuir, vender o arrendar alguna de las partes de la plataforma. Tampoco pueden modificar, alterar, manipular, ni realizar ingeniería inversa sobre la plataforma propiedad de <span className='text-orange-500'>ASOCIACIÓN CONEXION AL DESARROLLO DE EL SALVADOR</span> o extraer el código fuente.</p>

                    <p className='py-4' >Todos los comentarios o sugerencias suministradas por el usuario serán propiedad de Chambaticon, quien tendrá una licencia exclusiva, libre de regalías, totalmente pagada, mundial, perpetua e irrevocable para incorporar los comentarios o sugerencias en cualquiera de los servicios y/o actividades actuales o futuros de Chambaticon y utilizarlo para cualquier propósito, todo ello sin compensación adicional para usted y sin su aprobación. Usted acepta que todos los comentarios o sugerencias se considerarán no confidenciales. Además, usted garantiza que los comentarios o sugerencias no están sujetos a ningún término de licencia que pretenda exigir a Chambaticon el cumplimiento de ninguna obligación adicional con respecto a cualquiera de los productos, tecnologías o servicios actuales o futuros de la Chambaticon que incorporen cualquier comentario o sugerencia.</p>

                    <p className='py4'>
                    Si usted considera que alguno de los materiales proporcionados en el Sitio de Chambaticon o en relación con él violan sus derechos de autor u otros derechos de propiedad intelectual, envíe la información al siguiente correo electrónico <span className='text-orange-500'>hello@ticongle.agency</span>. Identificando el derecho de propiedad intelectual que presuntamente se ha violado. Deben incluirse todos los números de registro pertinentes, o una declaración sobre la titularidad de la obra. específicamente la ubicación del material que presuntamente viola sus derechos, con detalles suficientes para que Chambaticon pueda encontrarlo en el Sitio de Chambaticon. 

                    </p>


                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">9. Notificaciones y Comunicaciones</h1>
                    <p className='py-4'>Chambaticon puede enviarle notificaciones, anuncios u otras comunicaciones relacionadas con la plataforma a través de correo electrónico u otros medios. Usted acepta recibir dichas comunicaciones y reconoce que estas notificaciones constituyen un servicio legalmente vinculante.</p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">10.  Enlaces a Terceros</h1>
                    <p className='py-4'>La plataforma puede contener enlaces a sitios web de terceros que no están bajo el control de Chambaticon. No nos hacemos responsables del contenido, precisión, seguridad o prácticas de privacidad de estos sitios web. La inclusión de cualquier enlace a un sitio web de terceros no implica necesariamente una recomendación o respaldo por parte de Chambaticon. El uso de la información obtenida a través de estos sitios web externos es bajo el propio riesgo del usuario.</p>
                    <p>Debido a que no controlamos dicho contenido de terceros, usted como usuario entiende y acepta que Chambaticon no es responsable ni respalda dicho contenido de terceros; no garantizamos la exactitud, actualidad, idoneidad, fiabilidad o calidad de la información contenida en dicho contenido; y no asumimos ninguna responsabilidad por el contenido de terceros que resulte censurable, inexacto, engañoso o ilegal o que incumpla las presentes normas de uso puesto a disposición por usuarios, anunciantes y terceros.</p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">11.  Limitación de Responsabilidad</h1>
                    <p className='py-4'>El usuario acepta que <span className='text-orange-500' >ASOCIACIÓN CONEXION AL DESARROLLO DE EL SALVADOR</span> no está obligado a indemnizar ningún daño o perjuicio del usuario, cualquiera sea su naturaleza (consecuente, indirecto, punitivo, especial o fortuito, previsto o imprevisto) derivados de la utilización o de la imposibilidad de utilizar esta plataforma, de la utilización del usuario de la información y/o resultados obtenidos a través de la plataforma o las decisiones tomadas por el usuario con base en dicha información.</p>
                </div>
            </div>
           
            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">12. Modificación de Términos y Condiciones de Uso</h1>
                    
                    <p className='py-4'>Nos reservamos el derecho de modificar, actualizar o cambiar estos términos y condiciones de uso en cualquier momento y a nuestra sola discreción. Las modificaciones entrarán en vigencia inmediatamente después de su publicación en la plataforma. Se le notificará sobre cualquier cambio significativo mediante un aviso destacado en nuestra la plataforma o por otros medios que consideremos adecuados.</p>
                    
                    <p className='py-4'>Es su responsabilidad revisar periódicamente estos términos y condiciones para estar al tanto de las actualizaciones. El uso continuado de la plataforma después de la publicación de cualquier modificación constituirá su aceptación de dichas modificaciones.</p>

                    <p className='py-4'>Si no está de acuerdo con alguna modificación de estos términos y condiciones, le recomendamos que deje de utilizar nuestra plataforma. Sin embargo, si continúa utilizando la plataforma después de la publicación de las modificaciones, se entenderá que ha aceptado los términos y condiciones modificados.</p>
                </div>
            </div>

            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">13. Ley aplicable</h1>  
                    <p className='py-4'>Los presentes Términos y Condiciones se rigen y se interpretan de acuerdo con las leyes vigentes en la República El Salvador. El usuario y <span className='text-orange-500'>ASOCIACIÓN CONEXION AL DESARROLLO DE EL SALVADOR</span> acuerdan expresamente en someterse a la jurisdicción de los tribunales de la ciudad de San Salvador, República de El Salvador, renunciando a cualquier otro fuero o jurisdicción que pudiera corresponder.</p>
                </div>
            </div>
            <div className="w-full bg-cyan-900 p-5">
                <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                    <h1 className="text-orange-500 text-xl">14. Reclamos y Aclaraciones</h1>  
                    <p className='py-4'>Para presentar reclamaciones o solicitar aclaraciones, el usuario puede ponerse en contacto con nosotros a través de los siguientes medios:</p>
                    <ul className='py-4'>
                        <li>• Correo electrónico: hello@ticongle.agency</li>
                        <li>• Número de teléfono: 2249-2301</li>
                        <li>• Dirección: Calle Reforma, Local 251, Colonia San Benito, en la ciudad de San Salvador, República de El Salvador</li>
                    </ul>
                    <p className='text-white'>Estamos disponibles para atender sus consultas y resolver cualquier inquietud que pueda surgir en relación con nuestros servicios. Nos comprometemos a responder de manera oportuna y proporcionarle la asistencia necesaria para resolver cualquier problema que pueda surgir.</p>
                </div>
            </div>

        </div>
    )
}

export default TerminosYCondiciones;