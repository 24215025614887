import { ChangeEvent, useState } from "react";
import imagenesLogin from "../assets/imgLogin/imagenesLogin";
import FormLogin from "../components/Login/formLogin";
import { ValidateEmail } from "../function/Login";
import { Contact } from "../Interfaces/Contact";
import { alertLogin } from "../Interfaces/Login";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import IconLoading from "../components/iconLoading/IconLoading";


// types
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>

// Initial state
const initialStateContact = {
  name:"",
  email:"",
  phone:"",
  message:""
}

const initialStateInput = {
  input: "",
  message: '',
  state: false
}


const Contactanos = () => {


  const [ContactForm, setContactForm] = useState<Contact>(initialStateContact);

  const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

  const [sendingData, setSendingData] = useState(false);


  const MySwal = withReactContent(Swal);

  const handleFormContact = ({ target: { name, value, id } }: handleInputChange) => {
    setContactForm({...ContactForm, [name]:value});
  };


      // event form
  const FormContact  = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    let verifyValues = [
        { name: "name", value: ContactForm.name },
        { name: "gmail", value: ContactForm.email },
        { name: "tel", value: ContactForm.phone },
        { name: "description", value: ContactForm.message }
    ];

    const validateInputs = ValidateEmail(verifyValues);
    const state = validateInputs.filter(input => input.state === false).map((s) => { return false });
    setAlert(validateInputs);
    
    if (state.length >= 4) sendData();
          
    };

    function sendData() {

      setSendingData(true);

      const url = `${process.env.REACT_APP_API_URI}contact`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      };

        axios.post(url, ContactForm, config)
         .then(response => {
  
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: response.data.message,
               showConfirmButton: false,
               timer: 3500
            });

            setSendingData(false);

            setContactForm(initialStateContact);

         })
         .catch(err => {
          // console.log('err.response.data.message', err.response);
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 3500
            });
            setSendingData(false);
         })


   };

  return (
    <>
      <section className="text-gray-600 body-font relative bg-Light-purple">
      {/* <h1 className="text-4xl text-white font-bold text-center">Contáctanos</h1> */}
      <h1 className="text-Yellow text-center font-bold text-2xl sm:text-3xl">Contáctanos</h1>
        <div className="container px-5 pt-10 pb-10 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-2/3 md:w-1/2 bg-turquoise rounded-3xl overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe width="100%" height="100%" className="absolute inset-0" title="map" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15505.68168533808!2d-89.236251!3d13.6929681!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x34a1598d53f97c31!2sAsociaci%C3%B3n%20Conexi%C3%B3n%20al%20Desarrollo%20de%20El%20Salvador!5e0!3m2!1ses!2ssv!4v1661923186085!5m2!1ses!2ssv" style={{ "filter": "grayscale(1) contrast(1.2) opacity(0.4)" }}></iframe>
            <div className="bg-Light-purple relative flex flex-wrap py-6 rounded-lg shadow-md w-full">
              <div className="lg:w-3/5 px-6">
                <h2 className="title-font text-white tracking-widest">Dirección:</h2>
                <p className="text-Yellow">Calle La Reforma 249, San Salvador</p>
                <h2 className="title-font text-white tracking-widest mt-1">Correo Electrónico:</h2>
                <p className=" text-Yellow">info@conexion.sv</p>
                <h2 className="title-font text-white tracking-widest mt-1">Teléfono:</h2>
                <p className="text-Yellow">+503 2227-5172</p>
              </div>
              <div className="lg:w-2/5 px-6 mt-4 lg:mt-0">
                <h2 className="title-font text-white tracking-widest">Horario:</h2>
                <p className="mt-1 text-Yellow">
                  Lunes - Viernes: <br /> 8:00 am - 5:00 pm <br />
                  Sábados: <br />8:00 am - 12:00 pm <br />
                  Domingos: <br />
                  Cerrado
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/3 md:w-1/2 bg-turquoise flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 p-5 rounded-lg">
       
            <form onSubmit={FormContact}>
                <div className="relative mb-4">
                  <label className="leading-7 text-sm text-white">Nombre completo</label>
                  <input 
                    className="w-full bg-color-input-login form-control block px-4 py-2 mb-1 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" 
                    type="text"
                    id="name"
                    name="name"
                    value={ContactForm.name}
                    onChange={handleFormContact}
                    />
                    {alert.filter(input => input.input == "name" && input.state === true).map(message => (
                        <span className="text-red-500 text-start">{message.message}</span>
                    ))}
                </div>

                <div className="relative mb-4">
                  <label className="leading-7 text-sm text-white">Correo electrónico</label>
                  <input 
                    className="w-full bg-color-input-login form-control block px-4 py-2 mb-1 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                    type="email"
                    id="email" 
                    name="email"
                    value={ContactForm.email}
                    onChange={handleFormContact}
                    />
                    {alert.filter(input => input.input == "gmail" && input.state === true).map(message => (
                        <span className="text-red-500 text-start">{message.message}</span>
                    ))}
                </div>

                <div className="relative mb-4">
                  <label className="leading-7 text-sm text-white">Ingresa tu número de contacto (Móvil o fijo)</label>
                  <input 
                    className="w-full bg-color-input-login form-control block px-4 py-2 mb-1 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                    type="text" 
                    id="phone"
                    name="phone"
                    value={ContactForm.phone}
                    onChange={handleFormContact}
                    />
                    {alert.filter(input => input.input == "tel" && input.state === true).map(message => (
                        <span className="text-red-500 text-start">{message.message}</span>
                    ))}
                </div>

                <div className="relative mb-4">
                  <label className="leading-7 text-sm text-white">Ingresa tu comentario</label>
                  <textarea 
                    placeholder="Tu mensaje..."
                    className="w-full bg-color-input-login form-control block px-4 py-2 mb-1 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                    id="message" 
                    name="message"
                    value={ContactForm.message}
                    onChange={handleFormContact} 
                     ></textarea>
                     {alert.filter(input => input.input == "description" && input.state === true).map(message => (
                        <span className="text-red-500 text-start">{message.message}</span>
                    ))}
                </div>

                <button className="text-white bg-Yellow hover:bg-yellow-500 border-0 py-2 px-6 focus:outline-none rounded-lg text-lg w-full">
                
                  {!sendingData && 
                      <>
                        Enviar
                      </>
                  }

                  {sendingData && 
                      <IconLoading />
                  }

                </button>
            </form>
          
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactanos;