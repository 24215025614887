import { url } from "inspector";
import imgPerfilFreelancer from "../assets/imgPerfilFreelancer/imgPerfilFreelancer";
import imgDefault from '../assets/imgEditPerfil/user.png';

import { faUsers, faEnvelope, faPhone, faLocationDot, faLanguage, faPenToSquare, faChartLine, faBars, faCrow, faCrown, faGears, faChevronDown, faCreditCard, faFileLines, faCheckToSlot, faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebookSquare, faInstagram, faInstagramSquare, faTwitterSquare, faWhatsapp, faWhatsappSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { UpdatedInfoCompany } from "../Interfaces/Company";

import { useEffect, useState } from 'react';
import axios from "axios";
import Cookies from "universal-cookie";

import CompanySkeleton from "../components/Skeleton/ProfileCompany/companySkeleton";
import { isSet } from "util/types";

// import StarRatingComponent from "react-star-rating-component"
// import StarRatingComponent from "@types/react-star-rating-component";
// import {StarRating} from 'star-rating-react-ts';
import * as React from "react";


// inicialStateCompany
const initialStateUpdatedInfoCompany = {
  company_name: "",
  company_contact_name: "",
  gmail: "",
  states_id: "",
  country_id: "",
  company_description: "",
  phone: "",
  secondary_phone: "",
  activity_id: "",
  direction: "",
  photo: "",
  Facebook_link: "",
  Instagram_link: "",
  Twitter_link: "",
  Linkedin_link: "",
  id: "",
  email: "",
  plan: "",
  planes_id: "",
  complete_profile_status: 0,
  latitude: 13.692972702987948,
  longitude: -89.23626383096777
}

const interactionCallback = (next: number, prev: number, name: string) => ({
  next,
  prev,
  name
});

// const missingRequired = <StarRatingComponent />;



const CreacionProfileFreelancer = () => {

  //console.log('Url Chambaticon', process.env.REACT_APP_API_URI);

  //console.log('Url img', process.env.REACT_APP_PHOTO_URI);

  const [InfoCompany, setInfoCompany] = useState<UpdatedInfoCompany>(initialStateUpdatedInfoCompany);

  const [IsLoading, setIsLoading] = useState(true);

  const [ProfileStatus, setProfileStatus] = useState(0);

  const [dropdownOpen, setdropdownOpen] = useState(false);

  const cookies = new Cookies();
  // console.log('newInfoCompany', newInfoCompany);

  useEffect(() => {
    async function GetInfoCompany() {

      const idUser = cookies.get('Bearer');
      // console.log('getSesion',idUser.id);

      const urlInfoProfile = `${process.env.REACT_APP_API_URI}company/profile/${idUser.id}`;

      //console.log(urlInfoProfile)

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${idUser.token}`
        }
      };
      try {

        const resp = await axios.get(urlInfoProfile, config);

        // console.log("resp", resp);

        let imgCompany = "";

        if (resp.data.photo != null) {
          imgCompany = `${process.env.REACT_APP_PHOTO_URI}company-image/${resp.data.photo}`;
        } else {
          imgCompany = imgDefault;
        }

        let listInfo;

        if (typeof resp.data.activity !== 'undefined') {
          listInfo = {
            company_name: resp.data.company_name,
            company_contact_name: "",
            gmail: "",
            states_id: resp.data.status,
            country_id: resp.data.country_id,
            company_description: resp.data.company_description,
            phone: resp.data.phone,
            secondary_phone: resp.data.secondary_phone,
            activity_id: resp.data.activity.name,
            direction: resp.data.direction,
            photo: imgCompany,
            Facebook_link: resp.data.Facebook_link,
            Instagram_link: resp.data.Instagram_link,
            Twitter_link: resp.data.Twitter_link,
            Linkedin_link: resp.data.Linkedin_link,
            id: resp.data.user_id,
            email: resp.data.email,
            plan: resp.data.plan,
            planes_id: resp.data.planes_id,
            complete_profile_status: resp.data.complete_profile_status,
            latitude: resp.data.latitude,
            longitude: resp.data.longitude
          };
        } else {
          listInfo = {
            company_name: resp.data.company_name,
            gmail: "",
            company_contact_name: "",
            states_id: resp.data.status,
            country_id: resp.data.country_id,
            company_description: resp.data.company_description,
            phone: resp.data.phone,
            secondary_phone: resp.data.secondary_phone,
            activity_id: "",
            direction: resp.data.direction,
            photo: imgCompany,
            Facebook_link: resp.data.Facebook_link,
            Instagram_link: resp.data.Instagram_link,
            Twitter_link: resp.data.Twitter_link,
            Linkedin_link: resp.data.Linkedin_link,
            id: resp.data.user_id,
            email: resp.data.email,
            plan: resp.data.plan,
            planes_id: resp.data.planes_id,
            complete_profile_status: resp.data.complete_profile_status,
            latitude: resp.data.latitude,
            longitude: resp.data.longitude,
          };

          //  setProfileStatus(resp.data.complete_profile_status);
        }

        setInfoCompany(listInfo);
        setIsLoading(false);



      } catch (err) {
        //  console.log("err", err);
        // Handle Error Here
        // console.error(err);
      }
    };
    GetInfoCompany();
  }, []);
  return (
    <>

      {IsLoading && <CompanySkeleton />}

      {!IsLoading &&
        <div className="flex flex-wrap">
          {/* <div className="w-10% hidden md:flex">
            <img src={imgPerfilFreelancer.fondo_login_izquierdo_sin_icono2} className="w-full" alt="" />
          </div> */}
          <div className="w-full md:w-full bg-turquoise p-5">
            <div className="border border-Light-Blue p-5 rounded-lg">
              <div className="flex flex-wrap justify-center sm:justify-start">

                <div className="text-white absolute right-10 flex  w-[300px]">

                  <div className="flex w-full">

                    <div className="text-white">

                    {/* {(InfoCompany.complete_profile_status == 3) && <span>Completo</span>}

                    {(InfoCompany.complete_profile_status == 2) && <span>Incompleto</span>}

                    {(InfoCompany.complete_profile_status == 1) && <span>En validación</span>} */}

                    {(InfoCompany.complete_profile_status == 3 || InfoCompany.complete_profile_status == 2 || InfoCompany.complete_profile_status == 1) &&
                        
                        <Link className="" to="/editar-perfil-empresa" >
                          <button type="button" className="mr-4 text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 ">
                            Editar perfil
                          </button>
                        </Link>

                    }  
                    </div>

                    {(InfoCompany.complete_profile_status == 3) &&
                      <>
                        <div className="text-white">
                          <button
                            className="mr-4 text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40"
                            id="dropdownDefaultButton" onClick={() => setdropdownOpen(!dropdownOpen)} data-dropdown-toggle="dropdown"
                          >
                            Ver más
                            <FontAwesomeIcon className="text-white px-1" icon={faChevronDown} />
                          </button>
                        </div>

                        <div id="dropdown" className={`${dropdownOpen ? `opacity-100 visible` : 'invisible opacity-0'} z-10 divide-y divide-gray-100 rounded-lg shadow w-44 bg-orange-500 absolute top-12 right-6`}>
                          <ul className="py-2 text-sm text-white" aria-labelledby="dropdownDefaultButton">
                            <li>
                              <Link to={`/perfil-pago`} className="px-4 py-2 hover:bg-orange-400 flex items-center">
                                <FontAwesomeIcon className="text-white mr-4" icon={faCreditCard} /> Perfil de pago
                              </Link>
                            </li>
                            <li>
                              <Link to={`/historialFacturacion`} className="px-4 py-2 hover:bg-orange-400 flex items-center">
                                <FontAwesomeIcon className="text-white mr-4" icon={faClockRotateLeft} /> Historial de pago
                              </Link>
                            </li>
                            <li>
                              <Link to={`/DetallesSuscripcion`} className="px-4 py-2 hover:bg-orange-400 flex items-center">
                                <FontAwesomeIcon className="text-white mr-4" icon={faCheckToSlot} /> Detalles de suscripción
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>


                    }

                  </div>

                </div>

                {/* <Link className="absolute right-0" to="/editar-perfil-empresa" >
                <button type="button" className="mr-4 absolute top-0 right-10 text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 me-2 mb-2">
              
                  <FontAwesomeIcon
                    className="text-white text-xl px-1"
                    icon={faPenToSquare}
                  /> 
                  </button>
                </Link>

                {(InfoCompany.complete_profile_status == 3) &&
                
                  <>
                  <button id="dropdownDefaultButton" onClick={() => setdropdownOpen(!dropdownOpen)} data-dropdown-toggle="dropdown" className="absolute right-10 top-40" type="button">
                    <FontAwesomeIcon className="text-white text-2xl mx-2 ml-4 sm:ml-0 top-0 right-0 absolute" icon={faChevronDown} /></button>

                  <div id="dropdown" className={`${dropdownOpen ? `opacity-100 visible` : 'invisible opacity-0'} z-10 divide-y divide-gray-100 rounded-lg shadow w-44 bg-orange-500 absolute top-48 right-10`}>
                    <ul className="py-2 text-sm text-white" aria-labelledby="dropdownDefaultButton">
                      <li>
                        <Link to={`/perfil-pago`} className="px-4 py-2 hover:bg-orange-400 flex items-center">
                          <FontAwesomeIcon className="text-white mr-4" icon={faCreditCard} /> Perfil de pago
                        </Link>
                      </li>
                      <li>
                        <Link to={`/historialFacturacion`} className="px-4 py-2 hover:bg-orange-400 flex items-center">
                          <FontAwesomeIcon className="text-white mr-4" icon={faClockRotateLeft} /> Historial de pago
                        </Link>
                      </li>
                      <li>
                        <Link to={`/DetallesSuscripcion`} className="px-4 py-2 hover:bg-orange-400 flex items-center">
                          <FontAwesomeIcon className="text-white mr-4" icon={faCheckToSlot} /> Detalles de suscripción
                        </Link>
                      </li>
                    </ul>
                  </div>
                  </>
                } */}


                <div className="w-full lg:w-1/4 flex items-center justify-center">
                  <div>
                    <div
                      className="w-full lg:w-3/12 lg:order-2 flex"
                    >
                      <div className="relative">
                        <img
                          alt=""
                          src={InfoCompany.photo}
                          className="rounded-full relative w-48 h-48 align-middle object-cover border-8 border-orange-500"
                          style={{ "maxWidth": "198px" }}
                        />
                        <div className="mt-3 text-end">

                          {(InfoCompany.Facebook_link != null &&
                            <a href={InfoCompany.Facebook_link} target="_black">
                              <FontAwesomeIcon className="text-white transition-colors hover:text-Yellow mr-4 ml-4 sm:ml-0" size="2x" icon={faFacebookSquare} />
                            </a>
                          )}

                          {(InfoCompany.Twitter_link != null &&
                            <a href={InfoCompany.Twitter_link} target="_black">
                              <FontAwesomeIcon className="text-white transition-colors hover:text-Yellow mx-2" size="2x" icon={faTwitterSquare} />
                            </a>
                          )}

                          {(InfoCompany.Instagram_link != null &&
                            <a href={InfoCompany.Instagram_link} target="_black">
                              <FontAwesomeIcon className="text-white transition-colors hover:text-Yellow mx-2" size="2x" icon={faInstagramSquare} />
                            </a>
                          )}

                          {(InfoCompany.Linkedin_link != null &&
                            <a href={InfoCompany.Linkedin_link} target="_black">
                              <FontAwesomeIcon className="text-white transition-colors hover:text-Yellow mx-2" size="2x" icon={faLinkedin} />
                            </a>
                          )}
                          {/* <a href="#"><FontAwesomeIcon className="text-white text-2xl mx-2" icon={faWhatsapp} /></a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-3/4">
                  <div className="text-center lg:text-start lg:ml-5">
                    <h1 className="text-white font-bold text-2xl text-center lg:text-start mb-5 lg:mb-0">{InfoCompany.company_name}</h1>
                    <h3 className="text-orange-500 text-sm">Estado del perfil</h3>
                    <h3 className="text-white mb-2 text-sm">

                      {(InfoCompany.complete_profile_status == 3) && <span>Completo</span>}

                      {(InfoCompany.complete_profile_status == 2) && <span>Incompleto</span>}

                      {(InfoCompany.complete_profile_status == 1) && <span>En validación</span>}
                    </h3>

                    {
                      (InfoCompany.complete_profile_status != 2) &&
                      <>
                        <h3 className="text-orange-500 text-sm">Descripción</h3>
                        <p className="text-justify text-white mb-5">{InfoCompany.company_description} </p>
                      </>
                    }

                    <div className="flex flex-wrap h-30 md:h-14 justify-around lg:justify-start w-full">

                      {(InfoCompany.complete_profile_status == 3) &&
                        <>

                          <Link to={"/proyectos-empresa"} className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-center justify-center m-1 text-white bg-orange-500 p-3 md:mr-4 rounded-full text-sm">
                            Ver mis oportunidades
                          </Link>

                          <Link to={"/publicar-proyecto"} className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-center justify-center m-1 text-white bg-blue-500 p-3 md:mr-4 rounded-full text-sm" >
                            Crear oportunidad
                          </Link>

                          <Link to={"/Planes"} className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-center justify-center m-1 text-white bg-Dark-purple p-3 rounded-full" >
                            <FontAwesomeIcon className="text-white mr-4" icon={faCrown} /> Planes
                          </Link>

                        </>
                      }

                      {(InfoCompany.complete_profile_status == 2) &&
                        <Link to={"/editar-perfil-empresa"} className="w-full md:w-5/12 lg:w-1/2 xl:w-1/3 flex items-center justify-center m-1 text-white bg-orange-500 p-3 md:mr-4 rounded-full">
                          Debes de completar el perfil
                        </Link>
                      }

                      {(InfoCompany.complete_profile_status == 1) &&
                        <button className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 flex items-center justify-center m-1 text-white bg-orange-500 p-3 md:mr-4 rounded-full cursor-auto">
                          En Proceso de Validación
                        </button>
                      }

                      {(InfoCompany.complete_profile_status == 4) &&
                        <button className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 flex items-center justify-center m-1 text-white bg-orange-500 p-3 md:mr-4 rounded-full cursor-auto">
                          Perfil rechazado
                        </button>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* Hola
            <StarRating initialRating={5} readOnly={true}/> */}
            </div>
            <div className="border border-Light-Blue rounded-lg mt-3">
              <div className="flex flex-wrap m-4">
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                  <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                    <div className="flex justify-center items-center w-1/3">
                      <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faBars} />
                    </div>
                    <div className="text-start text-white font-semibold w-full">
                      <h2>Estado actual</h2>

                      {(InfoCompany.complete_profile_status == 3) && <span className="text-orange-500">Completo</span>}

                      {(InfoCompany.complete_profile_status == 2) && <span className="text-orange-500">Incompleto</span>}

                      {(InfoCompany.complete_profile_status == 1) && <span className="text-orange-500">En validación</span>}

                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                  <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                    <div className="flex justify-center items-center w-1/3">
                      <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faChartLine} />
                    </div>
                    <div className="text-start text-white font-semibold w-full">
                      <h2>Actividad económica</h2>
                      <h2 className="text-orange-500">{InfoCompany.activity_id}</h2>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                  <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                    <div className="flex justify-center items-center w-1/3">
                      <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faEnvelope} />
                    </div>
                    <div className="text-start text-white font-semibold w-full overflow-hidden">
                      <h2>Email</h2>
                      <h2 className="text-orange-500">{InfoCompany.email}</h2>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                  <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                    <div className="flex justify-center items-center w-1/3">
                      <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faPhone} />
                    </div>
                    <div className="text-start text-white font-semibold w-full">
                      <h2>Teléfono principal</h2>
                      <h2 className="text-orange-500">{InfoCompany.phone}</h2>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                  <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                    <div className="flex justify-center items-center w-1/3">
                      <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faPhone} />
                    </div>
                    <div className="text-start text-white font-semibold w-full">
                      <h2>Teléfono secundario</h2>
                      <h2 className="text-orange-500">{InfoCompany.secondary_phone}</h2>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                  <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                    <div className="flex justify-center items-center w-1/3">
                      <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLocationDot} />
                    </div>
                    <div className="text-start text-white font-semibold w-full">
                      <h2>Dirección</h2>
                      <h2 className="text-orange-500">{InfoCompany.direction}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default CreacionProfileFreelancer;