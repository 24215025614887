
import imgPerfilFreelancer from "../../../assets/imgPerfilFreelancer/imgPerfilFreelancer";
import { faEnvelope, faPhone, faLocationDot, faChartLine, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';





const SkeletonApplyPropuse = () => {

    return (
        <>
            <div className="w-full md:w-90% flex justify-center">
                <form className="w-full">
                    <div className="text-white font-bold text-xl sm:text-3xl my-4">
                        <Skeleton count={1} width={550} height={40} />
                    </div>
                    <div className="">
                        <h1 className="text-white font-bold text-xl sm:text-3xl my-4">
                            <Skeleton count={1} width={550} height={40} />
                        </h1>
                        <p className="text-white font-bold text-xl sm:text-3xl my-4 text-Yellow">
                            <Skeleton count={1} width={550} height={40} />
                        </p>
                    </div>
                    <div className="">
                        <div className="mb-6 text-start">
                            <Skeleton count={1} width={550} height={40} />
                        </div>
                    </div>
                    <div className="">
                        <div className="mb-6 text-start btn-editpro w-full">
                            <Skeleton count={1} width={550} height={40} />
                        </div>
                    </div>
                    <div className="">
                        <div className="mb-6 text-start">
                            <Skeleton count={1} width={550} height={40} />
                        </div>
                    </div>
                    <div className="columns-1 py-5 flex justify-start items-center">
                        <Skeleton count={1} width={150} height={40} />
                    </div>
                </form>
            </div>

        </>
    );
}

export default SkeletonApplyPropuse;