
import imgPerfilFreelancer from "../../../assets/imgPerfilFreelancer/imgPerfilFreelancer";

import { faUsers, faEnvelope, faPhone, faLocationDot, faLanguage, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProfileFreelancerSkeleton = () => {


  return (
    <>
      <div className="flex">
       
        <div className="w-full lg:w-12/12 bg-turquoise">
          <main className="profile-page">
            <section className="block relative">
              {/* {showInfo.imagen2} */}
              <Skeleton height={275} count={1} />
            </section>
            <section className="py-16 bg-turquoise">
              <div>
                <div
                  className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-xl -mt-16 bg-turquoise"
                >
                  <div className="px-6">
                    <div className="flex flex-wrap -mt-36 justify-center sm:justify-start">
                      <div>
                        <div
                          className="w-full lg:w-3/12 lg:order-2 flex"
                        >
                          <div className="relative">
                           <Skeleton circle width={195} height={195} count={1} /> 
                          </div>
                        </div>
                      </div>
                      <div className="text-center sm:text-start ml-5">
                        <h1 className="text-white font-bold text-2xl"><Skeleton width={280}  count={1} /> </h1>
                        <h3 className="text-white mb-2"><Skeleton width={220}  count={1} /></h3>
                           <Skeleton width={230}  count={1} />
                           <div className="mt-3 text-end flex">
                              <span className="pr-1">
                                 <Skeleton circle width={40} height={40} count={1} />
                              </span>
                              <span className="pr-1">
                                 <Skeleton circle width={40} height={40} count={1} />
                              </span>
                              <span className="pr-1">
                                 <Skeleton circle width={40} height={40} count={1} />
                              </span>
                              <span className="pr-1">
                                 <Skeleton circle width={40} height={40} count={1} />
                              </span>
                           </div>
                      </div>
                    </div>
                    <div className="block lg:flex">
                      <div className="lg:w-2/3 w-full">
                        <h3 className="text-start text-orange-500 font-semibold mt-14 mb-6"><Skeleton width={100} count={1} /></h3>
                        
                        <p className="text-justify font-semibold text-white text-sm"><Skeleton count={4} /></p>
                        <div className="columns-1 sm:columns-2 md:columns-2 mt-10">
                          <div className="p-4 flex bg-Light-purple rounded-md">
                            <div className="flex justify-center items-center w-1/3">
                              <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faUsers} />
                            </div>
                            <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                              <h2><Skeleton count={2} /></h2>
                              {/* <h2>{showInfo.dateOfBirth} años</h2> */}
                            </div>
                          </div>
                          <div className="p-4 flex bg-Light-purple rounded-md mt-5 md:mt-0">
                            <div className="flex justify-center items-center w-1/3">
                              <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faEnvelope} />
                            </div>
                            <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                             <h2><Skeleton count={2} /></h2>

                            </div>
                          </div>
                        </div>
                        <div className="columns-1 sm:columns-2 md:columns-2 mt-5">
                          <div className="p-4 flex bg-Light-purple rounded-md">
                            <div className="flex justify-center items-center w-1/3">
                              <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faPhone} />
                            </div>
                            <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                               <h2><Skeleton count={2} /></h2>

                            </div>
                          </div>
                          <div className="p-4 flex bg-Light-purple rounded-md mt-5 md:mt-0">
                            <div className="flex justify-center items-center w-1/3">
                              <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faPhone} />
                            </div>
                            <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                               <h2><Skeleton count={2} /></h2>

                            </div>
                          </div>
                        </div>
                        <div className="columns-1 sm:columns-2 md:columns-2 mt-5">
                          <div className="p-4 flex bg-Light-purple rounded-md">
                            <div className="flex justify-center items-center w-1/3">
                              <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLocationDot} />
                            </div>
                            <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                              <h2><Skeleton count={2} /></h2>

                            </div>
                          </div>
                          <div className="p-4 flex bg-Light-purple rounded-md mt-5 md:mt-0">
                            <div className="flex justify-center items-center w-1/3">
                              <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLanguage} />
                            </div>
                            <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                               <h2><Skeleton count={2} /></h2>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="lg:w-1/3 w-full p-5 block">
                        <div className="columns-1 sm:columns-1 lg:columns-1">
                          <div className="mt-16">
                              <Skeleton height={80} count={1} />
                          </div>
                          <div className="mt-3">
                              <Skeleton height={80} count={1} />
                          </div>
                        </div>
                        <div className="columns-1 sm:columns-1 lg:columns-1">
                          <div className="mt-3">
                              <Skeleton height={80} count={1} />
                          </div>
                          <div className="mt-3">
                              <Skeleton height={80} count={1} />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                                        
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}

export default ProfileFreelancerSkeleton;