
import React, { FC, HTMLAttributes, InputHTMLAttributes, useEffect, useState } from 'react';



interface Props {}

export const InputTestNumer: FC<Props & InputHTMLAttributes<HTMLInputElement>> = ({ value, onChange,...props }) => {

  const [localValue, setLocalValue] = useState(String(value));

  useEffect(() => {
    const isSame = Number(value) === Number(localValue);
    const isLastDot = localValue.slice(-1) === '.';
    const isLastDotZero = localValue.slice(-2) === '.0';

    if (!isSame || (!isSame && !isLastDot && !isLastDotZero)) {
      setLocalValue(String(value));
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return;
    }

    let nextValue = e.target.value
      .replace(',', '.')
      .replace(/[^\d.,]/g, '')
      .replace(/^0(\d+)/g, '$1')
      .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');

    if (nextValue === '.') {
      nextValue = '0.';
    }

    // some hack in ultimate hack component
    e.target.value = nextValue;

    setLocalValue(e.target.value);
    onChange(e);
  };

  return (
    <input
      {...props}
      value={localValue}
      onChange={handleChange}
    />
  );
};

export default InputTestNumer;