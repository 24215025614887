import { Link } from "react-router-dom";



import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

import { ProjectInfo } from "../../../Interfaces/InfoProyect";

import Modal from "../../modals/ModalProyectDetail";

import {StarRating} from 'star-rating-react-ts';
import SkeletonStar from '../../Skeleton/ProfileFreelancer/starsQualification';

interface Props {
   animation: string;
   state: String;
   idFreeRouter: String | undefined;
};



const initialStateProjectChamabticon = {
   description: "",
   end_date: "",
   id: "",
   knowledges_tags: "",
   project_name: "",
   company_id: "",
   completed: "",
   newMessages: "",
   rating: ""
};

const initialState = {
   assigned_status: 0,
   company_project_file:"",
   accept_proposals_files:0,
   company_name: "",
   photo:"",
   budget: "",
   created_at: "",
   delivery_date: "",
   description: "",
   end_date: "",
   file_folder_path: "",
   freelance_id: 0,
   id: 0,
   knowledges_tags:[],
   project_name: "",
   public_info: 0,
   publicationStatus: 0,
   publication_date: "",
   s_types_id: 0,
   status: 0,
   updated_at: "",
   user_company_id: 0
}

const Projects = ({ animation, state, idFreeRouter }: Props) => {

   const [IsLoading, setIsLoading] = useState(false);

   const [message, setMessage] = useState<Boolean>(false);

   const [projectInfoChambaticon, setProjectInfoChambaticon] = useState([initialStateProjectChamabticon]);

   const [addClassModal, setAddClassModal] = useState("hidden");

   const [IsLoadingModal, setIsLoadingModal] = useState(false);

   const [infoProject, setInfoProject] = useState<ProjectInfo>(initialState);


   // Cookies
   const cookies = new Cookies();
   const getSesion = cookies.get('Bearer');

   const [skeletonStar, setSkeletonStar] = useState(false);

   const [starFree, setStarFree] = useState(4);

   const themeStar = {
      colors: {
      backgroundDefault: 'lightgray',
      backgroundColorActive: '#FF9200',
      backgroundColorHover: '#FF9200',
      },
      size: 15,
   }

   useEffect(() => {
      async function getInfoUserSession() {

         const { id } = cookies.get('Bearer');

         let urlProjectChambaticon = "";

         if (state == "Public") {

            urlProjectChambaticon = `${process.env.REACT_APP_API_URI}freelancer/profile/${idFreeRouter}`;
          

         } else if (state == "session") {

            urlProjectChambaticon = `${process.env.REACT_APP_API_URI}freelancer/profile/${id}`;

         };

         // const urlProjectChambaticon = `${process.env.REACT_APP_API_URI}freelancer/profile/${getSesion.id}`;
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'Authorization': `Bearer ${getSesion.token}`
            }
         };

         try {
            const resp = await axios.get(urlProjectChambaticon, config);

            if (resp.data.data.chambaticon_projects.length != 0) {
              
               setMessage(false);
              
               setIsLoading(true);

               setProjectInfoChambaticon(resp.data.data.chambaticon_projects);

               setTimeout(() => { setIsLoading(false) }, 1500);

            } else {
               setMessage(true);
            }

            //   setIsLoading(false);

         } catch (err) {
            // Handle Error Here
            setMessage(true);
            console.error(err);
         }
      };
      getInfoUserSession();

   }, []);

   const showModalProject = (state: Boolean, id: Number | undefined) => {
      // console.log("CERRAR");
      if (state) {
         setAddClassModal("block");
         document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else {
         setAddClassModal("hidden");
         // console.log("CERRAR");
         document.getElementsByTagName("body")[0].style.overflow = "visible";
      }


      getInfoProject(id);

      setIsLoadingModal(true);

   };

   const getInfoProject = async (idProject:Number | undefined) =>{

   
      if(idProject != null || idProject != undefined){

         const urlCountry = `${process.env.REACT_APP_API_URI}project/get/${idProject}`;
   
          let config = {
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${getSesion.token}`
                  }
          };
          try {

              const resp = await axios.get(urlCountry, config);
             
              setInfoProject(resp.data);
            
              setTimeout(() =>{ setIsLoadingModal(false) }, 2000);
                
          } catch (err) {
              // Handle Error Here
              console.error(err);
          }
      }else{
          setInfoProject(initialState);
      }

 }


   return (
      <>

         <div className={`lg:p-4 text-white ${animation}`} id="contenedorProyectosConChambaticon" role="tabpanel" aria-labelledby="dashboard-tab">
            <div className="flex flex-wrap">
               { (message) && <p className="text-center w-full">No se encontraron Proyectos con Chambaticon</p> }
               { !(message) &&
                  <>
                     {projectInfoChambaticon[0].project_name != "" &&
                        <>
                           {IsLoading &&
                              <>
                                 <div className="w-full sm:w-1/2 xl:w-1/3 md:p-5">
                                    <div className="bg-Light-purple rounded-lg px-5 py-1 mb-3">
                                       <div className="flex flex-wrap">
                                          <div className="w-full mb-5">
                                             <div className="pt-5">
                                                <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3"><Skeleton count={1} /></h1>
                                                <h3 className="text-white font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                                <br />
                                                <h3 className="text-Yellow font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                                <a href="" className="text-Light-Blue"><Skeleton count={1} /></a>
                                                <p className="text-white text-sm text-left h-14 overflow-hidden">
                                                   <Skeleton count={1} />
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="w-full sm:w-1/2 xl:w-1/3 md:p-5">
                                    <div className="bg-Light-purple rounded-lg px-5 py-1 mb-3">
                                       <div className="flex flex-wrap">
                                          <div className="w-full mb-5">
                                             <div className="pt-5">
                                                <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3"><Skeleton count={1} /></h1>
                                                <h3 className="text-white font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                                <br />
                                                <h3 className="text-Yellow font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                                <a href="" className="text-Light-Blue"><Skeleton count={1} /></a>
                                                <p className="text-white text-sm text-left h-14 overflow-hidden">
                                                   <Skeleton count={1} />
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="w-full sm:w-1/2 xl:w-1/3 md:p-5">
                                    <div className="bg-Light-purple rounded-lg px-5 py-1 mb-3">
                                       <div className="flex flex-wrap">
                                          <div className="w-full mb-5">
                                             <div className="pt-5">
                                                <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3"><Skeleton count={1} /></h1>
                                                <h3 className="text-white font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                                <br />
                                                <h3 className="text-Yellow font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                                <a href="" className="text-Light-Blue"><Skeleton count={1} /></a>
                                                <p className="text-white text-sm text-left h-14 overflow-hidden">
                                                   <Skeleton count={1} />
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </>
                           }

                           {!IsLoading &&
                              <>
                                 {
                                    projectInfoChambaticon.map((project, key) => {
                                       return (
                                          <div key={key} className="w-full sm:w-1/2 xl:w-1/3 md:p-5">
                                             <div className="bg-Light-purple rounded-lg px-5 py-1 mb-3">
                                                <div className="flex flex-wrap">
                                                   <div className="w-full mb-5">
                                                      <div className="pt-5">
                                                         <button className="" onClick={() => { showModalProject(true, parseInt(project.id)) }}>
                                                            <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3">{project.project_name}</h1>
                                                         </button>

                                                         {  
                                                            (parseInt(project.completed) == 1) 
                                                            ? 
                                                               (<h3 className="text-white font-bold text-base text-left overflow-hidden mb-3">
                                                               {
                                                                  skeletonStar 
                                                                  ? 
                                                                  <SkeletonStar />
                                                                  :
                                                                  <StarRating 
                                                                     initialRating={parseInt(project.rating)} 
                                                                     readOnly={true} 
                                                                     theme={themeStar}
                                                                  /> 
                                                               }
                                                               </h3> ) 
                                                            : 
                                                               (<h3 className="text-white font-bold text-base text-left overflow-hidden mb-3">En proceso</h3>) 
                                                         }

                                                         <h3 className="text-Yellow font-bold text-base text-left overflow-hidden mb-3">{project.end_date}</h3>
                                                         {
                                                            (state == "session") &&
                                                            <div>
                                                               <Link to={`/mensajes-proyecto/freelancer/${project.id}/${project.company_id}/${project.completed}`}>
                                                                  <FontAwesomeIcon className="pr-1" icon={faComment} />
                                                                  Mensajes  {(project.completed == "0") && `+${project.newMessages}`}
                                                               </Link>
                                                            </div>
                                                         }

                                                         <p className="text-Light-Blue">{project.knowledges_tags}</p>
                                                         <p className="text-white text-sm text-left h-14 overflow-hidden">
                                                            {project.description}
                                                         </p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       )
                                    })
                                 }
                              </>
                           }



                        </>
                     }
                  </>
               }



            </div>
         </div>
         <Modal addClass={addClassModal} HandleModal={showModalProject} infoProject={infoProject} IsLoading={IsLoadingModal} />
      </>
   )
}

export default Projects;