import imgError404 from "../assets/imgError404/imgError404";

const Error404 = () =>{
  return(
      <div className="text-white text-center mt-10 mb-10">
        <h1 className="font-extrabold text-5xl">¡UPS ERROR<br />404!</h1>
        <h4 className="mt-5">Parece que esta página no existe; no pierdas la oportunidad de chambear<br />¿Qué te parece si volvemos al inicio?</h4>
        <div className="w-100 flex items-center justify-center">
          <img src={imgError404.error404} alt="" className="w-3/4 sm:w-1/3 mb-5" />
        </div>
        <a href="/" className="bg-Yellow p-5 rounded-full hover:bg-amber-600">VOLVER AL INICIO</a>
      </div>
  );
}

export default Error404;