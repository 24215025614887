
import { Cookies } from "react-cookie-consent";
import { Navigate, Outlet } from "react-router-dom";
import { HandleSession } from "../../function/Login";

export const ProtectedRoute = ({ isAuthenticated, children, redirecTo = "/" }) => {

    if (!isAuthenticated.session) { return <Navigate to={redirecTo} /> };

    // //return children
    return children ? children : <Outlet />
};