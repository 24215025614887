import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from 'react';
import Cookies from "universal-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2';

import { faCcVisa, faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import SkeletonPaymentHistory from "../components/Skeleton/paymentHistory/paymentHistorySkeleton";
import SkeletonDetailsSkeleton from "../components/Skeleton/subscriptionDetails/subscriptionDetailsSkeleton";

const ViewDetallesSuscripcion = () => {

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const urlData = `${process.env.REACT_APP_API_URI}subscription`;

    interface InterfaceDataSuscripcion {
        active: number,
        brand: string,
        last4: string,
        next_payment: string,
        plan: string,
        price: string,
        time: string
    }

    const initDataSuscripcion = {
        active: 1,
        brand: "5",
        last4: "4242",
        next_payment: "28-03-2024",
        plan: "Plan 1",
        price: "90",
        time: "28-02-2024"
    }

    interface InterfaceSuscripcionCancelada {
        message: string
    }

    const initSuscripcionCancelada = {
        message: ""
    }

    const planEmpresa = {
        plan: "Plan 1",
        planes_id: "1"
    }

    const [dataSuscripcion, setDataSuscripcion] = useState<InterfaceDataSuscripcion>(initDataSuscripcion);

    const [detallesSuscripcionCancelada, setDetallesSuscripcionCancelada] = useState<InterfaceSuscripcionCancelada>(initSuscripcionCancelada);

    const [dataPlanEmpresa, setDataPlanEmpresa] = useState(planEmpresa);

    const [animateSkeletonSubcroption, setAnimateSkeletonSubcroption] = useState(false);

    async function getSuscripcion() {

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        try {

            const resp = await axios.get(urlData, config);
            
            // setDataSuscripcion(resp.data.data);

            // setTimeout(() => { setAnimateSkeletonSubcroption(false); }, 2000);

            // setAnimateSkeletonSubcroption()

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }

    async function confirmarDeleteSuscripcion() {

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };
        try {

            const resp = await axios.delete(urlData, config);
            setDetallesSuscripcionCancelada(resp.data.details);

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }

    async function GetInfoCompany() {

        const idUser = cookies.get('Bearer');

        const urlCountry = `${process.env.REACT_APP_API_URI}company/profile/${getSesion.id}`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };
        try {

            const resp = await axios.get(urlCountry, config);

            setDataPlanEmpresa({
                plan: resp.data.plan,
                planes_id: resp.data.planes_id
            });


        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    useEffect(() => {
        // getSuscripcion();
        GetInfoCompany();
    }, []);

    const deleteSuscripcion = () => {
        Swal.fire({
            title: 'Cancelando suscripción...',
            text: "¿Esta seguro de querer cancelarlo?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {

                confirmarDeleteSuscripcion();

                Swal.fire({
                    title: "Suscripcion cancelada con exito",
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonText: 'OK'
                }).then(function () {
                    window.location.href = "/perfil-empresa";
                });
            }
        });
    };


    return (
        <>
            {(animateSkeletonSubcroption) ? (
                <SkeletonDetailsSkeleton></SkeletonDetailsSkeleton> 
            ):(
                <div className="bg-gradient-to-b from-Light-purple via-turquoise to-turquoise">
                    <section className="block md:flex md:flex-wrap h-full -mt-px">
                        <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                            <img className="opacity-50 py-10 h-1/2 lg:h-96 xl:h-35" src={imagenesEditProject.logo_blanco_mitad} alt="" />
                        </div>
                        <div className="w-full lg:w-4/5 mb-12 md:mb-0 flex flex-wrap justify-start pb-5 px-6">
                            <div className="w-full sm:w-5% mt-4 md:mt-4">
                                <Link to="" onClick={() => window.history.go(-1)}>
                                    <img src={imagenesEditProject.arrow_back_4} className="w-10% sm:w-full md:mx-4" alt="" />
                                </Link>
                            </div>
                            <div className="w-full md:w-95% flex justify-center">
                                <form className="w-full md:w-95%">
                                    <div className="flex justify-center pb-0 lg:pb-12">
                                        <h2 className="text-white text-2xl md:text-3xl text-center font-semibold py-2 md:py-8">Detalles de la suscripción</h2>
                                    </div>
                                    <div className="border-t-2">
                                        <div className="mb-6 text-start mt-5">
                                            <div className="flex flex-wrap">

                                                {(dataPlanEmpresa.planes_id != "1") ? (
                                                    <>
                                                        <div className="w-full sm:w-1/2 text-white">
                                                            <div className="flex justify-center sm:justify-start items-center">
                                                                <div>
                                                                    <h1 className="mt-3">Facturado por <span className="text-Morado-Ticongle font-extrabold">chamba</span><span className="text-Azul-Ticongle font-extrabold">ti</span><span className="text-Yellow font-extrabold">con</span></h1>
                                                                    <h1 className="mt-3">Plan actual: {dataSuscripcion.plan} ({dataSuscripcion.time})</h1>
                                                                    <h1 className="mt-3">Precio: <span className="text-Yellow">${dataSuscripcion.price}</span></h1>
                                                                    <h1 className="mt-3">Método de pago: {
                                                                        dataSuscripcion.brand === "visa" ? (
                                                                            <FontAwesomeIcon icon={faCcVisa} />
                                                                        ) : (
                                                                            <FontAwesomeIcon icon={faCcMastercard} />
                                                                        )
                                                                    } terminada en <span className="text-Yellow">{dataSuscripcion.last4}</span></h1>

                                                                    {
                                                                        dataSuscripcion.active === 1 && (
                                                                            <h1 className="mt-3">Tu próxima facturación:
                                                                                <span className="text-Yellow">
                                                                                    {
                                                                                        <>
                                                                                            &nbsp;{dataSuscripcion.next_payment}
                                                                                        </>
                                                                                    }
                                                                                </span>
                                                                            </h1>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-full sm:w-1/2 mt-5 sm:mt-0 flex justify-center sm:justify-end items-center">
                                                            <div className="flex justify-center sm:justify-end items-center">
                                                                <div>
                                                                    {
                                                                        dataSuscripcion.active === 1 ? (
                                                                            <div className="mt-3 bg-red-600 p-2 w-full text-white text-center"><Link to="" onClick={deleteSuscripcion}>Cancelar suscripción</Link></div>
                                                                        ) : (
                                                                            <div className="border-t-2 sm:border-t-0 p-3 text-justify">
                                                                                <h1 className="mt-3 text-white">Cancelaste tu suscripción, podrás obtener un nuevo plan hasta:
                                                                                    <span className="text-Yellow">
                                                                                        {
                                                                                            <>
                                                                                                &nbsp;{dataSuscripcion.next_payment}
                                                                                            </>
                                                                                        }
                                                                                    </span>
                                                                                </h1>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="w-full">
                                                        <h1 className="text-center text-white mt-3">No tienes suscripciones activas</h1>
                                                        <div className="w-full flex justify-center items-center">
                                                            <div className="mt-3 bg-Yellow p-3 text-white text-center">
                                                                <Link to={"/Planes"}>Contrata un plan</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </section>
                </div>
            )}
        </>
    )
}

export default ViewDetallesSuscripcion;