import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import SpecialtiesKnowledge from "../components/EditProfilefreelancer/specialties/specialtiesAndKnowledge";
import {Link} from "react-router-dom";

const EditProject = () =>{
    return(
        <>
            <div className="bg-turquoise">
                <section className="block md:flex md:flex-wrap h-full -mt-px">
                    <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                        <img className="opacity-50 py-10 h-1/2 lg:h-96 xl:h-35" src={imagenesEditProject.logo_blanco_mitad} alt="" />
                    </div>
                    <div className="w-full lg:w-4/5 mb-12 md:mb-0 flex flex-wrap justify-start pb-5 px-6">
                        <div className="w-full sm:w-5% mt-4 md:mt-4">
                            <Link to="" onClick={()=>window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full md:mx-4" alt="" />
                            </Link>
                        </div>
                        <SpecialtiesKnowledge />
                    </div>

                </section>
            </div>
        </>
    )
}

export default EditProject;