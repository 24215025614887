import { useState, useEffect } from "react";
import ExperienceFormal from "../../tabsExperience/ExperienceFormal";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";

const WorkExperience = () => {

    const [idUser, setIdUser] = useState<number>(0);

    const cookies = new Cookies();

    const [showTabs, setShowTabs] = useState<number>(1);

    const activeTab: string = "animate__animated animate__fadeIn";

    const [activeTabs, setActiveTabs] = useState(1);

    let params = useParams();

    const [paramURL, setParamURL] = useState<Number>()

    useEffect(() => {
        getIdUser();
        setParamURL(Number(params.id))
    }, []);

    const getIdUser = () => {
        const { id } = cookies.get('Bearer');
        setIdUser(id)
    }

    return (
        <>
            <div className="w-full mb-8">
                <div className=" flex-wrap -mb-px text-xs font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                    <div className="flex flex-wrap align-center justify-center">
                        <div className="flex w-full justify-center">
                            <h2 className="text-Yellow text-2xl md:text-3xl text-center font-semibold">
                                {
                                    paramURL == 0 ? "Registrar experiencia laboral" : "Actualizar experiencia laboral"
                                }
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myTabContent" className="w-full mb-5">
                  {(showTabs === 1) && <ExperienceFormal animation={activeTab} idFreelance={idUser} />}
            </div>
        </>
    )
}

export default WorkExperience;