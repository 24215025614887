import { Link } from "react-router-dom";

const PoliticasDeprivacidad = () => {
   return (
      <>
         <div className="flex">
            <div className="w-full bg-cyan-900 p-5">
               <div className="bg-cyan-800 p-5 rounded-lg text-justify text-white font-medium">
                  <div className="w-full my-5">
                     <Link to="" onClick={()=>window.location.href = "/"}>
                        <img src="/static/media/boton_back2_boton2.2da335d0bf766228caf2.png" className="w-15% sm:w-10% md:w-5%" alt="" />
                     </Link>
                  </div>
                  {/* <h1 className="text-orange-500 font-bold text-2xl">Centro de ayuda</h1> */}
                  {/* <br /> */}
                  <h3 className="text-orange-500 font-bold text-2xl">Política de Privacidad</h3>
                  {/* <br /> */}
                  {/* <h3>Última actualización: mayo 31, 2020 10:30 (GMT-6)</h3> */}
                  {/* <br /> */}
               </div>
               <div className="bg-cyan-800 rounded-lg mt-3 text-justify font-medium text-white p-5">
                  <h1 className="text-orange-500 text-xl">¿Qué información recolectamos?</h1>
                  <br />
                  <h4>
                     Recolectamos información tuya cuando te registras en nuestro sitio, llenas un formulario o navegas. Cuando te registres 
                     en la plataforma te pediremos que ingreses: tu nombre, tu dirección postal, tu dirección de correo electrónico, tu número 
                     telefónico o la información de tu tarjeta de crédito. Sin embargo, podrás visitar nuestro sitio en forma anónima.
                  </h4>
                  {/* <h4>
                     Recolectamos información tuya cuando te registras en nuestro sitio, llenas un formulario o navegas nuestro sitio. 
                     Cuando te registres en nuestro sitio te pediremos que ingreses: tu nombre, tu dirección postal, tu dirección de correo 
                     electrónico, tu número telefónico o la información de tu tarjeta de crédito. Sin embargo, podrás visitar nuestro sitio 
                     en forma anónima.
                  </h4> */}
                  <br />
                  <h1 className="text-orange-500 text-xl">¿Para qué utilizamos tu información?</h1>
                  <br />
                  <h4>Cualquiera de las informaciones que recolectamos sobre ti podrá ser usada de alguna de estas formas:</h4>
                  <br />
                  <ul className="list-disc px-4">
                     <li>Para personalizar tu experiencia (tu información nos ayuda a responder mejor a tus necesidades particulares).</li>
                     <li>Para mejorar nuestro sitio web. (nos esforzamos continuamente por mejorar las ofertas de nuestro sitio web, basándonos en la información y feedback que nos proporcionas)</li>
                     <li>Para mejorar el servicio al cliente. (tu información nos ayuda a responder de una manera más eficaz a tus consultas referentes a Servicio al Cliente, y a satisfacer tus necesidades)</li>
                     <li>Para procesar transacciones. Tu información, ya sea pública o privada, no será vendida, intercambiada, transferida o cedida a ninguna otra compañía por ninguna razón, sin tu consentimiento, excepto para el expreso propósito de prestar el servicio requerido.</li>
                     <li>Para gestionar un concurso, promoción, encuesta u otra funcionalidad del sitio.</li>
                     <li>Para enviar correos electrónicos periódicos La dirección de correo electrónico que nos brindas puede ser utilizada para enviarte información y actualizaciones, además de que ocasionalmente puedas recibir noticias de la compañía, información sobre los servicios, etc.</li>
                  </ul>
                  <br />
                  <p>
                     Nota: Si en algún momento quieres dejar de recibir tales correos electrónicos, incluimos las instrucciones al final de 
                     cada e-mail.
                  </p> 
                  <br />
                  <h1 className="text-orange-500 text-xl">¿Cómo protegemos tu información?</h1>
                  <br />
                  <h4>
                     Implementamos una serie de medidas de seguridad para mantener protegida tu información personal cada vez que ingresas, 
                     envías o accedes a tu información. Utilizamos un servidor seguro, toda la información sensible es transmitida a través 
                     de tecnología Secure Socket Layer (SSL). Seguidamente, es encriptada en nuestra Base de Datos para que solo puedan 
                     acceder quienes posean permisos específicos a nuestros sistemas, y se les exige que mantengan la confidencialidad de esa 
                     información. Luego de una transacción, tu información privada (tarjetas de crédito, números de seguridad social, 
                     información financiera, etc.) será almacenada en nuestro archivo durante más de 60 días para que puedas cerrar contratos 
                     en nuestro sitio con mayor facilidad, sin necesidad de reingresar la información de pago para cada proyecto que publicas.
                  </h4>
                  {/* <h4>
                     Implementamos una serie de medidas de seguridad para mantener protegida tu información personal cada vez que ingresas, 
                     envías o accedes a tu información personal. Utilizamos un servidor seguro. Toda la información sensible es transmitida a 
                     través de tecnología Secure Socket Layer (SSL). Enseguida es encriptada en nuestra Base de Datos para que sólo puedan 
                     acceder quienes posean permisos específicos a nuestros sistemas, y se les exige que mantengan la confidencialidad de esa 
                     información. Luego de una transacción, tu información privada (tarjetas de crédito, números de seguridad social, 
                     información financiera, etc.) será almacenada en nuestro archivo durante más de 60 días para que puedas cerrar contratos 
                     en nuestro sitio con mayor facilidad, sin necesidad de reingresar la información de pago para cada proyecto que publicas.
                  </h4> */}
                  <br />
                  <h1 className="text-orange-500 text-xl">¿Utilizamos cookies?</h1>
                  <h4>Sí (las Cookies son pequeños archivos que un sitio, o su prestador de servicio, transfiere al disco duro de tu computadora a través de tu navegador Web (si así lo habilitas). Esto permite que los sitios y sistemas de prestación de servicios reconozcan tu navegador, y capturen y recuerden cierta información). Las cookies nos ayudan a recordar, a entender y a almacenar tus preferencias para visitas futuras, y a compilar información adicional sobre el tráfico e interacción en el sitio, de modo de poder ofrecerte una mejor experiencia de usuario y perfeccionar las herramientas a futuro. Podremos también celebrar contratos con terceros que nos ayuden a entender mejor a nuestros visitantes del sitio. Estos prestadores de servicio no pueden utilizar en nuestro nombre la información recolectada, excepto para ayudarnos a llevar adelante nuestro negocio y mejorarlo.</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl">¿Revelamos información a terceros?</h1>
                  <h4>No vendemos, comercializamos ni transferimos a terceros tu información personal identificable. Esto no incluye a terceros en los que confiamos para asistirnos en la operación del sitio, en la gestión de nuestro negocio, o en el servicio que te ofrecemos, siempre y cuando ellos se comprometan a mantener la confidencialidad de esa información. También podremos ceder tu información cuando consideremos que es necesario para acatar la ley, hacer cumplir nuestras políticas del sitio, o proteger los derechos, propiedades y seguridad propios o ajenos. Sin embargo, la información identificable no personal puede ser suministrada a terceros para su utilización en acciones de marketing, publicidad y otras.</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl">Links de terceros</h1>
                  <h4>Ocasionalmente, y a nuestro criterio, podremos incluir u ofrecer en nuestro sitio web productos o servicios de terceros. Estos sitios de terceros poseen políticas de privacidad diferenciadas e independientes. Por lo tanto, no tenemos responsabilidad alguna por el contenido o actividades de estos sitios. No obstante, buscamos proteger la integridad de nuestro sitio, por lo que cualquier feedback sobre ellos será bienvenido.</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl">Cumplimiento del Acta de Protección de la Privacidad En Línea de California</h1>
                  <h4>Como valoramos tu privacidad, hemos tomado las medidas necesarias para cumplir con el Acta de Protección de la Privacidad En Línea de California. Por lo tanto, no compartiremos tu información personal con terceros sin tu consentimiento. Como parte del Acta de Protección de la Privacidad En Línea de California, todos los usuarios de nuestro sitio podrán modificar su información en cualquier momento, ingresando a su panel de control y dirigiéndose a la página “Editar Perfil”.</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl">Cumplimiento del Acta de Protección de la Privacidad En Línea de los Niños</h1>
                  <h4>Cumplimos con los requisitos del Acta de Protección de la Privacidad En Línea de los Niños (COPPA por sus siglas en inglés). No recolectamos información de ningún individuo menor de 13 años. Nuestro sitio web, productos y servicios están dirigidos a personas que tengan por lo menos 13 años o que sean mayores de esa edad.</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl">Únicamente Política de Privacidad Online</h1>
                  <h4>Esta política de privacidad online aplica únicamente para información recolectada a través de nuestro sitio web, y no a información recabada en forma offline.</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl">Términos y Condiciones</h1>
                  <h4>Por favor visita nuestra sección Términos y Condiciones respecto al uso, renuncia y limitación de responsabilidad, que rige la utilización de nuestro sitio web, en (aqui va el link del sitio)</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl">Tu Consentimiento</h1>
                  <h4>Al utilizar nuestro sitio, aceptas nuestra(AQUI VA EL LINK DE POLITICA DE PRIVACIDAD)</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl">Cambios a nuestra Política de Privacidad</h1>
                  <h4>Si decidimos alterar nuestra política de privacidad, publicaremos los cambios en esta página, y/o actualizaremos la fecha de modificación de la Política de Privacidad que figura debajo. Esta política fue modificada por última vez el 8 de mayo de 2012.</h4>
                  <br />
                  <h1 className="text-orange-500 text-xl"><Link to={'/Contactanos'}>Contáctanos</Link></h1>
                  {/* <h4>Si tienes alguna consulta respecto a la política de privacidad, puedes contactarnos a la siguiente dirección: <br /><br />http://chambaticon.com <br /><br />Calle La Reforma #249 Col. San Benito, San Salvador. <br /><br />San Salvador <br /><br />El Salvador <br /><br />soporte@chambaticon.com</h4> */}
                  <h4>Si tienes alguna consulta respecto a la política de privacidad, puedes contactarnos a la siguiente dirección: <br /><br />Calle La Reforma #249 Col. San Benito, San Salvador. <br /><br />San Salvador <br /><br />El Salvador <br /></h4>
               </div>
            </div>
         </div>
      </>
   )
}

export default PoliticasDeprivacidad;