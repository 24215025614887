import { ChangeEvent, useEffect, useState } from "react";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import axios from 'axios';

// InterFace
import { Employer } from "../../Interfaces/RegisterCompanies";
import { alertLogin } from "../../Interfaces/Login";

// Functions
import { ValidateEmail } from "../../function/Login";
import { InitialStateDepartment } from "../../Interfaces/departmentsAndmuniti";
import { Link, useNavigate } from "react-router-dom";

//Input Mask
import * as React from 'react';
import InputMask from 'react-input-mask';
import IconLoading from "../iconLoading/IconLoading";

// types
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | undefined>

const initialStateCompanies = {
    type: "2",
    names: "",
    lastnames: "",
    economic_activity: "default",
    email: "",
    country: "default",
    areaCode: "",
    phone: "",
    password: "",
    password_confirmation: ""
}

const initialStateInput = {
    input: "",
    message: '',
    state: false
}

const initialStateDepartment = {
    code: 0,
    id: 0,
    name: ""
}

const initialStateEconomicActivityDefault = {
    id: 0,
    name: ""
}



const RegEmployer = () => {

    const [registerEmployer, setRegisterEmployer] = useState<Employer>(initialStateCompanies);

    const [initialStateEconomicActivity, setInitialStateEconomicActivity] = useState([initialStateEconomicActivityDefault]);

    const [alert, setalert] = useState<alertLogin[]>([initialStateInput]);

    const [departmentlists, setDepartmentlists] = useState<InitialStateDepartment[]>([initialStateDepartment]);

    const [phone, setPhone] = React.useState<string>(""); //Input Mask

    const [termsAndConditions, setTermsAndConditions] = useState<boolean | undefined>(false);

    const [sendingData, setSendingData] = useState(false);

    const [inputPhone, setInputPhone] = useState(false);

    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();

    const handleInputChange = ({ target: { name, value, id } }: handleInputChange) => {

        if (name == 'tel') {
            setPhone(val);
        };

        if (name === "country") {
            const areaCodeCountry = departmentlists.filter(deparment => deparment.id == Number(value)).map((value) => { return value.code })
          
            if (areaCodeCountry[0] == 503) {
                setInputPhone(true);
            }else{
                setInputPhone(false);
            }
            registerEmployer.areaCode = String(areaCodeCountry[0]);
        };

        if (name == "termsAndConditions") {
            const checkbox = document.getElementById("termsAndConditions",) as HTMLInputElement | null;
            const valueCheck = checkbox?.checked;
            setTermsAndConditions(valueCheck);
        };

        setRegisterEmployer({ ...registerEmployer, [name]: value });
        // console.log("AQUI");
    };

    const handleEmployerSession = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let verifyValues = [
            { name: "name", value: registerEmployer.names },
            { name: "lastnames", value: registerEmployer.lastnames },
            { name: "economic_activity ", value: registerEmployer.economic_activity },
            { name: "gmail", value: registerEmployer.email },
            { name: "country", value: registerEmployer.country },
            { name: "areaCode", value: registerEmployer.areaCode },
            { name: "phone", value: registerEmployer.phone },
            { name: "password", value: registerEmployer.password },
            { name: "confirmPassword", value: registerEmployer.password_confirmation }
        ];
        const validateInputs = ValidateEmail(verifyValues);
        const state = validateInputs.filter(input => input.state === false).map((s) => { return false });
        setalert(validateInputs);
        if (state.length >= 7) {
            if (verifyValues[7].value === verifyValues[8].value) {
                if (termsAndConditions) {
                    sendData();
                } else {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Debes de aceptar los términos y condiciones',
                        showConfirmButton: false,
                        timer: 3500
                    });
                }
            } else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Las contraseñas no coinciden',
                    showConfirmButton: false,
                    timer: 3500
                });
            }
        };

    }

    function sendData() {

        setSendingData(true);

        const url = `${process.env.REACT_APP_API_URI}employer/register`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            }
        }

        axios.post(url, registerEmployer, config)
            .then(response =>{

                addPlansCompany(response.data.user.id);

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 4000
                }).then(() => {
                    navigate('/login-empresa');
                });

                setRegisterEmployer(initialStateCompanies);
                setSendingData(false);

            })
            .catch(err => {

                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });

                setSendingData(false);

            })
    };

    //Function to add plans to company
    async function addPlansCompany(id: number) {

        const newData = { company_id: id };

        const urlPlanID = `${process.env.REACT_APP_API_URI}company/plan/free/1`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            }
        };

        try{

            const resp = await axios.post(urlPlanID, newData, config);
            Swal.fire("Cuenta creada exitosamente", "", "success");

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        async function fetchData() {
            const urlCountry = `${process.env.REACT_APP_API_URI}country`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };
            try {
                const resp = await axios.get(urlCountry, config);
                setDepartmentlists(resp.data);
            } catch (err) {
                console.error(err);
            }
        };
        fetchData();

        async function getEconomicActivedy() {
            const urlCountry = `${process.env.REACT_APP_API_URI}economic-activity`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };
            try {
                const resp = await axios.get(urlCountry, config);
                setInitialStateEconomicActivity(resp.data);
            } catch (err) {
                console.error(err);
            }
        };
        getEconomicActivedy();
    }, []);

    const [shown, setShown] = React.useState(false);
    const switchShown = () => setShown(!shown);

    const [shown2, setShown2] = React.useState(false);
    const switchShown2 = () => setShown2(!shown2);

    const [val, setVal] = useState("");

    return (
        <div className="w-full md:w-95% flex justify-center">
            <form className="w-full md:w-8/12" onSubmit={handleEmployerSession}>
                <h2 className="text-Yellow text-2xl md:text-3xl text-center font-semibold py-2 md:py-8">Registro Empleador</h2>
                <div className="flex justify-center">
                    <div className="lg:columns-2 columns-2">

                    </div>
                </div>
                <div className="columns-1">
                    <p className="text-red-500 text-center w-full">
                        {alert.filter(input => input.input == "profile" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500 text-start">{message.message}</span>
                        ))}
                    </p>
                </div>
                <div className="lg:columns-1">
                    <div className="flex flex-wrap">
                        <div className="mb-6 text-start w-full md:w-1/2 md:pr-2">
                            <label htmlFor="names" className="text-white">Nombres</label>
                            <input
                                type="text"
                                id="names"
                                name="names"
                                value={registerEmployer.names}
                                onChange={handleInputChange}
                                className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                            />
                            {alert.filter(input => input.input == "name" && input.state === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>

                        <div className="mb-6 text-start w-full md:w-1/2">
                            <label htmlFor="lastnames" className="text-white">Apellidos</label>
                            <input
                                type="text"
                                id="lastnames"
                                name="lastnames"
                                value={registerEmployer.lastnames}
                                onChange={handleInputChange}
                                className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                            />
                            {alert.filter(input => input.input == "lastnames" && input.state === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                    </div>

                    <div className="text-start w-full lg:w-2/2 p-1 my-3">
                        <label htmlFor="nickname" className="text-white">Actividad económica</label>

                        <select
                            name="economic_activity"
                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                            value={registerEmployer.economic_activity}
                            onChange={handleInputChange}
                        >
                            <option value="default"> Actividad económica</option>
                            {
                                initialStateEconomicActivity.map((Activity, key) => {
                                    return <option key={key} value={Activity.id}>{Activity.name}</option>
                                })
                            }
                        </select>

                        {alert.filter(input => input.input == "economic_activity" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500 text-start">{message.message}</span>
                        ))}
                    </div>


                    <div className="mb-6 text-start">
                        <label htmlFor="nombreCompleto" className="text-white">Correo electrónico</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            value={registerEmployer.email}
                            onChange={handleInputChange}
                            className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        />
                        {alert.filter(input => input.input == "gmail" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500 text-start">{message.message}</span>
                        ))}
                    </div>
                </div>



                <div className="lg:columns-1">
                    <div className="mb-6 text-start">
                        <label htmlFor="departamento" className="text-white">País</label>
                        <select
                            id="departamento"
                            name="country"
                            value={registerEmployer.country}
                            onChange={handleInputChange}
                            className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        >
                            <option value="default" disabled>Seleccione un País</option>
                            {
                                departmentlists.map((departments, key) => {
                                    return <option key={key} value={departments.id}> {departments.name}</option>
                                })
                            }
                        </select>
                        {alert.filter(input => input.input == "country" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500 text-start">{message.message}</span>
                        ))}
                    </div>
                </div>
                <div className="columns-1">
                    <div className="mb-3 text-start">
                        <label htmlFor="municipio" className="text-white">Teléfono</label>
                        <div className="flex">
                            <input
                                type="number"
                                name="areaCode"
                                value={registerEmployer.areaCode}
                                onChange={handleInputChange}
                                disabled
                                className="bg-color-input-login form-control block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none w-1/3"
                                id="codigoArea"
                            />


                            &nbsp;
                            <input
                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                placeholder="Teléfono"
                                name="phone"
                                type="text"
                                pattern="[0-9]*"
                                value={registerEmployer.phone}
                                onChange={(e) => {
                                    e.target.validity.valid
                                        ?
                                        handleInputChange(e)
                                        :
                                        console.log()
                                }}
                            />

                        </div>
                    </div>
                    <p className="text-red-500 w-full text-start mb-5">

                        {alert.filter(input => input.input == "areaCode" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500">{message.message}</span>
                        ))}
                        <br />
                        {alert.filter(input => input.input == "tel" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500">{message.message}</span>
                        ))}

                    </p>
                </div>
                <div className="lg:columns-1">
                    <div className="mb-6 text-start">
                        <label htmlFor="password" className="text-white">Contraseña</label>
                        <div className="flex items-center">
                            <input
                                id="password"
                                name="password"
                                value={registerEmployer.password}
                                onChange={handleInputChange}
                                type={shown ? 'text' : 'password'}
                                className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                            />
                            <a onClick={switchShown} className='p-2 text-Yellow cursor-pointer -ml-12'>{shown ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</a>
                        </div>
                        {alert.filter(input => input.input == "password" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500 text-start">{message.message}</span>
                        ))}
                    </div>
                    <div className="mb-6 text-start">
                        <label htmlFor="confirmarPassword" className="text-white">Confirmar contraseña</label>
                        <div className="flex items-center">
                            <input
                                name="password_confirmation"
                                value={registerEmployer.password_confirmation}
                                onChange={handleInputChange}
                                type={shown2 ? 'text' : 'password'}
                                className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                id="confirmarPassword"
                            />
                            <a onClick={switchShown2} className='p-2 text-Yellow cursor-pointer -ml-12'>{shown2 ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</a>
                        </div>
                        {alert.filter(input => input.input == "confirmPassword" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500 text-start">{message.message}</span>
                        ))}
                    </div>
                </div>
                <div className="columns-1 py-5 px-2 flex justify-start items-center">
                    <input
                        className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none accent-pink-500  checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white checked:after:w-1"
                        type="checkbox"
                        name="termsAndConditions"
                        onChange={handleInputChange}
                        id="termsAndConditions"
                    />
                    <span className="text-white text-xs lg:text-xs xl:text-base">Acepto los <Link target="_blank" className="hover:text-Yellow" to="/terminos-de-servicio">términos y condiciones</Link></span>
                    {
                        alert.filter(input => input.input == "termsAndConditions" && input.state === true).map((message, key) => (
                            <span key={key} className="text-red-500">{message.message}</span>
                        ))
                    }
                </div>
                <div className="columns-1 pt-3">
                    <div className="text-center lg:text-left">
                        <button
                            type="submit"
                            className="inline-block px-7 py-3 bg-amber-500 text-white font-medium text-sm leading-snug uppercase rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full">

                            {!sendingData &&
                                <>
                                    Regístrate
                                </>
                            }

                            {sendingData &&
                                <IconLoading />
                            }
                        </button>

                    </div>
                </div>
            </form>
        </div>

    );
}
export default RegEmployer;
