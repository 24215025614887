import { useEffect, useState } from "react";
import OtherStudies from "./content-tabs/OtherStudies";
import Projects from "./content-tabs/Projects";
import Specialization from "./content-tabs/Specialization";
import Studies from "./content-tabs/Studies";
import WorkExperience from "./content-tabs/WorkExperience";
import Portfolio from "./content-tabs/Portfolio";
import axios from "axios";
import Cookies from "universal-cookie";



interface Props {
   idFree: String | undefined
   state: String
}

const TabsInfo = ({ idFree, state }: Props) => {

   const [showTabs, setShowTabs] = useState<number>(1);

   const cookies = new Cookies();

   const activeTab: string = "animate__animated animate__fadeIn";

   const [activeTabs, setActiveTabs] = useState(1);

   const [EspecialidadesTotal, setEspecialidadesTotal] = useState(0);

   const [ProyectosChambaticonFreealncer, setProyectosChambaticonFreealncer] = useState(0);

   const [WorkExperienceState, setWorkExperienceState] = useState(0);

   const [StudiesSate, setStudiesSate] = useState(0);

   const [OtherStudiesState, setOtherStudiesState] = useState(0);

   const [PortafolioFreelancer, setPortafolioFreelancer] = useState(0);

   const HandlerButtonsTabs = (ID: string) => {
      setShowTabs(Number(ID));
      setActiveTabs(Number(ID));
   };

   useEffect(() => {

      if (state == "Public") {

         setEspecialidadesTotal(0);
         setProyectosChambaticonFreealncer(0);
         setWorkExperienceState(0);
         setStudiesSate(0);
         setOtherStudiesState(0);
         setPortafolioFreelancer(0);

      } else if (state == "session") {

         setEspecialidadesTotal(1);
         setProyectosChambaticonFreealncer(1);
         setWorkExperienceState(1);
         setStudiesSate(1);
         setOtherStudiesState(1);
         setPortafolioFreelancer(1);

      };


      async function getSpecialties() {

         const { id } = cookies.get('Bearer');

         let urlEspecialidadesFree = "";

         if (state == "Public") {

            urlEspecialidadesFree = `${process.env.REACT_APP_API_URI}freelancer/list/knowledge-specializations/${idFree}`;

            let config = {
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
               }
            };

            try {

               const resp = await axios.get(urlEspecialidadesFree, config);
               const specializationFreelancer = resp.data.data.length;
               setEspecialidadesTotal(specializationFreelancer);

            } catch (err) {
               // Handle Error Here
               console.error("specializationFreelancer::", err);
            }

         }


      };
      getSpecialties();

      async function getInfoUserSession() {

         const { id } = cookies.get('Bearer');

         let urlProjectChambaticon = "";

         if (state == "Public") {
            urlProjectChambaticon = `${process.env.REACT_APP_API_URI}freelancer/profile/${idFree}`;
            let config = {
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
               }
            };

            try {
               const resp = await axios.get(urlProjectChambaticon, config);
               setProyectosChambaticonFreealncer(resp.data.data.chambaticon_projects.length);
            } catch (err) {
               console.error(err);
            }
         }



      };
      getInfoUserSession();

      async function getWorkExperience() {

         const { id } = cookies.get('Bearer');

         let urlWorkExperience = "";

         if (state == "Public") {
            urlWorkExperience = `${process.env.REACT_APP_API_URI}freelancer/list/work-experience/${idFree}`;

            let config = {
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
               }
            };

            try {
               const resp = await axios.get(urlWorkExperience, config);

               const dataExperienceFormal = resp.data.data;

               const experienceFormal = dataExperienceFormal.filter((element: { work_experience_type: number; }) => {
                  return element.work_experience_type == 1;
               });

               setWorkExperienceState(experienceFormal.length);

            } catch (err) {
               // Handle Error Here
               console.error(err);
            };

         };



      }
      getWorkExperience();

      async function getStudiesFreelancer() {

         const { id } = cookies.get('Bearer');
         let urlStudies = "";

         if (state == "Public") {

            urlStudies = `${process.env.REACT_APP_API_URI}freelancer/get/studies/${idFree}?area=Académica`;

            let config = {
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
               }
            };

            try {
               const resp = await axios.get(urlStudies, config);
               setStudiesSate(resp.data.length);

            } catch (err) {
               console.error(err);
            }

         }
      }

      getStudiesFreelancer();

      async function getOtherStudies() {
         const { id } = cookies.get('Bearer');

         let urlOtherStudies = "";

         if (state == "Public") {

            urlOtherStudies = `${process.env.REACT_APP_API_URI}freelancer/get/studies/${idFree}?area=Profesional`;
            let config = {
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
               }
            };

            try {
               const resp = await axios.get(urlOtherStudies, config);
               setOtherStudiesState(resp.data.data.length);

            } catch (err) {
               // Handle Error Here
               console.error(err);
            }
         }



      }
      getOtherStudies();


      async function getPortafolio() {

         // console.log("Obtener portafolio")
         const { id } = cookies.get('Bearer');

         let urlExperienceInformal = "";

         if (state == "Public") {

            urlExperienceInformal = `${process.env.REACT_APP_API_URI}freelancer/list/work-experience/${idFree}`;

            let config = {
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
               }
            };

            try {
               const resp = await axios.get(urlExperienceInformal, config);

               const dataExperienceInformal = resp.data.data;

               const experienceInformal = dataExperienceInformal.filter((element: { work_experience_type: number; }) => {
                  return element.work_experience_type == 2;
               });

               setPortafolioFreelancer(experienceInformal.length);



            } catch (err) {
               console.log(err);
            }

         };

      };
      getPortafolio();

   }, []);

   return (
      <>

         {/* Buttons */}
         <div className="mb-4 mt-5">
            <ul className=" flex-wrap -mb-px text-xs font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
               <div className="flex align-center justify-center">
                  <div className="inline-grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-1">

                     {(EspecialidadesTotal != 0) && (
                        <li className={`${(activeTabs == 1) ? 'border-b-orange-500 text-orange-500' : 'text-white hover:text-white hover:border-gray-300'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("1")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           {/* <button className={`${(activeTabs == 1) ? 'border-b-orange-500 text-orange-500' :'text-white' } h-18 inline-block p-4 rounded-t-lg border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent  dark:text-gray-400 border-gray-100 dark:border-gray-700`} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false" onClick={() => HandlerButtonsTabs("1")} >Especialización profesional</button> */}
                           <h6>Mis Especializaciones</h6>
                        </li>
                     )}

                     {(ProyectosChambaticonFreealncer != 0) && (
                        <li className={`${(activeTabs == 2) ? 'border-b-orange-500 text-orange-500' : 'text-white hover:text-white hover:border-gray-300'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("2")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           {/* <button className={`${(activeTabs == 2) ? 'border-b-orange-500 text-orange-500' :'text-white' } h-18 inline-block p-4 rounded-t-lg border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent dark:text-gray-400 border-gray-100 dark:border-gray-700`} id="btnProyectosConChambaticon" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false" onClick={() => HandlerButtonsTabs("2")}>Proyectos con Chambaticon</button> */}
                           <h6>Mis Proyectos</h6>
                        </li>
                     )}

                     {(WorkExperienceState != 0) && (
                        <li className={`${(activeTabs == 3) ? 'border-b-orange-500 text-orange-500' : 'text-white hover:text-white hover:border-gray-300'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("3")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           <h6>Experiencia laboral</h6>
                        </li>
                     )}

                     {(PortafolioFreelancer != 0) && (
                        <li className={`${(activeTabs == 4) ? 'border-b-orange-500 text-orange-500' : 'text-white hover:text-white hover:border-gray-300'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("4")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           <h6>Portafolio</h6>
                        </li>
                     )}

                     {(StudiesSate != 0) && (
                        <li className={`${(activeTabs == 5) ? 'border-b-orange-500 text-orange-500' : 'text-white hover:text-white hover:border-gray-300'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("5")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           <h6>Formación académica</h6>
                        </li>
                     )}

                     {(OtherStudiesState != 0) && (

                        <li className={`${(activeTabs == 6) ? 'border-b-orange-500 text-orange-500' : 'text-white hover:text-white hover:border-gray-300'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("6")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           <h6>Otros estudios</h6>
                        </li>
                     )}



                  </div>
               </div>
            </ul>
         </div>

         {/* Tabs */}

         {/* Container */}
         <div id="myTabContent" className="mb-5">


            {(EspecialidadesTotal != 0) && (
               (showTabs === 1) && <Specialization animation={activeTab} idFreeRouter={idFree} state={state} />
            )}

            {(ProyectosChambaticonFreealncer != 0) && (
               (showTabs === 2) && <Projects animation={activeTab} idFreeRouter={idFree} state={state} />
            )}

            {(WorkExperienceState != 0) && (
               (showTabs === 3) && <WorkExperience animation={activeTab} idFreeRouter={idFree} state={state} />
            )}

            {(PortafolioFreelancer != 0) && (
               (showTabs === 4) && <Portfolio animation={activeTab} idFreeRouter={idFree} state={state} />
            )}

            {(StudiesSate != 0) && (
               (showTabs === 5) && <Studies animation={activeTab} idFreeRouter={idFree} state={state} />
            )}

            {(OtherStudiesState != 0) && (
               (showTabs === 6) && <OtherStudies animation={activeTab} idFreeRouter={idFree} state={state} />
            )}


         </div>





      </>
   )
};

export default TabsInfo;