import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ImgProfileSkeleton = () =>{
    return(
        <>
            <Skeleton circle width={192} height={192} count={1} className="relative border-8 rounded-full object-cover align-middle border-Yellow flex justify-center" /> 
        </>
    )
}

export default ImgProfileSkeleton;