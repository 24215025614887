(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("star-rating-react-ts", ["react"], factory);
	else if(typeof exports === 'object')
		exports["star-rating-react-ts"] = factory(require("react"));
	else
		root["star-rating-react-ts"] = factory(root["react"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__156__) {
return 