import Skeleton from "react-loading-skeleton";

const StartsQualification = () => {
  
    return (
            <div className="flex">
                <Skeleton circle width={30} height={30} />
                <Skeleton circle width={30} height={30} />
                <Skeleton circle width={30} height={30} />
                <Skeleton circle width={30} height={30} />
                <Skeleton circle width={30} height={30} />
            </div>
    );
}

export default StartsQualification;
