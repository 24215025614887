import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faDownload, faEllipsis, faEllipsisH, faEllipsisVertical, faFilePen, faFolderPlus, faLink, faMinus, faPenClip, faPenToSquare, faX } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface Props {
   animation: string;
   state: String;
   idFreeRouter: String | undefined;
};

const initialStateExperienceInformal = {
   actual_work: 0,
   cargo: null,
   created_at: '',
   descripcion_puesto: '',
   experience_file_path: '',
   experience_titular: '',
   fecha_fin: '',
   fecha_inicio: '',
   freelance_experience_description: '',
   id: 0,
   nombre_empresa: '',
   updated_at: '',
   url_repo: '',
   user_id: 0,
   work_experience_type: 0
};

const Portfolio = ({ animation, state, idFreeRouter }: Props) => {

   const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

   const [IsLoading, setIsLoading] = useState(true);

   const MySwal = withReactContent(Swal);

   const [message, setMessage] = useState<Boolean>(false);

   const [experinceInformal, setExperinceInformal] = useState([initialStateExperienceInformal]);

   const [showOptions, setShowOptions] = useState(false);

   // Cookies
   const cookies = new Cookies();

   const getSesion = cookies.get("Bearer");

   async function getInfoUserSession() {

      const { id } = cookies.get('Bearer');

      let urlExperienceInformal = "";

      if (state == "Public") {

         urlExperienceInformal = `${process.env.REACT_APP_API_URI}freelancer/list/work-experience/${idFreeRouter}`;

      } else if (state == "session") {

         urlExperienceInformal = `${process.env.REACT_APP_API_URI}freelancer/list/work-experience/${id}`;

      }
      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getSesion.token}`
         }
      };

      try {

         setExperinceInformal([initialStateExperienceInformal])
         const resp = await axios.get(urlExperienceInformal, config);
         // console.log("resp ==>", resp);
         const dataExperienceInformal = resp.data.data;

         // console.log("dataExperienceInformal ==>", dataExperienceInformal);

         const experienceInformal = dataExperienceInformal.filter((element: { work_experience_type: number; }) => {
            return element.work_experience_type == 2;
         });

         // console.log("experienceInformal ==>", experienceInformal);

         if (experienceInformal.length != 0) {
            setMessage(false);
            setExperinceInformal(experienceInformal);
            setTimeout(() => {
               setIsLoading(false);
            }, 1500);


         } else {
            setMessage(true);
         }

         setIsLoading(false);

      } catch (err) {
         // Handle Error Here
         setMessage(true);
         setIsLoading(false)
         console.log(err);
      }
   };

   const deletePortafolio = async (id: number) => {
      MySwal.fire({
         title: '¿Estás seguro?',
         text: "¡No podrás revertir esto!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#d33',
         cancelButtonColor: '#3085d6',
         confirmButtonText: 'Sí, eliminar!',
         cancelButtonText: 'Cancelar'
      }).then(async (result) => {
         if (result.isConfirmed) {
            let url = `${process.env.REACT_APP_API_URI}freelancer/delete/work-experience/${id}`;
            let config = {
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${getSesion.token}`
               }
            };
            try {
               setIsLoading(true);
               const resp = await axios.put(url, {}, config);
               console.log('deletePortafolio', resp.data);
               setIsLoading(false);
               MySwal.fire(
                  '¡Registro eliminado!',
                  '',
                  'success'
               )

               getInfoUserSession();
            } catch (err) {
               console.error('Error', err);
               setIsLoading(false);
               MySwal.fire(
                  'Error!',
                  '¡Algo salió mal!',
                  'error'
               );
            }
            // console.log("Se eliminó el registro", id);
         }
      });
   }

   useEffect(() => {

      getInfoUserSession();

   }, []);


   function getDate(date: string) {

      const formatDate = date;
      var partesFecha = formatDate.split("-");
      // console.log(partesFecha);

      const monthR = (Number(partesFecha[1]) - 1);
      const dateWorkExperiences = monthNames[monthR] + ' ' + partesFecha[0];

      return dateWorkExperiences;

   };

   const handleDocumentExperienceInformal = (idFree: String | undefined, filename: string) => {

      const urlDocumentDownload = `${process.env.REACT_APP_PHOTO_URI}freelancer/briefcase/${idFree}/${filename}`

      axios({
         url: urlDocumentDownload,
         method: "GET",
         responseType: 'blob'
      }).then(res => {

         console.log("res ==>", res);

         // Descargar el archivo blob
         const url = window.URL.createObjectURL(new Blob([res.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', filename);
         document.body.appendChild(link);
         link.click();


      }).catch(function (error) {

         console.log(error);
         //  setSendingData(false);
         MySwal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error, al descargar el documento, vuelve a intentar más tarde.',
            showConfirmButton: false,
            timer: 3500
         });


      })

   };


   return (
      <>

         <div className={`lg:p-4 text-white ${animation}`} id="contenedorProyectosConChambaticon" role="tabpanel" aria-labelledby="dashboard-tab">
            {
               (state == "session") &&
               <div className="w-full text-center">
                  <Link
                     to="/registrar-portafolio/0"
                     className="flex items-center gap-2 px-6 py-3 text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none bg-gray-900/10 active:bg-gray-900/20 cursor-pointer">
                     Agregar
                     {/* <FontAwesomeIcon className="text-white text-lg" icon={faFolderPlus} /> */}
                  </Link>
               </div>
            }
            <div className="flex flex-wrap">
               {IsLoading
                  ?
                  <>
                     <div className="w-full sm:w-1/2 xl:w-1/3 md:p-5">
                        <div className="bg-Light-purple rounded-lg px-5 py-1 mb-3">
                           <div className="flex flex-wrap">
                              <div className="w-full mb-5">
                                 <div className="pt-5">
                                    <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3"><Skeleton count={1} /></h1>
                                    <h3 className="text-white font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                    <br />
                                    <h3 className="text-Yellow font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                    <a href="" className="text-Light-Blue"><Skeleton count={1} /></a>
                                    <p className="text-white text-sm text-left h-14 overflow-hidden">
                                       <Skeleton count={1} />
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="w-full sm:w-1/2 xl:w-1/3 md:p-5">
                        <div className="bg-Light-purple rounded-lg px-5 py-1 mb-3">
                           <div className="flex flex-wrap">
                              <div className="w-full mb-5">
                                 <div className="pt-5">
                                    <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3"><Skeleton count={1} /></h1>
                                    <h3 className="text-white font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                    <br />
                                    <h3 className="text-Yellow font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                    <a href="" className="text-Light-Blue"><Skeleton count={1} /></a>
                                    <p className="text-white text-sm text-left h-14 overflow-hidden">
                                       <Skeleton count={1} />
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="w-full sm:w-1/2 xl:w-1/3 md:p-5">
                        <div className="bg-Light-purple rounded-lg px-5 py-1 mb-3">
                           <div className="flex flex-wrap">
                              <div className="w-full mb-5">
                                 <div className="pt-5">
                                    <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3"><Skeleton count={1} /></h1>
                                    <h3 className="text-white font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                    <br />
                                    <h3 className="text-Yellow font-bold text-base text-left overflow-hidden mb-3"><Skeleton count={1} /></h3>
                                    <a href="" className="text-Light-Blue"><Skeleton count={1} /></a>
                                    <p className="text-white text-sm text-left h-14 overflow-hidden">
                                       <Skeleton count={1} />
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </>
                  :
                  experinceInformal[0].experience_titular != ""
                     ?
                     experinceInformal.map((experience, key) => {
                        return (
                           <>
                              <div className="w-full sm:w-1/2 xl:w-1/3 p-5">
                                 <div className="bg-Light-purple p-6 rounded-md shadow-md h-full relative">
                                    <div className="flex flex-wrap justify-between items-center mb-4">
                                       <div className="flex flex-wrap justify-center md:justify-start items-center w-2/3 order-1 md:order-1 mt-5 md:mt-0">
                                          <div className="text-lg font-semibold text-white w-full">{experience.experience_titular}</div>
                                       </div>

                                       {
                                          (state == "session") &&
                                          <button type="button" onClick={
                                             () => {
                                                setShowOptions(!showOptions)
                                             }
                                          } className="order-2 md:order-2 bg-Yellow hover:bg-yellow-600 text-white px-4 py-2 rounded-md">
                                             <FontAwesomeIcon icon={showOptions ? faX : faEllipsis} />
                                          </button>
                                       }



                                    </div>
                                    <div className="flex flex-wrap justify-between items-center mb-4">
                                       <div className={showOptions ? "w-4/5" : "w-full"}>
                                          <div className={`w-full`}>
                                             {experience.actual_work === 2 ?
                                                <h5 className="text-orange-500 text-start text-xs">Desde: {getDate(experience.fecha_inicio)} / {getDate(experience.fecha_fin)} </h5>
                                                : experience.actual_work === 1 &&
                                                <div>
                                                   <h5 className="text-orange-500 text-start text-xs">{getDate(experience.fecha_inicio)} / actualidad </h5>
                                                </div>
                                             }
                                          </div>
                                          <p className={`text-white text-sm text-left h-16 overflow-hidden w-full ${showOptions && "pr-3"}`}>
                                             {experience.freelance_experience_description.length > 120 ? experience.freelance_experience_description.substring(0, 120) + "..." : experience.freelance_experience_description}
                                          </p>
                                          <p className={`text-gray-600 my-2 w-full`}>
                                             {
                                                (experience.url_repo == '' || experience.url_repo == null) ? ''
                                                   :
                                                   <a className="text-white hover:text-Yellow text-xs" href={experience.url_repo} target="_blank">
                                                      <FontAwesomeIcon icon={faLink} /> Enlace de referencia
                                                   </a>
                                             }
                                          </p>
                                       </div>
                                       <div className={showOptions ? "flex flex-wrap justify-end w-1/5" : "hidden"}>
                                          <div className="w-full flex justify-end mb-1">
                                             {
                                                (experience.experience_file_path == '' || experience.experience_file_path == null)
                                                   ?
                                                   <button title="Descargar" disabled className="bg-Yellow hover:bg-yellow-600 text-white px-4 py-2 rounded-md w-full flex justify-center items-center cursor-not-allowed">
                                                      <FontAwesomeIcon className="text-white text-lg" icon={faCloudArrowDown} />
                                                   </button>
                                                   :
                                                   <button title="Descargar" onClick={() => { handleDocumentExperienceInformal(getSesion.id, experience.experience_file_path); }} className="bg-Yellow hover:bg-yellow-600 text-white px-4 py-2 rounded-md w-full flex justify-center items-center cursor-pointer">
                                                      <FontAwesomeIcon className="text-white text-lg" icon={faCloudArrowDown} />
                                                   </button>
                                             }
                                          </div>
                                          <div className="w-full flex justify-end my-1">
                                             {
                                                (state == "session") &&
                                                <Link title="Editar" to={`/registrar-portafolio/${experience.id}`} className="bg-Yellow hover:bg-yellow-600 text-white px-4 py-2 rounded-md w-full flex justify-center items-center"><FontAwesomeIcon className="text-white text-lg" icon={faFilePen} /></Link>
                                             }
                                          </div>
                                          <div className="w-full flex justify-end mt-1">
                                             <button title="Eliminar" onClick={() => { deletePortafolio(experience.id); }} className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md flex justify-center items-center w-full">
                                                <FontAwesomeIcon className="text-white text-lg" icon={faMinus} />
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              {/* <div key={key} className="w-full sm:w-1/2 xl:w-1/3 md:p-5 mb-4 mt-8">
                           <div className="bg-Light-purple rounded-lg px-5 py-1 mb-3">
                              <div className="flex flex-wrap">
                                 <div className="w-full mb-5">
                                    <div className="pt-5 relative">
                                       <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3">{experience.experience_titular}</h1>
                                       { experience.actual_work === 2 ?
                                             <h5 className="text-orange-500 text-start text-xs">Desde: {getDate(experience.fecha_inicio)} / {getDate(experience.fecha_fin)} </h5>
                                          : experience.actual_work === 1 &&
                                          <div>
                                             <h5 className="text-orange-500 text-start text-xs">{getDate(experience.fecha_inicio)} / actualidad </h5>  
                                          </div>
                                       }
                                       <p className="text-white text-sm text-left h-32 md:h-44 overflow-hidden">
                                          {experience.freelance_experience_description}
                                       </p>
                                       <div className="h-4">
                                          {
                                             (experience.url_repo == '' || experience.url_repo == null) ? ''
                                             :
                                             <a className="text-white hover:text-Yellow text-xs" href={experience.url_repo} target="_blank">
                                                <FontAwesomeIcon icon={faLink} /> Enlace de referencia
                                             </a>
                                          }
                                       </div>
                                       {
                                          (state == "session") &&
                                             <Link title="Editar" to={`/registrar-portafolio/${experience.id}`} className="mt-2 mb-4 inline-block py-2 px-4 bg-Yellow text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-amber-500 hover:shadow-lg focus:bg-amber-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-600 active:shadow-lg transition duration-150 ease-in-out absolute top-[-48px] right-0 rounded-tr">
                                                <FontAwesomeIcon className="text-white text-lg" icon={faPenClip} />
                                             </Link> 
                                       }
                                       {
                                          (experience.experience_file_path == '' || experience.experience_file_path == null) ? 
                                                <button title="Descargar" disabled className="mt-2 mb-4 inline-block py-2 px-4 bg-gray-500 text-white font-medium text-xs leading-tight uppercase shadow-md transition duration-150 ease-in-out absolute top-[-48px] right-12 rounded-tl">
                                                   <FontAwesomeIcon className="text-gray-700 text-lg" icon={faCloudArrowDown} />
                                                </button> 
                                             : 
                                                <button title="Descargar" onClick={() => handleDocumentExperienceInformal(idFreeRouter, experience.experience_file_path)} className="mt-2 mb-4 inline-block py-2 px-4 bg-Yellow text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-amber-500 hover:shadow-lg focus:bg-amber-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-600 active:shadow-lg transition duration-150 ease-in-out absolute top-[-48px] right-12 rounded-tl">
                                                   <FontAwesomeIcon className="text-white text-lg" icon={faCloudArrowDown} />
                                                </button>
                                       }
                                    </div>
                                 </div>
                              </div>
                           </div>
                           </div> */}
                           </>
                        )
                     })
                     :
                     <p className="text-center w-full">No se encontraron Registros del Portafolio</p>
               }
            </div>
         </div>
         {/* <Modal addClass={addClassModal} HandleModal={showModalProject} infoProject={infoProject} IsLoading={IsLoadingModal} /> */}
      </>
   )
}

export default Portfolio;