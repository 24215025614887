
import imgPerfilFreelancer from "../../../assets/imgPerfilFreelancer/imgPerfilFreelancer";
import { faUsers, faEnvelope, faPhone, faLocationDot, faLanguage, faPenToSquare, faChartLine, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';





const CompanySkeleton = () => {

  return (
    <>

    
      <div className="flex flex-wrap">
        {/* <div className="w-10% hidden md:flex">
          <img src={imgPerfilFreelancer.fondo_login_izquierdo_sin_icono2} className="w-full" alt="" />
        </div> */}
        <div className="w-full md:w-full bg-turquoise p-5">
          <div className="border border-Light-Blue p-5 rounded-lg">
            <div className="flex flex-wrap justify-center sm:justify-start relative">
              <div className="w-full lg:w-1/4 flex items-center justify-center">
              <div>
                  <div className="w-full lg:w-3/12 lg:order-2 flex">
                    <div className="relative">
                           <Skeleton circle width={190} height={190} count={1} /> 
                      <div className="mt-3 text-end flex justify-between ">
                            <Skeleton circle width={40} height={40} count={1} />
                            <Skeleton circle width={40} height={40} count={1} />
                            <Skeleton circle width={40} height={40} count={1} />
                            <Skeleton circle width={40} height={40} count={1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/4">
                <div className="text-start lg:ml-5">
                  <h1 className="text-white font-bold text-2xl text-center lg:text-start mb-5 lg:mb-0"><Skeleton count={1} width={150} /></h1>
                  <h3 className="text-orange-500 text-sm"><Skeleton count={1} width={150} /></h3>
                  <h3 className="text-white mb-2 text-sm">
                      <span><Skeleton count={1} width={150} /></span>
                  </h3>
                  <h3 className="text-orange-500 text-sm"><Skeleton count={1} width={150} /></h3>
                  <p className="text-justify text-white mb-5">
                      <Skeleton count={4} />  
                  </p>

                  <div className="flex">
                    <span className="p-2"><Skeleton count={1} width={160} /></span>
                    <span className="p-2"><Skeleton count={1} width={160} /></span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="border border-Light-Blue rounded-lg mt-3">
            <div className="flex flex-wrap m-4">
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                  <div className="flex justify-center items-center w-1/3">
                    <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faBars} />
                  </div>
                  <div className="text-start text-white font-semibold w-full">
                    <h2><Skeleton count={1} width={160} /></h2>
                    <h2 className="text-orange-500"><Skeleton count={1} width={160} /></h2>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                  <div className="flex justify-center items-center w-1/3">
                    <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faChartLine} />
                  </div>
                  <div className="text-start text-white font-semibold w-full">
                    <h2><Skeleton count={1} width={160} /></h2>
                    <h2 className="text-orange-500"><Skeleton count={1} width={160} /></h2>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                  <div className="flex justify-center items-center w-1/3">
                    <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faEnvelope} />
                  </div>
                  <div className="text-start text-white font-semibold w-full">
                    <h2><Skeleton count={1} width={160} /></h2>
                    <h2 className="text-orange-500"><Skeleton count={1} width={160} /></h2>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                  <div className="flex justify-center items-center w-1/3">
                    <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faPhone} />
                  </div>
                  <div className="text-start text-white font-semibold w-full">
                    <h2><Skeleton count={1} width={160} /></h2>
                    <h2 className="text-orange-500"><Skeleton count={1} width={160} /></h2>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                  <div className="flex justify-center items-center w-1/3">
                    <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faPhone} />
                  </div>
                  <div className="text-start text-white font-semibold w-full">
                    <h2><Skeleton count={1} width={160} /></h2>
                    <h2 className="text-orange-500"><Skeleton count={1} width={160} /></h2>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-1 h-auto">
                <div className="p-4 flex bg-Light-purple rounded-md w-full h-full items-center">
                  <div className="flex justify-center items-center w-1/3">
                    <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLocationDot} />
                  </div>
                  <div className="text-start text-white font-semibold w-full">
                    <h2><Skeleton count={1} width={160} /></h2>
                    <h2 className="text-orange-500"><Skeleton count={1} width={160} /></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySkeleton;