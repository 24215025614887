import imagenesDetallesProject from "../assets/imgDetallesProject/imgDetallesProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload, faEnvelope, faMoneyBill, faUser, faComment } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import fileDownload from "js-file-download";


import imgDefault from '../assets/imgEditPerfil/user.png';
import ProposalSkeleton from "../components/Skeleton/Proposal/ProposalSkeleton";
import ProposalSkeletonList from "../components/Skeleton/Proposal/ProposalSkeleton";
import IconLoading from "../components/iconLoading/IconLoading";
import IconLoadingWhite from "../components/iconLoading/IconLoadingWhite";
import Modal from "../components/modals/ModalFreelancerPropuse";

import { infoProposal } from "../Interfaces/infoProposal";

import ModalStar from "../components/modals/modalStar";

import { StarRating } from 'star-rating-react-ts';
import ProposalOfProject from "../components/proposals/Proposal";
import Cookies from "universal-cookie";

const initialStateProposal = {
    nameProject: "",
    gmailFreelancer: "",
    amount: "",
    dateOfDelivery: "",
    file: "",
    photo: "",
    freelace_id: "",
    state: "",
    proposal_id: "",
    names: "",
    lastnames: "",
    description: ''
};

const initialStatePropos = {
    description: '',
    photo: '',
    names: '',
    lastanames: '',
    id_freelance: ''
};


const DetallesProject = () => {

    const params = useParams();

    const MySwal = withReactContent(Swal);

    const [ListProposal, setListProposal] = useState([initialStateProposal]);

    const [MessageDefault, setMessageDefault] = useState(false);

    const [IsLoading, setIsLoading] = useState(true);

    const [NameProject, setNameProject] = useState("");

    const [assignedStatus, setAssignedStatus] = useState();

    const [StatusProject, setStatusProject] = useState("3");

    const [GetIdProyecto, setGetIdProyecto] = useState(params.id);

    const [sendingData, setSendingData] = useState(false);

    const [addClassModal, setAddClassModal] = useState("modalCloseProject");

    const [IsLoadingModal, setIsLoadingModal] = useState(false);

    const [star, setStar] = useState(false);

    const [qStar, setQStar] = useState(0);

    const [idProject, setIdProject] = useState(0);

    const [paymentProject, setpaymentProject] = useState(0);

    const [newMessageCaht, setNewMessageCaht] = useState(0);

    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const [animateProposal, setanimateProposal] = useState({
        animate: false,
        proposal: "0"
    });

    const [infoProposal, setInfoProposal] = useState<infoProposal>(initialStatePropos);

    const [qualifyFreelancer, setQualifyFreelancer] = useState({
        star: false,
        proposalID: "0"
    });

    const themeStar = {
        colors: {
            backgroundDefault: 'lightgray',
            backgroundColorActive: '#FF9200',
            backgroundColorHover: '#FF9200',
        },
        size: 30,
    }

    const acceptProposal = (idProposal: string, idfreelancer: string) => {
        MySwal.fire({
            title: 'Esta seguro de aceptar esta propuesta',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let animate = {
                    animate: true,
                    proposal: idProposal
                };
                setanimateProposal(animate);

                const urlAcceptProposal = `${process.env.REACT_APP_API_URI}company/accept-proposal/${idProposal}`;
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${getSesion.token}`
                    }
                };
                try {

                    const resp = await axios.post(urlAcceptProposal, {}, config);

                    let animate = {
                        animate: false,
                        proposal: idProposal
                    };

                    setanimateProposal(animate);

                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: resp.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    });

                    getProposalFree();

                } catch (err) {
                    console.error(err);
                    let animate = {
                        animate: false,
                        proposal: idProposal
                    };
                    setanimateProposal(animate);
                }
            } else if (result.isDenied) {
                Swal.fire('Propuesta cancelada', '', 'warning');
            }
        });
    };

    const RechazadaProposal = async (idProposal: string) => {

        MySwal.fire({
            title: 'Esta seguro de rechazar esta propuesta',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'Rechazar',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let animate = {
                    animate: true,
                    proposal: idProposal
                };
                setanimateProposal(animate);
                const urlProposal = `${process.env.REACT_APP_API_URI}company/deny-proposal/${idProposal}`;
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${getSesion.token}`
                    }
                };
                try {
                    const resp = await axios.post(urlProposal, config);
                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Propuesta Rechazada',
                        showConfirmButton: false,
                        timer: 3500
                    });
                    let animate = {
                        animate: false,
                        proposal: idProposal
                    };
                    setanimateProposal(animate);
                    getProposalFree();
                } catch (err) {
                    console.error(err);
                    let animate = {
                        animate: false,
                        proposal: idProposal
                    };
                    setanimateProposal(animate);
                }
            } else if (result.isDenied) {
                // Swal.fire('Propuesta cancelada', '', 'warning');
            }
        });

    };

    const ProjectFinishQ = (idProposal: string, idProject: any, idFreelancer: string) => {

        let animate = { animate: true, proposal: idProposal };

        setanimateProposal(animate);

        setTimeout(() => {

            let animate = { animate: false, proposal: idProposal };
            setanimateProposal(animate);

            let qualify = { star: true, proposalID: idProposal };
            setQualifyFreelancer(qualify);

        }, 3000);

    };

    const ProjectFinish = async (proposalID: string) => {

        const urlFinishProject = `${process.env.REACT_APP_API_URI}project/complete-project/${GetIdProyecto}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        try {

            const resp = await axios.post(urlFinishProject, config);

            MySwal.fire({
                position: 'center',
                icon: 'success',
                title: resp.data.message,
                showConfirmButton: false,
                timer: 2500
            });

            getProposalFree()

            setTimeout(() => {
                let qualify = { star: false, proposalID: proposalID };
                setQualifyFreelancer(qualify);
            }, 5000);

            setTimeout(() => {
                let animate = { animate: false, proposal: proposalID };
                setanimateProposal(animate);
            }, 6500);


        } catch (err: any) {
            console.error(err);

            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 2500
            });

            setTimeout(() => { let animate = { animate: false, proposal: proposalID }; setanimateProposal(animate); }, 4000);

        };

    };

    async function getProposalFree() {

        const urlInfoPrject = `${process.env.REACT_APP_API_URI}company/get/project-proposals/${params.id}`;
        console.log(urlInfoPrject);

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        try {

            const resp = await axios.get(urlInfoPrject, config);
            console.log("=)", resp.data);

            if (resp.data.data.length != 0) {

                setMessageDefault(true);

                setAssignedStatus(resp.data.project.assigned_status);

                setNameProject(resp.data.project.project_name);

                setStatusProject(resp.data.project.assigned_status);

                setpaymentProject(resp.data.project.payment);

                const dta = resp.data.data.map((item: any) => {

                    // console.log("dta ==>", dta);

                    return ({
                        nameProject: resp.data.project.project_name,
                        gmailFreelancer: item.email,
                        amount: item.budget,
                        id_freelance: item.freelace_id,
                        dateOfDelivery: resp.data.project.delivery_date,
                        file: item.proposal_file,
                        photo: item.photo,
                        freelace_id: item.freelace_id,
                        state: item.status,
                        proposal_id: item.proposal_id,
                        names: item.names,
                        lastnames: item.lastnames,
                        description: item.description
                    });
                });

                console.log("Helo == ? dta ==>", dta);
                setListProposal(dta);

                setMessageDefault(false);

                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);

            } else {
                setSendingData(false);
                setMessageDefault(true);
            };

        } catch (err) {
            console.error(err);
        };
    };

    async function getNewMessageChat() {

        const urlInfoPrject = `${process.env.REACT_APP_API_URI}company/get/project-proposals/${params.id}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        try {

            const resp = await axios.get(urlInfoPrject, config);

            if (resp.data.data.length != 0) {

                setNewMessageCaht(resp.data.project.newMessages);

            } else {
                setSendingData(false);
                setMessageDefault(true);
            };

        } catch (err) {
            console.error(err);

        }
    };

    useEffect(() => {
        getProposalFree();
        getNewMessageChat();
    }, []);

    const handleDocumentProposal = (url: string, filename: string) => {

        setSendingData(true);

        axios({
            url: url,
            method: "GET",
            responseType: 'blob'
        }).then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setSendingData(false);

        }).catch(function (error) {

            setSendingData(false);

            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error, al descargar el documento, vuelve a intentar más tarde.',
                showConfirmButton: false,
                timer: 3500
            });

        })

    };

    const other = (state: Boolean) => {
        // console.log("CERRAR");
        if (state) {
            setAddClassModal("modalOpenProject");
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        } else {
            setAddClassModal("modalCloseProject");
            // console.log("CERRAR");
            document.getElementsByTagName("body")[0].style.overflow = "visible";
        }
        setIsLoadingModal(true);
        setInterval(() => {
            setIsLoadingModal(false);
        }, 1000);
    }

    const showModalProject = (description: string, photo: string, names: string, lastnames: string, id_freelance: string) => {

        setInfoProposal({
            description: description,
            photo: photo,
            names: names,
            lastanames: lastnames,
            id_freelance: id_freelance
        });

    };

    const sendQualification = (id: string, proposalID: string) => {

        let animate = { animate: true, proposal: proposalID };

        setanimateProposal(animate);

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        const data = {
            project: GetIdProyecto,
            rating: qStar
        };

        const urlQ = `${process.env.REACT_APP_API_URI}rating/freelancer/${id}`;

        const response = axios.post(urlQ, data, config);

        response.then(res => {

            if (res.data.message == "Calificación registrada.") {

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Calificación registrada exitosamente',
                    showConfirmButton: false,
                    timer: 2500
                });

                setTimeout(() => { ProjectFinish(proposalID) }, 5000);

            }
        })
        response.catch(err => {

            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 3500
            });

            setTimeout(() => {
                let animate = { animate: false, proposal: proposalID };
                setanimateProposal(animate);
            }, 4000);

        });

    };

    const getQ = (e: number) => { setQStar(e) };

    return (
        <>
            <div className="bg-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    <div className="hidden lg:flex w-10% bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                        <img className="" src={imagenesDetallesProject.fondo_cadena} alt="" />
                    </div>
                    <div className="md:w-full lg:w-90% flex flex-wrap pb-5 lg:py-5">
                        <div className="w-full sm:w-5% mt-4 lg:mt-1">
                            <Link to="" onClick={() => window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                            </Link>
                        </div>
                        <div className="mx-8 sm:w-85%">
                            <div className="flex justify-start pb-2 lg:pb-4 mt-4 lg:mt-0 2xl:p-2">
                                <h1 className="text-Yellow font-bold text-xl sm:text-3xl">Propuestas de proyecto: {NameProject}</h1>
                                {/* <br /> */}
                                {/* <h1 className="text-Yellow font-bold text-xl sm:text-3xl">Estado del proyecto: {assignedStatus}</h1> */}
                            </div>
                            {/* <div className="columns-1 sm:columns-2 md:my-2 lg:columns-4 flex justify-strat 2xl:p-2"> */}
                            <div className="columns-1 sm:columns-2 md:my-2 lg:columns-4 flex justify-strat">

                                {(Number(StatusProject) == 2) &&
                                    <Link
                                        to={`/actualizar-proyecto/${params.id}`}
                                        className="
                                    inline-block w-95% sm:w-full 
                                    md:w-1/4 px-5 py-2 lg:py-3 bg-Yellow
                                  text-white font-medium text-xs sm:text-sm 
                                    xl:text-base text-center leading-snug rounded shadow-md
                                  hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 
                                   focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 
                                   active:shadow-lg transition duration-150 ease-in-out"
                                    >
                                        Editar Proyecto
                                    </Link>
                                }

                                {(Number(StatusProject) == 1) &&
                                    <Link
                                        to={`/informacion-proyecto/${params.id}`}
                                        className="
                                        inline-block w-95% sm:w-full md:w-1/4 px-5 
                                        py-2 lg:py-3 bg-Yellow text-white font-medium 
                                        text-xs sm:text-sm xl:text-base text-center leading-snug 
                                        rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 
                                        focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 
                                        active:shadow-lg transition duration-150 ease-in-out"
                                    >
                                        Ver información de proyecto
                                    </Link>
                                }

                            </div>

                            {/* {
                                (MessageDefault) &&
                                <p className="text-white text-center text-xl my-16">No tienes propuesta de Freelancer en este proyecto </p>
                            } */}

                            <div className="grid md:gap-4 lg:gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
                                <>

                                    {MessageDefault ? (
                                        <p className="text-white text-xl my-16 text-center">No tienes propuesta de Freelancer en este proyecto </p>
                                    ) : (

                                        (IsLoading) ? (
                                            <ProposalSkeletonList cards={6} />
                                        ) : (


                                            (
                                                (ListProposal.length != 0) &&
                                                ListProposal.map((proposal) => {
                                                    return (
                                                        <ProposalOfProject
                                                            proposal={proposal}
                                                            acceptProposal={acceptProposal}
                                                            RechazadaProposal={RechazadaProposal}
                                                            ProjectFinishQ={ProjectFinishQ}
                                                            sendingData={sendingData}
                                                            animateProposal={animateProposal}
                                                            handleDocumentProposal={handleDocumentProposal}
                                                            getQ={getQ}
                                                            themeStar={themeStar}
                                                            sendQualification={sendQualification}
                                                            star={star}
                                                            qualifyFreelancer={qualifyFreelancer}
                                                            newMessageCaht={newMessageCaht}
                                                            GetIdProyecto={GetIdProyecto}
                                                            assignedStatus={assignedStatus}
                                                            paymentProject={paymentProject}
                                                            showModalProject={showModalProject}
                                                            other={other}
                                                        />
                                                    )
                                                })
                                            )

                                        )



                                    )}









                                    {/* {!(MessageDefault) &&
                                        <>
                                            {(IsLoading) &&
                                                <ProposalSkeletonList cards={6} />
                                            }

                                            {!(IsLoading) &&
                                                <>
                                                    {
                                                        (ListProposal.length != 0) &&
                                                        ListProposal.map((proposal) => {
                                                            return (
                                                                <div className="w-full">
                                                                    <div className="flex flex-wrap">
                                                                        <div className="my-2 w-full pr-2 2xl:p-2">
                                                                            <div className={` ${(proposal.state == "1") ? 'bg-purple-cards' : 'bg-Dark-purple'}  rounded flex flex-wrap px-4 sm:px-4 py-3`}>
                                                                                <div className="flex justify-center mx-2 items-center w-full md:w-1/4 xl:w-22% 2xl:w-30%">
                                                                                    <Link to={`/freelancer/${proposal.freelace_id}`} target="_blank">
                                                                                        <img src={`${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${proposal.photo}`} className="h-24 w-24 md:h-16 md:w-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden object-cover object-center" alt="" />
                                                                                    </Link>
                                                                                </div>
                                                                                <div className="w-full md:w-65% xl:w-65% 2xl:w-3/5">
                                                                                    <Link to={`/freelancer/${proposal.freelace_id}`} target="_blank">
                                                                                        <h3 className="text-white text-sm flex h-6 w-full overflow-hidden mt-4"><FontAwesomeIcon className="pr-1" icon={faUser} />
                                                                                            <span className={`${proposal.state == "1" ? 'text-white' : 'text-white'}`}>
                                                                                                {proposal.names}  {proposal.lastnames}
                                                                                            </span>
                                                                                        </h3>
                                                                                    </Link>
                                                                                    <h3 className="text-white text-sm "><FontAwesomeIcon className="pr-1" icon={faMoneyBill} /> <span className={`${proposal.state == "1" ? 'text-white' : 'text-white'}`}>${proposal.amount}</span></h3>
                                                                                    <h3 className="text-white text-sm "><FontAwesomeIcon className="pr-1" icon={faCalendar} /> <span className={`${proposal.state == "1" ? 'text-white' : 'text-white'}`}> Finaliza: {proposal.dateOfDelivery}</span></h3>
                                                                                        {(proposal.state == "1") &&
                                                                                            <h3 className="text-white text-sm cursor-pointer">
                                                                                                <Link to={`/mensajes-proyecto/empresa/${GetIdProyecto}/${proposal.freelace_id}/${assignedStatus}`}>
                                                                                                    <FontAwesomeIcon className="pr-1" icon={faComment} /> 
                                                                                                    <span className="text-white"> Mensajes +{newMessageCaht}</span>
                                                                                                </Link>
                                                                                            </h3>
                                                                                        }

                                                                                </div>
                                                                                <div className="text-left w-full flex flex-wrap justify-center md:pl-2 h-46 md:h-24 overflow-hidden">
                                                                                    <div className="w-full pt-4 flex items-center justify-center overflow-hidden">
                                                                                        <button onClick={() => { showModalProject(proposal.description, proposal.photo, proposal.names, proposal.lastnames); other(true)}} className="text-white underline hover:text-Yellow hover:cursor-pointer">
                                                                                            Ver más
                                                                                        </button>
                                                                                    </div>

                                                                                    {sendingData &&
                                                                                        <IconLoadingWhite />
                                                                                    }

                                                                                    {!sendingData &&
                                                                                    <>
                                                                                     {(assignedStatus == 2 || assignedStatus == 1) &&
                                                                                        <div className={`${(proposal.state == "3") ? 'grid grid-cols-1 gap-1' : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 '}w-full  pt-1 text-xs justify-start`}>

                                                                                        {
                                                                                            ((proposal.state == "2") || (proposal.state == "1")) &&

                                                                                            <>
                                                                                                {
                                                                                                    (proposal.file != null) &&
                                                                                                    <button
                                                                                                        onClick={() => handleDocumentProposal(`${process.env.REACT_APP_PHOTO_URI}company-file/freelance-proposal/${proposal.proposal_id}/${proposal.file}`, `${proposal.file}`)}
                                                                                                        className="h-9 flex justify-center bg-Light-Blue items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 md:mr-1 my-2">
                                                                                                        Documento
                                                                                                    </button>

                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                        {(proposal.state == "1") &&
                                                                                            <>
                                                                                                <div 
                                                                                                    className="
                                                                                                            h-9 w-full
                                                                                                            bg-Dark-purple flex justify-center items-center 
                                                                                                            text-center font-roboto  xl:text-base text-white
                                                                                                            rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"
                                                                                                    onClick={() => ProjectFinishQ(proposal.proposal_id, GetIdProyecto, proposal.freelace_id)}>
                                                                                                    Finalizar
                                                                                                </div>
                                                                                            </>
                                                                                        }

                                                                                        {(proposal.state == "2") &&
                                                                                            <>
                                                                                                <button
                                                                                                    onClick={() => acceptProposal(proposal.proposal_id, proposal.freelace_id)}
                                                                                                    className="h-9 flex justify-center bg-purple-cards items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"
                                                                                                >
                                                                                                    Aceptar
                                                                                                </button>

                                                                                                <button
                                                                                                    onClick={() => RechazadaProposal(proposal.proposal_id)}
                                                                                                    className="h-9 flex justify-center bg-Yellow items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"
                                                                                                >
                                                                                                    Rechazar
                                                                                                </button>
                                                                                            </>
                                                                                        }

                                                                                        {(proposal.state == "3") &&

                                                                                            <div className="h-9 w-full flex justify-center items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer">
                                                                                                Propuesta Rechazada
                                                                                            </div>
                                                                                        }


                                                                                         </div>
                                                                                     }
                                                                                    
                                                                                     {(assignedStatus == 3) &&
                                                                                        <>
                                                                                            <div className="flex justify-center items-center">
                                                                                                <div className="flex justify-center">
                                                                                                    <button className="bg-Yellow text-white text-sm font-bold transition-colors rounded-lg hover:bg-dark-yellow px-4 py-2 mx-2">
                                                                                                        Proyecto finalizado
                                                                                                    </button>
                                                                                                </div> 

                                                                                                <div className="flex justify-center">
                                                                                                
                                                                                                {(paymentProject == 0) &&
                                                                                                    <Link 
                                                                                                        to={`/pago-freelancer/${GetIdProyecto}`}
                                                                                                        className="bg-Light-Blue text-white text-sm font-bold transition-colors rounded-lg hover:bg-dark-yellow px-4 py-2 mx-2 pt-2 text-center"
                                                                                                    >
                                                                                                            Proceder a pagar 
                                                                                                    </Link>
                                                                                                }

                                                                                                </div> 
                                                                                            </div>
                                                                                        </>
                                                                                     }
                                                                                    </>

                                                                                      
                                                                                    }
                                                                                </div>
                                                                                {star &&
                                                                                    <div className="w-full m-2">
                                                                                        <h4 className="text-white text-center">Califica al freelancer</h4>
                                                                                        <div className="flex flex-wrap justify-center">
                                                                                            <StarRating onClick={getQ} theme={themeStar} />
                                                                                        </div>
                                                                                        <div className="flex flex-wrap justify-center">
                                                                                            <button 
                                                                                                className="h-9 flex justify-center bg-Dark-purple items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 my-2 cursor-pointer"
                                                                                                onClick={()=> {sendQualification(proposal.freelace_id, proposal.proposal_id)}}
                                                                                            >
                                                                                                Calificar
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                        </>
                                    } */}
                                </>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Modal info={infoProposal} addClass={addClassModal} HandleModal={other} IsLoading={IsLoadingModal} />
            {/* <ModalStar info={infoProposal} addClass={addClassModal} HandleModal={other} IsLoading={IsLoadingModal}/> */}
        </>
    )
}

export default DetallesProject;