
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types'
import React, { Component } from 'react'


import imgDefault from '../../../assets/imgEditPerfil/user.png';
  
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


interface Props{
   cards:Number
}


const ProposalSkeletonList = ({cards}:Props) => {
   
   
    return (
      <>    
         {
            Array(cards).fill(0).map((item, index) =>{
               return(
                  <div className="w-full" key={index}>
                     <div className="flex flex-wrap">
                        <div className="my-2 w-full pr-2 2xl:p-2">
                           <div className={`bg-Light-Blue rounded flex flex-wrap px-4 sm:px-2 py-3`}>
                              <div className="flex justify-center w-full md:w-1/5 xl:w-1/4 2xl:w-30%">
                                 <Skeleton circle width={60} height={60} count={1} /> 
                              </div>
                              <div>
                                    <h1 className="text-white text-sm py-2"><Skeleton width={250} count={1} /></h1>
                                    <h3 className="text-white text-sm "><Skeleton width={250} count={1} /></h3>
                                    <h3 className="text-white text-sm "><Skeleton width={250} count={1} /></h3>
                                    <h3 className="text-white text-sm "><Skeleton width={250} count={1} /></h3>
                              </div>
                              <div className="text-left w-full flex justify-center md:pl-2 h-30">
                                    <div className="w-full pt-8 text-xs flex flex-wrap justify-evenly ">
                                       <Skeleton width={150} count={1} height={35} />
                                       <Skeleton width={150} count={1} height={35} />
                                    </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )
            })
         }    
      </>
  )
};

export default ProposalSkeletonList;