
import React from 'react'

import imagenesDetallesProject from "../../../assets/imgDetallesProject/imgDetallesProject";


import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props{
   cards:Number
}

const CardsProjectSkeleton = ({cards}:Props) => {


  return (
      <>    
         {
            Array(cards).fill(0).map((item, index) =>{
               return(
                  <div className="w-full" key={index}>
                  <div className="bg-purple-cards rounded-lg px-5 py-1 mb-3">
                      <div className="flex flex-wrap">
                          <div className="w-full">
                              <div className="pt-5">
                                  <h1 className="text-white font-bold text-base text-left sm:text-xl overflow-hidden mb-3"><Skeleton count={1} /></h1>
                                  <div className="w-full">
                                      <div className="h-10 md:h-12 py-2 overflow-hidden">
                                          <div className="flex flex-wrap">
                                             <p className="w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded p-2"><Skeleton count={1} /></p>
                                             <p className="w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded p-2"><Skeleton count={1} /></p>
                                             <p className="w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded p-2"><Skeleton count={1} /></p>                                              
                                          </div>
                                      </div>
                                  </div>
                                  <p className="text-white text-sm text-left h-14 overflow-hidden"><Skeleton count={3} /></p>
                              </div>
                          </div>
                          <div className="w-full">
                              <div className="pt-5 flex justify-end">
                                 <p className="w-full sm:w-1/2 lg:w-1/3 text-center text-white font-bold text-white rounded p-2"><Skeleton count={1} /></p>

                                  
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
               )
            })
         }    
      </>
  )
}

export default CardsProjectSkeleton