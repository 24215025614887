import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PhotoProfileSkeleton = () =>{
    return(
        <div className='-mt-1'>
            <Skeleton circle width={65} height={65} count={1} className="rounded-full object-cover" /> 
        </div>
    )
}

export default PhotoProfileSkeleton;