
// libraries
import { ChangeEvent, useEffect, useState } from "react";
import imagenesLogin from "../../assets/imgLogin/imagenesLogin";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import axios from 'axios';


// InterFaces
import { FormFreelancer } from "../../Interfaces/registerFreelancer";
import { alertLogin } from "../../Interfaces/Login";
import { InitialStateDepartment, InitialStateMunicipalities } from "../../Interfaces/departmentsAndmuniti";

// Functions
import { ValidateEmail } from "../../function/Login";
import { useNavigate, Link } from "react-router-dom";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import IconLoading from "../iconLoading/IconLoading";
import ReactSelect from "react-select";
import ReactInputMask from "react-input-mask";



// type
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>

const inititalStateFormFreelancer = {
   // email: "daniel@gmail.com",
   // names: "Eliezer Daniel",
   // lastnames: "Velasquez Rodriguez",
   // state: "1",
   // city: "1",
   // password: "",
   // password_confirmation: "",
   typeDocument: "",
   document: ""
};

const initialStateInput = {
   input: "",
   message: '',
   state: false
};

const initialStateDepartment = {
   code:0,
   id: 0,
   name: ""
};

const initialStateMunicipalities = {
   name: "",
   id: 0
};



const RegisterFreealncer = () => {

   const [registerFreelancer, setRegisterFreelancer] = useState<FormFreelancer>(inititalStateFormFreelancer);

   const [alert, setalert] = useState<alertLogin[]>([initialStateInput]);

   const MySwal = withReactContent(Swal);

   const [termsAndConditions, setTermsAndConditions] = useState<boolean | undefined>(false);

   const [sendingData, setSendingData] = useState(false);

   const navigate = useNavigate();

   // event form
   const handleLoginSession = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      let verifyValues = [
         { name: "typeDocument", value: registerFreelancer.typeDocument},
         { name: "document", value: registerFreelancer.document}
      ];

      const validarInputs = verifyValues.map((input) => {
         //Validar la mascara del input
         if (input.name === "document") {
            console.log(input.value, "input.value");
            // Verificar si tiene _ para validar si es un input con mascara
            let inputMask = input.value.includes("_");
            if (inputMask || input.value === "") {
               return { input: input.name, message: "Campo obligatorio", state: true };
            } else {
               return { input: input.name, message: "", state: false };
            }
         } else {
            if (input.value === "" || input.value === "default") {
               return { input: input.name, message: "Campo obligatorio", state: true };
            } else {
               return { input: input.name, message: "", state: false };
            }
         }
      });

      const state = validarInputs.filter(input => input.state === false).map((s) => { return false });

      setalert(validarInputs);

      if (state.length >= 2){

         if(termsAndConditions){
            sendData();
         } else {
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: 'Debes aceptar los términos y condiciones',
               showConfirmButton: false,
               timer: 5000
            });
         }
      } else {
         MySwal.fire({
            position: 'center',
            icon: 'error',
            title: 'Por favor, asegúrate de completar todos los campos.',
            showConfirmButton: false,
            timer: 5000
         });
      }
   };


   function sendData() {

      setSendingData(true);

      const url = `${process.env.REACT_APP_API_URI}registro/link-account-registro`;
      // const url = `${process.env.REACT_APP_API_URI}register/link-account-registro`;

      
      let config = {
         headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
         }
      };

      const verifyIdentity = {
         typeDocument:registerFreelancer.typeDocument,
         document:registerFreelancer.document
      };

      // console.log("Verify Identify ==> ",verifyIdentity);
      
      
      axios.post(url, verifyIdentity, config)
         .then(response => {
            console.log("response ==> ",response);
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: response.data.message,
               showConfirmButton: false,
               timer: 5000
            }).then(() => {
               navigate('/Login');
            });

            setRegisterFreelancer(inititalStateFormFreelancer);
            
            setSendingData(false);
         })
         .catch(err => {
            console.log(err);
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 5000
            });

            setSendingData(false);
         });
   };


   const handleInputChange = ({ target: { name, value, id } }: handleInputChange) => {
      // if (name === "state") getMunicipalities(value);
      if (name == "termsAndConditions") {
         const checkbox = document.getElementById("termsAndConditions",) as HTMLInputElement | null;
         const valueCheck = checkbox?.checked;
         setTermsAndConditions(valueCheck);
      };

      setRegisterFreelancer({ ...registerFreelancer, [name]: value });
   };



   return (
      <div className="w-full md:w-95% overflow-hidden">
         <div className="flex justify-center w-full">
            <h2 className="text-Yellow text-2xl md:text-3xl text-center font-semibold py-10">Verificación de perfil</h2>
         </div>
         <div className="flex justify-center items-center">
            <form className="w-full md:w-8/12 mt-5 md:mt-28 lg:mt-20" onSubmit={handleLoginSession}>
               <h6 className="text-white text-base text-center pb-4">Todos los campos son obligatorios <span className="text-red-600">*</span></h6>
               <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2 p-1">
                     <label htmlFor="typeDocument" className="text-white">Tipo de documento</label>
                     <select
                        value={registerFreelancer.typeDocument}
                        name="typeDocument"
                        onChange={handleInputChange}
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"  
                        defaultValue={'default'}
                     >
                        <option value="default" >Seleccione un tipo de documento</option>
                        <option value="1">DUI</option>
                        <option value="2">NIT</option>
                     </select>
                     {alert.filter(input => input.input == "typeDocument" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>

                  <div className="w-full md:w-1/2 p-1">
                     <label htmlFor="document" className="text-white">Documento</label>
                     <ReactInputMask
                        mask={registerFreelancer.typeDocument === "1" ? "99999999-9" : "9999-999999-999-9"}
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="document"
                        value={registerFreelancer.document}
                        onChange={handleInputChange}
                        disabled={registerFreelancer.typeDocument === "" || registerFreelancer.typeDocument === "default" ? true : false}
                     />
                     {alert.filter(input => input.input == "document" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>

              

               </div>
               {/* <div className="lg:columns-1">
                  <div className="mb-6 text-start">
                     <label htmlFor="nombreCompleto" className="text-white">Nombres</label>
                     <input
                        type="text"
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="names"
                        id="2" //do not remove ID, it is important to validate
                        value={registerFreelancer.names}
                        onChange={handleInputChange}
                     />
                     {alert.filter(input => input.input == "name" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>
                  <div className="mb-6 text-start">
                     <label htmlFor="nombreCompleto" className="text-white">Apellidos</label>
                     <input
                        type="text"
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="lastnames"
                        value={registerFreelancer.lastnames}
                        onChange={handleInputChange}
                     />
                     {alert.filter(input => input.input == "lastnames" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>
               </div>
               <div className="lg:columns-1">
                  <div className="mb-6 text-start">
                     <label htmlFor="email" className="text-white">Correo electrónico</label>
                     <input
                        type="text"
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="email"
                        value={registerFreelancer.email}
                        id="1" //do not remove ID, it is important to validate
                        onChange={handleInputChange}
                     />
                     {alert.filter(input => input.input == "gmail" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>
               </div>
               <div className="lg:columns-2">
                  <div className="mb-6 text-start">
                     <label htmlFor="departamento" className="text-white">Departamento</label>
                     <select
                        id="3" //do not remove ID, it is important to validate
                        value={registerFreelancer.state}
                        name="state"
                        onChange={handleInputChange}
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"  
                        defaultValue={'default'}
                     >
                        <option value="default" >Seleccione un departamento </option>
                        {
                           departmentlists.map((departments, key) => {
                              return <option key={key} value={departments.id}>{departments.name}</option>
                           })
                        }
                     </select>
                     {alert.filter(input => input.input == "department" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>
                  <div className="mb-6 text-start">
                     <label htmlFor="municipio" className="text-white">Municipio</label>
                     <select
                        id="4" //do not remove ID, it is important to validate
                        value={registerFreelancer.city}
                        name="city"
                        onChange={handleInputChange}
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        defaultValue={'default'}
                     >
                        <option value="default">Seleccione un municipio</option>
                        {
                           municipalities.map((municipaliti, key) => {
                              return <option key={key} value={municipaliti.id}>{municipaliti.name}</option>
                           })
                        }
                     </select>
                     {alert.filter(input => input.input == "municipality" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>
               </div>
               <div className="lg:columns-1">
                  <div className="mb-6 text-start">
                     <label htmlFor="password" className="text-white">Contraseña</label>
                     <div className="flex items-center">
                     <input
                        type={shown ? 'text' : 'password'}
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="password"
                        id="5" //do not remove ID, it is important to validate
                        value={registerFreelancer.password}
                        onChange={handleInputChange}
                     />
                     <a onClick={switchShown} className='p-2 text-Yellow cursor-pointer -ml-12'>{shown ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</a>
                     </div>
                     {alert.filter(input => input.input == "password" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>

                  <div className="mb-6 text-start">
                     <label htmlFor="confirmarPassword" className="text-white">Confirmar contraseña</label>
                     <div className="flex items-center">
                     <input
                        type={shown2 ? 'text' : 'password'}
                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="password_confirmation"
                        id="6" //do not remove ID, it is important to validate
                        value={registerFreelancer.password_confirmation}
                        onChange={handleInputChange}
                     />
                     <a onClick={switchShown2} className='p-2 text-Yellow cursor-pointer -ml-12'>{shown2 ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</a>
                     </div>
                     {alert.filter(input => input.input == "confirmPassword" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))}
                  </div>
               </div> */}
               <div className="columns-1 py-5 px-2 flex justify-start items-center">
                  <input 
                     className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none accent-pink-500  checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white checked:after:w-1"
                     type="checkbox"
                     name="termsAndConditions"
                     onChange={handleInputChange}
                     id="termsAndConditions"
                     />
                  <span className="text-white text-xs lg:text-xs xl:text-base">Acepto los <Link target="_blank" className="hover:text-Yellow" to="/terminos-de-servicio">términos y condiciones</Link></span>
                  {
                     alert.filter(input => input.input == "termsAndConditions" && input.state === true).map(message => (
                        <span className="text-red-500">{message.message}</span>
                     ))
                  }
               </div>



               <div className="columns-1">
                  <div className="text-center lg:text-left">
                        <button
                           type="submit"
                           className="inline-block px-7 py-3 bg-Yellow text-white font-medium text-sm leading-snug uppercase rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                        >
                           {!sendingData && 
                              <>Regístrate</>
                           }
                           
                           {sendingData &&  <IconLoading /> }

                        </button>
      
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};


export default RegisterFreealncer;