import { useState } from "react";
import imagenesLogin from "../assets/imgLogin/imagenesLogin";
import FormLogin from "../components/Login/formLogin";
import FormLoginEmpresa from "../components/Login/formLoginEmpresa";
import FormLoginEmployer from "../components/Login/formLoginemployer";
import logo from "../assets/imgLogin/maletin.png"

const LoginEmpresa = () =>{

  const [showTabs, setShowTabs] = useState<number>(1);

  const activeTab: string = "animate__animated animate__fadeIn";

  const [activeTabs, setActiveTabs] = useState(1);

  const HandlerButtonsTabs = (numberTabs:number) => {
    setShowTabs(numberTabs);
    setActiveTabs(numberTabs);
};

  return(
    <div className="bg-color-login overflow-auto">
      <div className="h-full text-gray-800 flex flex-wrap">
          <div className="flex justify-center lg:justify-between items-center flex-wrap h-full w-full g-6">
              <div className="xl:w-3/12 lg:w-4/12 md:w-9/12 mb-12 md:mb-0 w-full justify-center sm:justify-center md:justify-center hidden lg:block bg-gradient-to-b from-Light-purple" id="contenedorImg">
                  <img src={logo} id="img" className="" alt="" />
                  <img src={imagenesLogin.logoGrandeChambaticon} id="img" className="flex sm:flex md:flex lg:hidden mt-10 w-1/2" alt="" />
              </div>
              <div className="xl:w-9/12 lg:w-8/12 md:w-8/12 w-full mb-12 md:mb-0 pb-5 px-6">
                  <FormLoginEmpresa animation={activeTab} />
              </div>
          </div>
      </div>
    </div>
  );
}

export default LoginEmpresa;