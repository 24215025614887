
import { url } from "inspector";
import imgPerfilFreelancer from "../../assets/imgPerfilFreelancer/imgPerfilFreelancer";

import { faUsers, faEnvelope, faPhone, faLocationDot, faLanguage, faPenToSquare, faMapLocationDot, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgDefault from '../../assets/imgEditPerfil/user.png';

import { faFacebookSquare, faInstagram, faInstagramSquare, faTwitterSquare, faWhatsapp, faWhatsappSquare, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import axios from 'axios';
import Cookies from 'universal-cookie';
import { ShowInfoFreelancer, UpdatedInfoFreelancer } from "../../Interfaces/UpdatedInfoFreelancer";
import { userInfo } from "os";

import TabsInfo from '../TabsProfileFreelancers/Tabs';
import ProfileFreelancerSkeleton from "../Skeleton/ProfileFreelancer/profileFreelancer";

import {StarRating} from 'star-rating-react-ts';
import SkeletonStar from '../Skeleton/ProfileFreelancer/starsQualification';

// InitialState
const initialStateShowInfoFreelancer = {
  infoProjectFreelncer: "",
  hambaticon_projects: "",
  names: "",
  email: "",
  nickName: "",
  dateOfBirth: "",
  phone: "",
  phone_alternative: "",
  sex: "",
  dui: "",
  nit: "",
  country: "",
  department: "",
  Distrito:"",
  city: "",
  language: [],
  direction: "",
  about_me: "",
  facebook: "",
  instagram: "",
  twitter: "",
  linkedin: "",
  imagen1: "",
  imagen2: "",
  id: "",
  briefcase_link: ''
}


const FreelancerShareProfile = () => {


  // Info user
  
  const [showInfo, setShowInfo] = useState<ShowInfoFreelancer>(initialStateShowInfoFreelancer);

  const [DataUser, setDataUser] = useState<any>(undefined);

  const [IsLoading, setIsLoading] = useState(true);

  const [StatusProfileFreelancer, setStatusProfileFreelancer] = useState(0);

  const [starFree, setStarFree] = useState(0);

  const [average, setAverage] = useState('0');

  const [skeletonStar, setSkeletonStar] = useState(true);

  const themeStar = {
    colors: {
      backgroundDefault: 'lightgray',
      backgroundColorActive: '#FF9200',
      backgroundColorHover: '#FF9200',
    },
    size: 30,
  };

  //  Get info user
  const params = useParams();

  const [setIdUser, idUser] = useState();

   //  Get info user
   useEffect(() => {

    const getQualification = async (idGetQualification: undefined) =>{
      const urlQ = `${process.env.REACT_APP_API_URI}rating/freelancer/${idGetQualification}`;
        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        };
        const response = await axios.get(urlQ, config);
        
        if(response.data.length != 0)
        {
          let suma = 0;
          let promedio = 0;
  
          for (let i = 0; i < response.data.length; i++) {
            suma = parseFloat(response.data[i].rating) + suma;
          }
        
          promedio = suma / response.data.length;
          setAverage(promedio.toFixed(1));
          setStarFree(Math.trunc(promedio));
        }
        setSkeletonStar(false);
    }

    async function getInfoUserSession() {
      // const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/profile/${getSesion.id}`;
      const urlPerfilFreelancerPublic = `${process.env.REACT_APP_API_URI}freelancer/share/${params.username}`;
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      };

      
      try {
        const resp = await axios.get(urlPerfilFreelancerPublic, config);
      
        setStatusProfileFreelancer(resp.data.data.complete_profile_status);
        setDataUser(resp.data.data);

        getQualification(resp.data.data.user_id);
        setIsLoading(false);

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    
    getInfoUserSession();

    


  }, []);

  // console.log('StatusProfileFreelancer', StatusProfileFreelancer);

  // console.log('showInfo', showInfo)
  var sectionStyle = {
    backgroundImage: "url(" + `${showInfo.imagen2}` + ")"
  };



  // Cookies
  const cookies = new Cookies();
  const getSesion = cookies.get('Bearer');

  // console.log('getSesion-getSesion', getSesion);


  //   console.log('Data user',DataUser);

  if (DataUser) {

    // console.log('DataUser', DataUser);

    let nameSex = "";

    if (DataUser.sexo == 1) {
      nameSex = "Hombre";
    } else if (DataUser.sexo == 2) {
      nameSex = "Mujer";
    } else {
      nameSex = "Otro";
    }

    showInfo.names = DataUser.user[0].names + " " + DataUser.user[0].lastnames;
    showInfo.nickName = DataUser.user[0].username;
    showInfo.dateOfBirth = DataUser.age;
    showInfo.phone = DataUser.phone;
    showInfo.phone_alternative = DataUser.phone_alternative;
    showInfo.sex = nameSex;
    showInfo.country = DataUser.country.name;
    showInfo.department = DataUser.state.name;
    showInfo.city = DataUser.city.name;
    showInfo.infoProjectFreelncer = DataUser.projects_data;

    if (DataUser.languages[0] != null) {
      showInfo.language = DataUser.languages;
    } else {
      showInfo.language = [{ id: 0, name: "" }];
    }

    showInfo.direction = DataUser.direction;
    showInfo.about_me = DataUser.about_me;

    if (DataUser.social_media_links[0]) {
      showInfo.facebook = DataUser.social_media_links[0];
    } else {
      showInfo.facebook = 0;
    }

    if (DataUser.social_media_links[1]) {
      showInfo.twitter = DataUser.social_media_links[1];
    } else {
      showInfo.twitter = 0;
    }

    if (DataUser.social_media_links[2]) {
      showInfo.linkedin = DataUser.social_media_links[2];
    } else {
      showInfo.linkedin = 0;
    }

    if (DataUser.social_media_links[3]) {
      showInfo.instagram = DataUser.social_media_links[3];
    } else {
      showInfo.instagram = 0
    }

    if (DataUser.briefcase_link != null && DataUser.briefcase_link != undefined){
      showInfo.briefcase_link = DataUser.briefcase_link 
    }else {
      showInfo.briefcase_link = "0"
    }

    if (DataUser.photo != null) {
      // showInfo.imagen1            = `http://localhost:8000/freelancer/profile-img/${DataUser.photo}`;
      showInfo.imagen1 = `${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${DataUser.photo}`;
    } else {
      showInfo.imagen1 = imgDefault;
    }

    

    if (DataUser.cover_photo != null) {
      
      const nameIngBanner = DataUser.cover_photo;

      showInfo.imagen2 = <div
        id="bgbanner"
        className="w-full h-full bg-center bg-cover opacity-20"
        style={{ backgroundImage: `url(${process.env.REACT_APP_PHOTO_URI}freelancer/profile-cover/${DataUser.cover_photo})`, backgroundRepeat: "no-repeat" }}
      ></div>;

    } else {
      showInfo.imagen2 = <div className="w-full h-full bg-center bg-cover opacity-20"
        style={{ backgroundImage: "url(" + imgPerfilFreelancer.banner_a_medida + ")", backgroundRepeat: "no-repeat" }}>
      </div>;
    }

  };

  return (
    <>

      {IsLoading && <ProfileFreelancerSkeleton />}

      {!IsLoading &&
        <div className="flex">
          <div className="w-2/12 hidden lg:flex">
            <img src={imgPerfilFreelancer.fondo_login_izquierdo_sin_icono2} className="w-full" alt="" />
          </div>
          <div className="w-full lg:w-10/12 bg-turquoise">
            <main className="profile-page">
              <section className="block bg-black relative" style={{ height: "275px" }}>
                {showInfo.imagen2}
              </section>
              <section className="py-16 bg-turquoise">
                <div>
                  <div
                    className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-xl -mt-16 bg-turquoise"
                  >
                    <div className="px-6">
                      <div className="flex flex-wrap -mt-36 justify-center sm:justify-start">
                        <div>
                          <div
                            className="w-full lg:w-3/12 lg:order-2 flex"
                          >
                            <div className="relative">
                              <img
                                alt="..."
                                src={showInfo.imagen1}
                                className="rounded-full relative w-48 h-48 align-middle object-cover border-8 border-orange-500"
                                style={{ maxWidth: "190px" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center sm:text-start md:ml-5">
                          <h1 className="text-white font-bold text-2xl">{showInfo.names}</h1>
                          <h1 className="flex items-end justify-center md:justify-start">
                            {
                              skeletonStar 
                              ? 
                                <SkeletonStar />
                              :
                                <StarRating 
                                  initialRating={starFree} 
                                  readOnly={true} 
                                  theme={themeStar}
                                /> 
                            }
                            <span className="text-white">({average})</span>
                          </h1>
                          {/* <h3 className="text-white mb-2">{showInfo.nickName}</h3> */}
                          {/* <span className="text-white border rounded-full p-1 text-sm">&nbsp;DUI: {showInfo.dui}&nbsp;</span> */}
                          <div className="mt-3">

                            {(showInfo.facebook != 0 &&
                              <a href={"https://www.facebook.com/"+showInfo.facebook} target="_black">
                                <FontAwesomeIcon className="text-white text-2xl mr-4 ml-4 sm:ml-0" icon={faFacebookSquare} />
                              </a>
                            )}

                            {(showInfo.twitter != 0 &&
                              <a href={"https://twitter.com/"+showInfo.twitter} target="_black">
                                <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faTwitterSquare} />
                              </a>
                            )}

                            {(showInfo.instagram != 0 &&
                              <a href={"https://www.instagram.com/"+showInfo.instagram} target="_black">
                                <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faInstagramSquare} />
                              </a>
                            )}

                            {(showInfo.linkedin != 0 &&
                              <a href={"https://www.linkedin.com/in/"+showInfo.linkedin} target="_black">
                                <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLinkedin} />
                              </a>
                            )}

                            {(showInfo.briefcase_link != "0" &&
                              <a href={"https://www.linkedin.com/in/"+showInfo.linkedin} target="_black">
                                <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faBriefcase} />
                              </a>
                            )}


                            {/* <a href="#">
                              <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faWhatsapp} />
                            </a> */}

                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap">
                        <div className="lg:w-2/3 w-full">
                          <div className="p-4">
                            <h3 className="text-start text-orange-500 font-semibold mt-14">Acerca de</h3>
                            <p className="text-justify font-semibold text-white text-sm">{showInfo.about_me}</p>
                          </div>

                    
                          <div className="flex flex-wrap mt-5">
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faUsers} />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Edad:</h2>
                                  <h2>{showInfo.dateOfBirth} años</h2>
                                </div>
                              </div>
                            </div>
                            {/* <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faEnvelope} />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Email:</h2>
                                  <h2>{showInfo.email}</h2>
                                </div>
                              </div>
                            </div> */}
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLocationDot} />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Municipio:</h2>
                                  <h2>{showInfo.city}</h2>
                                </div>
                              </div>
                            </div>
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faMapLocationDot} />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Departamento:</h2>
                                  <h2>{showInfo.department}</h2>
                                </div>
                              </div>
                            </div>
                            {/* <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLocationDot} />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Dirección:</h2>
                                  <h2>{showInfo.direction}</h2>
                                </div>
                              </div>
                            </div> */}
                            <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon className="text-white text-2xl mr-4" icon={faLanguage} />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Idiomas:</h2>
                                  <h2>
                                        {showInfo.language.map((idioma: any, index:number) =>{

                                            let name = "";
                                          
                                            if(!( showInfo.language.length-1 == index) ){
                                              name = idioma.name +", "
                                            }else{
                                              name = idioma.name;
                                            }
                                            
                                            return name;

                                          })
                                        }
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="lg:w-1/3 w-full">
                          <div className="flex flex-wrap">
                            <div className="w-1/2 lg:w-full p-2">
                              <div className="p-4 flex bg-Light-Blue rounded-md mt-5">
                                <div className="w-full">
                                  <h1 className="text-start text-white font-semibold">Proyectos finalizados</h1>
                                  <h1 className="text-end text-white font-semibold text-3xl">{showInfo.infoProjectFreelncer.finished}</h1>
                                </div>
                              </div>
                            </div>
                            <div className="w-1/2 lg:w-full p-2">
                              <div className="p-4 flex bg-Light-Blue rounded-md mt-5">
                                <div className="w-full">
                                  <h1 className="text-start text-white font-semibold">Proyectos en proceso</h1>
                                  <h1 className="text-end text-white font-semibold text-3xl">{showInfo.infoProjectFreelncer.working}</h1>
                                </div>
                              </div>
                            </div>
                            <div className="w-1/2 lg:w-full p-2">
                              <div className="p-4 flex bg-Light-Blue rounded-md mt-5">
                                <div className="w-full">
                                  <h1 className="text-start text-white font-semibold">Proyectos aplicados</h1>
                                  <h1 className="text-end text-white font-semibold text-3xl">{showInfo.infoProjectFreelncer.applied}</h1>
                                </div>
                              </div>
                            </div>
                            {/*<div className="w-1/2 lg:w-full p-2">
                              <div className="p-4 flex bg-Light-Blue rounded-md mt-5">
                                <div className="w-full">
                                  <h1 className="text-start text-white font-semibold">Proyectos Rechazados</h1>
                                  <h1 className="text-end text-white font-semibold text-3xl">{showInfo.infoProjectFreelncer.rejected}</h1>
                                </div>
                              </div>
                            </div>*/}
                          </div>
                        </div>
                      </div>


                      {/* components tabs */}
                      <div className="mb-4 mt-4">
                        <TabsInfo idFree={DataUser.user_id} state="Public"></TabsInfo>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      }
    </>
  );
}

export default FreelancerShareProfile;