import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { ValidateEmail } from "../function/Login";
import { alertLogin } from "../Interfaces/Login";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from "universal-cookie";
import axios from "axios";
import IconLoading from "../components/iconLoading/IconLoading";
import InputTestNumer from "../components/NumerInput/NumerCInput";
import { Project, ProjectPropouse } from "../Interfaces/Project";
import SkeletonApplyPropuse from "../components/Skeleton/formApplyPropouse/SkeletonApplyProuse";

const initializeSateAplyProject = {
    budget: "",
    file: "",
    project_proposal: "",
    project_id: "",
    id_type_opportunities:4
};

const initStatePropuseNoFile = {
    budget: "",
    project_proposal: "",
    project_id: ""
};

const initialStateInput = {
    input: "",
    message: '',
    state: false
};

const initialStateProject = {
    s_types_id: "",
    project_name: "",
    user_company_id: "",
    delivery_date: "",
    dp: 0,
    description: "",
    publication_date: "",
    budget: "",
    public_info: "",
    knowledges: []
};

type handleInputChangeApluProject = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
const AplyProFree = () => {

    const params = useParams();

    const [AplyProject, setAplyProject] = useState(initializeSateAplyProject);

    const [AplyProjectNoFile, setAplyProjectNoFile] = useState(initStatePropuseNoFile);

    const [infoProject, setInfoProject] = useState<ProjectPropouse>(initialStateProject);

    const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

    const [IsLoading, setIsLoading] = useState(true);

    const [termsAndConditions, setTermsAndConditions] = useState<boolean | undefined>(false);

    const MySwal = withReactContent(Swal);

    const [sendingData, setSendingData] = useState(false);

    const [LastAmount, setLastAmount] = useState<string>("");

    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const navigate = useNavigate();

    const [characterTextLength, setCharacterTextLength] = useState(1500);

    const [lengthText, setLengthText] = useState(0);

    let fileRefProfile = null;

    useEffect(() => {
        getInfoProject();
    }, []);

    const uploadFiles = (e: { target: { files: any; }; }) => {

        let files = e.target.files;

        let image = files[0];
        fileRefProfile = image;

        let imageUrl = URL.createObjectURL(image);

        if (fileRefProfile.type === "application/pdf") {
            AplyProject.file = fileRefProfile;
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: "Solo se aceptan archivos .pdf",
                showConfirmButton: false,
                timer: 3500
            });
        };
    };

    const handleInputAplyProject = ({ target: { name, value, id, } }: handleInputChangeApluProject) => {
        if( name == 'project_proposal' ){
            setLengthText(value.length);
            if( infoProject.dp == 1){
                console.log(value.length);
                if( value.length >= 1500 ){
                    setLengthText(1500);
                    setAplyProject({ ...AplyProject, [name]: value.substring(0,1500) });
                    infoProject.description = value.substring(0,1500);
                } else {
                    setAplyProject({ ...AplyProject, [name]: value });
                }
            } else {
                if( value.length >= 1500 ){
                    setLengthText(1500);
                    setAplyProjectNoFile({ ...AplyProjectNoFile, [name]: value.substring(0,1500) });
                    infoProject.description = value.substring(0,1500);
                } else {
                    setAplyProject({ ...AplyProject, [name]: value });
                    setAplyProjectNoFile({ ...AplyProjectNoFile, [name]: value });
                }
            }
        } else {
            if (name == "termsAndConditions") {
                const checkbox = document.getElementById("termsAndConditions",) as HTMLInputElement | null;
                const valueCheck = checkbox?.checked;
                setTermsAndConditions(valueCheck);
            };
            if( infoProject.dp == 1){
                setAplyProject({ ...AplyProject, [name]: value });
            } else {
                setAplyProjectNoFile({ ...AplyProjectNoFile, [name]: value });
            }
        }
    };

    const handleAplyProject = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(infoProject.dp == 1){
            AplyProject.project_id = String(params.id);
            AplyProject.budget = LastAmount;
            let verifyValues = [
                { name: "budget", value: AplyProject.budget },
                { name: "description", value: AplyProject.project_proposal },
                { name: "documents", value: AplyProject.file },
                { name: "termsAndConditions", value: String(termsAndConditions) }
            ];
            const validateInputs = await ValidateEmail(verifyValues);
            setAlert(validateInputs);
            const state = validateInputs.filter(input => input.state === false).map((s) => { return false });
            if (state.length >= 4) sendData();
        } else { 
            AplyProjectNoFile.project_id = String(params.id);
            AplyProjectNoFile.budget = LastAmount;
            let verifyValues = [
                { name: "budget", value: AplyProjectNoFile.budget },
                { name: "description", value: AplyProjectNoFile.project_proposal },
                { name: "termsAndConditions", value: String(termsAndConditions) }
            ];
            const validateInputs = await ValidateEmail(verifyValues);
            setAlert(validateInputs);
            const state = validateInputs.filter(input => input.state === false).map((s) => { return false });
            if (state.length >= 3) sendData();
         }
    };

    const sendData = () => {
        setSendingData(true);
        const url = `${process.env.REACT_APP_API_URI}freelancer/apply-project/${getSesion.id}`;
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        }
        if(infoProject.dp == 1){
            console.log(AplyProject);
            axios.post(url, AplyProject, config)
            .then(response => {
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });
                setSendingData(false);
                setTimeout(() => {
                    navigate('/perfil-freelancer');
                }, 4000);
            })
            .catch(err => {
                console.log(err);
                setSendingData(false);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });
            })
        } else {
            console.log(AplyProjectNoFile);
            axios.post(url, AplyProjectNoFile, config)
            .then(response => {
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });
                setSendingData(false);
                setTimeout(() => {
                    navigate('/perfil-freelancer');
                }, 4000);
            })
            .catch(err => {
                console.log(err);
                setSendingData(false);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });
            })
        }
    };

    async function getInfoProject() {

        const urlProject = `${process.env.REACT_APP_API_URI}project/get/${params.id}?id_type_opportunities=4`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };
        try {
            const resp = await axios.get(urlProject, config);
            if (resp.data) {
                infoProject.s_types_id = resp.data.s_types_id;
                infoProject.project_name = resp.data.project_name;
                infoProject.delivery_date = resp.data.delivery_date;
                infoProject.dp = resp.data.accept_proposals_files;
                infoProject.description = resp.data.description;
                infoProject.publication_date = resp.data.publication_date;
                infoProject.budget = resp.data.budget.id;
                infoProject.public_info = resp.data.public_info;
                if (resp.data.knowledges_tags.length > 0) {
                    const knowledgesListDefault = resp.data.knowledges_tags.map((item: any) => {
                        return { "value": item.id, "label": item.name }
                    });
                    const getknowledgesList = resp.data.knowledges_tags.map((item: any) => {
                        return item.id
                    });
                }
                setIsLoading(false);
            }
            console.log(resp.data);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <div className="bg-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                        <img className="opacity-50 py-10 " src={imagenesEditProject.logo_blanco_mitad} alt="" />
                    </div>
                    <div className="md:w-full lg:w-4/5 flex flex-wrap justify-start pb-5 lg:py-5">
                    <div className="w-full sm:w-20 md:w-20 pl-2 -m-2  mt-4">
                <Link to="" onClick={() => window.history.go(-1)}>
                    <img src={imagenesEditProject.arrow_back_4} className="w-15% md:w-full mx-4 sm:w-auto" alt="" />
                </Link>
            </div>
                        <div className="w-full md:w-90% flex justify-center">
                            <form className="w-11/12 lg:w-70%" onSubmit={handleAplyProject}>
                                <div className="flex justify-center items-center pb-0 lg:pb-12">
                                    <img src={imagenesEditProject.logo} alt="chambaticon" className="w-10/12 md:w-55% my-4 lg:my-5 sm:my-10 md:my-10" />
                                </div>
                                {IsLoading
                                    ?
                                    <SkeletonApplyPropuse />
                                    :
                                    <>
                                        <div className="text-center">
                                            <h1 className="text-white font-bold text-xl sm:text-3xl my-4">Aplicando al proyecto:</h1>
                                            <p className="font-bold text-xl sm:text-3xl my-4 text-Yellow">
                                                {infoProject.project_name}
                                            </p>
                                        </div>

                                        <div className="lg:columns-1">
                                            <div className="mb-6 text-start">
                                                <label htmlFor="nombreCompleto" className="text-white mb-8">Monto a cobrar ($USD)</label>
                                                <InputTestNumer
                                                    value={LastAmount}
                                                    onChange={(e) => setLastAmount(e.target.value)}
                                                    className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                                    name="budget"
                                                    id="2"
                                                    placeholder="$0.00"
                                                />
                                                {
                                                    alert.filter(input => input.input == "budget" && input.state === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        { infoProject.dp == 1 &&
                                            <div className="columns-1">
                                                <div className="mb-6 text-start btn-editpro w-full">
                                                    <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Adjuntar documento/s complementario</label>
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        onChange={uploadFiles}
                                                        className="btn-editpro form-control block w-full mt-2 pr-4 text-xs md:text-lg font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                                    />
                                                    {
                                                        alert.filter(input => input.input == "documents" && input.state === true).map((message, key) => (
                                                            <span key={key} className="text-red-500">{message.message}</span>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }

                                        <div className="lg:columns-1">
                                            <div className="mb-6 text-start">
                                                <label htmlFor="direction" className="text-white">Describe tu propuesta </label>
                                                <textarea
                                                    className="form-control block w-full px-4 py-2 mt-2 text-base font-normal text-white bg-clip-padding rounded transition ease-in-out m-0 focus:outline-none"
                                                    rows={17}
                                                    name="project_proposal"
                                                    placeholder="Tu mensaje..."
                                                    value={AplyProject.project_proposal}
                                                    onChange={handleInputAplyProject}
                                                >
                                                </textarea>
                                                <div className="text-[rgba(500,100,0,0.5)] italic">{lengthText} / {characterTextLength}</div>
                                                {
                                                    alert.filter(input => input.input == "description" && input.state === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))
                                                }
                                            </div>
                                        </div>


                                        <div className="columns-1 py-5 px-2 flex justify-start items-center">
                                            <input
                                                className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none accent-pink-500  checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white checked:after:w-1"
                                                type="checkbox"
                                                name="termsAndConditions"
                                                onChange={handleInputAplyProject}
                                            />

                                            <span className="text-white text-xs lg:text-xs xl:text-base">Acepto los <Link to={'/terminos-de-servicio'} target="_blank" className="hover:text-Yellow">términos y condiciones</Link></span>
                                            {
                                                alert.filter(input => input.input == "termsAndConditions" && input.state === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>

                                        <div className="columns-1 flex justify-center">
                                            <div className="text-center py-12 lg:text-left w-full md:w-1/2">
                                                <button
                                                    type="submit"
                                                    className="inline-block px-7 py-3 bg-Yellow text-white font-medium text-base leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                                                >
                                                    {!sendingData &&
                                                        <>
                                                            Enviar propuesta
                                                        </>
                                                    }

                                                    {sendingData &&
                                                        <IconLoading />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default AplyProFree;
