import imgs from "../../assets/imgDetallesProject/imgDetallesProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXmark, faDownload} from "@fortawesome/free-solid-svg-icons";
import axios, { Axios } from "axios";

import { useState } from "react";
import { ProjectInfo } from "../../Interfaces/InfoProyect";
import { Link } from "react-router-dom";

import imgDefault from '../../assets/imgEditPerfil/user.png';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


import fileDownload from "js-file-download";
import ProjectInfoSkeleton from "../Skeleton/modal/infoProyectoSkeleton";
import IconLoading from "../iconLoading/IconLoading";
import IconLoadingWhite from "../iconLoading/IconLoadingWhite";
import IconLoadingModal from "../iconLoading/IconLoadingModal";


const initialState = {
    assigned_status: 0,
    company_name: "",
    photo:"",
    budget: "",
    created_at: "",
    delivery_date: "",
    description: "",
    end_date: "",
    file_folder_path: "",
    freelance_id: 0,
    id: 0,
    knowledges_tags: [],
    project_name: "",
    public_info: 0,
    publicationStatus: 0,
    publication_date: "",
    s_types_id: 0,
    status: 0,
    updated_at: "",
    user_company_id: 0
}

interface Props {
    addClass:string;
    infoProject:ProjectInfo;
    IsLoading:boolean;
    HandleModal: (state:Boolean, id?:Number) => void
 }

const Modal =  ({addClass, HandleModal, infoProject, IsLoading}:Props) =>{


    const [sendingData, setSendingData] = useState(false);

    const MySwal = withReactContent(Swal);

    // console.log('sendingData', sendingData);
    const handleClick = (url:string, filename:string) =>{
        
        setSendingData(true);
        axios({
            url:url,
            method:"GET",
            responseType:'blob'
        }).then(res =>{
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setSendingData(false);

        }).catch(function (error){
            setSendingData(false);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error, al descargar el documento, vuelve a intentar más tarde.',
                showConfirmButton: false,
                timer: 3500
            });
        })
    }

    const closeModal = () => {
        document.getElementsByTagName("body")[0].style.overflow = "visible";
     };

    return(
        <>


            <div className={`${addClass} modal  bg-black bg-opacity-50 fade fixed top-0  mx-auto pb-96 pt-20 px-1 sm:px-10 xl:px-14 2xl:px-52 w-full h-full outline-none overflow-x-hidden overflow-y-scroll justify-center" id="exampleModalXl" tabIndex={-1} aria-labelledby="exampleModalXlLabel" aria-modal="true" role="dialog`}>
                <div className="modal-dialog modal-xl relative w-auto pointer-events-none">
                    <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-Dark-purple bg-clip-padding rounded-md outline-none text-current">

                        <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
                            <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalXlLabel"></h5>
                        
                            <button type="button"
                                className="p-1 btn-close box-content text-white text-4xl border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-red-400 hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal" aria-label="Close" onClick={() => HandleModal(false)}><FontAwesomeIcon icon={faXmark}/>
                            </button>
                            
                        </div>
                        <div className="modal-body relative py-4 px-8 xl:px-24">

                            {IsLoading && <ProjectInfoSkeleton /> }
                          

                            {!IsLoading && 
                                <div className="flex flex-wrap">
                                <div className="w-full md:w-30% lg:w-3/12">
                                    <div className=" flex justify-center mb-8">
                                        <div className="w-48 h-48 overflow-hidden">

                                            {(infoProject.photo == null) ? (
                                                
                                                <img src={imgDefault} className="rounded-full relative w-48 h-48 align-middle object-cover border-8 border-orange-500" alt="" />

                                                ) : (
                                                
                                                <img src={`${process.env.REACT_APP_PHOTO_URI}company-image/${infoProject.photo}`} className="rounded-full relative w-48 h-48 align-middle object-cover border-8 border-orange-500" alt="" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">

                                    {(infoProject.knowledges_tags.length != 0 || infoProject.knowledges_tags.length != null) && 
                                        infoProject.knowledges_tags.map((know:any) =>{
                                            return(
                                                <div 
                                                    className="bg-Light-Blue w-47% m-px flex items-center justify-center px-px text-center text-white rounded py-1.5 mb-4 text-xs md:text-2xs lg:text-xs xl:text-base">
                                                        {know.name}
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                                <div className="w-full md:w-70% lg:w-9/12 md:pl-12 relative">
                                    <h1 className="text-white text-lg md:text-4xl">{infoProject.company_name}</h1>
                                    <h2 className="text-Yellow text-base md:text-3xl py-4">{infoProject.project_name}</h2>
                                    <div className="items-center py-4">
                                        <p className="text-white text-base md:text-xl">Fecha de entrega: 
                                        <br className="block md:hidden"></br><span className="text-Yellow">{infoProject.delivery_date}</span></p>
                                    </div>
                                    <h2 className="text-white text-base md:text-3xl py-4">Presupuesto: <span className="text-Yellow">{infoProject.budget.range}</span></h2>
                                    <p className="text-white text-bse md:text-lg mb-8 md:mb-24">{infoProject.description}</p>
                                    <div className="bottom-0 right-0 w-full lg:w-4/5 sm:absolute sm:flex justify-end mt-2 mb-2">

                                 

                                            <>
                                                {infoProject.accept_proposals_files == 1 && 
                                                    <Link 
                                                    onClick={() => handleClick(`${process.env.REACT_APP_PHOTO_URI}company-file/project-file/${infoProject.id}`, `${infoProject.company_project_file}`) }
                                                    className="lg:px-7 py-2 my-2 sm:my-0 bg-purple-button hover:opacity-0.5 text-white font-medium text-sm leading-snug rounded shadow-md hover:bg-fuchsia-700 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full sm:w-4/12 h-2/4 flex justify-center sm:justify-around sm:ml-3" to={""}>
                                                        
                                                        {sendingData && 
                                                            <>
                                                                {/* <IconLoading /> */}
                                                                {/* <IconLoadingWhite /> */}
                                                                <IconLoadingModal />
                                                            </>
                                                            
                                                        }

                                                        {!sendingData && 
                                                            <>
                                                                <FontAwesomeIcon className="px-1 sm:px-0" icon={faDownload} /> 
                                                                Descargar adjunto
                                                            </>
                                                        }
                                            
                                                    </Link>
                                                }
                                            </>
                                        

                                        <Link to={`/enviar-propuesta/${infoProject.id}`} onClick={closeModal}
                                        className="inline-block lg:px-7 py-2 my-2 sm:my-0 bg-Yellow text-white font-medium text-sm leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full sm:w-4/12 h-2/4 text-center sm:ml-3">
                                            Aplicar proyecto
                                        </Link>

                                    </div>
                                </div>
                            </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal;