import React, { useState } from 'react';
import './StarRating.scss';
import { Star } from './Star';

declare global {
  interface StarTheme {
    colors?: {
      backgroundColorActive?: string;
      backgroundColorHover?: string;
      backgroundDefault?: string;
    },
    size?: number,
  }
}

interface StarRatingProps {
  numStars?: number;
  initialRating?: number
  onClick?: (rating: number) => unknown;
  theme?: StarTheme;
  readOnly?: boolean;
  icon?: JSX.Element
}

export const StarRating: React.FC<StarRatingProps> =
    ({
       numStars = 5,
       initialRating = 0,
       onClick: clickCallback,
       theme,
       readOnly = false,
       icon,
     }): JSX.Element => {
      if (numStars < 1) {
        throw(new Error(`Expected a value of at least 1, received ${numStars}`))
      }

      if (numStars < initialRating) {
        throw(new Error(`The provided rating of ${initialRating} exceeds the maximum ${numStars} stars displayed`))
      }

      const [numStarsActive, setNumStarsActive] = useState<number>(initialRating);
      const [curRating, setCurRating] = useState<number>(initialRating);
      const [isHovering, setIsHovering] = useState<boolean>(false);

      const handleMouseEnterStar = (rating: number): void => {
        setNumStarsActive(rating);
        setIsHovering(true);
      };

      const handleMouseLeave = (): void => {
        setNumStarsActive(curRating);
        setIsHovering(false);
      };

      const handleClickStar = (e: React.MouseEvent<HTMLButtonElement>, rating: number): void => {
        (e.target as HTMLButtonElement).blur();
        setCurRating(rating);
        setIsHovering(false);
        setNumStarsActive(rating);
        clickCallback?.(rating);
      };

      const stars: JSX.Element[] =
          Array.from({length: numStars}, (_, idx) => (
              <Star
                  onEnter={handleMouseEnterStar}
                  onClick={handleClickStar}
                  isStarActive={idx < numStarsActive}
                  rating={idx + 1}
                  key={`star-${idx}`}
                  isHovering={isHovering}
                  theme={theme}
                  readOnly={readOnly}
                  icon={icon}
              />
          ));

      return (
          <section
              data-testid="star-rating"
              onMouseLeave={handleMouseLeave}
              className="starRating">
            {stars}
          </section>
      );
    };