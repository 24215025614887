import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';

import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function InformacionPlaza() {

    const id_internships = useParams();
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    // Cookies
    const cookies = new Cookies();
    const getSesion = cookies.get("Bearer");

    const initialStateJob = {
        application_deadline:"",
        created_at:"",
        description:"",
        end_date:"",
        id:"",
        id_company:"",
        id_type_contract:"",
        id_type_job_vacancy:"",
        id_type_opportunities:"",
        id_type_schedule:"",
        id_work_modality:"",
        job_title:"",
        location:"",
        salary:"",
        schedules:"",
        start_date:"",
        status:"",
        updated_at:"",
    };

    const [job, setJob] = useState(initialStateJob);

    const applyJob = () => {
        MySwal.fire({
            title: "¿Está seguro?",
            text: "Sí, deseo enviar mi perfil para esta pasantía",
            color: "#fff",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Enviar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) sendData();
        });
    };

    const sendData = () => {

        const url = `${process.env.REACT_APP_API_URI}freelancer/apply-project/${getSesion.id}`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        }

        const newData = {
            budget: "",
            id_type_opportunities: 2,
            project_id: id_internships.id,
            project_proposal: "",
        };

        console.log("newData ==>", newData);

        axios.post(url, newData, config)
            .then(response => {
                MySwal.fire({
                    title: "¡Completado!",
                    text: "Tu perfil ha sido enviado con éxito! En los próximos días recibirás una notificación sobre el proceso a través de tu correo electrónico.",
                    color: "#fff",
                    icon: "success"
                });

                setTimeout(() => {
                    navigate('/perfil-freelancer');
                }, 5000);
            })
            .catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });
            })

    };

    const typeJob = ["","Fija", "Temporal"];
    const typeOfContract = ["", "Tiempo completo", "Tiempo parcial"];
    const typeOfSchedule = ["", "Fijo", "Rotativo"];
    const modality = ["", "Presencial", "Remoto", "Híbrido"];

    useEffect(() => {

        const InfoJob = async () => {

            let urlListOpportunity = `${process.env.REACT_APP_API_URI}project/get/${id_internships.id}?id_type_opportunities=2`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${getSesion.token}`
                }
            };

            try {
                const resp = await axios.get(urlListOpportunity, config);
                const getInfoJob = resp.data;
                console.log("job ==>", getInfoJob);
                setJob(getInfoJob);

            } catch (err) {
                console.error(err);
            };
        };
        InfoJob();

    }, []);

    return (
        <div className="bg-turquoise">
            <section className="block -mt-px md:flex md:flex-wrap">

                <div className="md:w-full lg:w-5/5 flex flex-wrap justify-center pb-5 lg:py-5 md:pt-4 lg-pt-0">

                    <div className="w-full md:w-full flex justify-center">
                        <div className="w-11/12">

                            <div className="flex justify-start pb-0 lg:pb-4 py-4">
                                <Link to="" onClick={() => window.history.go(-1)} className='w-16 h-16'>
                                    <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                                </Link>
                                <h1 className="text-Yellow text-center font-bold text-6xl w-full">Plaza</h1>
                            </div>

                            <div className="flex flex-col ">

                                <h1 className="w-full text-left my-4 text-white lg:text-4xl md:text-3xl sm:text-3xl xs:text-xl">
                                    {job.job_title}
                                </h1>

                                <div className="xl:w-full lg:w-full md:w-full sm:w-full xs:w-full mx-auto flex flex-col gap-4 items-center relative">

                                    <p className="w-fit text-white dark:text-gray-400 text-md">
                                        {job.description}
                                    </p>



                                    <div className="w-full my-auto py-6 flex flex-col justify-center gap-2">
                                        <div className="w-full flex sm:flex-row xs:flex-col gap-2 justify-center">

                                            <div className="w-full">
                                                <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">

                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Tipo de plaza</dt>
                                                        <dd className="text-lg text-white">{typeJob[job.id_type_job_vacancy]}</dd>
                                                    </div>
                                                   
                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Tipo de contrato </dt>
                                                        <dd className="text-lg text-white">{typeOfContract[job.id_type_contract]}</dd>
                                                    </div>
                                                   
                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Tipo de horario </dt>
                                                        <dd className="text-lg text-white">{typeOfSchedule[job.id_type_schedule]}</dd>
                                                    </div>
                                                    
                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Modalidad </dt>
                                                        <dd className="text-lg text-white">{modality[job.id_work_modality]}</dd>
                                                    </div>

                                                    {
                                                        (job.id_type_schedule == "1") ? (
                                                        <div className="flex flex-col pb-3">
                                                            <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Horario de trabajo
                                                            </dt>
                                                            <dd className="text-lg text-white">{job.schedules}</dd>
                                                        </div>
                                                        ):(
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        (job.id_work_modality == "1" || job.id_work_modality == "3") ? (
                                                        
                                                        <div className="flex flex-col pb-3">
                                                            <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">
                                                                Ubicación
                                                            </dt>
                                                            <dd className="text-lg text-white">{job.location}</dd>
                                                        </div>

                                                        ):(
                                                            <></>
                                                        )
                                                    }

                                                </dl>
                                            </div>

                                            <div className="w-full">
                                                <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">

                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Salario</dt>
                                                        <dd className="text-lg text-white">${job.salary}</dd>
                                                    </div>
                                                   
                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Fecha de inicio</dt>
                                                        <dd className="text-lg text-white">{job.start_date}</dd>
                                                    </div>
                                                    
                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Fecha de finalización</dt>
                                                        <dd className="text-lg text-white">{job.end_date}</dd>
                                                    </div>
                                                   
                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Fecha limite de aplicación</dt>
                                                        <dd className="text-lg text-white">{job.application_deadline}</dd>
                                                    </div>



                                                    <div className="flex justify-left ">
                                                        <a onClick={() => applyJob()} className="w-1/2 text-center rounded-lg bg-Yellow text-white border-0 py-4 px-3 lg:text-base md:text-sm mt-4">
                                                            Enviar mi perfil
                                                        </a>
                                                    </div>


                                                </dl>
                                            </div>

                                        </div>

                                        {/* <div className="my-10 lg:w-full xs:w-full h-full">

            <h1 className="w-fit my-4 pb-1 pr-2 rounded-b-md border-b-4 border-blue-600 dark:border-b-4 dark:border-yellow-600 dark:text-white lg:text-4xl md:text-3xl xs:text-xl">My Location</h1>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d252230.02028974562!2d38.613328040215286!3d8.963479542403238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85cef5ab402d%3A0x8467b6b037a24d49!2sAddis%20Ababa!5e0!3m2!1sen!2set!4v1710567234587!5m2!1sen!2set"
                className="rounded-lg w-full h-full">
            
            </iframe>

        </div> */}
                                    </div>




                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default InformacionPlaza;