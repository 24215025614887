import axios from 'axios';
import React, { useEffect, useState } from 'react'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';



function NewViemProjects(props) {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const initialStateBudget = {
        id: "",
        range: ""
    };
    const [listBudget, setListBudget] = useState([initialStateBudget]);

    const initialStateListSpecialization = {
        created_at: "",
        id: 0,
        name: "",
        updated_at: ""
    };
    const [specializations, setSpecializations] = useState([initialStateListSpecialization]);



    useEffect(() => {


        async function getBudget() {

            const urlBudget = `${process.env.REACT_APP_API_URI}budget`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };

            try {
                const resp = await axios.get(urlBudget, config);
                setListBudget(resp.data);
            } catch (err) {
                console.error(err);
            }
        };
        getBudget();

        async function getSpecialties() {

            const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };
            try {

                const resp = await axios.get(urlCountry, config);
                setSpecializations(resp.data.specializationTypes);

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        getSpecialties();
    }, [])



    // const typeInternshi = (param) => {
    //     const id_type_internshi = param;
    //     const typeInternshi = ["", "Remunerada", "No remunerada"];
    //     return typeInternshi[id_type_internshi];
    // };

    return (
        <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-col w-full mb-12">
                <h1 className="text-white font-bold text-2xl sm:text-3xl">Proyectos</h1>
            </div>
            <div className="w-full mx-auto">
                <Carousel
                    responsive={responsive}
                    showDots={true}
                    arrows={false}
                    autoPlay={true}
                    infinite={true}
                    autoPlaySpeed={2000}
                    dotListClass="custom-dot-list-style"
                >

                    {
                        props.data.map((project) => {

                            return (
                                <div className="px-4 lg:w-full">


                                    <div className="max-w-sm p-6 bg-purple-button border border-purple-button rounded-lg shadow">
                                        <a href="#">
                                            <h5 className="mb-2 text-xl font-bold tracking-tight text-Yellow">
                                                {project.project_name}
                                            </h5>
                                        </a>
                                        <p className="mb-3 font-normal text-white text-justify">
                                            {project.description.substring(0, 115)} ...

                                        </p>
                                        <div className="pb-2">

                                            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                                {(project.public_info == "1") ? (
                                                    <>Nombre empresa</>
                                                ) : (
                                                    <></>
                                                )}
                                            </span>

                                            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                                {
                                                    specializations.map((specialization) => {
                                                        if (specialization.id == project.s_types_id) {
                                                            return (
                                                                specialization.name
                                                            )
                                                        }
                                                    })
                                                }
                                            </span>

                                            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                                Entrega: {project.delivery_date}

                                            </span>

                                            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                                {
                                                    listBudget.map((Budget) => {
                                                        if (Budget.id == project.budget_id) {
                                                            return (
                                                                Budget.range
                                                            )
                                                        }
                                                    })
                                                }
                                            </span>

                                        </div>

                                        {(props.type_user_session === 3) ? (
                                                <Link to={`/informacion-proyecto/${project.id}`} className="flex justify-end">
                                                    <button
                                                        className="flex items-center gap-2 px-6 py-3 text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none bg-gray-900/10 active:bg-gray-900/20 cursor-pointer"
                                                        type="button">
                                                        Ver más
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                            stroke-width="2" className="w-4 h-4">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
                                                        </svg>
                                                    </button>
                                                </Link>
                                            ) : (
                                                <></>
                                        )}

                                    </div>
                                </div>
                            )

                        })
                    }


                </Carousel>
            </div>
        </div>

    );


}

export default NewViemProjects;