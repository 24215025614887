
import React from 'react'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const UpdatedProjectSkeleton = () => {
  return (


   <form className="w-11/12 " >
      <br /><br /><br /><br />
   <div className="flex justify-start pb-0 lg:pb-4">
   <h1 className="text-Yellow font-bold text-3xl"><Skeleton count={1} /></h1>
   </div>

   <div className="bg-bluecard-big p-4 rounded">
   <h1 className="text-Yellow font-bold text-3xl text-left mb-8"><Skeleton count={1} /></h1>
   <div className="flex flex-wrap">
      <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
         <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base"><Skeleton count={1} /></label>
         
         <Skeleton count={1} />
      </div>
      <div className="mb-6 text-start btn-editpro w-full md:w-1/2 lg:w-2/3">
         <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base"><Skeleton count={1} /></label>
         
         <Skeleton count={1} />
      </div>
   </div>
   <div className="flex flex-wrap">
      <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
         <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base"><Skeleton count={1} /></label>
         
         <Skeleton count={1} />
      </div>
      <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
         <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base"><Skeleton count={1} /></label>
         
      </div>
      <div className="mb-6 text-start md:pr-2 w-full md:w-1/2 lg:w-1/3">
         
      </div>
   </div>
   <div className="flex flex-wrap">
      <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
         <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base"><Skeleton count={1} /></label>
         
         <Skeleton count={1} />
      </div>
      <div className="mb-6 text-start btn-editpro w-full md:w-1/2 lg:w-2/3">
         <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base"><Skeleton count={1} /></label>
         
         <Skeleton count={1} />
      </div>
   </div>
   <div className="text-justify md:text-left">
      <div>
         <label htmlFor="" className="text-white lg:text-sm xl:text-base"><Skeleton count={1} /></label>
      </div>
 
      </div>
   </div>


   </form>

  )
}

export default UpdatedProjectSkeleton;