import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faDownload } from "@fortawesome/free-solid-svg-icons";
import axios, { Axios } from "axios";

import { useState } from "react";
import { infoProposal } from "../../Interfaces/infoProposal";
import { Link } from "react-router-dom";

import imgDefault from '../../assets/imgEditPerfil/user.png';


import fileDownload from "js-file-download";
import ProjectInfoSkeleton from "../Skeleton/modal/infoProposalSkeleton";
import IconLoading from "../iconLoading/IconLoading";
import IconLoadingWhite from "../iconLoading/IconLoadingWhite";
import IconLoadingModal from "../iconLoading/IconLoadingModal";

import SkeletonStar from '../../components/Skeleton/ProfileFreelancer/starsQualification';
import { StarRating } from 'star-rating-react-ts';

import Cookies from "universal-cookie";


interface Props {
    addClass: string;
    IsLoading: boolean;
    HandleModal: (state: Boolean) => void
    info: infoProposal
}

const cookies = new Cookies();

const getSesion = cookies.get('Bearer');

const ModalFreelancerPropuse = ({ info, addClass, HandleModal, IsLoading }: Props) => {

    const [starFree, setStarFree] = useState(0);

    const [average, setAverage] = useState('0');

    const [skeletonStar, setSkeletonStar] = useState(true);

    const themeStar = {
        colors: {
            backgroundDefault: 'lightgray',
            backgroundColorActive: '#FF9200',
            backgroundColorHover: '#FF9200',
        },
        size: 30,
    };

    const getQualification = async () => {
        const urlQ = `${process.env.REACT_APP_API_URI}rating/freelancer/${info.id_freelance}`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };
        const response = await axios.get(urlQ, config);
        
        if (response.data.length != 0) {
        
            let suma = 0;
            let promedio = 0;

            for (let i = 0; i < response.data.length; i++) {
                suma = parseFloat(response.data[i].rating) + suma;
            }
        
        
            promedio = suma / response.data.length;
            setAverage(promedio.toFixed(1));
            setStarFree(Math.trunc(promedio));
        
        };
        
        setSkeletonStar(false);
    }

    getQualification();

    return (
        <>


            <div className={`${addClass} modal  bg-black bg-opacity-50 fade fixed top-0  mx-auto pb-96 pt-20 px-1 sm:px-10 xl:px-14 2xl:px-52 w-full h-full outline-none overflow-x-hidden overflow-y-scroll justify-center" id="exampleModalXl" tabIndex={-1} aria-labelledby="exampleModalXlLabel" aria-modal="true" role="dialog`}>
                <div className="modal-dialog modal-xl relative w-auto pointer-events-none">
                    <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-Dark-purple bg-clip-padding rounded-md outline-none text-current">

                        <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
                            <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalXlLabel"></h5>

                            <button type="button"
                                className="p-1 btn-close box-content text-white text-4xl border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-red-400 hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal" aria-label="Close" onClick={() => HandleModal(false)}><FontAwesomeIcon icon={faXmark} />
                            </button>

                        </div>
                        <div className="modal-body relative py-4 px-8 xl:px-24">

                            {IsLoading && <ProjectInfoSkeleton />}


                            {!IsLoading &&
                                <div className="flex flex-wrap">
                                    <div className="w-full md:w-30% lg:w-3/12">
                                        <div className=" flex justify-center mb-8">
                                            <div className="w-52 h-60 overflow-hidden">

                                                {(info.photo == null) ? (

                                                    <img src={imgDefault} className="rounded-full relative w-48 h-48 align-middle object-cover border-8 border-orange-500" alt="" />

                                                ) : (

                                                    <img src={`${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${info.photo}`} className="rounded-full relative w-48 h-48 align-middle object-cover border-8 border-orange-500" alt="" />
                                                )}

                                                <div className="flex py-4 items-center justify-center ">
                                                    {
                                                        skeletonStar
                                                            ?
                                                            <SkeletonStar />
                                                            :
                                                            <StarRating
                                                                initialRating={starFree}
                                                                readOnly={true}
                                                                theme={themeStar}
                                                            />
                                                    }
                                                    <span className="text-white">({average})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-70% lg:w-9/12 md:pl-12 relative">
                                        <h1 className="text-white text-lg md:text-4xl">Propuesta</h1>
                                        <h1 className="text-white text-basic md:text-xl">{info.names} {info.lastanames}</h1>
                                        <p className="text-Yellow text-base md:text-3xl py-4 whitespace-pre-line">{info.description}</p>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalFreelancerPropuse;

function useEffect(arg0: () => void, arg1: never[]) {
    throw new Error("Function not implemented.");
}
