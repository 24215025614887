import { isAfter, isBefore, isEqual } from "@formkit/tempo"

export const validateData = (data) =>{
    let errors = [];
    // console.log(data, "Data a validar");
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    let hoy = `${year}-${month < 10 ? '0'+month : month}-${today.getDate() < 10 ? '0'+today.getDate() : today.getDate()}`

    let fechaInicio = "";
    let fechaFin = "";

    // console.log(hoy, "Fecha actual");

    data.map((item) => {
        if(item.value === '' || item.value === undefined || item.value === null || item.value === 0){
            errors.push({
                input: item.name,
                message: "Campo requerido",
                status: true
            });
            return;
        }

        if (item.name === 'dateInit') {

            // console.log(item.value, "Fecha de inicio");

            fechaInicio = item.value;
            
            if(isBefore(item.value, hoy)){ 
                errors.push({
                    input: item.name,
                    message: "La fecha de inicio debe ser mayor o igual a la fecha actual",
                    status: true
                });
            }
        }  

        if (item.name === "dateLimitToApply") {
            if(isAfter(item.value, fechaInicio) || isEqual(item.value, fechaInicio)){
                if (fechaInicio !== ""){
                    errors.push({
                        input: item.name,
                        message: "La fecha límite para aplicar debe ser menor a la fecha de inicio",
                        status: true
                    });
                }
            }
            if (isBefore(item.value, hoy)) {
                errors.push({
                    input: item.name,
                    message: "La fecha límite para aplicar debe ser mayor o igual a la fecha actual",
                    status: true
                });
            }
        }

        if(item.name === 'dateEnd'){
            fechaFin = item.value;
            if(isBefore(item.value, fechaInicio)){
                errors.push({
                    input: item.name,
                    message: "La fecha de fin debe ser mayor o igual a la fecha de inicio",
                    status: true
                });
            }
            return;
        }

        if(item.name === 'start_year' || item.name === 'end_year'){
            if(item.value > year ){
                // console.log("Años validados");
                errors.push({
                    input: item.name,
                    message: "El año debe ser menor",
                    status: true
                });
            }
            return;
        }

        if(Array.isArray(item.value)){
            if(item.value.length === 0){
                errors.push({
                    input: item.name,
                    message: "Debe seleccionar al menos una opción",
                    status: true
                });
            }
            return;
        }
        
        errors.push({
            input: item.name,
            message: "",
            status: false
        });
        return;
    });
    return errors;
}