
import React, { useEffect, useState } from 'react';
// css
import './App.css';

import Cookies from 'universal-cookie';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
// Components
import './css/main.css';
import { HandleSession } from './function/Login';
import SessionContentChambaticon from './components/ProtectedRoute/contenText';
import CookieConsent from 'react-cookie-consent';
import RouterApp from './components/Router/Routers';


function App() {

  const [sessionContect, setsessionContect] = useState({ session: false });
  
  const cookies = new Cookies();   
  
  const getSession = cookies.get('Bearer');

  let sessionChamabticon;

  if(getSession){
  
    sessionChamabticon = { session: true };
  
  }else{
    
    sessionChamabticon = { session: false };

  };

  return (
    <div id="App" className='wrapper'>

      <SessionContentChambaticon.Provider value={sessionChamabticon} >
        <RouterApp />
        <CookieConsent
          location="bottom"
          buttonText="Aceptar"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#057dad" }}
          buttonStyle={{ color: "white", fontSize: "13px", backgroundColor: "#ee9218" }}
          expires={150}
        >
          <h1 style={{ textAlign: "center" }}>Utilizamos cookies para optimizar nuestro sitio web y nuestro servicio.</h1>
        </CookieConsent>
      </SessionContentChambaticon.Provider>
    </div>
  );
}

export default App;
