import { alertLogin, InputForm } from "../Interfaces/Login";

export const ValidateForm = (data: InputForm[]) => {

    let errors: alertLogin[] = [];

    const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const regExUrl = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;

    console.log(data);

    data.map((input) => {

        switch (input.typeValidate) {

            case 'str-r': {
                if (input.value === '' || input.value === null) {
                    errors.push({
                        input: input.name,
                        message: 'Campo requerido',
                        state: true
                    });
                } else {
                    errors.push({
                        input: input.name,
                        message: '',
                        state: false
                    })
                }
                break;
            }
            case 'int-r': {
                if ( input.value === 0 || input.value === null ) {
                    errors.push({
                        input: input.name,
                        message: 'Campo requerido',
                        state: true
                    });
                } else {
                    errors.push({
                        input: input.name,
                        message: '',
                        state: false
                    })
                }
                break;
            }
            default: {
                errors.push({
                   input:"requeridos",
                   message:'Campos requeridos',
                   state:true
                });
                break;
            }
        }
    })
    console.log(errors,"8888888");
    return errors;
}