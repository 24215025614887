import imagenesEditPerfil from "../assets/imgEditPerfil/imagenesEditPerfil.js";
import EditProfileFreelancer from "../components/EditProfilefreelancer/EditInfofreelancer";


const EditPerfil = () =>{


    return(
    <>
        <div className="-mt-5">
            <section className="block md:flex md:flex-wrap bg-gradient-to-b from-Light-purple via-turquoise to-turquoise">
                   <EditProfileFreelancer></EditProfileFreelancer>              
            </section>
        </div>
    </>
    )
}

export default EditPerfil;