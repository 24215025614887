import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import { faPenToSquare, faFolderPlus, faFilePen, faMinus } from "@fortawesome/free-solid-svg-icons";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


interface Props {
   animation: String;
   idFreeRouter: String | undefined;
   state: String | undefined;
}

const initialStateStudies = {
   code: "",
   country_name: "",
   emision_year: "",
   institution: "",
   state_name: "",
   study_degree: "",
   external_origin:"",
   study_level: "",
   id: ""
}

const OtherStudies = ({ animation, idFreeRouter, state }: Props) => {

   const MySwal = withReactContent(Swal);


   const [Studies, setStudies] = useState([initialStateStudies]);

   const [IsLoading, setIsLoading] = useState<Boolean>();


   // Cookies
   const cookies = new Cookies();

   const getSesion = cookies.get("Bearer");

   const { id } = cookies.get('Bearer');

      let urlCountry = "";

      if (state == "Public") {

         urlCountry = `${process.env.REACT_APP_API_URI}freelancer/get/studies/${idFreeRouter}?area=Profesional`;

      } else if (state == "session") {

         urlCountry = `${process.env.REACT_APP_API_URI}freelancer/get/studies/${id}?area=Profesional`;

      }

      async function fetchData(id: number) {

         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'Authorization': `Bearer ${getSesion.token}`
            }
         };

         try {
            setIsLoading(true);

            setStudies([initialStateStudies])

            const resp = await axios.get(urlCountry, config);

            console.log("Otros estudios ==>", resp.data.data);
            setStudies(resp.data.data);

            setIsLoading(false);

         } catch (err) {
            // Handle Error Here
            console.error(err);
            setIsLoading(false);
         }
      };

   useEffect(() => {

      fetchData(id);

   }, []);


   const monthNames = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];

   function getDate(date:string){
        
     const formatDate = date; 
     var partesFecha = formatDate.split("-");
     // console.log(partesFecha);
  
     const monthR = (Number(partesFecha[1]) - 1);
     const dateWorkExperiences = monthNames[monthR] +' '+ partesFecha[0];
     
     return dateWorkExperiences;
  };

  const deleteStudy = async (id:number) => {
   MySwal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
         let url = `${process.env.REACT_APP_API_URI}freelancer/delete/another-studies/${id}`;
         let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
                }
         };
         try {
            setIsLoading(true);
            const resp = await axios.put(url, {}, config);
            // console.log('deleteStudy', resp.data);
            setIsLoading(false);
            MySwal.fire(
               '¡Registro eliminado!',
               '',
               'success'
            )
            fetchData(id);
            // window.location.reload();
         } catch (err) {
            console.error('Error', err);
            setIsLoading(false);
            MySwal.fire(
               'Error!',
               '¡Algo salió mal!',
               'error'
            );
         }
         console.log("Se eliminó el registro", id);
      }
   });
 }

   return (
      <>

         {
            (state == "session") &&
            <div className="w-full text-center">
               <Link
                  to="/registrar-otros-estudios/0"
                  className="mb-4 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-lg shadow-lg hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                   Agregar
                  {/* <FontAwesomeIcon className="text-white text-lg" icon={faFolderPlus} /> */}
               </Link>
            </div>
         }

         <div className={`p-4 text-white ${animation}`} id="contenedorFormacionAcademica" role="tabpanel" aria-labelledby="contacts-tab">

            <div className="flex flex-wrap">

               {(IsLoading) ?
                  Array(4).fill(0).map((item, index) => {

                     return (
                        <div className="w-full sm:w-1/2 p-2" key={index}>
                           <div className="bg-cyan-900 p-5 rounded-lg ">
                              <h2 className="text-start"><Skeleton count={1} /></h2>
                              <h3 className="text-orange-500 text-start text-xs">
                                 <Skeleton count={2} />
                              </h3>

                              <div className="mt-4">
                                 <div className="text-start">
                                    <h3><Skeleton count={1} /></h3>
                                    <h3><Skeleton count={1} /></h3>
                                 </div>
                              </div>

                           </div>
                        </div>
                     )
                  })
                  :
                  (Studies[0].country_name != "" 
                     ?
                     Studies.map((studied, key) => {
                        return (
                           <>
                              <div className="w-full sm:w-1/2 xl:w-1/3 p-5">
                                    <div className="bg-Light-purple p-6 rounded-md shadow-md h-full">
                                       <div className="flex flex-wrap justify-between items-center mb-4 sm:mb-0">
                                          <div className="flex flex-wrap justify-center md:justify-start items-center w-full md:w-2/3 order-2 md:order-1 mt-5 md:mt-0">
                                             <div className="text-lg font-semibold text-white w-full pr-4">
                                                {studied.institution}
                                             </div>
                                             <div className="text-sm text-white w-full pr-4">
                                                {studied.institution}
                                             </div>
                                             <div className="text-sm text-white w-full pr-4">
                                                {studied.study_degree}
                                             </div>
                                             <div className="text-xs text-orange-500 w-full pr-4 text-start my-2">
                                                Fecha de emisión: {studied.emision_year}
                                                <br />
                                                {studied.state_name}
                                             </div>
                                          </div>
                                          <div className="flex justify-center md:justify-end space-x-2 w-full md:w-1/3 order-1 md:order-2">
                                             {
                                                (state == "session") &&
                                                <>
                                                <Link title="Editar" to={`/registrar-otros-estudios/${studied.id}`} className="bg-Yellow hover:bg-yellow-600 text-white px-4 py-2 rounded-md"><FontAwesomeIcon className="text-white text-lg" icon={faFilePen} /></Link>
                                                <button title="Editar" onClick={
                                                   () => deleteStudy(Number(studied.id))
                                                } className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md flex justify-center items-center"><FontAwesomeIcon className="text-white text-lg" icon={faMinus} /></button>
                                                </>
                                             }
                                             
                                          </div>
                                       </div>
                                    </div>
                              </div>
                           {/* <div key={key} className="w-full sm:w-1/2 p-2">
                              <div className="bg-cyan-900 p-5 rounded-lg relative">
                                 <h2 className="text-start">{studied.institution}</h2>
                                 <h3 className="text-orange-500 text-start text-xs">
                                    Fecha de emisión: {getDate(studied.emision_year)}
                                    <br />
                                    {studied.state_name}
                                 </h3>

                                 <div className="mt-4">
                                    <div className="text-start">
                                       <h3>Tipo de estudio: {studied.study_level}</h3>
                                       <h3>Nombre del titulo: {studied.study_degree}</h3>
                                        <div className="flex justify-center pt-2">
                                          {(studied.external_origin == "1") ? (
                                                <NavLink className="text-white active block py-2 pr-4 pl-3 md:mx-2 md:bg-transparent md:p-0 dark:text-white" to={"/vertificate-verification"}>
                                                   <button className="inline-flex items-center rounded-full bg-Yellow border-0 py-1 px-6 transition-colors focus:outline-none hover:bg-amber-600 lg:text-base md:text-sm mt-4 md:mt-0">
                                                      Ver más
                                                   </button>
                                                </NavLink>
                                          ) : (
                                             <></>
                                          )}
                                       </div> 
                                    </div>
                                 </div>

                                 {
                                    (state == "session") &&
                                    <Link to={`/registrar-otros-estudios/${studied.id}`} className="mt-2 mb-4 inline-block p-1 bg-Yellow text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-amber-500 hover:shadow-lg focus:bg-amber-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-600 active:shadow-lg transition duration-150 ease-in-out absolute top-0 right-2 rounded-lg">
                                       <FontAwesomeIcon className="text-white text-lg" icon={faPenToSquare} />
                                    </Link>
                                 }


                              </div>
                           </div> */}
                           </>
                        )
                     })
                     : <p className="text-center w-full">No se encontraron otros estudios registrados</p>
                  ) 
               }

            </div>
         </div>
      </>
   )
}

export default OtherStudies;

