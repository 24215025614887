import loading from "../../assets/Icons/loading.svg";

const IconLoading = () =>{
   return(
      <div className="flex justify-center items-center text-white rounded-lg">
         <img src={loading} className="iconLoading" />
      </div>
   )
}

export default IconLoading;
