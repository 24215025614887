
import { Link } from "react-router-dom";
import imagenesEditProject from "../../../assets/imgEditProject/imgEditProject";
import 'react-credit-cards/es/styles-compiled.css';
import Skeleton from "react-loading-skeleton";


const SkeletonPerfilPago = () => {

    return (
        <>
            <div className="w-full bg-gradient-to-b from-Light-purple p-5">
                <div className="flex flex-wrap">
                    <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                        <Link to="" onClick={()=>window.history.go(-1)}>
                            <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                        </Link>
                    </div>
                </div>
                <div className="flex flex-wrap my-5 md:p-10">
                    <div className="p-5 bg-turquoise h-full w-full rounded-lg relative">
                        <div>
                            <h3 className="text-Yellow font-bold text-3xl text-center md:text-left mb-8"><Skeleton count={1} width="100%" ></Skeleton> </h3>
                            

                            <div className="flex flex-wrap">

                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>
                                
                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>

                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>

                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>

                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>

                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>

                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>
                                
                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>

                            </div>
                            
                            <div className="flex flex-wrap">
                                
                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>

                                <div className="w-full md:w-1/2 md:pr-4 mb-2 py-6">
                                    <label htmlFor="sex" className="text-white">
                                        <Skeleton count={1} width="100%" ></Skeleton>
                                    </label>
                                    <Skeleton count={1} width="100%" height="90%" ></Skeleton>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SkeletonPerfilPago;