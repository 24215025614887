
import { Link, useNavigate } from "react-router-dom";
import imagenesEditProject from "../../assets/imgEditProject/imgEditProject";

import 'react-credit-cards/es/styles-compiled.css';
import { ChangeEvent, useEffect, useState } from "react";
import { InitialStateCountry } from "../../Interfaces/departmentsAndmuniti";
import axios from "axios";
import { fileItems } from "@syncfusion/ej2-filemanager";
import { validateInputsProfilePay } from "../../function/validateProfilePay";
import { alertLogin } from "../../Interfaces/Login";
import Cookies from "universal-cookie";
import { async } from "q";
import { ProfilePay } from "../../Interfaces/profilepay";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import InputMask from 'react-input-mask';
import SkeletonPerfilPago from "../Skeleton/perfilPago/PerfilPagoSkeleton";

// type
type TypehandleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>




const initialPaymentForm = {
    user: '',
    type: 'J',
    document_type: 'NIT',
    document_number: '',
    first_name: '',
    first_lastname: '',
    direction: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    business_name: '',
    attorney_name: '',
};

const initialStateCountry = {
    code: 0,
    id: 0,
    name: ""
};

const initialStateInput = {
    input: "",
    message: '',
    state: false
}

const PerfilPago = () => {

    const [paymentForm, setPaymentForm] = useState<ProfilePay>(initialPaymentForm);

    const [showInformacion, setshowInformacion] = useState(false);

    const [filterPais, setFilterPais] = useState(false);

    const [typeFilter, setTypeFilter] = useState('J');

    const [Countries, setCountries] = useState<InitialStateCountry[]>([initialStateCountry]);

    const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

    const [typeMask, setTypeMask] = useState('9999-999999-999-9');

    const [typeNumberMask, setTypeNumberMask] = useState('');

    const [aminateSkeletonProfilePay, setAminateSkeletonProfilePay] = useState(true);

    const [typePerson, settypePerson] = useState([
        { id: "J", name: "Persona jurídica" },
        { id: "N", name: "Persona natural" }
    ]);

    const [typeDocument, settypeDocument] = useState([{ id: "NIT", name: "NIT" }]);

    const MySwal = withReactContent(Swal);

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const navigate = useNavigate();


    const handleInputChange = ({ target: { name, value, id, } }: TypehandleInputChange) => {


        if (name == "document_number") setTypeNumberMask(value);

        if (value == "J") {

            settypeDocument([{ id: "NIT", name: "NIT" }]);
            paymentForm.document_type = "NIT";
            setshowInformacion(false);
            setFilterPais(false);

        } else if (value == 'N') {

            setshowInformacion(true);
            setFilterPais(true);

            paymentForm.document_type = "DUI";
            settypeDocument([
                { id: "DUI", name: "DUI" },
                { id: "Pasaporte", name: "Pasaporte" },
                { id: "Residencia", name: "Residencia " }
            ]);

        };

        setPaymentForm({ ...paymentForm, [name]: value });


    };


    const handleUpdatedInfoValidate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let verifyValues = [
            { name: "type", value: paymentForm.type },
            { name: "pay profile", value: paymentForm.document_type },
            { name: "document_number", value: paymentForm.document_number },
            { name: "first_name", value: paymentForm.first_name },
            { name: "first_lastname", value: paymentForm.first_lastname },
            { name: "direction", value: paymentForm.direction },
            { name: "country", value: paymentForm.country },
            { name: "state", value: paymentForm.state },
            { name: "city", value: paymentForm.city },
            { name: "postal_code", value: paymentForm.postal_code },
            { name: "business_name", value: paymentForm.business_name },
            { name: "attorney_name", value: paymentForm.attorney_name },
        ];

        const validatedInputs = validateInputsProfilePay(verifyValues);

        setAlert(validatedInputs);

        const state = validatedInputs.filter(input => input.state === false).map((s) => { return false });

        paymentForm.user = getSesion.id;

        if (paymentForm.type == "J") {

            paymentForm.first_name = null;
            paymentForm.first_lastname = null;

        }else if(paymentForm.type == "N"){

            paymentForm.business_name = null;
            paymentForm.attorney_name = null;

        };

        if (state.length >= 9) sendData();
    };

    const sendData = async () => {

        const urlProfilePay = `${process.env.REACT_APP_API_URI}billing-profiles`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getSesion.token}`
            }
        };

        axios.post(urlProfilePay, paymentForm, config)
            .then(response => {

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 4000
                }).then(() => {
                    navigate('/perfil-empresa');
                });
            })
            .catch(err =>{

                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 5500
                });

            })
    };



    useEffect(() => {

        async function GetInfoCompany() {

            const idUser = cookies.get('Bearer');

            const urlcompany = `${process.env.REACT_APP_API_URI}company/profile/${idUser.id}`;
           
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${getSesion.token}`
                }
            };

            try {

                const resp = await axios.get(urlcompany, config);
                getProfilepay(resp.data.username);

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        GetInfoCompany();

        async function fetchData() {
            const urlCountry = `${process.env.REACT_APP_API_URI}country`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };
            try {

                const resp = await axios.get(urlCountry, config);
                setCountries(resp.data);

            } catch (err) {
                console.error(err);
            }

        };
        fetchData();

        async function getProfilepay(name: String) {

            const urlCompanyProfilePay = `${process.env.REACT_APP_API_URI}billing-profiles/${name}`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${getSesion.token}`
                }
            };

            try{

                const resp = await axios.get(urlCompanyProfilePay, config);

                if (resp.data != "") {
                    if (resp.data.first_name != "") {

                        if (resp.data.type == "J") {

                            let infoprofilePay = {
                                user: resp.data.user_id,
                                type: resp.data.type,
                                document_type: resp.data.document_type,
                                document_number: resp.data.document_number,
                                direction: resp.data.direction,
                                country: resp.data.country_id,
                                state: resp.data.state,
                                city: resp.data.city,
                                postal_code: resp.data.postal_code,
                                business_name: resp.data.business_name,
                                attorney_name: resp.data.attorney_name,
                                first_name: '',
                                first_lastname: ''
                            };

                            settypeDocument([{ id: "NIT", name: "NIT" }]);
                            setshowInformacion(false);
                            setPaymentForm(infoprofilePay);

                            setTimeout(() => { setAminateSkeletonProfilePay(false); }, 2000);

                        } else if (resp.data.type == "N") {

                            let infoprofilePay = {
                                user: resp.data.user_id,
                                type: resp.data.type,
                                document_type: resp.data.document_type,
                                document_number: resp.data.document_number,
                                direction: resp.data.direction,
                                country: resp.data.country_id,
                                state: resp.data.state,
                                city: resp.data.city,
                                postal_code: resp.data.postal_code,
                                business_name: "",
                                attorney_name: "",
                                first_name: resp.data.first_name,
                                first_lastname: resp.data.first_lastname
                            };

                            settypeDocument([
                                { id: "DUI", name: "DUI" },
                                { id: "Pasaporte", name: "Pasaporte" },
                                { id: "Residencia", name: "Residencia " }
                            ]);

                            setshowInformacion(true);
                            setPaymentForm(infoprofilePay);
                            setTimeout(() => { setAminateSkeletonProfilePay(false); }, 2000);

                        }

                    } else {
                        setTimeout(() => { setAminateSkeletonProfilePay(false); }, 2000);
                    };

                } else {

                    setTimeout(() => { setAminateSkeletonProfilePay(false); }, 2000);

                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
                setTimeout(() => { setAminateSkeletonProfilePay(false); }, 2000);

            }
        }

    }, []);

    return (
        <>

            {(aminateSkeletonProfilePay) ? (
                <SkeletonPerfilPago></SkeletonPerfilPago>
            ) : (
                <div className="w-full bg-gradient-to-b from-Light-purple p-5">
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                            <Link to="" onClick={() => window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-5 md:p-10">
                        <div className="p-5 bg-turquoise h-full w-full rounded-lg relative">
                            <form onSubmit={handleUpdatedInfoValidate}>
                                <h3 className="text-Yellow font-bold text-3xl text-center md:text-left mb-8">Perfil pago</h3>


                                <div className="flex flex-wrap">

                                    <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                        <label htmlFor="sex" className="text-white">Seleccione un tipo de persona</label>
                                        <select
                                            name="type"
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                            id="type"
                                            value={paymentForm.type}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" selected> Seleccione facturación </option>
                                            {
                                                typePerson.map((type) => {
                                                    return (
                                                        <option value={type.id}>{type.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            alert.filter(input => input.input == "type" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                        <label htmlFor="sex" className="text-white">Seleccione tipo de identificación</label>
                                        <select
                                            name="document_type"
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                            id="document_type"
                                            value={paymentForm.document_type}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" selected> Seleccione tipo de identificación </option>
                                            {
                                                typeDocument.map((item) => {
                                                    return (
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            alert.filter(input => input.input == "document_type" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full md:w-1/2 md:pr-4 mb-2">

                                        <label className="text-white " htmlFor="nameContact">Número del Documento</label>

                                        {showInformacion ? (

                                            <InputMask
                                                // mask={typeMask}
                                                mask={typeNumberMask !== "" ? '99999999-9' : ""}
                                                type="text"
                                                id="document_number"
                                                className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                name="document_number"
                                                value={paymentForm.document_number}
                                                onChange={handleInputChange}
                                            />

                                        ) : (

                                            <InputMask
                                                // mask={typeMask}
                                                mask={typeNumberMask !== "" ? '9999-999999-999-9' : ""}
                                                type="text"
                                                id="document_number"
                                                className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                name="document_number"
                                                value={paymentForm.document_number}
                                                onChange={handleInputChange}
                                            />

                                        )}

                                        {
                                            alert.filter(input => input.input == "document_number" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                        <label htmlFor="sex" className="text-white">Selecciona tu páis</label>


                                        {filterPais ? (
                                            <select
                                                name="country"
                                                id="country"
                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                value={paymentForm.country}
                                                onChange={handleInputChange}
                                            >
                                                <option value="" selected> Selecciona tu páis </option>
                                                {
                                                    Countries.map((Countries) => {
                                                        return <option value={Countries.id}>{Countries.name}</option>
                                                    })
                                                }
                                            </select>
                                        ) : (
                                            <select
                                                name="country"
                                                id="country"
                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                value={paymentForm.country}
                                                onChange={handleInputChange}
                                            >
                                                <option value="" selected> Selecciona tu páis </option>
                                                {
                                                    Countries.filter(pias => pias.id == 3).map((Countries) => {
                                                        return <option value={Countries.id}>{Countries.name}</option>
                                                    })
                                                }
                                            </select>
                                        )}

                                        {
                                            alert.filter(input => input.input == "country" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                        <label className="text-white " htmlFor="nameContact">Ingresa su estado</label>
                                        <input
                                            type="text"
                                            id="state"
                                            className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                            name="state"
                                            value={paymentForm.state}
                                            onChange={handleInputChange}
                                        />
                                        {
                                            alert.filter(input => input.input == "state" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                        <label className="text-white " htmlFor="nameContact">Ingresa su ciudad</label>
                                        <input
                                            type="text"
                                            id="city"
                                            className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                            name="city"
                                            value={paymentForm.city}
                                            onChange={handleInputChange}
                                        />
                                        {
                                            alert.filter(input => input.input == "city" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                        <label className="text-white " htmlFor="nameContact">Código Postal</label>
                                        <input
                                            type="text"
                                            id="postal_code"
                                            className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                            name="postal_code"
                                            value={paymentForm.postal_code}
                                            onChange={handleInputChange}
                                        />
                                        {
                                            alert.filter(input => input.input == "postal_code" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                        <label className="text-white " htmlFor="nameContact">Dirección</label>
                                        <input
                                            type="text"
                                            id="direction"
                                            className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                            name="direction"
                                            value={paymentForm.direction}
                                            onChange={handleInputChange}
                                        />
                                        {
                                            alert.filter(input => input.input == "direction" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                </div>

                                {showInformacion ? (
                                    <div className="flex flex-wrap">

                                        <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                            <label className="text-white " htmlFor="nameContact">Nombre</label>
                                            <input
                                                type="text"
                                                id="first_name"
                                                className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                name="first_name"
                                                value={paymentForm.first_name}
                                                onChange={handleInputChange}
                                            />
                                            {
                                                alert.filter(input => input.input == "first_name" && input.state === true).map(message => (
                                                    <span className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>

                                        <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                            <label className="text-white " htmlFor="nameContact">Apellido</label>
                                            <input
                                                type="text"
                                                id="first_lastname"
                                                className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                name="first_lastname"
                                                value={paymentForm.first_lastname}
                                                onChange={handleInputChange}
                                            />
                                            {
                                                alert.filter(input => input.input == "first_lastname" && input.state === true).map(message => (
                                                    <span className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>

                                    </div>
                                ) : (

                                    <div className="flex flex-wrap">

                                        <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                            <label className="text-white " htmlFor="nameContact">Nombre de empresa</label>
                                            <input
                                                type="text"
                                                id="business_name"
                                                className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                name="business_name"
                                                value={paymentForm.business_name}
                                                onChange={handleInputChange}
                                            />
                                            {
                                                alert.filter(input => input.input == "business_name" && input.state === true).map(message => (
                                                    <span className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>

                                        <div className="w-full md:w-1/2 md:pr-4 mb-2">
                                            <label className="text-white " htmlFor="nameContact">Nombre del representarte</label>
                                            <input
                                                type="text"
                                                id="attorney_name"
                                                className="form-control w-full block px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                                                name="attorney_name"
                                                value={paymentForm.attorney_name}
                                                onChange={handleInputChange}
                                            />
                                            {
                                                alert.filter(input => input.input == "attorney_name" && input.state === true).map(message => (
                                                    <span className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>

                                    </div>

                                )}
                                <button className="inline-block w-full md:w-1/5 px-7 py-5 md:py-3 bg-Yellow text-white font-medium lg:text-sm xl:text-base leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out text-center">Guardar</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}



        </>
    );
}

export default PerfilPago;