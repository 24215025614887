
import Skeleton from "react-loading-skeleton";
import imagenesEditProject from "../../../assets/imgEditProject/imgEditProject";


const SkeletonPagoPlan = () => {

    return (
        <>
            <div className="w-full bg-gradient-to-b from-Light-purple p-5">
                <div className="flex flex-wrap">
                    <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                        <Skeleton circle count={1} height={60}></Skeleton>
                    </div>
                </div>

                <div className="p-2 my-5 md:p-10 bg-turquoise rounded-lg">
                    <h3 className="text-Yellow font-bold text-3xl text-center md:text-left pt-10 md:pt-0 md:mt-10"><Skeleton count={1} width="30%"></Skeleton> </h3>
                    <form>
                        <div className="flex flex-wrap">

                            <div className="w-full lg:w-1/2 p-2">
                                <div>
                                    <div className="flex flex-wrap">
                                        <div className="w-full md:w-1/1 md:pr-4">
                                            
                                            <div className="my-10">
                                                <Skeleton count={1} width="60%" height={150}></Skeleton>
                                            </div>
                                            
                                            <label className="text-white" htmlFor="card">
                                                <Skeleton count={1} width="100%"></Skeleton>
                                            </label>

                                        </div>

                                        <div className="w-full md:w-1/1 md:pr-4 mb-2">
                                            <label className="text-white" htmlFor="cardholder">
                                                <Skeleton count={1} width="100%"></Skeleton>
                                            </label>
                                            <Skeleton count={1} width="100%" height={40}></Skeleton>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-wrap">
                                    <div className="w-1/2 md:pr-4 mb-2">
                                        <label className="text-white" htmlFor="cardholder">
                                            <Skeleton count={1} width="100%"></Skeleton>
                                        </label>
                                        <Skeleton count={1} width="100%" height={40}></Skeleton>
                                    </div>

                                    <div className="w-1/2 md:pr-4 mb-2">
                                        <label className="text-white" htmlFor="cardholder">
                                            <Skeleton count={1} width="100%"></Skeleton>
                                        </label>
                                        <Skeleton count={1} width="100%" height={40}></Skeleton>
                                    </div>
                                </div>
                                
                                <div className="flex flex-wrap">
                                    <div className="w-1/2 md:pr-4 mb-2">
                                        <label className="text-white" htmlFor="cardholder">
                                            <Skeleton count={1} width="100%"></Skeleton>
                                        </label>
                                        <Skeleton count={1} width="100%" height={40}></Skeleton>
                                    </div>

                                    <div className="w-1/2 md:pr-4 mb-2">
                                        <label className="text-white" htmlFor="cardholder">
                                            <Skeleton count={1} width="100%"></Skeleton>
                                        </label>
                                        <Skeleton count={1} width="100%" height={40}></Skeleton>
                                    </div>
                                </div>




                            </div>
                            <div className="p-5 md:hidden w-full">
                                <hr className="my-10 md:my-0 text-white border w-full" />
                            </div>
                            <div className="w-full lg:w-1/2 p-2 flex items-center justify-center">
                                <section className="text-white body-font overflow-hidden w-full">
                                    <div className="container mx-auto">
                                        <div className="w-full mx-auto flex flex-wrap">
                                            <div className="w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                                                <h2 className="text-sm title-font text-white tracking-widest">
                                                    <Skeleton count={1} width="100%"></Skeleton>
                                                </h2>
                                                <h2 className="text-Yellow text-2xl md:text-3xl font-semibold ">
                                                    <Skeleton count={1} width="100%"></Skeleton>
                                                </h2>
                                                <div className="flex mb-4">
                                                    <h1 className="flex-grow text-white border-b-2 border-Azul-Ticongle py-2 text-lg px-1">
                                                        <Skeleton count={1} width="100%"></Skeleton>
                                                    </h1>
                                                </div>
                                                <p className="leading-relaxed mb-4">
                                                    <Skeleton count={4} width="100%"></Skeleton>
                                                </p>
                                                <div className="border-t border-gray-200 py-5">
                                                    <Skeleton count={1} width="100%"></Skeleton>
                                                </div>
                                                <div className="border-t border-gray-200 py-5">
                                                    <Skeleton count={1} width="100%"></Skeleton>
                                                </div>
                                                <div className="border-t border-gray-200 py-5">
                                                    <Skeleton count={1} width="100%"></Skeleton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </>
    );
}

export default SkeletonPagoPlan;